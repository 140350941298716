import "./EmployeeEvaluationCommentsPopup.css";

import { EmployeeCommentInfo, EmployeeEvaluationComment } from "../components/EmployeeEvaluationComment";
import { useEffect, useMemo, useState } from "react";

import { DateTime } from "luxon";
import { EmployeeEvaluationsService } from "../../../services/EmployeeEvaluationsService";
import { EmptyState } from "../../../../../lib/components/emptystates/EmptyState";
import { ErrorPopup } from "../../../../../lib/components/popup/ErrorPopup";
import { PopupContainer } from "../../../../../lib/layouts/containers/popup-container/PopupContainer";
import { PopupContent } from "../../../../../lib/layouts/containers/popup-content/PopupContent";
import { PopupHeader } from "../../../../../lib/layouts/containers/popup-header/PopupHeader";
import { Spacer } from "../../../../../lib/components/separator/Spacer";
import { translate } from "../../../../../lib/infrastructure/i18n/InternationalizationService";
import { usePopup } from "../../../../../lib/infrastructure/ui/UIServices";
import { useServiceCallPro2 } from "../../../../../lib/hooks/useServiceCall";

export interface IEmployeeEvaluationCommentsPopupProps {
    employeeId: string;
    locationId: string;
    evaluationId: string;
}

var evaluationsSvc = new EmployeeEvaluationsService()

export function EmployeeEvaluationCommentsPopup(props: IEmployeeEvaluationCommentsPopupProps) {
    const openPopup = usePopup();

    const [comments, setComments] = useState<EmployeeCommentInfo[]>();

    const [getItemCommentsCall, getItemCommentsCallIsLoading] = useServiceCallPro2(evaluationsSvc, evaluationsSvc.getEmployeeEvaluationComments);



    useEffect(() => {
        if (!props.employeeId || !props.evaluationId) return;

        getItemCommentsCall(props.locationId, props.employeeId, props.evaluationId)
            .then((r) => {

                var mappedComments: EmployeeCommentInfo[] = r.comments.map((c) => ({
                    text: c.comment,
                    createdBy: c.createdBy?.userDisplayName ?? "-",
                    createdDate: c.createdDate ? DateTime.fromISO(c.createdDate).toFormat("dd/MM/yyyy HH'h'mm") : ""
                }));

                setComments(mappedComments);

            })
            .catch((error) => {
                if (!error) return;
                openPopup(<ErrorPopup>{error.response.data.message}</ErrorPopup>);
            })
    }, [props.locationId, props.employeeId, props.evaluationId]);


    const renderComments = useMemo(() =>
        comments && comments.length > 0 ?
            <>
                <div className="comments">
                    {comments?.map((comment, idx) => <EmployeeEvaluationComment key={idx} comment={comment} />)}
                </div>
                <Spacer mode="vertical" px={20} />
            </>
            :
            <div className="table-empty-loader-container">
                <EmptyState hideSubtitle title={translate("BUSINESSLINES.EVALUATIONS.COMMENTS.Comments")} subtitleMessage={translate("BUSINESSLINES.EVALUATIONS.COMMENTS.NoComments")} />
            </div>
        , [comments])


    return (
        <PopupContainer className="popup-evaluation-comments">
            <PopupHeader
                title={translate("BUSINESSLINES.EVALUATIONS.COMMENTS.ConsultComments")}
            />
            <PopupContent isLoading={getItemCommentsCallIsLoading}>
                {renderComments}
            </PopupContent>
        </PopupContainer>
    );
}