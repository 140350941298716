import { ConfigurationProvider } from "../../../lib/infrastructure/configuration/ConfigurationProvider";
import { EmployeeCareerLadderStepCreateDTO } from "../models/dtos/EmployeeCareerLadderStepCreateDTO";
import { EmployeeCareerLadderTreeResponseDTO } from "../models/dtos/EmployeeCareerLadderTreeResponseDTO";
import { EmployeePositionRecordCreateDTO } from "../models/dtos/EmployeePositionRecordCreateDTO";
import { EmployeePositionRecordDTO } from "../models/dtos/EmployeePositionRecordDTO";
import { EmployeePositionRecordUpdateDTO } from "../models/dtos/EmployeePositionRecordUpdateDTO";
import { EmployeePositionRecordsResponseDTO } from "../models/dtos/EmployeePositionRecordsResponseDTO";
import { HttpClient } from "../../../lib/infrastructure/http/HttpClient";

const employeeCareerLadderMock: EmployeeCareerLadderTreeResponseDTO = {
    nodes: [
        {
            careerLadderStepId: 38,
            role: { roleId: 1, description: 'Operador Logistica I', jobGrade: 3, isDisabled: false, numberOfPositions: 4 },
            roleId: 1,
            startDate: "2024-01-01T00:00:00+00:00",
            endDate: "2024-01-01T00:00:00+00:00",
        },
        {
            careerLadderStepId: 39,
            role: { roleId: 9, description: 'Operador Logistica II', jobGrade: 4, isDisabled: false, numberOfPositions: 0 },
            roleId: 9,
            startDate: "2024-01-01T00:00:00+00:00",
            endDate: "2024-01-01T00:00:00+00:00",
        }
    ]
}

const Route = (path: string) => {
    return ConfigurationProvider.getConfiguration().App.BackendUrl + path;
};

export class EmployeePositionRecordsService {



    getEmployeePositionRecords(locationId: string, employeeValmetId: string): Promise<EmployeePositionRecordsResponseDTO> {
        return HttpClient.sessionRequest<EmployeePositionRecordsResponseDTO>({
            method: "GET",
            url: Route(`/api/v1/Locations/${locationId}/Employees/${employeeValmetId}/PositionRecords`),

        })
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                throw error;
            });
    }


    createEmployeePositionRecord(locationId: string, employeeValmetId: string, request: EmployeePositionRecordCreateDTO): Promise<EmployeePositionRecordDTO> {
        return HttpClient.sessionRequest<EmployeePositionRecordDTO>({
            method: "POST",
            url: Route(`/api/v1/Locations/${locationId}/Employees/${employeeValmetId}/PositionRecords`),
            data: request,
        })
            .then((response) => {
                return response.data
            })
            .catch((error) => {
                throw error;
            });
    }

    

    getEmployeePositionRecordById(locationId: string, employeeValmetId: string,  positionRecordId: string): Promise<EmployeePositionRecordDTO> {
        return HttpClient.sessionRequest<EmployeePositionRecordDTO>({
            method: "GET",
            url: Route(`/api/v1/Locations/${locationId}/Employees/${employeeValmetId}/PositionRecords/${positionRecordId}`),
        })
            .then((response) => {
                return response.data
            })
            .catch((error) => {
                throw error;
            });
    }


    editEmployeePositionRecord(locationId: string, employeeValmetId: string, positionRecordId: string, request: EmployeePositionRecordUpdateDTO): Promise<EmployeePositionRecordDTO> {
        return HttpClient.sessionRequest<EmployeePositionRecordDTO>({
            method: "PUT",
            url: Route(`/api/v1/Locations/${locationId}/Employees/${employeeValmetId}/PositionRecords/${positionRecordId}`),
            data: request,
        })
            .then((response) => {
                return response.data
            })
            .catch((error) => {
                throw error;
            });
    }


    deleteEmployeePositionRecord(locationId: string, employeeValmetId: string, positionRecordId: string): Promise<void> {
        return HttpClient.sessionRequest({
            method: "DELETE",
            url: Route(`/api/v1/Locations/${locationId}/Employees/${employeeValmetId}/PositionRecords/${positionRecordId}`),
        })
            .then((_) => { })
            .catch((error) => {
                throw error;
            });
    }











    getEmployeeCareerLadder(locationId: string, employeeId: string): Promise<EmployeeCareerLadderTreeResponseDTO | null> {
        return new Promise((resolve) => {
            setTimeout(() => {
                resolve({
                    nodes: employeeCareerLadderMock.nodes
                });
            }, 1000);
        });

        // return HttpClient.sessionRequest<EmployeeCareerLadderTreeResponseDTO>({
        //     method: "GET",
        //     url: Route(`/api/v1/Locations/${locationId}/Employees/${employeeId}/CareerLadder`),
        // })
        //     .then((res) => {
        //         if (!res.data) return null;
        //         return res.data;
        //     })
        //     .catch((error) => {
        //         throw error;
        //     });
    }

    addEmployeeNode(locationId: string, requestDto: EmployeeCareerLadderStepCreateDTO): Promise<void> {
        return HttpClient.sessionRequest({
            method: "POST",
            // url: Route(`/api/v1/Locations/${locationId}/Employees/${employeeId}/CareerLadder`),
            data: requestDto,
        })
            .then((_) => { })
            .catch((error) => {
                throw error;
            });
    }

    removeEmployeeNode(locationId: string, employeeId: string, nodeId: string): Promise<void> {
        return HttpClient.sessionRequest({
            method: "DELETE",
            // url: Route(`/api/v1/Locations/${locationId}/Employees/${employeeId}/CareerLadder/${nodeId}`),
        })
            .then((_) => { })
            .catch((error) => {
                throw error;
            });
    }


    /***************************************************************************************************/
    /*********************************************** /ME ***********************************************/

    getMyCareerLadder(locationId: string): Promise<EmployeeCareerLadderTreeResponseDTO | null> {
        return new Promise((resolve) => {
            setTimeout(() => {
                resolve({
                    nodes: employeeCareerLadderMock.nodes
                });
            }, 1000);
        });

        // return HttpClient.sessionRequest<EmployeeCareerLadderTreeResponseDTO>({
        //     method: "GET",
        //     url: Route(`/api/v1/Locations/${locationId}/me/CareerLadder`),
        // })
        //     .then((res) => {
        //         if (!res.data) return null;
        //         return res.data;
        //     })
        //     .catch((error) => {
        //         throw error;
        //     });
    }

}