import { useCallback, useEffect, useState } from "react";
import { useClosePopup, useCreateNotification, usePopup } from "../../../../lib/infrastructure/ui/UIServices";

import { AllValid } from "../../../../common/validators/ValidateFormControls";
import { ErrorPopup } from "../../../../lib/components/popup/ErrorPopup";
import { ExceptionType } from "../../../../lib/infrastructure/http/ExceptionType";
import { FormFieldTextArea } from "../../../../lib/components/form/form-field/FormFieldTextArea";
import { FormFieldTextInput } from "../../../../lib/components/form/form-field/FormFieldTextInput";
import { FormSection } from "../../../../lib/components/form/section/FormSection";
import { FullScreenLoader } from "../../../../lib/components/loader/FullScreenLoader";
import { JobPositionsService } from "../../services/JobPositionsService";
import { MessageBar } from "../../../../lib/components/message-bar/MessageBar";
import { Notification } from "../../../../lib/components/notifications/Notification";
import { PopupActionButtons } from "../../../../lib/layouts/containers/popup-buttons/PopupActionButtons";
import { PopupContainer } from "../../../../lib/layouts/containers/popup-container/PopupContainer";
import { PopupContent } from "../../../../lib/layouts/containers/popup-content/PopupContent";
import { PopupHeader } from "../../../../lib/layouts/containers/popup-header/PopupHeader";
import { PositionUpdateDTO } from "../../models/dtos/PositionUpdadeDTO";
import { Spacer } from "../../../../lib/components/separator/Spacer";
import { translate } from "../../../../lib/infrastructure/i18n/InternationalizationService";
import { trimString } from "../../../../lib/utils/TrimString";
import { useFormControl } from "../../../../lib/components/form/Form";
import { useServiceCallPro } from "../../../../lib/hooks/useServiceCall";
import { validateWhiteSpaceStrings } from "../../../../lib/validators/ValidateWhiteSpaceStrings";

var jobPositionsSvc = new JobPositionsService();

interface IEditJobPositionPopupProps {
  businessLineId: string;
  locationId: string;
  roleId: string;
  jobPositionId: number;
  onCompleted: () => void;
}

export function EditJobPositionPopup(props: IEditJobPositionPopupProps) {
  const closePopup = useClosePopup();
  const openPopup = usePopup();

  const createNotification = useCreateNotification();

  const getJobPositionByIdCall = useServiceCallPro(jobPositionsSvc.getJobPositionByID);
  const updateJobPositionCall = useServiceCallPro(jobPositionsSvc.editJobPosition);

  const nameFormControl = useFormControl<string>({ validators: [validateWhiteSpaceStrings()], enableAutoValidate: true, });
  const goalFormControl = useFormControl<string>({ validators: [validateWhiteSpaceStrings()], enableAutoValidate: true, });
  const descriptionFormControl = useFormControl<string>({ validators: [validateWhiteSpaceStrings()], enableAutoValidate: true, });
  const qualificationFormControl = useFormControl<string>({ validators: [validateWhiteSpaceStrings()], enableAutoValidate: true, });

  const [validationAlert, setValidationAlert] = useState("");


  useEffect(() => {
    if (props.locationId && props.businessLineId && props.roleId && props.jobPositionId) {
      getJobPositionByIdCall
        .invoke(props.locationId, props.businessLineId, props.roleId, "" + props.jobPositionId)
        .then((response) => {
          nameFormControl.setValue(response?.name);
          goalFormControl.setValue(response?.objective);
          descriptionFormControl.setValue(response?.description);
          qualificationFormControl.setValue(response?.qualification);
        })
        .catch((_) => {
          openPopup(<ErrorPopup>{translate("BUSINESSLINES.ROLES.JOBPOSITIONS.POPUP.LoadingJobPositionError")}</ErrorPopup>);
        });
    }
  }, []);


  const editJobPosition = useCallback(() => {
    if (!props.locationId || !props.businessLineId || !props.roleId || !props.jobPositionId || !AllValid(nameFormControl.validate(), goalFormControl.validate(), descriptionFormControl.validate(), qualificationFormControl.validate())) return;

    let request : PositionUpdateDTO = {
      name: trimString(nameFormControl.value),
      objective: trimString(goalFormControl.value),
      description: trimString(descriptionFormControl.value),
      qualification: trimString(qualificationFormControl.value)
    }
    updateJobPositionCall
      .invoke(
        props.locationId,
        props.businessLineId,
        props.roleId,
        props.jobPositionId,
        request
      )
      .then((_) => {
        props.onCompleted();
        closePopup();
        createNotification(
          <Notification
            type="success"
            title={translate("COMMON.SYSTEMPOPUPS.Success")}
            text={translate("BUSINESSLINES.ROLES.JOBPOSITIONS.POPUP.EditJobPositionSuccess")}
          />
        );
      })
      .catch((error) => {
        if (!error) return;

        const errorMessage = error?.response?.data?.message || translate("ERRORS.UnknownError");
        const enumCode = error?.response?.data?.enumCode;

        if (enumCode === ExceptionType.ERROR_VALIDATION_NAME_ALREADY_EXISTS) {
            setValidationAlert(errorMessage);
        } else {
            openPopup(<ErrorPopup>{errorMessage}</ErrorPopup>);
        }
      });
  }, [
    props.locationId,
    props.businessLineId,
    props.roleId,
    props.jobPositionId,
    props.onCompleted,
    nameFormControl,
    goalFormControl,
    descriptionFormControl,
    qualificationFormControl,
    closePopup,
    openPopup,
    createNotification,
    updateJobPositionCall
  ]);

  return (
    <PopupContainer className="popup-edit-job-position">
      {updateJobPositionCall.isLoading ? <FullScreenLoader /> : null}

      <PopupHeader
        title={translate("BUSINESSLINES.ROLES.JOBPOSITIONS.POPUP.EditJobPosition")}
      />
      <PopupContent isLoading={getJobPositionByIdCall.isLoading}>
        <FormSection>
          <FormFieldTextInput
            formControl={nameFormControl}
            label={translate("BUSINESSLINES.ROLES.JOBPOSITIONS.INFOANDFORM.Name")}
            placeholder={translate("BUSINESSLINES.ROLES.JOBPOSITIONS.INFOANDFORM.NamePlaceholder")}
          />
          <FormFieldTextArea
            formControl={goalFormControl}
            label={translate("BUSINESSLINES.ROLES.JOBPOSITIONS.INFOANDFORM.Goal")}
          // placeholder={translate("BUSINESSLINES.ROLES.JOBPOSITIONS.INFOANDFORM.GoalPlaceholder")}
          />
          <FormFieldTextArea
            formControl={descriptionFormControl}
            label={translate("BUSINESSLINES.ROLES.JOBPOSITIONS.INFOANDFORM.Description")}
          // placeholder={translate("BUSINESSLINES.ROLES.JOBPOSITIONS.INFOANDFORM.DescriptionPlaceholder")}
          />
          <FormFieldTextArea
            formControl={qualificationFormControl}
            label={translate("BUSINESSLINES.ROLES.JOBPOSITIONS.INFOANDFORM.Qualification")}
          // placeholder={translate("BUSINESSLINES.ROLES.JOBPOSITIONS.INFOANDFORM.QualificationPlaceholder")}
          />
        </FormSection>

        {validationAlert && <>
          <Spacer mode={"vertical"} px="30" />
          <MessageBar type="error" text={validationAlert} />
        </>}

        <Spacer mode={"vertical"} px="30" />
        <PopupActionButtons
          buttons={[
            {
              text: translate("COMMON.Cancel"),
              type: "tertiary",
              onClick: () => closePopup(),
            },
            {
              text: translate("COMMON.Save"),
              type: "primary",
              onClick: editJobPosition,
            },
          ]}
        />
      </PopupContent>
    </PopupContainer>
  );
}
