import "./SelectSkillsPopup.css";

import { useCallback, useEffect, useMemo, useState } from "react";
import { useClosePopup, usePopup } from "../../../lib/infrastructure/ui/UIServices";

import { ColumnDefinition } from "../../../lib/components/table/TableInterfaces";
import { ConditionalRender } from "../../../lib/functional/ConditionalRender";
import { ReactComponent as DeleteIcon } from "../../../lib/assets/icons/remover.svg";
import { ErrorPopup } from "../../../lib/components/popup/ErrorPopup";
import { FormFieldSelectSingle } from "../../../lib/components/form/form-field/FormFieldSelectSingle";
import { FormSection } from "../../../lib/components/form/section/FormSection";
import { MessageBar } from "../../../lib/components/message-bar/MessageBar";
import { PopupActionButtons } from "../../../lib/layouts/containers/popup-buttons/PopupActionButtons";
import { PopupContainer } from "../../../lib/layouts/containers/popup-container/PopupContainer";
import { PopupContent } from "../../../lib/layouts/containers/popup-content/PopupContent";
import { PopupHeader } from "../../../lib/layouts/containers/popup-header/PopupHeader";
import { ResponsiveDataTable } from "../../../lib/components/table/ResponsiveDataTable";
import { ScalableIconButton } from "../../../lib/components/buttons/ScalableIconButton";
import { SkillsCategoryDTO } from "../pages/CreateEvaluationMatrixPage";
import { SkillsService } from "../../admin/skills/services/SkillsService";
import { Spacer } from "../../../lib/components/separator/Spacer";
import { ValidationErrorMessage } from "../../../lib/infrastructure/http/APIError";
import { translate } from "../../../lib/infrastructure/i18n/InternationalizationService";
import { useFormControl } from "../../../lib/components/form/Form";
import { useServiceCallPro } from "../../../lib/hooks/useServiceCall";

interface ISelectSkillsPopupProps {
    onCompletedOperations: (selectedCategories: SkillsCategoryDTO[]) => void;
    locationId: string | undefined;
    businessLineId: string | undefined;
    matrixId?: string | undefined;
    selectedCategories: SkillsCategoryDTO[];
}

const labelCategorySelector = (item: SkillsCategoryDTO) => item.name;
const idCategorySelector = (item: SkillsCategoryDTO) => item.id;


var skillsSvc = new SkillsService();

export function SelectSkillsPopup(props: ISelectSkillsPopupProps) {
    const closePopup = useClosePopup();
    const openPopup = usePopup();

    const getActiveSkillsCategoriesCall = useServiceCallPro(skillsSvc.getSkillCategories);

    const [categories, setCategories] = useState<SkillsCategoryDTO[]>([])
    const [selectedCategories, setSelectedCategories] = useState<SkillsCategoryDTO[]>(props.selectedCategories)
    const [validationAlert, setValidationAlert] = useState<ValidationErrorMessage>({ showAlert: false });

    const categoriesFormControl = useFormControl<SkillsCategoryDTO>({});

    /****************************
    * DATA REQUESTS
    *****************************/

    const getActiveSkillsCategories = useCallback(() => {
        if (!props.locationId) return;

        getActiveSkillsCategoriesCall.invoke("" + props.locationId, { filterByIsActive: true })
            .then((data) => {
                const mappedOptions: SkillsCategoryDTO[] = data.skillsCategories.map(category => ({
                    id: category.id,
                    name: category.categoryName,
                    skills: [],
                }));
                setCategories(mappedOptions);
            })
            .catch((error) => {
                if (!error) return;
                openPopup(<ErrorPopup>{error.response.data.message}</ErrorPopup>);
            });
    }, [props.locationId, getActiveSkillsCategoriesCall, openPopup]);


    const getFilteredCategories = useCallback(() => {
        return categories.filter(category => !selectedCategories.some(selectedCategory => selectedCategory.id === category.id));
    }, [categories, selectedCategories]);


    /****************************
     * DATA MANIPULATION EFFECTS
     *****************************/

    useEffect(() => {
        getActiveSkillsCategories();
    }, []);


    useEffect(() => {
        if (categoriesFormControl.value) {
            handleClickAddCategoryBtn(categoriesFormControl.value as SkillsCategoryDTO);
        }
    }, [categoriesFormControl.value]);


    /****************************
     * USER ACTIONS
     *****************************/

    const handleClickAddCategoryBtn = useCallback((category: SkillsCategoryDTO) => {
        setSelectedCategories(prevSelectedCategories => {
            if (!prevSelectedCategories.some(c => c.id === category.id)) {
                return [...prevSelectedCategories, category];
            }
            return prevSelectedCategories;
        });
        categoriesFormControl.setValue(undefined);
        setValidationAlert({ showAlert: false });
    }, [categoriesFormControl]);


    const handleClickRemoveCategoryBtn = useCallback((category: SkillsCategoryDTO) => {
        setSelectedCategories(selectedCategories.filter(c => c.id !== category.id));
    }, [selectedCategories]);


    const handleSelectClicked = useCallback(() => {
        if (selectedCategories.length) {
            props.onCompletedOperations(selectedCategories);
            closePopup();
        }
        else
            setValidationAlert({
                showAlert: true,
                message: translate("BUSINESSLINES.MATRIX.ERRORS.NoCategoriesSelected"),
            });
    }, [props.onCompletedOperations, selectedCategories, closePopup]);


    /****************************
     * CSS & HTML
     *****************************/

    const columns: ColumnDefinition<SkillsCategoryDTO>[] = useMemo(() => [
        {
            cellRenderProp: (category) => category.name,
            headerRender: translate("BUSINESSLINES.MATRIX.INFOANDFORM.Name"),
            isMobilePrimaryCell: true,
        },
        {
            cellRenderProp: (category) => {
                return (
                    <ScalableIconButton
                        icon={<DeleteIcon />}
                        onClick={() => handleClickRemoveCategoryBtn(category)}
                        size={24}
                    />)
            },
            columnKey: "delete",
            width: "24px",
            isMobileHeaderIcon: true,
        },
    ], [handleClickRemoveCategoryBtn]);


    return (
        <PopupContainer className="popup-select-skills-categories">
            <PopupHeader title={translate("BUSINESSLINES.MATRIX.POPUP.SelectSkills")} />
            <PopupContent isLoading={getActiveSkillsCategoriesCall.isLoading}>
                <FormSection isInputGap>
                    <FormFieldSelectSingle
                        formControl={categoriesFormControl}
                        label={translate("BUSINESSLINES.MATRIX.INFOANDFORM.Skills")}
                        placeholder={translate("BUSINESSLINES.MATRIX.INFOANDFORM.SkillsPlaceholder")}
                        options={getFilteredCategories() || []}
                        labelSelector={labelCategorySelector}
                        idSelector={idCategorySelector}
                        maxHeightOptions={"16rem"}
                    />
                </FormSection>
                <Spacer mode={"vertical"} px="12" />
                {selectedCategories.length ?
                    <ResponsiveDataTable
                        columnDefinitions={columns}
                        items={selectedCategories}
                        totalitems={selectedCategories.length}
                    // isLoading={} 
                    /> : null}
                <ConditionalRender if={validationAlert.showAlert}>
                    <Spacer mode="vertical" px="10" />
                    <MessageBar type="error" text={"" + validationAlert.message} />
                </ConditionalRender>
                <Spacer mode={"vertical"} px="30" />
                <PopupActionButtons
                    buttons={[
                        {
                            text: translate("COMMON.Cancel"),
                            type: "tertiary",
                            onClick: () => closePopup(),
                        },
                        {
                            text: translate("COMMON.Select"),
                            type: "primary",
                            onClick: handleSelectClicked,
                        },
                    ]}
                />
            </PopupContent>
        </PopupContainer>
    );
}
