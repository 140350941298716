import { EmployeeActionItemState, EmployeeActionItemStateInfo, EmployeeActionItemStateTranslationTerms, EvaluationActionItemStateAsEnum, getActionItemStates } from "../../../models/dtos/EmployeeActionItemState";
import { useCallback, useEffect, useMemo } from "react";
import { useClosePopup, useCreateNotification, usePopup } from "../../../../../lib/infrastructure/ui/UIServices";

import { AllValid } from "../../../../../common/validators/ValidateFormControls";
import { DateTime } from "luxon";
import { EmployeeActionItemUpdateDTO } from "../../../models/dtos/EmployeeActionItemUpdateDTO";
import { EmployeeActionItemsService } from "../../../services/EmployeeActionItemsService";
import { EmployeeFollowUpItemStatusInfo } from "../../../models/dtos/EmployeeFollowUpItemStatus";
import { ErrorPopup } from "../../../../../lib/components/popup/ErrorPopup";
import { FormFieldDatePicker } from "../../../../../lib/components/form/form-field/FormFieldDatePicker";
import { FormFieldSelectSingle } from "../../../../../lib/components/form/form-field/FormFieldSelectSingle";
import { FormFieldTextArea } from "../../../../../lib/components/form/form-field/FormFieldTextArea";
import { FormFieldTextInput } from "../../../../../lib/components/form/form-field/FormFieldTextInput";
import { FormSection } from "../../../../../lib/components/form/section/FormSection";
import { FullScreenLoader } from "../../../../../lib/components/loader/FullScreenLoader";
import { Notification } from "../../../../../lib/components/notifications/Notification";
import { PopupActionButtons } from "../../../../../lib/layouts/containers/popup-buttons/PopupActionButtons";
import { PopupContainer } from "../../../../../lib/layouts/containers/popup-container/PopupContainer";
import { PopupContent } from "../../../../../lib/layouts/containers/popup-content/PopupContent";
import { PopupHeader } from "../../../../../lib/layouts/containers/popup-header/PopupHeader";
import { Spacer } from "../../../../../lib/components/separator/Spacer";
import { hasOneElementSelected } from "../../../../../lib/validators/ValidateSelectSingle";
import { translate } from "../../../../../lib/infrastructure/i18n/InternationalizationService";
import { useFormControl } from "../../../../../lib/components/form/Form";
import { useServiceCallPro } from "../../../../../lib/hooks/useServiceCall";
import { validateDateTime } from "../../../../../lib/validators/ValidateDateTime";
import { validateWhiteSpaceStrings } from "../../../../../lib/validators/ValidateWhiteSpaceStrings";
import { getActionItemByKey, getActionItemOptions, SelectOption } from "../../../../../common/helpers/getActionItemOptions";

interface IEditEmployeeActionItemPopupProps {
    employeeValmetId: string;
    locationId: string;
    actionItemId: string;
    onCompletedOperations: () => void;
}

var svc = new EmployeeActionItemsService();

const labelItemStatusSelector = (item: EmployeeActionItemStateInfo) => item.description;
const idItemStatusSelector = (item: EmployeeActionItemStateInfo) => item.id;

const labelNameSelector = (item: SelectOption) => item.description;
const idNameSelector = (item: SelectOption) => item.id;


export function EditEmployeeActionItemPopup(props: IEditEmployeeActionItemPopupProps) {
    const closePopup = useClosePopup();
    const openPopup = usePopup();
    const createNotification = useCreateNotification();

    const getEmployeeActionItemByIdCall = useServiceCallPro(svc.getEmployeeActionItemById);
    const editActionItemCall = useServiceCallPro(svc.updateEmployeeActionItem);

    const actionItemOptions = useMemo(() => {
        return getActionItemOptions()
    }, []);

    const itemNameForm = useFormControl<SelectOption>({
        validators: [hasOneElementSelected()],
        enableAutoValidate: true,
    });

    const itemDescriptionForm = useFormControl<string>({
        validators: [validateWhiteSpaceStrings()],
        enableAutoValidate: true,
    });

    const itemStartDateForm = useFormControl<DateTime>({
        validators: [validateDateTime()],
        enableAutoValidate: false
    });

    const itemEndDateForm = useFormControl<DateTime>({
        validators: [],
        enableAutoValidate: false
    });

    const itemStateForm = useFormControl<EmployeeFollowUpItemStatusInfo>({
        validators: [hasOneElementSelected()],
        enableAutoValidate: true,
    });


    const itemsStatus = useMemo(() => {
        return getActionItemStates();
    }, []);

    /* 
        const itemsStatus = useMemo(() => {
            return getAnalysisRequestStatus().filter(status => status.id !== itemStateForm.value?.id);
        }, [itemStateForm.value?.id]); */


    /****************************
    * DATA REQUESTS
    *****************************/

    const getFollowUpItemSummaryById = useCallback(() => {
        if (!props.actionItemId) return;

        getEmployeeActionItemByIdCall.invoke(props.locationId, props.employeeValmetId, props.actionItemId)
            .then((response) => {
                if (!response) { return; }

                itemNameForm.setValue(getActionItemByKey(response.itemName))
                itemDescriptionForm.setValue(response.itemDescription)

                if (response.startDate) { itemStartDateForm.setValue(DateTime.fromISO(response.startDate)); }
                if (response.endDate) { itemEndDateForm.setValue(DateTime.fromISO(response.endDate)); }

                if (response.state) {
                    itemStateForm.setValue({
                        id: response.state.toString(),
                        description: translate(EmployeeActionItemStateTranslationTerms[response.state])
                    })
                }
            })
            .catch((error) => {
                if (!error) return;
                openPopup(<ErrorPopup>{error.response.data.message}</ErrorPopup>);
            });
    }, [props.actionItemId,
        props.employeeValmetId,
        props.locationId,
        getEmployeeActionItemByIdCall,
        itemNameForm,
        itemDescriptionForm,
        itemStateForm,
        itemStartDateForm,
        itemEndDateForm,
        openPopup]);



    /****************************
    * DATA MANIPULATION EFFECTS
    *****************************/




    useEffect(() => {
        getFollowUpItemSummaryById();
    }, []);





    /****************************
    * USER ACTIONS
    *****************************/

    const handleSaveClicked = useCallback(() => {

        if (!AllValid(itemNameForm.validate(), itemDescriptionForm.validate(), itemStartDateForm.validate(), itemStateForm.validate())) return;

        const requestDTO: EmployeeActionItemUpdateDTO = {
            itemName: "" + itemNameForm.value?.id,
            itemDescription: "" + itemDescriptionForm.value,
            startDate: "" + itemStartDateForm.value?.toISO(),
            endDate: itemEndDateForm.value?.toISO() || null,
            state: itemStateForm.value ? EvaluationActionItemStateAsEnum[parseInt(itemStateForm?.value?.id)] : EmployeeActionItemState.REGISTERED
        };

        editActionItemCall
            .invoke(props.locationId, props.employeeValmetId, props.actionItemId, requestDTO)
            .then(() => {
                createNotification(<Notification
                    type="success"
                    title={translate("COMMON.SYSTEMPOPUPS.Success")}
                    text={translate("EMPLOYEES.ITEMS.POPUPS.EditEditFollowUpItemSuccess")}
                />);
                props.onCompletedOperations();
                closePopup();

            })
            .catch((error) => {
                if (!error) return;
                openPopup(<ErrorPopup>{error.response.data.message}</ErrorPopup>);
            });
    }, [closePopup,
        createNotification,
        openPopup,
        props.onCompletedOperations,
        editActionItemCall.invoke,
        props.employeeValmetId,
        props.locationId,
        props.actionItemId,
        itemNameForm.value,
        itemDescriptionForm.value,
        itemStartDateForm.value,
        itemEndDateForm.value,
        itemStateForm.value,
        itemNameForm.validate,
        itemDescriptionForm.validate,
        itemStartDateForm.validate,
        itemEndDateForm.validate,
        itemStateForm.validate]);




    /****************************
     * CSS & HTML
     *****************************/

    return <PopupContainer className="popup-edit-employee-certification">
        {editActionItemCall.isLoading ? <FullScreenLoader /> : null}
        <PopupHeader title={translate("EMPLOYEES.ITEMS.POPUPS.EditFollowUpItem")} />
        <PopupContent isLoading={getEmployeeActionItemByIdCall.isLoading}>
            <FormSection isInputGap childrenFlexDirColumn>

                <FormFieldSelectSingle
                        formControl={itemNameForm}
                        label={translate("EMPLOYEES.ITEMS.INFOANDFORM.Name")}
                        placeholder={translate("COMMON.SelectAnOption")}
                        options={actionItemOptions}
                        labelSelector={labelNameSelector}
                        idSelector={idNameSelector}
                        maxHeightOptions={"16rem"}
                    />
                    
                <FormFieldTextArea
                    formControl={itemDescriptionForm}
                    label={translate("EMPLOYEES.ITEMS.INFOANDFORM.Description")}
                    placeholder={translate("EMPLOYEES.ITEMS.INFOANDFORM.DescriptionPlaceholder")}
                />
                <FormSection isInputGap>
                    <FormFieldDatePicker
                        formControl={itemStartDateForm}
                        label={translate("EMPLOYEES.ITEMS.INFOANDFORM.StartDate")}
                        placeholder={translate("EMPLOYEES.ITEMS.INFOANDFORM.StartDatePlaceholder")}
                        maxDate={itemEndDateForm.value}
                    />
                    <FormFieldDatePicker
                        formControl={itemEndDateForm}
                        label={translate("EMPLOYEES.ITEMS.INFOANDFORM.ConclusionDate")}
                        placeholder={translate("EMPLOYEES.ITEMS.INFOANDFORM.ConclusionDatePlaceholder")}
                        minDate={itemStartDateForm.value}
                    />
                </FormSection>
                <FormFieldSelectSingle
                    formControl={itemStateForm}
                    label={translate("EMPLOYEES.ITEMS.INFOANDFORM.Status")}
                    placeholder={translate("EMPLOYEES.ITEMS.INFOANDFORM.StatusPlaceholder")}
                    options={itemsStatus}
                    labelSelector={labelItemStatusSelector}
                    idSelector={idItemStatusSelector}
                    maxHeightOptions={"16rem"}
                />
            </FormSection>
            <Spacer mode={"vertical"} px="30" />
            <PopupActionButtons
                buttons={[
                    {
                        text: translate("COMMON.Cancel"),
                        type: "tertiary",
                        onClick: () => closePopup(),
                    },
                    {
                        text: translate("COMMON.Save"),
                        type: "primary",
                        onClick: handleSaveClicked,
                    },
                ]}
            />
        </PopupContent>
    </PopupContainer>;
}