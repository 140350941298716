import { Optional, ValidatorFunction } from "../../lib/components/form/Form";
import { translate } from "../../lib/infrastructure/i18n/InternationalizationService";


export function validateNullStrings(): ValidatorFunction<Optional<string>> {
    return (value: string | undefined) => {
        if (!value || value?.trim() === "") {
            return translate("COMMON.FORM.VALIDATIONS.CannotBeEmpty");
        }
        return null;
    };
}