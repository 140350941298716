import './ConsultEmployeeEvaluationHistoryPopup.css';

import { useCallback, useEffect, useState } from "react";
import { useClosePopup, useCreateNotification, usePopup } from '../../../../../lib/infrastructure/ui/UIServices';

import { EmployeeEvaluationHistory } from '../components/EmployeeEvaluationHistory';
import { EmployeeEvaluationHistoryDetailsDTO } from '../../../models/dtos/EmployeeEvaluationHistoryDetailsDTO';
import { EmployeeEvaluationsService } from '../../../services/EmployeeEvaluationsService';
import { EmptyState } from '../../../../../lib/components/emptystates/EmptyState';
import { ErrorPopup } from '../../../../../lib/components/popup/ErrorPopup';
import { Loader } from '../../../../../lib/components/loader/Loader';
import { PopupContainer } from '../../../../../lib/layouts/containers/popup-container/PopupContainer';
import { PopupContent } from '../../../../../lib/layouts/containers/popup-content/PopupContent';
import { PopupHeader } from '../../../../../lib/layouts/containers/popup-header/PopupHeader';
import { translate } from '../../../../../lib/infrastructure/i18n/InternationalizationService';
import { useFormControl } from '../../../../../lib/components/form/Form';
import { useServiceCallPro } from '../../../../../lib/hooks/useServiceCall';

var svc = new EmployeeEvaluationsService();

export interface IConsultEmployeeEvaluationHistoryPopupProps {
    employeeId: string;
    evaluationId: string;
    locationId: string;
}

export function ConsultEmployeeEvaluationHistoryPopup(props: IConsultEmployeeEvaluationHistoryPopupProps) {
    const openPopup = usePopup();
    const closePopup = useClosePopup();
    const createNotification = useCreateNotification();

    const [evaluationHistory, setEvaluationHistory] = useState<EmployeeEvaluationHistoryDetailsDTO[]>([]);
    const [totalItems, setTotalItems] = useState<number>();

    const [currentPage, setCurrentPage] = useState(0);
    const [itemsPerPage, setItemsPerPage] = useState(10);
    const [searchWord, setSearchWord] = useState<string>();

    const [timer, setTimer] = useState<NodeJS.Timeout>();

    var searchBoxFormControl = useFormControl<string>({
        isDisabled: false,
        enableAutoValidate: false,
    });



    /*****************
     * SERVICE CALLS 
    ****************/
    const getEvaluationHistoryCall = useServiceCallPro(svc.getEmployeeEvaluationHistory);


    /****************************
    * DATA REQUESTS
    *****************************/

    const handleOnPageAndItemsChanged = useCallback(
        (items: number, currentPage: number) => {
            setItemsPerPage(items);
            setCurrentPage(currentPage);
        },
        [setItemsPerPage, setCurrentPage]
    );


    const getEvaluationHistory = useCallback(() => {

        if (!props.employeeId || !props.evaluationId) return;


        getEvaluationHistoryCall.invoke(props.locationId, props.employeeId, props.evaluationId)
            .then((data) => {
                setEvaluationHistory(data.history);
                setTotalItems(data.totalItems);
            })
            .catch((error) => {
                if (!error) return;
                openPopup(<ErrorPopup>{error.response.data.message}</ErrorPopup>);
            });
    }, [props.locationId, props.employeeId, props.evaluationId, getEvaluationHistoryCall, openPopup]);



    /****************************
     * DATA MANIPULATION EFFECTS
    *****************************/

    useEffect(() => {
        getEvaluationHistory();
    }, [props.employeeId, props.evaluationId]);


    /****************************
     * USER ACTIONS
    *****************************/

    /****************************
     * CSS & HTML
    *****************************/

    return (<PopupContainer className="popup-evaluation-history">
        <PopupHeader title={translate("BUSINESSLINES.EVALUATIONS.INFOANDFORM.History")} />
        <PopupContent>
            {getEvaluationHistoryCall.isLoading
                ? <Loader />
                : evaluationHistory && evaluationHistory.length > 0
                    ? <EmployeeEvaluationHistory history={evaluationHistory}/>
                    : <div className="table-empty-loader-container">
                        <EmptyState hideSubtitle title={translate("BUSINESSLINES.EVALUATIONS.INFOANDFORM.History")} subtitleMessage={translate("BUSINESSLINES.EVALUATIONS.HISTORY.NoHistoryLines")} />
                    </div>}
        </PopupContent>
    </PopupContainer>)
}