import "./SuggestNextRolePopup.css";

import { useCallback, useEffect, useMemo, useState } from "react";
import { useClosePopup, usePopup } from "../../../../lib/infrastructure/ui/UIServices";

import { CareerLadderService } from "../../../career-ladder/services/CareerLadderService";
import { CareerLadderStepTreeNodeDTO } from "../../../career-ladder/models/dtos/CareerLadderStepTreeNodeDTO";
import { CareerLadderTree } from "../../../career-ladder/components/tree-node/CareerLadderTree";
import { ColumnDefinition } from "../../../../lib/components/table/TableInterfaces";
import { CriteriaSummaryDTO } from "../../../roles/models/dtos/CriteriaSummaryDTO";
import { CriteriasService } from "../../../roles/services/CriteriasService";
import { ErrorPopup } from "../../../../lib/components/popup/ErrorPopup";
import { Loader } from "../../../../lib/components/loader/Loader";
import { PopupActionButtons } from "../../../../lib/layouts/containers/popup-buttons/PopupActionButtons";
import { PopupContainer } from "../../../../lib/layouts/containers/popup-container/PopupContainer";
import { PopupContent } from "../../../../lib/layouts/containers/popup-content/PopupContent";
import { PopupHeader } from "../../../../lib/layouts/containers/popup-header/PopupHeader";
import { ResponsiveDataTable } from "../../../../lib/components/table/ResponsiveDataTable";
import { Spacer } from "../../../../lib/components/separator/Spacer";
import { translate } from "../../../../lib/infrastructure/i18n/InternationalizationService";
import { useServiceCallPro } from "../../../../lib/hooks/useServiceCall";

var svc = new CareerLadderService();
const criteriasSvc = new CriteriasService();

interface ISuggestNextRolePopupProps {
    locationId: string;
    businessLineId: string;
    currentRoleId: number;
    onCompletedOperations: (nextRoleId: number) => void;
}


const getNextRoles = (nodes: CareerLadderStepTreeNodeDTO[], targetRoleId: number): CareerLadderStepTreeNodeDTO | undefined => {
    for (let index = 0; index < nodes.length; index++) {
        const element = nodes[index];

        if (element.roleId === targetRoleId) return element

        var result = getNextRoles(element.children, targetRoleId);

        if (result !== undefined) return result;
    }

    return undefined;
}

export function SuggestNextRolePopup(props: ISuggestNextRolePopupProps) {
    const openPopup = usePopup();
    const closePopup = useClosePopup();

    const [currentRole, setCurrentRole] = useState<CareerLadderStepTreeNodeDTO[]>([])
    const [criterias, setCriterias] = useState<CriteriaSummaryDTO[]>([]);
    const [showCriterias, setShowCriterias] = useState<boolean>(false);
    const [nextRoleId, setNextRoleId] = useState<number>();

    const getCareerLadderCall = useServiceCallPro(svc.getCareerLadder);
    const getCriteriasCall = useServiceCallPro(criteriasSvc.getCriterias);

    /****************************
    * DATA REQUESTS
    *****************************/

    const getCriterias = useCallback((selectedNextRoleId: number) => {
        if (!props.locationId || !props.businessLineId || !selectedNextRoleId) return;

        getCriteriasCall
            .invoke(props.locationId, props.businessLineId, "" + selectedNextRoleId)
            .then((res) => {
                if (res) {
                    setCriterias(res.criterias);
                }
            })
            .catch((error) => {
                if (!error) return;
                openPopup(<ErrorPopup>{error.response.data.message}</ErrorPopup>);
            });
    }, [props.locationId, props.businessLineId, openPopup, setCriterias, getCriteriasCall.invoke]);


    const getCareerLadderByRoleId = useCallback(() => {
        if (!props.locationId || !props.businessLineId || !props.currentRoleId) return;

        getCareerLadderCall.invoke(props.locationId, props.businessLineId)
            .then((data) => {
                if (data) {
                    const role = getNextRoles(data.nodes, props.currentRoleId)
                    setCurrentRole(role ? [role] : [])
                }
            })
            .catch((error) => {
                if (!error) return;
                openPopup(<ErrorPopup>{error.response.data.message}</ErrorPopup>);
            });
    }, [props.locationId, props.businessLineId, props.currentRoleId, getCareerLadderCall.invoke, openPopup, setCurrentRole]);


    /****************************
     * DATA MANIPULATION EFFECTS
     *****************************/

    useEffect(() => {
        getCareerLadderByRoleId();
    }, []);


    /****************************
     * USER ACTIONS
     *****************************/

    const handleNextRoleSelected = useCallback((selectedNextRoleId: number) => {
        getCriterias(selectedNextRoleId);
        setNextRoleId(selectedNextRoleId);
        setShowCriterias(true);
    }, [getCriterias]);


    const handleSuggestNextRole = useCallback(() => {
        if (!nextRoleId) return;
        props.onCompletedOperations(nextRoleId);
        closePopup();
    }, [nextRoleId, props.onCompletedOperations, closePopup]);


    /****************************
     * CSS & HTML
     *****************************/

    const columns: ColumnDefinition<CriteriaSummaryDTO>[] = useMemo(
        () => [
            {
                cellRenderProp: (v) => v.name,
                headerRender: translate("BUSINESSLINES.ROLES.CRITERIAS.INFOANDFORM.Name"),
                width: "35%",
                isMobilePrimaryCell: true,
            },
            {
                cellRenderProp: (v) => v.description,
                headerRender: translate("BUSINESSLINES.ROLES.CRITERIAS.INFOANDFORM.Description"),
                isMobilePrimaryCell: true,
            },
        ], []);


    const renderNextRoleCriterias = useMemo(() => {
        if (!showCriterias) return null;

        return <div className="next-role-criterias">
            <div className="subtitle">{translate("BUSINESSLINES.ROLES.CRITERIAS.Criterias")}</div>
            {getCriteriasCall.isLoading ?
                <div className="criterias-loader"><Loader /></div>
                : (
                    criterias.length ?
                        <ResponsiveDataTable
                            items={criterias || []}
                            columnDefinitions={columns}
                            totalitems={0}
                        />
                        : <div className="small-copy empty-criterias">{translate("BUSINESSLINES.EVALUATIONS.POPUPS.EmptyCriterias")}</div>
                )}
        </div>
    }, [columns, showCriterias, criterias, getCriteriasCall.isLoading]);


    return (
        <PopupContainer className="popup-suggest-next-role" desktopPxWidth="750">
            <PopupHeader title={translate("BUSINESSLINES.EVALUATIONS.POPUPS.NextRole")} />
            <PopupContent isLoading={getCareerLadderCall.isLoading}>
                <div>{translate("BUSINESSLINES.EVALUATIONS.POPUPS.NextRolePopupText")}</div>
                <Spacer mode={"vertical"} px="18" />
                <CareerLadderTree
                    careerLadderNodes={currentRole}
                    locationId={props.locationId}
                    businessLineId={props.businessLineId}
                    onCompleteOperations={() => { }}
                    isNextRoleCareerLadder
                    handleNextRoleSelected={handleNextRoleSelected}
                />
                {renderNextRoleCriterias}
                <Spacer mode={"vertical"} px="30" />
                <PopupActionButtons
                    buttons={[
                        {
                            text: translate("COMMON.Cancel"),
                            type: "tertiary",
                            onClick: () => closePopup(),
                        },
                        {
                            text: translate("BUSINESSLINES.EVALUATIONS.INFOANDFORM.SuggestNextRole"),
                            type: "primary",
                            onClick: handleSuggestNextRole,
                            isDisabled: !nextRoleId
                        },
                    ]}
                />
            </PopupContent>
        </PopupContainer>
    );
}
