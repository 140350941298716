import * as qs from "qs";

import { ConfigurationProvider } from "../../../lib/infrastructure/configuration/ConfigurationProvider";
import { HttpClient } from "../../../lib/infrastructure/http/HttpClient";
import { RoleCreateDTO } from "../models/dtos/RoleCreateDTO";
import { RoleDetailsDTO } from "../models/dtos/RoleDetailsDTO";
import { RoleUpdateDTO } from "../models/dtos/RoleUpdateDTO";
import { RoleUpdateStateDTO } from "../models/dtos/RoleUpdateStateDTO";
import { RolesQueryOptions } from "../models/dtos/RolesQueryOptions";
import { RolesResponseDTO } from "../models/dtos/RolesResponseDTO";

const Route = (path: string) => {
    return ConfigurationProvider.getConfiguration().App.BackendUrl + path;
};



export class RolesService {

    getRoles(locationId: string, businessLineId: string, query: RolesQueryOptions, abortSignal: AbortSignal | undefined): Promise<RolesResponseDTO | null> {

        return HttpClient.sessionRequest<RolesResponseDTO>({
            method: "GET",
            url: Route(`/api/v1/Locations/${locationId}/BusinessLines/${businessLineId}/Roles`),
            signal: abortSignal,
            params: query,
            paramsSerializer: (params) => {
                return qs.stringify(params, { arrayFormat: "repeat" });
            },
        })
            .then((response) => {

                if (!response.data) return null;
                return response.data
            })
            .catch((error) => {
                throw error;
            });
    }


    createRole(locationId: string, businessLineId: string, requestDto: RoleCreateDTO): Promise<void> {

        return HttpClient.sessionRequest<RoleDetailsDTO>({
            method: "POST",
            url: Route(`/api/v1/Locations/${locationId}/BusinessLines/${businessLineId}/Roles`),
            data: requestDto,
        })
            .then((_) => { })
            .catch((error) => {
                throw error;
            });
    }


    getRoleDetailsById(locationId: string, businessLineId: string, roleId: string): Promise<RoleDetailsDTO | null> {

        return HttpClient.sessionRequest<RoleDetailsDTO>({
            method: "GET",
            url: Route(`/api/v1/Locations/${locationId}/BusinessLines/${businessLineId}/Roles/${roleId}`),
        })
            .then((response) => {

                if (!response.data) return null;

                return response.data;
            })
            .catch((error) => {
                throw error;
            });
    }


    editRole(locationID: string, businessLineId: string, roleId: number, requestDto: RoleUpdateDTO): Promise<void> {
        return HttpClient.sessionRequest({
            method: "PUT",
            url: Route(`/api/v1/Locations/${locationID}/BusinessLines/${businessLineId}/Roles/${roleId}`),
            data: requestDto
        })
            .then((_) => { })
            .catch((error) => {
                throw error;
            });
    }


    updateRoleState(locationID: string, businessLineId: string, roleId: number, isToDisableRole: boolean): Promise<void> {

        var request: RoleUpdateStateDTO = {
            state: isToDisableRole
        };

        return HttpClient.sessionRequest({
            method: "PUT",
            url: Route(`/api/v1/Locations/${locationID}/BusinessLines/${businessLineId}/Roles/${roleId}/State`),
            data: request
        })
            .then((_) => {
            })
            .catch((error) => {
                throw error;
            });
    }
}
