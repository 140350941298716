export enum EvaluationFollowUpItemStatus {
    REGISTERED = 1,
    INPROGRESS = 2,
    COMPLETED = 3,
    CANCELED = 4,
}


export const EvaluationFollowUpItemStatusTranslationTerms: { [key: string]: string } = {
    1: "COMMON.STATUS.Registered",
    2: "COMMON.STATUS.InProgress",
    3: "COMMON.STATUS.Completed",
    4: "COMMON.STATUS.Canceled",
}


export const EvaluationFollowUpItemStatusAsEnum: { [key: number]: EvaluationFollowUpItemStatus } = {
    1: EvaluationFollowUpItemStatus.REGISTERED,
    2: EvaluationFollowUpItemStatus.INPROGRESS,
    3: EvaluationFollowUpItemStatus.COMPLETED,
    4: EvaluationFollowUpItemStatus.CANCELED,
};