import "./JobPositionDetailsPopup.css";

import { useCallback, useEffect, useState } from "react";
import { useClosePopup, usePopup } from "../../../../lib/infrastructure/ui/UIServices";

import { ErrorPopup } from "../../../../lib/components/popup/ErrorPopup";
import { FormSection } from "../../../../lib/components/form/section/FormSection";
import { InfoBlock } from "../../../../lib/components/info-block/InfoBlock";
import { JobPositionsService } from "../../services/JobPositionsService";
import { PopupActionButtons } from "../../../../lib/layouts/containers/popup-buttons/PopupActionButtons";
import { PopupContainer } from "../../../../lib/layouts/containers/popup-container/PopupContainer";
import { PopupContent } from "../../../../lib/layouts/containers/popup-content/PopupContent";
import { PopupHeader } from "../../../../lib/layouts/containers/popup-header/PopupHeader";
import { PositionDetailsDTO } from "../../models/dtos/PositionDetailsDTO";
import { Spacer } from "../../../../lib/components/separator/Spacer";
import { translate } from "../../../../lib/infrastructure/i18n/InternationalizationService";
import { useGlobalLocation } from "../../../../lib/infrastructure/location/LocationServices";
import { useServiceCallPro } from "../../../../lib/hooks/useServiceCall";

const jobPositionsSvc = new JobPositionsService();

interface IJobPositionDetailsPopupProps {
    roleId: string;
    businessLineId: string;
    positionId: number;
}

export function JobPositionDetailsPopup(props: IJobPositionDetailsPopupProps) {
    const closePopup = useClosePopup();
    const openPopup = usePopup();
    const locationID = useGlobalLocation();

    const [jobPosition, setJobPosition] = useState<PositionDetailsDTO>();


    const getJobPositionCall = useServiceCallPro(jobPositionsSvc.getJobPositionByID);

    /****************************
    * DATA REQUESTS
    *****************************/
    
    const getPosition = useCallback(() => {
        if (!locationID && !props.businessLineId && !props.roleId && !props.positionId) return;

        getJobPositionCall
            .invoke("" + locationID, props.businessLineId, ""+props.roleId, "" + props.positionId)
            .then((res) => {
                if (res) {
                    setJobPosition(res);
                }
            })
            .catch((error) => {
                if (!error) return;
                openPopup(<ErrorPopup>{error.response.data.message}</ErrorPopup>);
            });
    }, [locationID, props.businessLineId, props.roleId, openPopup, setJobPosition, getJobPositionCall]);


    /****************************
     * DATA MANIPULATION EFFECTS
     *****************************/
    useEffect(() => {
        getPosition();
    }, []);

    /****************************
     * USER ACTIONS
     *****************************/


    /****************************
     * CSS & HTML
     *****************************/



    return (
        <PopupContainer className="popup-job-position-details">
            <PopupHeader title={jobPosition?.name||""} />
            <PopupContent>
                <FormSection childrenFlexDirColumn>
                    <InfoBlock
                        label={translate("BUSINESSLINES.ROLES.JOBPOSITIONS.INFOANDFORM.Goal")}
                        value={jobPosition?.objective||""}
                    />
                    <InfoBlock
                        label={translate("BUSINESSLINES.ROLES.JOBPOSITIONS.INFOANDFORM.Description")}
                        value={jobPosition?.description||""}
                    />
                    <InfoBlock
                        label={translate("BUSINESSLINES.ROLES.JOBPOSITIONS.INFOANDFORM.Qualification")}
                        value={jobPosition?.qualification||""}
                    />
                </FormSection>
                <Spacer mode={"vertical"} px="30" />
                <PopupActionButtons
                    buttons={[
                        {
                            text: translate("COMMON.Close"),
                            type: "tertiary",
                            onClick: () => closePopup(),
                        }
                    ]}
                />
            </PopupContent>
        </PopupContainer>
    );
}
