export enum EvaluationState {
    READY_TO_EVALUATE = 1,
    IN_PROGRESS = 2,
    CONFIRMED = 3,
    CALIBRATED = 4,
    CONCLUDED = 5,
    CANCELED = 6,
    REJECTED = 7
}


export const EvaluationStatusTranslationTerms: { [key: string]: string } = {
    1: "COMMON.STATUS.ReadyToEvaluate",
    2: "COMMON.STATUS.InProgress",
    3: "COMMON.STATUS.Confirmed",
    4: "COMMON.STATUS.Calibrated",
    5: "COMMON.STATUS.Concluded",
    6: "COMMON.STATUS.Canceled",
    7: "COMMON.STATUS.Rejected",
}


export const EvaluationStatusAsEnum: { [key: number]: EvaluationState } = {
    1: EvaluationState.READY_TO_EVALUATE,
    2: EvaluationState.IN_PROGRESS,
    3: EvaluationState.CONFIRMED,
    4: EvaluationState.CALIBRATED,
    5: EvaluationState.CONCLUDED,
    6: EvaluationState.CANCELED,
    7: EvaluationState.REJECTED,
};



export const EvaluationFollowUpItemStatusAsEnum: { [key: number]: EvaluationState } = {
    1: EvaluationState.READY_TO_EVALUATE,
    2: EvaluationState.IN_PROGRESS,
    3: EvaluationState.CONFIRMED,
    4: EvaluationState.CALIBRATED,
    5: EvaluationState.CONCLUDED,
    6: EvaluationState.CANCELED,
    7: EvaluationState.REJECTED,
};