import { AllValid } from "../../../../common/validators/ValidateFormControls";
import { DateTime } from "luxon";
import { EvaluationFollowUpItemDTO } from "../../models/EvaluationFollowUpItemDTO";
import { EvaluationFollowUpItemStatus } from "../../models/EvaluationFollowUpItemStatus";
import { FormFieldDatePicker } from "../../../../lib/components/form/form-field/FormFieldDatePicker";
import { FormFieldTextArea } from "../../../../lib/components/form/form-field/FormFieldTextArea";
import { FormFieldTextInput } from "../../../../lib/components/form/form-field/FormFieldTextInput";
import { FormSection } from "../../../../lib/components/form/section/FormSection";
import { PopupActionButtons } from "../../../../lib/layouts/containers/popup-buttons/PopupActionButtons";
import { PopupContainer } from "../../../../lib/layouts/containers/popup-container/PopupContainer";
import { PopupContent } from "../../../../lib/layouts/containers/popup-content/PopupContent";
import { PopupHeader } from "../../../../lib/layouts/containers/popup-header/PopupHeader";
import { Spacer } from "../../../../lib/components/separator/Spacer";
import { translate } from "../../../../lib/infrastructure/i18n/InternationalizationService";
import { trimString } from "../../../../lib/utils/TrimString";
import { useCallback, useMemo } from "react";
import { useClosePopup, usePopup, usePopupRef } from "../../../../lib/infrastructure/ui/UIServices";
import { useFormControl } from "../../../../lib/components/form/Form";
import { validateDateTime } from "../../../../lib/validators/ValidateDateTime";
import { validateWhiteSpaceStrings } from "../../../../lib/validators/ValidateWhiteSpaceStrings";
import { EmployeeActionItemDetailsDTO } from "../../../employees/models/dtos/EmployeeActionItemDetailsDTO";
import { EmployeeActionItemCreateDTO } from "../../../employees/models/dtos/EmployeeActionItemCreateDTO";
import { EmployeeActionItemState, EmployeeActionItemStateInfo, EvaluationActionItemStateAsEnum, getActionItemStates } from "../../../employees/models/dtos/EmployeeActionItemState";
import { EvaluationsService } from "../../services/EvaluationsService";
import { useServiceCallPro } from "../../../../lib/hooks/useServiceCall";
import { ErrorPopup } from "../../../../lib/components/popup/ErrorPopup";
import { FullScreenLoader } from "../../../../lib/components/loader/FullScreenLoader";
import { hasOneElementSelected } from "../../../../lib/validators/ValidateSelectSingle";
import { EmployeeFollowUpItemStatusInfo } from "../../../employees/models/dtos/EmployeeFollowUpItemStatus";
import { FormFieldSelectSingle } from "../../../../lib/components/form/form-field/FormFieldSelectSingle";
import { getActionItemOptions, SelectOption } from "../../../../common/helpers/getActionItemOptions";

interface IAddFollowUpItemPopupProps {
    locationdId: string;
    businessLineId?: string;
    evaluationPeriodId?: string;
    matrixId?: string;
    evaluationId?: string;
    onCompletedOperations: (followUpItem: EmployeeActionItemDetailsDTO) => void;
}

const labelItemStatusSelector = (item: EmployeeActionItemStateInfo) => item.description;
const idItemStatusSelector = (item: EmployeeActionItemStateInfo) => item.id;
const labelNameSelector = (item: SelectOption) => item.description;
const idNameSelector = (item: SelectOption) => item.id;

var svc = new EvaluationsService();

export function AddFollowUpItemPopup(props: IAddFollowUpItemPopupProps) {
    const closePopup = useClosePopup();
    const openPopup = usePopup();

    const actionItemOptions = useMemo(() => {
        return getActionItemOptions()
    }, []);

    const actionItemStates = useMemo(() => {
        return getActionItemStates();
    }, []);

    const nameFormControl =  useFormControl<SelectOption>({
        validators: [hasOneElementSelected()],
        enableAutoValidate: true,
    });

    const descriptionFormControl = useFormControl<string>({
        validators: [validateWhiteSpaceStrings()],
        enableAutoValidate: true,
    });

    const startDateFormControl = useFormControl<DateTime>({
        validators: [validateDateTime()],
        enableAutoValidate: false,
    });

    const endDateFormControl = useFormControl<DateTime>({
        validators: [],
        enableAutoValidate: false
    });

    const itemStateForm = useFormControl<EmployeeFollowUpItemStatusInfo>({
        validators: [hasOneElementSelected()],
        enableAutoValidate: true,
    });

    const createActionItemCall = useServiceCallPro(svc.createEvaluationActionItem);



    /****************************
     * DATA MANIPULATION EFFECTS
     *****************************/


    /****************************
     * USER ACTIONS
     *****************************/

    const handleAddClicked = useCallback(() => {

        if (!AllValid(nameFormControl.validate(), descriptionFormControl.validate(), startDateFormControl.validate(), itemStateForm.validate())) return;
        if (!props.locationdId || !props.businessLineId || !props.evaluationId || !props.evaluationPeriodId) return;


        const requestDTO: EmployeeActionItemCreateDTO = {
            itemName: "" + nameFormControl.value?.id,
            itemDescription: "" + descriptionFormControl.value,
            startDate: "" + startDateFormControl.value?.toISO(),
            endDate: endDateFormControl.value?.toISO() || null,
            state: itemStateForm.value?.id ? EvaluationActionItemStateAsEnum[parseInt(itemStateForm?.value?.id)] : EmployeeActionItemState.REGISTERED
        };

        createActionItemCall.invoke(props.locationdId, props.businessLineId, props.evaluationPeriodId, "" + props.matrixId, props.evaluationId, requestDTO).then((res) => {
            props.onCompletedOperations(res);
            closePopup();
        }).catch((error) => {
            if (!error) return;
            openPopup(<ErrorPopup>{error.response.data.message}</ErrorPopup>);
        })
    }, [nameFormControl.validate,
    descriptionFormControl.validate,
    startDateFormControl.validate,
    itemStateForm.validate,
    nameFormControl.value,
    descriptionFormControl.value,
    startDateFormControl.value,
    endDateFormControl.value,
    itemStateForm.value,
    props.locationdId,
    props.businessLineId,
    props.evaluationId,
    props.evaluationPeriodId,
    createActionItemCall.invoke,
    props.onCompletedOperations,
        closePopup,
        openPopup]);





    return (
        <PopupContainer className="popup-add-follow-up-item">
            <PopupHeader title={translate("EMPLOYEES.ITEMS.POPUPS.AddFollowUpItem")} />
            <PopupContent>
                {createActionItemCall.isLoading && <FullScreenLoader />}
                <FormSection childrenFlexDirColumn>

                    <FormFieldSelectSingle
                        formControl={nameFormControl}
                        label={translate("BUSINESSLINES.EVALUATIONS.INFOANDFORM.FollowUpItemName")}
                        placeholder={translate("COMMON.SelectAnOption")}
                        options={actionItemOptions}
                        labelSelector={labelNameSelector}
                        idSelector={idNameSelector}
                        maxHeightOptions={"16rem"}
                    />

                    <FormFieldTextArea
                        formControl={descriptionFormControl}
                        label={translate("BUSINESSLINES.EVALUATIONS.INFOANDFORM.FollowUpDescription")}
                        placeholder={translate("BUSINESSLINES.EVALUATIONS.INFOANDFORM.FollowUpDescriptionPlaceholder")}
                    />
                    <FormSection isInputGap>
                        <FormFieldDatePicker
                            formControl={startDateFormControl}
                            label={translate("BUSINESSLINES.EVALUATIONS.INFOANDFORM.FollowUpStartDate")}
                            placeholder={translate("BUSINESSLINES.EVALUATIONS.INFOANDFORM.FollowUpStartDatePlaceholder")}
                        />
                        <FormFieldDatePicker
                            formControl={endDateFormControl}
                            label={translate("EMPLOYEES.ITEMS.INFOANDFORM.ConclusionDate")}
                            placeholder={translate("EMPLOYEES.ITEMS.INFOANDFORM.ConclusionDatePlaceholder")}
                            minDate={startDateFormControl.value}
                        />
                    </FormSection>
                    <FormFieldSelectSingle
                        formControl={itemStateForm}
                        label={translate("EMPLOYEES.ITEMS.INFOANDFORM.Status")}
                        placeholder={translate("EMPLOYEES.ITEMS.INFOANDFORM.StatusPlaceholder")}
                        options={actionItemStates}
                        labelSelector={labelItemStatusSelector}
                        idSelector={idItemStatusSelector}
                        maxHeightOptions={"16rem"}
                    />
                </FormSection>
                <Spacer mode={"vertical"} px="30" />
                <PopupActionButtons
                    buttons={[
                        {
                            text: translate("COMMON.Cancel"),
                            type: "tertiary",
                            onClick: () => closePopup(),
                        },
                        {
                            text: translate("COMMON.Add"),
                            type: "primary",
                            onClick: handleAddClicked,
                        },
                    ]}
                />
            </PopupContent>
        </PopupContainer>
    );
}
