import { APISkillSummaryDTOToDomainMapper } from "../models/mappers/APISkillSummaryDTOToDomainMapper";
import { APISkillsCategoryMapper } from "../models/mappers/APISkillsCategoryMapper";
import { APISkillsCategoryToDomainMapper } from "../models/mappers/APISkillsCategoryToDomainMapper";
import { CategorySkillsResponseDTO } from "../models/dtos/CategorySkillsResponseDTO";
import { ConfigurationProvider } from "../../../../lib/infrastructure/configuration/ConfigurationProvider";
import { GetSkillCategoriesByMultipleIdsDTO } from "../models/dtos/GetSkillCategoriesByMultipleIdsDTO";
import { HttpClient } from "../../../../lib/infrastructure/http/HttpClient";
import { SkillCategoriesIncludeSkillsDTO } from "../models/dtos/SkillCategoriesIncludeSkillsDTO";
import { SkillCategoriesQueryOptions } from "../models/dtos/SkillsCategoriesQueryOptions";
import { SkillCategoriesResponse } from "../models/domain/SkillCategoriesResponse";
import { SkillCategoriesResponseDTO } from "../models/dtos/SkillCategoriesResponseDTO";
import { SkillCreateDTO } from "../models/dtos/SkillCreateDTO";
import { SkillDomainDTO } from "../models/domain/SkillDomainDTO";
import { SkillSummaryDTO } from "../models/dtos/SkillSummaryDTO";
import { SkillUpdateStateDTO } from "../models/dtos/SkillUpdateStateDTO";
import { SkillsCategory } from "../models/domain/SkillsCategory";
import { SkillsCategoryCreateDTO } from "../models/dtos/SkillsCategoryCreateDTO";
import { SkillsCategorySummaryDTO } from "../models/dtos/SkillsCategorySummaryDTO";
import { SkillsCategoryUpdateDTO } from "../models/dtos/SkillsCategoryUpdateDTO";
import { SkillsCategoryUpdateStateDTO } from "../models/dtos/SkillsCategoryUpdateStateDTO";
import { SkillsQueryOptions } from "../models/dtos/SkillsQueryOptions";
import { SkillsResponseDomain } from "../models/domain/SkillsResponseDomain";
import { UpdateSkillRequestDTO } from "../models/dtos/UpdateSkillRequestDTO";
import qs from "qs";

const Route = (path: string) => {
    return ConfigurationProvider.getConfiguration().App.BackendUrl + path;
};

export class SkillsService {

    /***************************** SKILLS CATEGORIES *****************************/


    getSkillCategories(locationId: string, queryOptions?: SkillCategoriesQueryOptions): Promise<SkillCategoriesResponse> {

        return HttpClient.sessionRequest<SkillCategoriesResponseDTO>({
            method: "GET",
            url: Route(`/api/v1/Locations/${locationId}/SkillCategories`),
            params: queryOptions,
            paramsSerializer: (params) => {
                return qs.stringify(params, { arrayFormat: "repeat" });
            },
        })
            .then((response) => {
                return ({
                    skillsCategories: response.data.skillsCategories.map(c => APISkillsCategoryMapper(c)),
                    totalItems: response.data.totalItems
                })
            })
            .catch((error) => {
                throw error;
            });
    }




    getMultipleCategoriesIncludeSkills(locationId: string, categoryIds: GetSkillCategoriesByMultipleIdsDTO): Promise<SkillCategoriesIncludeSkillsDTO> {


        return HttpClient.sessionRequest<SkillCategoriesIncludeSkillsDTO>({
            method: "POST",
            url: Route(`/api/v1/Locations/${locationId}/SkillCategories/ByCategoryIds`),
            data: categoryIds
        })
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                throw error;
            });
    }





    getSkillsCategoryById(locationId: string, categoryId: string): Promise<SkillsCategory> {
        return HttpClient.sessionRequest<SkillsCategorySummaryDTO>({
            method: "GET",
            url: Route(`/api/v1/Locations/${locationId}/SkillCategories/${categoryId}`),
        })
            .then((response) => { return APISkillsCategoryToDomainMapper(response.data); })
            .catch((error) => { throw error; });
    }



    createSkillsCategory(locationId: string, requestDto: SkillsCategoryCreateDTO): Promise<void> {
        return HttpClient.sessionRequest({
            method: "POST",
            url: Route(`/api/v1/Locations/${locationId}/SkillCategories`),
            data: requestDto,
        })
            .then((_) => { })
            .catch((error) => {
                throw error;
            });
    }




    uploadSkillCategoriesFile(locationId: string, file: File): Promise<void> {

        var formData = new FormData();
        formData.append("file", file)

        return HttpClient.sessionRequest({
            method: "POST",
            headers: {
                "Content-Type": "multipart/form-data",
                "Accept": "application/json",
            },
            url: Route(`/api/v1/Locations/${locationId}/SkillCategories/FromXLSX`),
            data: formData
        })
            .then((_) => { })
            .catch((error) => {
                throw error;
            });
    }


    updateSkillsCategory(locationId: string, skillCategoryID: number, categoryName: string, isOptional: boolean): Promise<void> {
        var request: SkillsCategoryUpdateDTO = {
            name: categoryName,
            isOptional: isOptional
        };

        return HttpClient.sessionRequest({
            method: "PUT",
            url: Route(`/api/v1/Locations/${locationId}/SkillCategories/${skillCategoryID}`),
            data: request
        })
            .then((_) => {
            })
            .catch((error) => {
                throw error;
            });
    }




    updateSkillsCategoryState(locationId: string, skillCategoryID: number, newState: boolean): Promise<string> {

        var request: SkillsCategoryUpdateStateDTO = {
            state: newState
        };

        return HttpClient.sessionRequest<string>({
            method: "PUT",
            url: Route(`/api/v1/Locations/${locationId}/SkillCategories/${skillCategoryID}/State`),
            data: request
        })
            .then((response) => {
                return response.status.toString()
            })
            .catch((error) => {
                throw error;
            });
    }



    /***************************** CATEGORY SKILLS *****************************/

    getSkills(locationId: string, skillsCategoryID: string, queryOptions?: SkillsQueryOptions): Promise<SkillsResponseDomain> {


        return HttpClient.sessionRequest<CategorySkillsResponseDTO>({
            method: "GET",
            url: Route(`/api/v1/Locations/${locationId}/SkillCategories/${skillsCategoryID}/Skills`),
            params: queryOptions,
            paramsSerializer: (params) => {
                return qs.stringify(params, { arrayFormat: "repeat" });
            },
        })
            .then((response) => {
                return ({
                    skills: response.data.categorySkills.map(c => APISkillSummaryDTOToDomainMapper(c)),
                    total: response.data.totalItems
                })
            })
            .catch((error) => {
                throw error;
            });
    }




    getSkillById(locationId: string, categoryId: string, skillId: number): Promise<SkillDomainDTO> {
        return HttpClient.sessionRequest<SkillSummaryDTO>({
            method: "GET",
            url: Route(`/api/v1/Locations/${locationId}/SkillCategories/${categoryId}/Skills/${skillId}`),
        })
            .then((response) => { return APISkillSummaryDTOToDomainMapper(response.data) })
            .catch((error) => { throw error; });
    }


    // getCategoriesSkills(locationId: string, categoriesIDs: number[]): Promise<SkillDomainDTO> {
    //     return HttpClient.sessionRequest<SkillSummaryDTO>({
    //         method: "GET",
    //         url: Route(`/api/v1/Locations/${locationId}/SkillCategories/${categoryId}/Skills/${skillId}`),
    //     })
    //         .then((response) => { return APISkillSummaryDTOToDomainMapper(response.data) })
    //         .catch((error) => { throw error; });
    // }



    createSkill(locationId: string, skillsCategoryID: string, requestDto: SkillCreateDTO): Promise<void> {
        return HttpClient.sessionRequest({
            method: "POST",
            url: Route(`/api/v1/Locations/${locationId}/SkillCategories/${skillsCategoryID}/Skills`),
            data: requestDto,
        })
            .then((_) => { })
            .catch((error) => {
                throw error;
            });
    }






    updateSkill(locationId: string, categoryId: string, skillId: number, skillName: string, skillDescription: string): Promise<void> {
        var request: UpdateSkillRequestDTO = {
            name: skillName,
            description: skillDescription
        };

        return HttpClient.sessionRequest({
            method: "PUT",
            url: Route(`/api/v1/Locations/${locationId}/SkillCategories/${categoryId}/Skills/${skillId}`),
            data: request
        })
            .then((_) => {
            })
            .catch((error) => {
                throw error;
            });
    }



    updateSkillState(locationId: string, skillCategoryID: string, skillID: number, newState: boolean): Promise<void> {

        var request: SkillUpdateStateDTO = {
            state: newState
        };

        return HttpClient.sessionRequest<string>({
            method: "PUT",
            url: Route(`/api/v1/Locations/${locationId}/SkillCategories/${skillCategoryID}/Skills/${skillID}/State`),
            data: request
        })
            .then((_) => { })
            .catch((error) => {
                throw error;
            });
    }
}