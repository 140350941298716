import "./CreateEvaluationMatrixPage.css";

import { SkillLevelDTO, SkillsCategoryDTO } from "./CreateEvaluationMatrixPage";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useCreateNotification, usePopup, useWindowResize } from "../../../lib/infrastructure/ui/UIServices";

import { AllValid } from "../../../common/validators/ValidateFormControls";
import { Button } from "../../../lib/components/buttons/Button";
import { ChecklistCard } from "../components/ChecklistCard";
import { DateTime } from "ts-luxon";
import { ReactComponent as EditIcon } from "../../../lib/assets/icons/editar.svg";
import { ErrorPopup } from "../../../lib/components/popup/ErrorPopup";
import { EvaluationMatricesService } from "../services/EvaluationMatricesService";
import { EvaluationMatrixCategorySkillsUpdateDTO } from "../models/dtos/edit-matrix/EvaluationMatrixCategorySkillsUpdateDTO";
import { EvaluationMatrixSkillRoleRelationDTO } from "../models/dtos/EvaluationMatrixSkillRoleRelationDTO";
import { EvaluationMatrixSummaryDTO } from "../models/dtos/EvaluationMatrixSummaryDTO";
import { EvaluationMatrixUpdateDTO } from "../models/dtos/edit-matrix/EvaluationMatrixUpdateDTO";
import { FormFieldTextInput } from "../../../lib/components/form/form-field/FormFieldTextInput";
import { FormSection } from "../../../lib/components/form/section/FormSection";
import { FullScreenLoader } from "../../../lib/components/loader/FullScreenLoader";
import { IconButton } from "../../../lib/components/buttons/IconButton";
import { InfoBlock } from "../../../lib/components/info-block/InfoBlock";
import { Notification } from "../../../lib/components/notifications/Notification";
import { PageLayout } from "../../../lib/layouts/main-content/PageLayout";
import { PagePermissions } from "../../../lib/infrastructure/authorization/PagePermissions";
import { Permission } from "../../../models/api/gate/Permission";
import { RoleSummary } from "../../roles/models/domain/RoleSummary";
import { ReactComponent as RolesIcon } from "../../../lib/assets/icons/roles.svg";
import { ReactComponent as SaveIcon } from "../../../lib/assets/icons/save.svg";
import { SelectRolesPopup } from "../popups/SelectRolesPopup";
import { SelectSkillsPopup } from "../popups/SelectSkillsPopup";
import { SkillsCategoryCard } from "../components/SkillsCategoryCard";
import { ReactComponent as SkillsIcon } from "../../../lib/assets/icons/skills.svg";
import { SkillsService } from "../../admin/skills/services/SkillsService";
import { Tag } from "../../../lib/components/tag/Tag";
import { TitledCardContainer } from "../../../lib/layouts/containers/card/TitledCardContainer";
import { translate } from "../../../lib/infrastructure/i18n/InternationalizationService";
import { trimString } from "../../../lib/utils/TrimString";
import { useFormControl } from "../../../lib/components/form/Form";
import { useGlobalBusinessLine } from "../../../lib/infrastructure/business-line/BusinessLineServices";
import { useGlobalLocation } from "../../../lib/infrastructure/location/LocationServices";
import { useHasPermissions } from "../../../lib/infrastructure/authorization/useHasLocationPermissions";
import { useParams } from "react-router-dom";
import { useServiceCallPro } from "../../../lib/hooks/useServiceCall";
import { useUpdatePageData } from "../../../lib/infrastructure/navigation/hooks/useUpdatePageData";
import { validateWhiteSpaceStrings } from "../../../lib/validators/ValidateWhiteSpaceStrings";

const topPermissions = [Permission.GLOBAL_ADMIN, Permission.LOCATION_ADMIN, Permission.HUMAN_RESOURCES];


var matrixesSvc = new EvaluationMatricesService();
const skillsSvc = new SkillsService();


export function EditEvaluationMatrixPage() {

  const { isLoadingPermissions, hasPermission } = useHasPermissions(topPermissions);
  const { id: businessLineID, matrixId, versionId } = useParams();
  const windowResize = useWindowResize();

  const locationID = useGlobalLocation();
  const useSelectedBusinessLine = useGlobalBusinessLine();
  const createNotification = useCreateNotification();
  const updateRouteDate = useUpdatePageData();
  const openPopup = usePopup();



  const getMatrixSkillsRolesByVersionIdCall = useServiceCallPro(matrixesSvc.getMatrixSkillsRolesByVersionId);
  const getSkillsByCategoryIdsCall = useServiceCallPro(skillsSvc.getMultipleCategoriesIncludeSkills);
  const getMatrixSummaryByVersionId = useServiceCallPro(matrixesSvc.getEvaluationMatrixSummaryByVersionId);
  const updateEvaluationMatrixCall = useServiceCallPro(matrixesSvc.updateEvaluationMatrix);




  const [evaluationMatrixSkills, setEvaluationMatrixSkills] = useState<SkillsCategoryDTO[]>([]);
  const [evaluationMatrixRoles, setEvaluationMatrixRoles] = useState<RoleSummary[]>([]);
  const [evaluationMatrixChecklist, setEvaluationMatrixChecklist] = useState<string[]>([]);
  const [skillLevels, setSkillLevels] = useState<SkillLevelDTO[]>([]);
  const [matrixVersionSummary, setMatrixVersionSummary] = useState<EvaluationMatrixSummaryDTO>();

  const [isEditModeEnabled, setIsEditModeEnabled] = useState<boolean>(false);


  const nameFormControl = useFormControl<string>({
    validators: [validateWhiteSpaceStrings()],
    enableAutoValidate: true,
  });

  const managerFormControl = useFormControl<string>({
    validators: [validateWhiteSpaceStrings()],
    enableAutoValidate: true,
  });




  useEffect(() => {
    getMatrixSkills();
    getMatrixVersionById();
  }, [locationID, matrixId, versionId, useSelectedBusinessLine?.id]);




  const getMatrixSkills = useCallback(() => {
    if (!locationID || !matrixId || !versionId || !useSelectedBusinessLine?.id) return;

    getMatrixSkillsRolesByVersionIdCall.invoke("" + locationID, "" + useSelectedBusinessLine?.id, matrixId, versionId)
      .then((response) => {
        if (!response) return;

        const mappedSkills: SkillsCategoryDTO[] = response?.skillCategoryDetails?.map((category) => ({
          id: category.id,
          name: category.name,
          skills: category.skills.map((skill) => ({
            id: skill.skillID,
            name: skill.name,
            isDisabled: skill.isDisabled,
            description: skill.description
          }))
        }));

        const mappedSkillsRolesRelation: SkillLevelDTO[] = response.skillRoles.map((i) => ({
          skillId: i.skillId,
          roleId: i.roleId,
          competenceLevel: i.competenceLevel !== null ? String(i.competenceLevel) : "",
          isValid: true
        }));

        setEvaluationMatrixSkills(mappedSkills);
        setEvaluationMatrixRoles(response.rolesSummary);
        setSkillLevels(mappedSkillsRolesRelation);

      })
      .catch((error) => {
        if (!error) return;
        openPopup(<ErrorPopup>{error.response.data.message}</ErrorPopup>);
      });
  }, [getMatrixSkillsRolesByVersionIdCall, locationID, matrixId, setEvaluationMatrixSkills,
    setEvaluationMatrixRoles, setSkillLevels, openPopup, useSelectedBusinessLine?.id, versionId]);






  const getMatrixVersionById = useCallback(() => {

    if (!locationID || !matrixId || !versionId || !useSelectedBusinessLine?.id) return;

    getMatrixSummaryByVersionId.invoke("" + locationID, "" + useSelectedBusinessLine?.id, matrixId, versionId)
      .then((response) => {
        if (!response) return;

        setMatrixVersionSummary(response);
        nameFormControl.setValue(response.description);
        managerFormControl.setValue(response.manager.userEmail);
        updateRouteDate(["##MATRIX##", DateTime.fromISO(response.creationDate).toFormat("dd/MM/yyyy")]);
      })
      .catch((error) => {
        if (!error) return;
        openPopup(<ErrorPopup>{error.response.data.message}</ErrorPopup>);
      });
  }, [getMatrixSummaryByVersionId, updateRouteDate, locationID, managerFormControl, matrixId, nameFormControl, openPopup, useSelectedBusinessLine?.id, versionId]);








  const getSkillsByCategoryIds = useCallback((categoryIDs: number[]) => {

    if (!locationID) return;

    getSkillsByCategoryIdsCall.invoke("" + locationID, { categoryIds: categoryIDs })
      .then((response) => {

        const mappedResponse: SkillsCategoryDTO[] = response.categories.map((category) => ({
          id: category.id,
          name: category.name,
          skills: category.skills.map((skill) => ({
            id: skill.skillID,
            name: skill.name,
            isDisabled: skill.isDisabled,
            description: skill.description
          }))
        }));


        setEvaluationMatrixSkills(mappedResponse);

      })
      .catch((error) => {
        if (!error) return;
        openPopup(<ErrorPopup>{error.response.data.message}</ErrorPopup>);
      });
  }, [getSkillsByCategoryIdsCall, locationID, openPopup]);



  const handleSelectRoles = useCallback((selectedRoles: RoleSummary[]) => {
    setEvaluationMatrixRoles(selectedRoles);
  }, [setEvaluationMatrixRoles])


  const handleSelectSkills = useCallback((selectedCategories: SkillsCategoryDTO[]) => {
    setEvaluationMatrixSkills(selectedCategories);
    getSkillsByCategoryIds(selectedCategories.map(category => category.id));
  }, [getSkillsByCategoryIds, setEvaluationMatrixSkills]);


  const handleLevelInputChange = useCallback((skillLevels: SkillLevelDTO[]) => {
    setSkillLevels(skillLevels);
  }, [setSkillLevels])


  const handleChecklistChange = useCallback((checklist: string[]) => {
    setEvaluationMatrixChecklist(checklist)
  }, [setEvaluationMatrixChecklist])



  const handleEditMatrixClicked = useCallback(() => {

    if (!locationID || !businessLineID || !matrixId) return;


    if (evaluationMatrixRoles.length === 0) {
      openPopup(<ErrorPopup>{translate("BUSINESSLINES.MATRIX.ERRORS.NoRolesSelected")}</ErrorPopup>);
      return;
    }

    if (skillLevels.length === 0) {
      openPopup(<ErrorPopup>{translate("BUSINESSLINES.MATRIX.ERRORS.NoSkillsSelected")}</ErrorPopup>);
      return;
    }

    if (!AllValid(nameFormControl.validate(), managerFormControl.validate())) {
      return;
    }

    var skillLevelsClone = [...skillLevels];

    if (skillLevelsClone.filter(sl => !sl.isValid).length > 0) {
      openPopup(<ErrorPopup>{translate("BUSINESSLINES.MATRIX.ERRORS.InputFieldInvalid")}</ErrorPopup>);
      return;
    }

    const evaluationMatrixCategorySkills: EvaluationMatrixCategorySkillsUpdateDTO[] = evaluationMatrixSkills.map(category => ({
      categoryId: category.id,
      skillRoles: category.skills.flatMap(skill =>
        skillLevelsClone
          .filter(sl => sl.skillId === skill.id)
          .map(sl => ({
            skillId: sl.skillId,
            roleId: sl.roleId,
            competenceLevel: sl.competenceLevel ? parseInt(sl.competenceLevel) || null : null,
          } as EvaluationMatrixSkillRoleRelationDTO)
          )
      )
    }));

    console.log(evaluationMatrixCategorySkills)


    var requestDto: EvaluationMatrixUpdateDTO = {
      managerEmail: trimString(managerFormControl.value || ""),
      description: trimString(nameFormControl.value || ""),

      categorySkills: evaluationMatrixCategorySkills,

      checklistItems: evaluationMatrixChecklist.map(item => ({
        description: item
      }))
    };


    updateEvaluationMatrixCall.invoke("" + locationID, businessLineID, "" + matrixId, requestDto)
      .then((_) => {
        setIsEditModeEnabled(false);
        getMatrixSkills();
        getMatrixVersionById();
        createNotification(
          <Notification
            type="success"
            title={translate("COMMON.SYSTEMPOPUPS.Success")}
            text={translate("BUSINESSLINES.MATRIX.NOTIFICATIONS.EditMatrixSuccess")}
          />
        );
      })
      .catch((error) => {
        if (!error) return;
        openPopup(<ErrorPopup>{error.response.data.message}</ErrorPopup>);
      })


  }, [locationID, businessLineID, matrixId, evaluationMatrixRoles.length, skillLevels, nameFormControl,
    managerFormControl, evaluationMatrixSkills, evaluationMatrixChecklist, updateEvaluationMatrixCall, openPopup, getMatrixSkills, getMatrixVersionById, createNotification]);






  const isMatrixAvailableToEdit = useMemo(() => {

    if (!matrixVersionSummary || matrixVersionSummary.isDisabled || !matrixVersionSummary.isCurrentMatrixVersion)
      return false;

    return true;

  }, [matrixVersionSummary]);




  const renderCreateEvaluationMatrixBtns = useMemo(() => {
    if (isLoadingPermissions || !hasPermission) return null;
    if (windowResize > 768)
      return (<>
        {evaluationMatrixSkills && evaluationMatrixSkills.length && isEditModeEnabled ?
          <Button
            text={translate("BUSINESSLINES.MATRIX.SelectRoles")}
            type="secondary"
            onClick={() => locationID && businessLineID && matrixId ? openPopup(<SelectRolesPopup locationId={`${locationID}`} businessLineId={"" + businessLineID} matrixId={"" + matrixId} selectedRoles={evaluationMatrixRoles} onCompletedOperations={handleSelectRoles} />) : null}
          /> : null}
        {isEditModeEnabled ?
          <Button
            text={translate("BUSINESSLINES.MATRIX.SelectSkills")}
            type="secondary"
            onClick={() => locationID && businessLineID && matrixId ? openPopup(<SelectSkillsPopup locationId={`${locationID}`} businessLineId={"" + businessLineID} matrixId={"" + matrixId} selectedCategories={evaluationMatrixSkills} onCompletedOperations={handleSelectSkills} />) : null}
          /> : null}

        {isMatrixAvailableToEdit && !isEditModeEnabled ?
          <Button
            text={translate("BUSINESSLINES.MATRIX.EditMatrix")}
            type="primary"
            onClick={() => { setIsEditModeEnabled(!isEditModeEnabled) }}
          /> : null}

        {isEditModeEnabled ?
          <Button
            text={translate("COMMON.Save")}
            type="primary"
            onClick={() => { handleEditMatrixClicked() }}
          /> : null}


      </>
      );
    else
      return (<>
        {evaluationMatrixSkills && evaluationMatrixSkills.length && isEditModeEnabled ?
          <IconButton
            icon={<RolesIcon />}
            type="secondary"
            onClick={() => locationID && businessLineID && matrixId ? openPopup(<SelectRolesPopup locationId={`${locationID}`} businessLineId={"" + businessLineID} matrixId={"" + matrixId} selectedRoles={evaluationMatrixRoles} onCompletedOperations={handleSelectRoles} />) : null}
          /> : null}
        {isEditModeEnabled ?
          <IconButton
            icon={<SkillsIcon />}
            type="secondary"
            onClick={() => locationID && businessLineID && matrixId ? openPopup(<SelectSkillsPopup locationId={`${locationID}`} businessLineId={"" + businessLineID} matrixId={"" + matrixId} selectedCategories={evaluationMatrixSkills} onCompletedOperations={handleSelectSkills} />) : null}
          /> : null}
        {isMatrixAvailableToEdit && !isEditModeEnabled ?
          <IconButton
            icon={<EditIcon />}
            type="primary"
            onClick={() => { setIsEditModeEnabled(!isEditModeEnabled) }}
          /> : null}

        {isEditModeEnabled ?
          <IconButton
            icon={<SaveIcon />}
            type="primary"
            onClick={() => { handleEditMatrixClicked() }}
          /> : null}
      </>
      );
  }, [isLoadingPermissions, hasPermission, windowResize, evaluationMatrixSkills,
    isEditModeEnabled, isMatrixAvailableToEdit, locationID, businessLineID, matrixId, openPopup,
    evaluationMatrixRoles, handleSelectRoles, handleSelectSkills, handleEditMatrixClicked]);






  const renderSkillsCategoriesCards = useMemo(() => {
    if (evaluationMatrixSkills && evaluationMatrixSkills.length)
      return <SkillsCategoryCard
        locationId={"" + locationID}
        businessLineId={"" + businessLineID}
        matrixId={matrixId}
        isEditionModeEnabled={isMatrixAvailableToEdit && isEditModeEnabled}
        roles={evaluationMatrixRoles}
        skillsCategories={evaluationMatrixSkills}
        skillsLevels={skillLevels}
        handleLevelInputChange={handleLevelInputChange}
        handleManageSkills={(selectedSkills: SkillsCategoryDTO[]) => { setEvaluationMatrixSkills(selectedSkills); }}
      />
  }, [evaluationMatrixSkills, locationID, businessLineID, matrixId, isMatrixAvailableToEdit, isEditModeEnabled, evaluationMatrixRoles, skillLevels, handleLevelInputChange])




  return (
    <PagePermissions permissions={topPermissions} >
      <PageLayout
        tabTitle={translate("BUSINESSLINES.MATRIX.MatrixDetails")}
        pageTitle={translate("BUSINESSLINES.MATRIX.MatrixDetails")}
        className="create-evaluation-matrix"
        showBreadcrumb
        actions={useSelectedBusinessLine && !useSelectedBusinessLine.isDisabled && renderCreateEvaluationMatrixBtns}
      >

        {getMatrixSkillsRolesByVersionIdCall.isLoading && <FullScreenLoader />}
        {getSkillsByCategoryIdsCall.isLoading && <FullScreenLoader />}
        {getMatrixSummaryByVersionId.isLoading && <FullScreenLoader />}
        {updateEvaluationMatrixCall.isLoading && <FullScreenLoader />}

        <TitledCardContainer className="basic-info-card" title={translate("BUSINESSLINES.MATRIX.INFOANDFORM.BasicInfo")}
          extraTitle={
            matrixVersionSummary ?
              <Tag
                text={matrixVersionSummary && matrixVersionSummary.isDisabled ? translate("COMMON.Disabled") : translate("COMMON.Enabled")}
                backgroundColor={matrixVersionSummary.isDisabled ? "status-light-grey" : "status-green"}
                isTiny={windowResize < 1024}
              /> : null}>
          <FormSection isInputGap>
            {isEditModeEnabled ?
              <>
                <FormFieldTextInput
                  formControl={nameFormControl}
                  label={translate("BUSINESSLINES.MATRIX.INFOANDFORM.Area")}
                  placeholder={translate("BUSINESSLINES.MATRIX.INFOANDFORM.AreaPlaceholder")}
                  alternativeStyle
                />
                <FormFieldTextInput
                  formControl={managerFormControl}
                  label={translate("BUSINESSLINES.MATRIX.INFOANDFORM.Manager")}
                  placeholder={translate("BUSINESSLINES.MATRIX.INFOANDFORM.ManagerPlaceholder")}
                  alternativeStyle
                />
              </> :
              <FormSection unsetFlexGrow>
                <InfoBlock label={translate("BUSINESSLINES.MATRIX.INFOANDFORM.Area")} value={matrixVersionSummary?.description ?? "-"} />
                <InfoBlock label={translate("BUSINESSLINES.MATRIX.INFOANDFORM.Manager")} value={matrixVersionSummary?.manager.userDisplayName ?? "-"} />
              </FormSection>
            }
          </FormSection>
        </TitledCardContainer>
        {renderSkillsCategoriesCards}
        <ChecklistCard locationId={"" + locationID} businessLineId={"" + businessLineID} matrixId={matrixId} matrixVersionId={versionId} isEditionModeEnabled={isMatrixAvailableToEdit && isEditModeEnabled} onChange={handleChecklistChange} />
      </PageLayout>
    </PagePermissions>

  );
}