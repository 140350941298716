import "./FollowUpEmployeeEvaluationCard.css";

import { useEffect, useMemo, useState } from "react";

import { CardContainer } from "../../../../../lib/layouts/containers/card/CardContainer";
import { ColumnDefinition } from "../../../../../lib/components/table/TableInterfaces";
import { DateTimeHelper } from "../../../../../lib/helpers/datetime/DateTime";
import { EmployeeActionItemStateTranslationTerms } from "../../../models/dtos/EmployeeActionItemState";
import { EmployeeActionItemSummaryDTO } from "../../../models/dtos/EmployeeActionItemSummaryDTO";
import { ResponsiveDataTable } from "../../../../../lib/components/table/ResponsiveDataTable";
import { Tag } from "../../../../../lib/components/tag/Tag";
import { getEvaluationFollowUpItemStatusCss } from "../../../../evaluations/helpers/getEvaluationFollowUpItemStatusCss";
import { translate } from "../../../../../lib/infrastructure/i18n/InternationalizationService";
import { getActionItemTranslationByKey } from "../../../../../common/helpers/getActionItemOptions";

interface IFollowUpEmployeeEvaluationCardProps {
    items: EmployeeActionItemSummaryDTO[];
    isLoading: boolean;
}


export function FollowUpEmployeeEvaluationCard(props: IFollowUpEmployeeEvaluationCardProps) {

    const [followUpItems, setFollowUpItems] = useState<EmployeeActionItemSummaryDTO[]>(props.items);

    /****************************
     * DATA MANIPULATION EFFECTS
     *****************************/

    useEffect(() => {
        setFollowUpItems(props.items);
    }, [props.items])

    /****************************
     * USER ACTIONS
     *****************************/

    /****************************
     * CSS & HTML
     *****************************/
    const columns: ColumnDefinition<EmployeeActionItemSummaryDTO>[] = useMemo(() => [
        {
            cellRenderProp: (item) =>  getActionItemTranslationByKey(item.itemName),
            headerRender: translate("BUSINESSLINES.EVALUATIONS.INFOANDFORM.FollowUpItem"),
            isMobilePrimaryCell: true,
            width: "20%",
        },
        {
            cellRenderProp: (item) => item.itemDescription,
            headerRender: translate("BUSINESSLINES.EVALUATIONS.INFOANDFORM.FollowUpDescription"),
        },
        {
            cellRenderProp: (item) => <Tag
                isTiny
                text={item ? translate(EmployeeActionItemStateTranslationTerms[item.state]) : "-"}
                backgroundColor={getEvaluationFollowUpItemStatusCss(item.state ?? -1)} />,
            headerRender: translate("BUSINESSLINES.EVALUATIONS.INFOANDFORM.FollowUpStatus"),
            isStatusCell: true,
            width: "100px",
        },
        {
            cellRenderProp: (item) => item.startDate ? DateTimeHelper.formatDateTimeLocale(item.startDate) : "-",
            headerRender: translate("BUSINESSLINES.EVALUATIONS.INFOANDFORM.FollowUpStartDate"),
            width: "120px",
        },
        {
            cellRenderProp: (item) => item.endDate ? DateTimeHelper.formatDateTimeLocale(item.endDate) : "-",
            headerRender: translate("BUSINESSLINES.EVALUATIONS.INFOANDFORM.FollowUpConclusionDate"),
            width: "132px",
        },
    ], []);


    return <CardContainer className="follow-up-card">
        <div className="card-header">
            <div className="subtitle">{translate("BUSINESSLINES.EVALUATIONS.INFOANDFORM.FollowUp")}</div>
        </div>
        <ResponsiveDataTable
            columnDefinitions={columns}
            items={followUpItems}
            totalitems={followUpItems.length}
            isLoading={props.isLoading}
        />
    </CardContainer>;
}