import "./RejectEvaluationPopup.css";

import { useCallback, useState } from "react";
import { useClosePopup, useCreateNotification, usePopup } from "../../../../lib/infrastructure/ui/UIServices";

import { AllValid } from "../../../../common/validators/ValidateFormControls";
import { CardContainer } from "../../../../lib/layouts/containers/card/CardContainer";
import { ConditionalRender } from "../../../../lib/functional/ConditionalRender";
import { ErrorPopup } from "../../../../lib/components/popup/ErrorPopup";
import { EvaluationDetailsDTO_V2 } from "../../models/EvaluationDetailsDTO_V2";
import { EvaluationState } from "../../models/EvaluationStatus";
import { EvaluationsService } from "../../services/EvaluationsService";
import { FormContainer } from "../../../../lib/layouts/containers/form/FormContainer";
import { FormFieldTextArea } from "../../../../lib/components/form/form-field/FormFieldTextArea";
import { FullScreenLoader } from "../../../../lib/components/loader/FullScreenLoader";
import { Notification } from "../../../../lib/components/notifications/Notification";
import { PopupActionButtons } from "../../../../lib/layouts/containers/popup-buttons/PopupActionButtons";
import { PopupContainer } from "../../../../lib/layouts/containers/popup-container/PopupContainer";
import { PopupContent } from "../../../../lib/layouts/containers/popup-content/PopupContent";
import { PopupHeader } from "../../../../lib/layouts/containers/popup-header/PopupHeader";
import { Spacer } from "../../../../lib/components/separator/Spacer";
import { translate } from "../../../../lib/infrastructure/i18n/InternationalizationService";
import { useFormControl } from "../../../../lib/components/form/Form";
import { useServiceCallPro } from "../../../../lib/hooks/useServiceCall";
import { validateWhiteSpaceStrings } from "../../../../lib/validators/ValidateWhiteSpaceStrings";

export interface IRejectEvaluationPopupProps {
    locationId?: string,
    businessLineID?: string,
    evaluationPeriodID?: string,
    matrixId?: string,
    evaluationId? : string,

    employeeName?: string,

    onRejectEvaluation: (freshEvaluation: EvaluationDetailsDTO_V2) => void; 
}

const evaluationsService = new EvaluationsService();


export function RejectEvaluationPopup(props: IRejectEvaluationPopupProps) {
    const openPopup = usePopup();
    const closePopup = useClosePopup();
    const createNotification = useCreateNotification();


    const reasonFormControl = useFormControl<string>({
        validators: [validateWhiteSpaceStrings()],
        enableAutoValidate: true,
        isDisabled: false,
    });


    const updateEvaluationStatusCall = useServiceCallPro(evaluationsService.updateEvaluationStatus);
    
    /****************************
    * DATA REQUESTS
    *****************************/
    const updateEvaluationStatus = useCallback((state: EvaluationState, reason: string) => {
        if (!props.businessLineID ||
            !props.evaluationPeriodID ||
            !props.matrixId ||
            !props.evaluationId) return;


        updateEvaluationStatusCall.invoke(`${props.locationId}`, props.businessLineID, props.evaluationPeriodID, props.matrixId, props.evaluationId, {
            nextStatus: state,
            statusReason: reason
        })
            .then((res) => {
                props.onRejectEvaluation(res);
                closePopup();
                createNotification(<Notification
                    type="success"
                    title={translate("COMMON.SYSTEMPOPUPS.Success")}
                    text={translate("BUSINESSLINES.EVALUATIONS.NOTIFICATIONS.RejectEvaluationSuccessMessage")}
                />);
            })
            .catch((error) => {
                if (!error) return;
                openPopup(<ErrorPopup>{error.response.data.message}</ErrorPopup>);
            });
    }, [props.businessLineID,
        props.evaluationPeriodID,
        props.matrixId,
        props.evaluationId,
        props.locationId,
        props.onRejectEvaluation,
        updateEvaluationStatusCall.invoke,
        createNotification,
        openPopup])



    /****************************
     * DATA MANIPULATION EFFECTS
     *****************************/

    /****************************
     * USER ACTIONS
     *****************************/

    const handleOnClickReject = useCallback(() => {
        if (!props.businessLineID ||
            !props.evaluationPeriodID ||
            !props.matrixId ||
            !props.evaluationId) return;

        if (!AllValid(reasonFormControl.validate())) return;
        
        updateEvaluationStatus(EvaluationState.REJECTED, reasonFormControl.value || "")

    }, [props.businessLineID,
        props.evaluationPeriodID,
        props.matrixId,
        props.evaluationId,
        props.locationId,
        reasonFormControl.validate,
        reasonFormControl.value
    ]);


    
    /****************************
     * CSS & HTML
     *****************************/
    
    return (
        <PopupContainer className="popup-reject-evaluation">
            <PopupHeader title={translate("BUSINESSLINES.EVALUATIONS.POPUPS.RejectEvaluation")} />
            <ConditionalRender if={updateEvaluationStatusCall.isLoading}> <FullScreenLoader/></ConditionalRender>
            <PopupContent>
                <div className="reject-evaluation">
                    <CardContainer className="employee-card">
                        <div className="small-copy employee-label">{translate("BUSINESSLINES.EVALUATIONS.INFOANDFORM.Employee")}</div>
                        <div className="employee-value">{props.employeeName}</div>
                    </CardContainer>
                    <FormContainer>
                        <FormFieldTextArea
                            formControl={reasonFormControl}
                            label={translate("BUSINESSLINES.EVALUATIONS.INFOANDFORM.Reason")}
                            placeholder={translate("BUSINESSLINES.EVALUATIONS.INFOANDFORM.RejectReasonPlaceholder")}
                        />
                    </FormContainer>
                </div>
                <Spacer mode={"vertical"} px="30" />
                <PopupActionButtons
                    buttons={[
                        {
                            text: translate("COMMON.Cancel"),
                            type: "tertiary",
                            onClick: () => closePopup(),
                        },
                        {
                            text: translate("COMMON.Reject"),
                            className: "reject-btn",
                            type: "secondary",
                            onClick: handleOnClickReject,
                        },
                    ]}
                />
            </PopupContent>
        </PopupContainer>
    );
}