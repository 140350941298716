import { ConfigurationProvider } from "../../../lib/infrastructure/configuration/ConfigurationProvider";
import { CriteriaCreateDTO } from "../models/dtos/CriteriaCreateDTO";
import { CriteriaDTO } from "../models/dtos/CriteriaDTO";
import { CriteriaUpdateDTO } from "../models/dtos/CriteriaUpdateDTO";
import { CriteriasResponseDTO } from "../models/dtos/CriteriasResponseDTO";
import { HttpClient } from "../../../lib/infrastructure/http/HttpClient";

const Route = (path: string) => {
    return ConfigurationProvider.getConfiguration().App.BackendUrl + path;
};

export class CriteriasService {

    getCriterias(locationId: string, businessLineId: string, roleId: string): Promise<CriteriasResponseDTO | null> {
        return HttpClient.sessionRequest<CriteriasResponseDTO>({
            method: "GET",
            url: Route(`/api/v1/Locations/${locationId}/BusinessLines/${businessLineId}/Roles/${roleId}/RoleCriterias`),
        })
            .then((response) => {

                if (!response.data) return null;

                return (response.data)
            })
            .catch((error) => {
                throw error;
            });
    }

    addCriteria(locationId: string, businessLineId: string, roleId: string, requestDto: CriteriaCreateDTO): Promise<void> {
        return HttpClient.sessionRequest<CriteriaDTO>({
            method: "POST",
            url: Route(`/api/v1/Locations/${locationId}/BusinessLines/${businessLineId}/Roles/${roleId}/RoleCriterias`),
            data: requestDto,
        })
            .then((_) => { })
            .catch((error) => {
                throw error;
            });
    }

    getCriteriaByID(locationId: string, businessLineId: string, roleId: string, criteriaId: string): Promise<CriteriaDTO | null> {
        return HttpClient.sessionRequest<CriteriaDTO>({
            method: "GET",
            url: Route(`/api/v1/Locations/${locationId}/BusinessLines/${businessLineId}/Roles/${roleId}/RoleCriterias/${criteriaId}`),
        })
            .then((response) => {

                if (!response.data) return null;
                return response.data;
            })
            .catch((error) => {
                throw error;
            });
    }

    editCriteria(locationID: string, businessLineId: string, roleId: string, criteriaId: number, requestDto: CriteriaUpdateDTO): Promise<void> {
        return HttpClient.sessionRequest({
            method: "PUT",
            url: Route(`/api/v1/Locations/${locationID}/BusinessLines/${businessLineId}/Roles/${roleId}/RoleCriterias/${criteriaId}`),
            data: requestDto
        })
            .then((_) => { })
            .catch((error) => {
                throw error;
            });
    }

    deleteCriteria(locationId: string, businessLineId: string, roleId: string, criteriaId: number): Promise<void> {
        return HttpClient.sessionRequest<string>({
            method: "DELETE",
            url: Route(`/api/v1/Locations/${locationId}/BusinessLines/${businessLineId}/Roles/${roleId}/RoleCriterias/${criteriaId}`),
        })
            .then((_) => { })
            .catch((error) => {
                throw error;
            });
    }

}
