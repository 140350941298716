
export function getEmployeeCertificationStatusCss(statusId: number) {
    switch (statusId) {
        case 0:
            return "status-light-grey";
        case 1:
            return "status-yellow";
        case 2:
            return "status-green";
        case 3:
            return "status-red";
        default:
            return undefined;    
        }
}
