import "./MyEvaluationsPage.css";

import { useCallback, useEffect, useMemo, useState } from "react";
import { usePopup, useWindowResize } from "../../../../lib/infrastructure/ui/UIServices";

import { ColumnDefinition } from "../../../../lib/components/table/TableInterfaces";
import { EmployeeEvaluationSummaryDTO } from "../../../employees/models/dtos/EmployeeEvaluationSummaryDTO";
import { ErrorPopup } from "../../../../lib/components/popup/ErrorPopup";
import { MyService } from "../../services/MyService";
import { PageLayout } from "../../../../lib/layouts/main-content/PageLayout";
import { QueryOptions } from "../../../../models/api/QueryOptions";
import { ResponsiveDataTable } from "../../../../lib/components/table/ResponsiveDataTable";
import { Tag } from "../../../../lib/components/tag/Tag";
import axios from "axios";
import { translate } from "../../../../lib/infrastructure/i18n/InternationalizationService";
import { useGlobalLocation } from "../../../../lib/infrastructure/location/LocationServices";
import { useNavigate } from "react-router-dom";
import { useServiceCallPro } from "../../../../lib/hooks/useServiceCall";

var svc = new MyService();

export interface IOrderInfo {
    direction: "ASC" | "DESC" | "NONE";
    columnKey: string;
}

export function MyEvaluationsPage() {
    const navigate = useNavigate();
    const windowResize = useWindowResize();
    const openPopup = usePopup();

    const locationID = useGlobalLocation();
    const locationIDAsString = useMemo(() => "" + locationID, [locationID])

    const [evaluations, setEvaluations] = useState<EmployeeEvaluationSummaryDTO[]>([]);
    const [totalItems, setTotalItems] = useState<number>();

    const [itemsPerPage, setItemsPerPage] = useState(10);
    const [currentpage, setCurrentPage] = useState(0);


    const getMyEvaluationsCall = useServiceCallPro(svc.getMyEvaluations);

    /****************************
    * DATA REQUESTS
    *****************************/

    const getMyEvaluations = useCallback((controller: AbortController | undefined) => {
        if (!locationIDAsString) return;

        var queryOpts: QueryOptions = {
            //searchWord: searchWord,
            page: currentpage,
            pageLength: itemsPerPage
        };

        getMyEvaluationsCall.invoke(locationIDAsString, queryOpts, controller?.signal)
            .then((data) => {
                if (data) {
                    setEvaluations(data.evaluations);
                    setTotalItems(data.totalItems);
                }
            })
            .catch((error) => {
                if (!error || axios.isCancel(error)) return;
                openPopup(<ErrorPopup>{error.response.data.message}</ErrorPopup>);
            });
    }, [locationIDAsString, currentpage, itemsPerPage, getMyEvaluationsCall, openPopup]);


    /****************************
     * DATA MANIPULATION EFFECTS
     *****************************/

    useEffect(() => {
        const controller = new AbortController();
        if (!locationIDAsString) return;

        getMyEvaluations(controller);

        return () => { controller.abort() };
    }, [locationIDAsString, currentpage, itemsPerPage]);




    /****************************
     * USER ACTIONS
     *****************************/

    const handleOnPageAndItemsChanged = useCallback((items: number, currentPage: number) => {
        setItemsPerPage(items);
        setCurrentPage(currentPage);
    }, [setItemsPerPage, setCurrentPage]);


    const handleEvaluationPeriodDetailsClicked = useCallback((evaluation: EmployeeEvaluationSummaryDTO) => {
        navigate(`${evaluation.evaluationId}`);
    }, [navigate]);


    /****************************
     * CSS & HTML
     *****************************/

    const columns: ColumnDefinition<EmployeeEvaluationSummaryDTO>[] = useMemo(
        () => [
            {
                cellRenderProp: (v) => v.evaluationPeriodName,
                headerRender: translate("EMPLOYEES.EVALUATIONS.INFOANDFORM.Name"),
                isMobilePrimaryCell: true,
            },
            {
                cellRenderProp: (v) => v.roleName,
                headerRender: translate("EMPLOYEES.INFOANDFORM.Role"),
            },
            {
                cellRenderProp: (v) => (
                    <Tag
                        text={"" + v.evaluationFinalScore}
                        isTiny={windowResize < 1024}
                    />
                ),
                headerRender: windowResize > 768 && translate("EMPLOYEES.EVALUATIONS.INFOANDFORM.Evaluation"),
                width: "110px",
                isMobileHeaderIcon: true
            },
        ], [windowResize]);


    return (
        <PageLayout
            tabTitle={translate("EMPLOYEES.EVALUATIONS.Evaluations")}
            pageTitle={translate("EMPLOYEES.EVALUATIONS.Evaluations")}
            className="my-evaluations"
        >

            <ResponsiveDataTable
                columnDefinitions={columns}
                items={evaluations || []}
                totalitems={totalItems || 0}
                paginationOptions={{ itemsPerPage: itemsPerPage, itemsPerPageOptions: [10, 20, 30] }}
                currentpage={currentpage}
                isLoading={getMyEvaluationsCall.isLoading}
                onPageAndItemsChanged={handleOnPageAndItemsChanged}
                onClickRow={handleEvaluationPeriodDetailsClicked}
            />
        </PageLayout>
    );
}
