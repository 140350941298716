import { regexOnlyZeroAndPositiveNumbersOrEmpty, validateRegexOrEmpty } from "../../../lib/validators/ValidateRegex";
import { useCallback, useEffect } from "react";

import { FormFieldTextInput } from "../../../lib/components/form/form-field/FormFieldTextInput";
import { translate } from "../../../lib/infrastructure/i18n/InternationalizationService";
import { useFormControl } from "../../../lib/components/form/Form";
import { useInitLoader } from "../../../lib/hooks/useServiceCall";

interface IEvaluationInputProps {
    initialValue?: string;
    onChange: (value: string, isValid: boolean) => void;
}

export function EvaluationInput(props: IEvaluationInputProps) {

  const initLoader = useInitLoader();
    const levelFormControl = useFormControl<string>({
        initialValue: props.initialValue && props.initialValue.toString(),
        validators: [validateRegexOrEmpty(regexOnlyZeroAndPositiveNumbersOrEmpty, false, "")],
    });

    const handleChange = useCallback(() => {
        props.onChange(levelFormControl.value as string, levelFormControl.validate());
    }, [levelFormControl.value, props.onChange, levelFormControl.validate]);

    useEffect(() => {
        !initLoader && handleChange();
    }, [levelFormControl.value]);


    return (
        <FormFieldTextInput
            formControl={levelFormControl}
            placeholder={translate("BUSINESSLINES.MATRIX.INFOANDFORM.Level")}
            alternativeStyle
        />
    );

};