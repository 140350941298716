import { regexOnlyPositiveIntegersExcludingZeroAndAllowNull, validateRegexOrEmpty } from "../../../../lib/validators/ValidateRegex";
import { useCallback, useEffect, useState } from "react";
import { useClosePopup, useCreateNotification, usePopup } from "../../../../lib/infrastructure/ui/UIServices";

import { AllValid } from "../../../../common/validators/ValidateFormControls";
import { ConditionalRender } from "../../../../lib/functional/ConditionalRender";
import { ErrorPopup } from "../../../../lib/components/popup/ErrorPopup";
import { ExceptionType } from "../../../../lib/infrastructure/http/ExceptionType";
import { FormFieldTextInput } from "../../../../lib/components/form/form-field/FormFieldTextInput";
import { FormSection } from "../../../../lib/components/form/section/FormSection";
import { FullScreenLoader } from "../../../../lib/components/loader/FullScreenLoader";
import { MessageBar } from "../../../../lib/components/message-bar/MessageBar";
import { Notification } from "../../../../lib/components/notifications/Notification";
import { PopupActionButtons } from "../../../../lib/layouts/containers/popup-buttons/PopupActionButtons";
import { PopupContainer } from "../../../../lib/layouts/containers/popup-container/PopupContainer";
import { PopupContent } from "../../../../lib/layouts/containers/popup-content/PopupContent";
import { PopupHeader } from "../../../../lib/layouts/containers/popup-header/PopupHeader";
import { RoleUpdateDTO } from "../../models/dtos/RoleUpdateDTO";
import { RolesService } from "../../services/RolesService";
import { Spacer } from "../../../../lib/components/separator/Spacer";
import { ValidationErrorMessage } from "../../../../lib/infrastructure/http/APIError";
import { translate } from "../../../../lib/infrastructure/i18n/InternationalizationService";
import { trimString } from "../../../../lib/utils/TrimString";
import { useFormControl } from "../../../../lib/components/form/Form";
import { useServiceCallPro } from "../../../../lib/hooks/useServiceCall";
import { validateWhiteSpaceStrings } from "../../../../lib/validators/ValidateWhiteSpaceStrings";

var rolesSvc = new RolesService();

interface IEditRolePopupProps {
  roleId: number;
  businessLineId: string;
  locationId: string;
  onCompleted: () => void;
}

export function EditRolePopup(props: IEditRolePopupProps) {
  const closePopup = useClosePopup();
  const openPopup = usePopup();

  const createNotification = useCreateNotification();

  const getRoleDetailsByIdCall = useServiceCallPro(rolesSvc.getRoleDetailsById);
  const updateRoleCall = useServiceCallPro(rolesSvc.editRole);

  const roleDescription = useFormControl<string>({
    validators: [validateWhiteSpaceStrings()],
    enableAutoValidate: true,
  });

  const jobGradeFormControl = useFormControl<string>({
    validators: [validateRegexOrEmpty(regexOnlyPositiveIntegersExcludingZeroAndAllowNull, false, "COMMON.FORM.VALIDATIONS.PositiveNumber")],
    enableAutoValidate: true,
  });


  const [validationAlert, setValidationAlert] = useState<ValidationErrorMessage>({ showAlert: false });


  useEffect(() => {
    if (!props.locationId || !props.businessLineId || !props.roleId) return;


    getRoleDetailsByIdCall
      .invoke(props.locationId, "" + props.businessLineId, "" + props.roleId)
      .then((response) => {
        roleDescription.setValue(response?.description);
        jobGradeFormControl.setValue(!response?.jobGrade ? "" : "" + response.jobGrade);
      })
      .catch((_) => {
        openPopup(<ErrorPopup>{translate("BUSINESSLINES.ROLES.POPUP.LoadingRoleError")}</ErrorPopup>);
      });

  }, []);










  const handleUpdateRoleClicked = useCallback(() => {

    if (!AllValid(roleDescription.validate(), jobGradeFormControl.validate())) return;

    let request: RoleUpdateDTO = {
      description: trimString(roleDescription.value),
      jobGrade: jobGradeFormControl.value ? parseInt(jobGradeFormControl.value) : undefined,
    };


    const handleSuccess = () => {
      props.onCompleted();
      closePopup();
      createNotification(
        <Notification
          type="success"
          title={translate("COMMON.SYSTEMPOPUPS.Success")}
          text={translate("BUSINESSLINES.ROLES.POPUP.EditRoleSuccess")}
        />
      );
    };


    const handleError = (error: any) => {
      if (!error) return;

      const errorMessage = error?.response?.data?.message || translate("ERRORS.UnknownError");
      const enumCode = error?.response?.data?.enumCode;


      if (enumCode === ExceptionType.ERROR_VALIDATION_NAME_ALREADY_EXISTS) {
        setValidationAlert({
          showAlert: true,
          message: errorMessage,
        });
      } else {
        openPopup(<ErrorPopup>{errorMessage}</ErrorPopup>);
      }
    };


    updateRoleCall
      .invoke(props.locationId, props.businessLineId, props.roleId, { description: trimString(roleDescription.value), jobGrade: jobGradeFormControl.value ? parseInt(jobGradeFormControl.value) : undefined, })
      .then(handleSuccess)
      .catch(handleError);

  }, [roleDescription, jobGradeFormControl, updateRoleCall, props, closePopup, createNotification, openPopup]);






  return (
    <PopupContainer className="popup-edit-role">
      {updateRoleCall.isLoading ? <FullScreenLoader /> : null}

      <PopupHeader
        title={translate("BUSINESSLINES.ROLES.POPUP.EditRole")}
      />
      <PopupContent isLoading={getRoleDetailsByIdCall.isLoading}>
        <FormSection>
          <div>
            <FormFieldTextInput
              formControl={roleDescription}
              label={translate("BUSINESSLINES.ROLES.INFOANDFORM.Name")}
              placeholder={translate("BUSINESSLINES.ROLES.INFOANDFORM.NamePlaceholder")}
            />

            <ConditionalRender if={validationAlert.showAlert}>
              <Spacer mode="vertical" px="10" />
              <div className="name-already-exists">
                <MessageBar type="error" text={"" + validationAlert.message} />
              </div>
            </ConditionalRender>
          </div>
          <FormFieldTextInput
            formControl={jobGradeFormControl}
            label={translate("BUSINESSLINES.ROLES.INFOANDFORM.JobGrade")}
            placeholder={translate("BUSINESSLINES.ROLES.INFOANDFORM.JobGradePlaceholder")}
          />
        </FormSection>
        <Spacer mode={"vertical"} px="30" />
        <PopupActionButtons
          buttons={[
            {
              text: translate("COMMON.Cancel"),
              type: "tertiary",
              onClick: () => closePopup(),
            },
            {
              text: translate("COMMON.Save"),
              type: "primary",
              onClick: handleUpdateRoleClicked,
            },
          ]}
        />
      </PopupContent>
    </PopupContainer>
  );
}
