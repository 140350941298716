import { Navigate, Outlet, Route, Routes } from "react-router-dom";

import { BusinessLinesPage } from "../features/admin/business-lines/pages/BusinessLinesPage";
import { EditLocationGroupAccessPage } from "../features/admin/location-group-accesses/pages/EditLocationGroupAccessPage";
import { LocationsContainer } from "../features/admin/layout/LocationsContainer";
import { LocationsHome } from "../features/admin/locations/pages/LocationsHomePage";
import { ModuleDefinition } from "../lib/infrastructure/modules/ModuleDefinition";
import { Permission } from '../models/api/gate/Permission';
import { RouteGuard } from "../lib/infrastructure/authorization/RouteGuard";
import { SkillsCategoriesPage } from "../features/admin/skills/pages/SkillsCategoriesPage";
import { SkillsPage } from "../features/admin/skills/pages/SkillsPage";
import { translate } from "../lib/infrastructure/i18n/InternationalizationService";
import { useEffect } from "react";
import { useRegisterBreadcrumbRoutes } from "../lib/infrastructure/navigation/hooks/useBreadcrumb";

const moduleDefinition: ModuleDefinition = {
  basePath: "/settings/locations",
  routes: [
    {
      name: () => translate("GLOBALADMIN.LOCATIONS.Locations"),
      pathPattern: "/",
    },
    {
      name: () => "##LOCATION##",
      pathPattern: "/:id",
    },
    {
      name: () => translate("GLOBALADMIN.LOCATIONS.ACCESSGROUPS.AccessGroups"),
      pathPattern: "/:id/groupaccess",
    },
    {
      name: () => translate("GLOBALADMIN.LOCATIONS.BUSINESSLINES.BusinessLines"),
      pathPattern: "/:id/businesslines",
    },
    {
      name: () => translate("GLOBALADMIN.LOCATIONS.SKILLS.SkillsCategories"),
      pathPattern: "/:id/skills",
    },
    {
      name: () => "##SKILLSCATEGORY##",
      pathPattern: "/:id/skills/:categoryId",
    },
  ],
};


const locationPermissions = [Permission.GLOBAL_ADMIN,
Permission.LOCATION_ADMIN];



export function LocationsModule() {
  const registerBreadcrumbRoutes = useRegisterBreadcrumbRoutes();


  useEffect(() => {
    registerBreadcrumbRoutes(moduleDefinition);
  }, []);


  return (

    <RouteGuard permissions={locationPermissions} >
      <Routes>
        <Route index element={<LocationsHome />} />
        <Route
          path=":id/*"
          element={
            <LocationsContainer>
              <Outlet />
            </LocationsContainer>}
        >
          <Route index element={<Navigate to="groupaccess" />} />
          <Route path="groupaccess" element={<EditLocationGroupAccessPage />} />
          <Route path="businesslines" element={<BusinessLinesPage />} />
          <Route path="skills" element={<SkillsCategoriesPage />} />
          <Route path="skills/:categoryId" element={<SkillsPage />} />
        </Route>

      </Routes>
    </RouteGuard>
  );
}
