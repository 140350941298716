import { useCallback, useState } from "react";
import { useClosePopup, useCreateNotification, usePopup } from "../../../../../lib/infrastructure/ui/UIServices";

import { AllValid } from "../../../../../common/validators/ValidateFormControls";
import { ConditionalRender } from "../../../../../lib/functional/ConditionalRender";
import { ErrorPopup } from "../../../../../lib/components/popup/ErrorPopup";
import { ExceptionType } from "../../../../../lib/infrastructure/http/ExceptionType";
import { FormContainer } from "../../../../../lib/layouts/containers/form/FormContainer";
import { FormFieldTextArea } from "../../../../../lib/components/form/form-field/FormFieldTextArea";
import { FormFieldTextInput } from "../../../../../lib/components/form/form-field/FormFieldTextInput";
import { FullScreenLoader } from "../../../../../lib/components/loader/FullScreenLoader";
import { MessageBar } from "../../../../../lib/components/message-bar/MessageBar";
import { Notification } from "../../../../../lib/components/notifications/Notification";
import { PopupActionButtons } from "../../../../../lib/layouts/containers/popup-buttons/PopupActionButtons";
import { PopupContainer } from "../../../../../lib/layouts/containers/popup-container/PopupContainer";
import { PopupContent } from "../../../../../lib/layouts/containers/popup-content/PopupContent";
import { PopupHeader } from "../../../../../lib/layouts/containers/popup-header/PopupHeader";
import { SkillsService } from "../../services/SkillsService";
import { Spacer } from "../../../../../lib/components/separator/Spacer";
import { ValidationErrorMessage } from "../../../../../lib/infrastructure/http/APIError";
import { translate } from "../../../../../lib/infrastructure/i18n/InternationalizationService";
import { trimString } from "../../../../../lib/utils/TrimString";
import { useFormControl } from "../../../../../lib/components/form/Form";
import { useServiceCallPro } from "../../../../../lib/hooks/useServiceCall";
import { validateWhiteSpaceStrings } from "../../../../../lib/validators/ValidateWhiteSpaceStrings";

interface IAddSkillPopupProps {
    onChangesMade: () => void;
    locationId: string;
    categoryId: string;
}

var skillsSvc = new SkillsService();

export function AddSkillPopup(props: IAddSkillPopupProps) {
    const closePopup = useClosePopup();
    const openPopup = usePopup();
    const createNotification = useCreateNotification();

    const createSkillCall = useServiceCallPro(skillsSvc.createSkill);

    const nameFormControl = useFormControl<string>({
        validators: [validateWhiteSpaceStrings()],
        enableAutoValidate: true,
    });

    const skillDescriptionForm = useFormControl<string>({
        validators: [validateWhiteSpaceStrings()],
        enableAutoValidate: true,
    });


    const [validationAlert, setValidationAlert] = useState<ValidationErrorMessage>({ showAlert: false });





    const handleAddSkillClicked = useCallback(() => {

        if (!AllValid(nameFormControl.validate())) return;


        const handleSuccess = () => {
            closePopup();
            props.onChangesMade();
            createNotification(
                <Notification
                    type="success"
                    title={translate("COMMON.SYSTEMPOPUPS.Success")}
                    text={translate("GLOBALADMIN.LOCATIONS.SKILLS.POPUP.AddSkillSuccess")}
                />
            );
        };

        const handleError = (error: any) => {
            if (!error) return;

            const errorMessage = error?.response?.data?.message || translate("ERRORS.UnknownError");
            const enumCode = error?.response?.data?.enumCode;

            if (enumCode === ExceptionType.ERROR_VALIDATION_NAME_ALREADY_EXISTS) {
                setValidationAlert({
                    showAlert: true,
                    message: errorMessage,
                });
            } else {
                openPopup(<ErrorPopup>{errorMessage}</ErrorPopup>);
            }
        };

        createSkillCall.invoke("" + props.locationId, "" + props.categoryId, { name: trimString(nameFormControl.value), description: trimString(skillDescriptionForm.value) })
            .then(handleSuccess)
            .catch(handleError);


    }, [nameFormControl.value, nameFormControl.validate, skillDescriptionForm.value, closePopup, createSkillCall.invoke,
        openPopup, props.onChangesMade, createNotification, setValidationAlert, props.locationId]);



    return (
        <PopupContainer className="popup-add-skill">
            {createSkillCall.isLoading ? <FullScreenLoader /> : null}

            <PopupHeader title={translate("GLOBALADMIN.LOCATIONS.SKILLS.POPUP.AddSkill")} />
            <PopupContent>

                <FormContainer>
                    <FormFieldTextInput
                        formControl={nameFormControl}
                        label={translate("GLOBALADMIN.LOCATIONS.SKILLS.INFOANDFORM.SkillName")}
                        placeholder={translate("GLOBALADMIN.LOCATIONS.SKILLS.INFOANDFORM.SkillNamePlaceholder")}
                    />
                    <FormFieldTextArea
                        formControl={skillDescriptionForm}
                        label={translate("GLOBALADMIN.LOCATIONS.SKILLS.INFOANDFORM.SkillDescription")}
                        placeholder={translate("GLOBALADMIN.LOCATIONS.SKILLS.INFOANDFORM.SkillDescriptionPlaceholder")}
                    />

                    <ConditionalRender if={validationAlert.showAlert}>
                        <div className="name-already-exists">
                            <MessageBar type="error" text={"" + validationAlert.message} />
                        </div>
                    </ConditionalRender>

                </FormContainer>

                <Spacer mode={"vertical"} px="30" />
                <PopupActionButtons
                    buttons={[
                        {
                            text: translate("COMMON.Cancel"),
                            type: "tertiary",
                            onClick: () => closePopup(),
                        },
                        {
                            text: translate("COMMON.Add"),
                            type: "primary",
                            onClick: handleAddSkillClicked,
                        },
                    ]}
                />
            </PopupContent>
        </PopupContainer>
    );
}
