import { ConfigurationProvider } from "../../../lib/infrastructure/configuration/ConfigurationProvider";
import { EmployeeDetailsDTO } from "../models/dtos/EmployeeDetailsDTO";
import { EmployeeSummaryDTO } from "../models/dtos/EmployeeSummaryDTO";
import { EmployeesResponseDTO } from "../models/dtos/EmployeesResponseDTO";
import { HttpClient } from "../../../lib/infrastructure/http/HttpClient";
import { QueryOptions } from "../../../models/api/QueryOptions";
import qs from "qs";

const employeeSummaryMock: EmployeeDetailsDTO = {
    employeeInfo: {
        userId: "1",
        userEmployeeID: "45230",
        userEmail: "john.doe@doamin.com",
        userDisplayName: "John Doe"
    },
    roleId: 1,
    roleDescription: "Operador Logística",
    evaluationTotal: 0,
}

const Route = (path: string) => {
    return ConfigurationProvider.getConfiguration().App.BackendUrl + path;
};

export class EmployeesService {

    getEmployees(locationId: string, query: QueryOptions, abortSignal: AbortSignal | undefined): Promise<EmployeesResponseDTO | null> {
        return HttpClient.sessionRequest<EmployeesResponseDTO>({
            method: "GET",
            url: Route(`/api/v1/Locations/${locationId}/Employees`),
            signal: abortSignal,
            params: query,
            paramsSerializer: (params) => {
                return qs.stringify(params, { arrayFormat: "repeat" });
            },
        })
            .then((response) => {
                if (!response.data) return null;
                return {
                    employees: response.data.employees,
                    total: response.data.total
                }
            })
            .catch((error) => {
                throw error;
            });
    }

    

    getEmployeeSummary(locationId: string, employeeValmetId: string): Promise<EmployeeSummaryDTO | null> {
        return HttpClient.sessionRequest<EmployeeSummaryDTO>({
            method: "GET",
            url: Route(`/api/v1/Locations/${locationId}/Employees/${employeeValmetId}`),

        })
            .then((response) => {
                if (!response.data) return null;
                
                return response.data; 
            })
            .catch((error) => {
                throw error;
            });
    }



    getEmployeeBonus(locationId: string, employeeValmetId: string): Promise<EmployeeSummaryDTO | null> {
        return HttpClient.sessionRequest<EmployeeSummaryDTO>({
            method: "GET",
            url: Route(`/api/v1/Locations/${locationId}/Employees/${employeeValmetId}`),

        })
            .then((response) => {
                if (!response.data) return null;
                
                return response.data; 
            })
            .catch((error) => {
                throw error;
            });
    }

}
