import "./ViewRolesPage.css";

import { useCallback, useEffect, useMemo, useState } from "react";
import { useClosePopup, useCreateNotification, usePopup, useWindowResize } from "../../../lib/infrastructure/ui/UIServices";
import { useNavigate, useParams } from "react-router-dom";
import { ReactComponent as AddIcon } from "../../../lib/assets/icons/add.svg";
import { Button } from "../../../lib/components/buttons/Button";
import { ColumnDefinition } from "../../../lib/components/table/TableInterfaces";
import { CreateRolePopup } from "./popups/CreateRolePopup";
import { ReactComponent as EditIcon } from "../../../lib/assets/icons/editar.svg";
import { EditRolePopup } from "./popups/EditRolePopup";
import { ErrorPopup } from "../../../lib/components/popup/ErrorPopup";
import { FormFieldTextInput } from "../../../lib/components/form/form-field/FormFieldTextInput";
import { FullScreenLoader } from "../../../lib/components/loader/FullScreenLoader";
import { IconButton } from "../../../lib/components/buttons/IconButton";
import { Notification } from "../../../lib/components/notifications/Notification";
import { PageLayout } from "../../../lib/layouts/main-content/PageLayout";
import { PagePermissions } from "../../../lib/infrastructure/authorization/PagePermissions";
import { Permission } from "../../../models/api/gate/Permission";
import { ResponsiveDataTable } from "../../../lib/components/table/ResponsiveDataTable";
import { RoleSummary } from "../models/domain/RoleSummary";
import { RolesQueryOptions } from "../models/dtos/RolesQueryOptions";
import { RolesService } from "../services/RolesService";
import { ScalableIconButton } from "../../../lib/components/buttons/ScalableIconButton";
import { ReactComponent as SearchIcon } from "../../../lib/assets/icons/search-icon.svg";
import { Toggle } from "../../../lib/components/form/toggle/Toggle";
import { WarningPopup } from "../../../lib/components/popup/WarningPopup";
import axios from "axios";
import { translate } from "../../../lib/infrastructure/i18n/InternationalizationService";
import { useFormControl } from "../../../lib/components/form/Form";
import { useGlobalBusinessLine } from "../../../lib/infrastructure/business-line/BusinessLineServices";
import { useGlobalLocation } from "../../../lib/infrastructure/location/LocationServices";
import { useHasPermissions } from "../../../lib/infrastructure/authorization/useHasLocationPermissions";
import { useServiceCallPro } from "../../../lib/hooks/useServiceCall";

var rolesSvc = new RolesService();

export interface IOrderInfo {
  direction: "ASC" | "DESC" | "NONE";
  columnKey: string;
}

const basePermissions = [Permission.GLOBAL_ADMIN, Permission.LOCATION_ADMIN, Permission.HUMAN_RESOURCES, Permission.LOCATION_MANAGER];
const topPermissions = [Permission.GLOBAL_ADMIN, Permission.LOCATION_ADMIN, Permission.HUMAN_RESOURCES];

export function ViewRolesPage() {

  const { isLoadingPermissions, hasPermission } = useHasPermissions(topPermissions);
  const navigate = useNavigate();
  const closePopup = useClosePopup();
  const createNotification = useCreateNotification();

  const { id: businessLineID } = useParams();

  const windowResize = useWindowResize();

  const openPopup = usePopup();
  const locationID = useGlobalLocation();

  const [roles, setRoles] = useState<RoleSummary[]>([]);
  const [totalItems, setTotalItems] = useState<number>();

  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [currentpage, setCurrentPage] = useState(0);

  const [timer, setTimer] = useState<NodeJS.Timeout>();
  const [searchWord, setSearchWord] = useState<string>();

  var searchboxController = useFormControl<string>({
    isDisabled: false,
    enableAutoValidate: false,
  });


  /*****************
   * SERVICE CALLS 
   ****************/

  const getRolesCall = useServiceCallPro(rolesSvc.getRoles);
  const updateRoleStateCall = useServiceCallPro(rolesSvc.updateRoleState);

  const useSelectedBusinessLine = useGlobalBusinessLine();



  useEffect(() => {

    const controller = new AbortController();
    getRoles(controller);
    return () => { controller.abort() };

  }, [currentpage, itemsPerPage, searchWord]);




  const getRoles = useCallback((controller: AbortController | undefined) => {

    if (!locationID || !businessLineID) {
      console.log("## Cannot GET Roles based on invalid values ##", {
        "Location_ID": locationID,
        "BL_ID": businessLineID
      });
      return;
    }

    var queryOpts: RolesQueryOptions = {
      searchWord: searchWord,
      page: currentpage,
      pageLength: itemsPerPage
    };

    getRolesCall.invoke(`${locationID}`, "" + businessLineID, queryOpts, controller?.signal)
      .then((data) => {
        setRoles(data?.roles || []);
        setTotalItems(data?.totalItems);
      })
      .catch((error) => {
        if (!error || axios.isCancel(error)) return;
        openPopup(<ErrorPopup>{error.response.data.message}</ErrorPopup>);
      });
  }, [locationID, businessLineID, searchWord, currentpage, itemsPerPage, getRolesCall, openPopup]);



  useEffect(() => {
    if (timer)
      clearTimeout(timer);

    var responseTimeOut = setTimeout(() => {
      if (timer)
        setSearchWord(searchboxController.value);
      setCurrentPage(0);
    }, 1000);

    setTimer(responseTimeOut);

  }, [searchboxController.value]);





  const handleOnPageAndItemsChanged = useCallback(
    (items: number, currentPage: number) => {
      setItemsPerPage(items);
      setCurrentPage(currentPage);
    },
    [setItemsPerPage, setCurrentPage]
  );





  const handleUpdateRoleStateClicked = useCallback((roleID: number, isToDisable: boolean) => {
    if (!locationID || !businessLineID || !roleID) {
      console.log("## Cannot Update Role State based on invalid values ##", {
        "Location_ID": locationID,
        "BL_ID": businessLineID,
        "Role_ID": roleID
      });
      return;
    }

    updateRoleStateCall.invoke("" + locationID, businessLineID, roleID, isToDisable)
      .then((_) => {
        getRoles(undefined);
        createNotification(<Notification type="success" title={translate("COMMON.SYSTEMPOPUPS.Success")} text={isToDisable ? translate("BUSINESSLINES.ROLES.POPUP.DeactivateRoleSuccess") : translate("BUSINESSLINES.ROLES.POPUP.ActivateRoleSuccess")}></Notification>);
      })
      .catch((error) => {
        if (!error) return;
        openPopup(<ErrorPopup>{error.response.data.message}</ErrorPopup>);
      });


  }, [locationID, businessLineID, updateRoleStateCall, getRoles, createNotification, openPopup]);



  const canEditRole = useMemo(() => {

    if (isLoadingPermissions || !hasPermission)
      return false;

    if (!locationID || !businessLineID)
      return false;

    if (!useSelectedBusinessLine || useSelectedBusinessLine.isDisabled)
      return false;

    return true;

  }, [businessLineID, hasPermission, isLoadingPermissions, locationID, useSelectedBusinessLine]);




  const columns: ColumnDefinition<RoleSummary>[] = useMemo(
    () => [
      {
        cellRenderProp: (v) => v.description,
        headerRender: translate("BUSINESSLINES.ROLES.INFOANDFORM.Role"),
        isMobilePrimaryCell: true,
      },
      {
        cellRenderProp: (v) => v.jobGrade ?? "N/A",
        headerRender: translate("BUSINESSLINES.ROLES.INFOANDFORM.JobGrade"),
        width: "20%",
      },
      {
        cellRenderProp: (v) => v.numberOfPositions,
        headerRender: translate("BUSINESSLINES.ROLES.INFOANDFORM.JobPositions"),
        width: "20%",
      },
      {
        cellRenderProp: (v) => (
          <div className="toggle-div">
            {useSelectedBusinessLine && !useSelectedBusinessLine.isDisabled ? (
              <>
                <Toggle value={!v.isDisabled} />
                <div
                  className="toggle-div-overlay"
                  onClick={(ev) => {
                    ev.stopPropagation();
                    if (!v.isDisabled) {
                      openPopup(
                        <WarningPopup
                          className="disable-role-popup"
                          onDismissClicked={() => closePopup()}
                          onConfirmClicked={() => handleUpdateRoleStateClicked(v.roleId, true)}
                          dismissText={translate("COMMON.Cancel")}
                          confirmText={translate("BUSINESSLINES.ROLES.POPUP.Deactivate")}
                        >
                          {translate("BUSINESSLINES.ROLES.POPUP.DeactivateRoleConfirmText")}
                        </WarningPopup>
                      );
                    } else {
                      if (v.roleId) {
                        openPopup(
                          <WarningPopup
                            onDismissClicked={() => closePopup()}
                            onConfirmClicked={() => handleUpdateRoleStateClicked(v.roleId, false)}
                            dismissText={translate("COMMON.Cancel")}
                            confirmText={translate("BUSINESSLINES.ROLES.POPUP.Activate")}
                          >
                            {translate("BUSINESSLINES.ROLES.POPUP.ActivateRoleConfirmText")}
                          </WarningPopup>
                        );
                      }
                    }
                  }}
                />
              </>
            ) : null}
          </div>
        ),
        headerRender: <></>,
        columnKey: "status",
        width: "5%",
        isSortable: false,
        isMobilePrimaryCell: true,
      },
      {
        cellRenderProp: (v) => (
          canEditRole ? (
            <ScalableIconButton
              icon={<EditIcon />}
              onClick={() =>
                openPopup(
                  <EditRolePopup
                    roleId={v.roleId}
                    businessLineId={String(businessLineID)}
                    locationId={String(locationID)}
                    onCompleted={() => getRoles(undefined)}
                  />
                )
              }
              size={24}
            />
          ) : null
        ),
        columnKey: "edit",
        width: "24px",
        isMobileHeaderIcon: true,
      },
    ], [useSelectedBusinessLine, openPopup, closePopup, handleUpdateRoleStateClicked, canEditRole, businessLineID, locationID, getRoles]);



  const renderCreateRoleBtn = useMemo(() => {

    if (isLoadingPermissions || !hasPermission) return null;

    if (windowResize > 768)
      return (
        <Button
          text={translate("BUSINESSLINES.ROLES.CreateRole")}
          type="primary"
          onClick={() => locationID && businessLineID ?
            openPopup(<CreateRolePopup
              locationId={`${locationID}`}
              businessLineId={"" + businessLineID}
              onCompletedOperations={() => getRoles(undefined)} />) : null}
        />
      );
    else
      return (
        <IconButton
          icon={<AddIcon />}
          type="primary"
          onClick={() => locationID && businessLineID ?
            openPopup(<CreateRolePopup
              locationId={`${locationID}`}
              businessLineId={"" + businessLineID}
              onCompletedOperations={() => getRoles(undefined)} />) : null}
        />
      );
  }, [isLoadingPermissions, hasPermission, windowResize, locationID, businessLineID, openPopup, getRoles]);



  return (
    <PagePermissions permissions={basePermissions} >
      <PageLayout
        tabTitle={translate("BUSINESSLINES.ROLES.Roles")}
        pageTitle={translate("BUSINESSLINES.ROLES.Roles")}
        className="roles"
        showBreadcrumb
        actions={useSelectedBusinessLine && !useSelectedBusinessLine.isDisabled && renderCreateRoleBtn}
      >

        {updateRoleStateCall.isLoading && <FullScreenLoader />}

        <FormFieldTextInput
          formControl={searchboxController}
          icon={<SearchIcon />}
          placeholder={translate("COMMON.FORM.SearchPlaceholder")}
          className="search-box"
        />
        <ResponsiveDataTable
          columnDefinitions={columns}
          items={roles || []}
          totalitems={totalItems || 0}
          paginationOptions={{ itemsPerPage: itemsPerPage, itemsPerPageOptions: [10, 20, 30] }}
          currentpage={currentpage}
          isLoading={getRolesCall.isLoading}
          onPageAndItemsChanged={handleOnPageAndItemsChanged}
          onClickRow={(role) => navigate(`${role.roleId}`)}
        />
      </PageLayout>
    </PagePermissions>

  );
}
