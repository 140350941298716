import { Navigate, Outlet, Route, Routes } from "react-router-dom";

import { BusinessLinesContainer } from "../common/layouts/content/BusinessLinesContainer";
import { CareerLadderPage } from "../features/career-ladder/pages/CareerLadderPage";
import { CreateEvaluationMatrixPage } from "../features/matrices/pages/CreateEvaluationMatrixPage";
import { EditEvaluationMatrixPage } from "../features/matrices/pages/EditEvaluationMatrixPage";
import { EvaluationMatrixPage } from "../features/matrices/pages/EvaluationMatrixPage";
import { EvaluationPage } from "../features/evaluations/pages/evaluation-detail-page/EvaluationPage";
import { EvaluationPeriodAbsencesPage } from "../features/periods/pages/EvaluationPeriodAbsencesPage";
import { EvaluationPeriodCollaboratorsPage } from "../features/periods/pages/EvaluationPeriodCollaboratorsPage";
import { EvaluationPeriodDetailsPage } from "../features/periods/pages/EvaluationPeriodDetailsPage";
import { EvaluationPeriodMatrixDetailsPage } from "../features/periods/pages/EvaluationPeriodMatrixDetailsPage";
import { ModuleDefinition } from "../lib/infrastructure/modules/ModuleDefinition";
import { Permission } from "../models/api/gate/Permission";
import { RoleDetailsPage } from "../features/roles/pages/RoleDetailsPage";
import { RouteGuard } from "../lib/infrastructure/authorization/RouteGuard";
import { ViewBusinessLinesPage } from "../features/business-lines/pages/ViewBusinessLinesPage";
import { ViewEvaluationMatricesPage } from "../features/matrices/pages/ViewEvaluationMatricesPage";
import { ViewEvaluationPeriodsPage } from "../features/periods/pages/ViewEvaluationPeriodsPage";
import { ViewRolesPage } from "../features/roles/pages/ViewRolesPage";
import { translate } from "../lib/infrastructure/i18n/InternationalizationService";
import { useEffect } from "react";
import { useRegisterBreadcrumbRoutes } from "../lib/infrastructure/navigation/hooks/useBreadcrumb";

const moduleDefinition: ModuleDefinition = {
    basePath: "/careerladder",
    routes: [
        {
            name: () => translate("BUSINESSLINES.BusinessLines"),
            pathPattern: "/businesslines",
        },
        {
            name: () => "##BUSINESSLINE##",
            pathPattern: "/businesslines/:id/roles",
        },
        {
            name: () => "##ROLE##",
            pathPattern: "/businesslines/:id/roles/:roleId",
        },
        {
            name: () => "##BUSINESSLINE##",
            pathPattern: "/businesslines/:id/periods",
        },
        {
            name: () => "##EVALUATIONPERIOD##",
            pathPattern: "/businesslines/:id/periods/:periodId",
        },
        {
            name: () => translate("BUSINESSLINES.EVALUATIONPERIODS.ABSENCES.Absences"),
            pathPattern: "/businesslines/:id/periods/:periodId/absences",
        },
        {
            name: () => "##AREA##",
            pathPattern: "/businesslines/:id/periods/:periodId/matrices/:matrixId",
        },
        {
            name: () => "##EMPLOYEE##",
            pathPattern: "/businesslines/:id/periods/:periodId/matrices/:matrixId/evaluations/:evaluationId",
        },
        {
            name: () => "##BUSINESSLINE##",
            pathPattern: "/businesslines/:id/matrix",
        },
        {
            name: () => "##AREA##",
            pathPattern: "/businesslines/:id/matrix/:matrixId",
        },
        {
            name: () => "##MATRIX##",
            pathPattern: "/businesslines/:id/matrix/:matrixId/versions/:versionId/details",
        },
        {
            name: () => "##BUSINESSLINE##",
            pathPattern: "/businesslines/:id/career",
        },
        {
            name: () => translate("EMPLOYEES.Employees"),
            pathPattern: "/employees",
        }
    ],
};


export const humanResourcePermissions = [
    Permission.LOCATION_ADMIN,
    Permission.HUMAN_RESOURCES
];

export const adminPermissions = [
    Permission.GLOBAL_ADMIN,
    Permission.LOCATION_ADMIN,
    Permission.HUMAN_RESOURCES
];

export const basePermissions = [
    Permission.GLOBAL_ADMIN,
    Permission.LOCATION_ADMIN,
    Permission.HUMAN_RESOURCES,
    Permission.LOCATION_MANAGER
];


export function CareerLadderModule() {
    const registerBreadcrumbRoutes = useRegisterBreadcrumbRoutes();

    useEffect(() => {
        registerBreadcrumbRoutes(moduleDefinition);
    }, []);


    return (
        <RouteGuard permissions={basePermissions}>
            <Routes>
                <Route path="/" element={<Navigate to="businesslines" />} />
                <Route path="businesslines" element={<ViewBusinessLinesPage />} />
                <Route
                    path="businesslines/:id"
                    element={
                        <BusinessLinesContainer>
                            <Outlet />
                        </BusinessLinesContainer>}
                >

                    {/* <Route index element={<PermissionNavigate to="periods" fallbackTo="matrix" permissions={basePermissions} />} /> */}

                    <Route index element={<Navigate to="periods" />} />

                    <Route path="roles" element={<ViewRolesPage />} />
                    <Route path="roles/:roleId" element={<RoleDetailsPage />} />

                    <Route path="periods" element={<ViewEvaluationPeriodsPage />} />
                    <Route path="periods/:periodId" element={<EvaluationPeriodDetailsPage />} />

                    <Route path="periods/:periodId/absences" element={<EvaluationPeriodAbsencesPage />} />
                    <Route path="periods/:periodId/collaborators" element={<EvaluationPeriodCollaboratorsPage />} />

                    <Route path="periods/:periodId/matrices/:matrixId" element={<EvaluationPeriodMatrixDetailsPage />} />
                    <Route path="periods/:periodId/matrices/:matrixId/evaluations/:evaluationId" element={<EvaluationPage />} />

                    <Route path="matrix" element={<ViewEvaluationMatricesPage />} />
                    <Route path="matrix/:matrixId" element={<EvaluationMatrixPage />} />
                    <Route path="matrix/:matrixId/versions/:versionId/details" element={<EditEvaluationMatrixPage />} />
                    <Route path="matrix/create" element={<CreateEvaluationMatrixPage />} />

                    <Route path="career" element={<CareerLadderPage />} />
                </Route>
            </Routes>
        </RouteGuard>
    );
}