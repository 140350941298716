import { ConfigurationProvider } from "../../../lib/infrastructure/configuration/ConfigurationProvider";
import { EvaluationPeriodAbsencesImportResponseDTO } from "../models/dtos/EvaluationPeriodAbsencesImportResponseDTO";
import { EvaluationPeriodAbsenteeismResponseDTO } from "../models/dtos/AbsencesResponseDTO";
import { HttpClient } from "../../../lib/infrastructure/http/HttpClient";
import { QueryOptions } from "../../../models/api/QueryOptions";
import qs from "qs";

const Route = (path: string) => {
    return ConfigurationProvider.getConfiguration().App.BackendUrl + path;
};

export class AbsenteeismCodeService {

    getAbsences(locationId: string, businessLineId: string, evaluationPeriodID: string, query: QueryOptions, abortSignal: AbortSignal | undefined): Promise<EvaluationPeriodAbsenteeismResponseDTO | null> {

        return HttpClient.sessionRequest<EvaluationPeriodAbsenteeismResponseDTO>({
            method: "GET",
            url: Route(`/api/v1/Locations/${locationId}/BusinessLines/${businessLineId}/EvaluationPeriods/${evaluationPeriodID}/Absenteeism`),
            signal: abortSignal,
            params: query,
            paramsSerializer: (params) => {
                return qs.stringify(params, { arrayFormat: "repeat" });
            },
        })
            .then((response) => {
                if (!response) return null;
                return response.data;
            })
            .catch((error) => {
                throw error;
            });
    }


    importAbsences(locationId: string, businessLineId: string, evaluationPeriodID: string, file: File): Promise<EvaluationPeriodAbsencesImportResponseDTO | null> {
        var formData = new FormData();
        formData.append("file", file)

        return HttpClient.sessionRequest<EvaluationPeriodAbsencesImportResponseDTO>({
            method: "POST",
            headers: {
                "Content-Type": "multipart/form-data",
                "Accept": "application/json",
            },
            url: Route(`/api/v1/Locations/${locationId}/BusinessLines/${businessLineId}/EvaluationPeriods/${evaluationPeriodID}/Absenteeism/FromXLSX`),
            data: formData
        })
            .then((response) => {
                if (!response.data) return null;
                return ({
                    invalidAbsenteeismsCount: response.data.invalidAbsenteeismsCount
                })
            })
            .catch((error) => {
                throw error;
            });
    }

}