import React, { createContext, useContext,  useState } from "react";
import { BusinessLineDTO } from "../../../features/admin/business-lines/models/dtos/BusinessLineDTO";



export interface BusinessLineSvcsContextState {
  businessLine: BusinessLineDTO | undefined;
  setBusinessLine: React.Dispatch<React.SetStateAction<BusinessLineDTO | undefined>>;
}

export const BusinessLineSvcContext = createContext<BusinessLineSvcsContextState | null>(null);

interface IBusinessLineSvcsContextProps {
  children: React.ReactNode;
}



export function BusinessLineServices({ children }: IBusinessLineSvcsContextProps) {
  const [businessLine, setBusinessLine] = useState<BusinessLineDTO>();

  const value = { businessLine, setBusinessLine };

  return (
    <BusinessLineSvcContext.Provider value={value}>
      {children}
    </BusinessLineSvcContext.Provider>
  );
}

export function useGlobalBusinessLine() {
  const context = useContext(BusinessLineSvcContext);
  if (!context) {
    throw new Error("useGlobalBusinessLine must be used within BusinessLineServices");
  }

  return context.businessLine;
}

export function useSetGlobalBusinessLine() {
  const context = useContext(BusinessLineSvcContext);
  if (!context) {
    throw new Error("useSetGlobalBusinessLine must be used within BusinessLineServices");
  }

  return context.setBusinessLine;
}
