import { useCallback, useState } from "react";
import { useClosePopup, useCreateNotification, usePopup } from "../../../../lib/infrastructure/ui/UIServices";

import { AllValid } from "../../../../common/validators/ValidateFormControls";
import { ErrorPopup } from "../../../../lib/components/popup/ErrorPopup";
import { ExceptionType } from "../../../../lib/infrastructure/http/ExceptionType";
import { FormFieldTextArea } from "../../../../lib/components/form/form-field/FormFieldTextArea";
import { FormFieldTextInput } from "../../../../lib/components/form/form-field/FormFieldTextInput";
import { FormSection } from "../../../../lib/components/form/section/FormSection";
import { FullScreenLoader } from "../../../../lib/components/loader/FullScreenLoader";
import { JobPositionsService } from "../../services/JobPositionsService";
import { MessageBar } from "../../../../lib/components/message-bar/MessageBar";
import { Notification } from "../../../../lib/components/notifications/Notification";
import { PopupActionButtons } from "../../../../lib/layouts/containers/popup-buttons/PopupActionButtons";
import { PopupContainer } from "../../../../lib/layouts/containers/popup-container/PopupContainer";
import { PopupContent } from "../../../../lib/layouts/containers/popup-content/PopupContent";
import { PopupHeader } from "../../../../lib/layouts/containers/popup-header/PopupHeader";
import { PositionCreateDTO } from "../../models/dtos/PositionCreateDTO";
import { Spacer } from "../../../../lib/components/separator/Spacer";
import { translate } from "../../../../lib/infrastructure/i18n/InternationalizationService";
import { trimString } from "../../../../lib/utils/TrimString";
import { useFormControl } from "../../../../lib/components/form/Form";
import { useServiceCallPro } from "../../../../lib/hooks/useServiceCall";
import { validateWhiteSpaceStrings } from "../../../../lib/validators/ValidateWhiteSpaceStrings";

interface IAddJobPositionPopupProps {
    onCompletedOperations: () => void;
    locationId: string;
    businessLineId: string;
    roleId: string;
}

var jobPositionsSvc = new JobPositionsService();

export function AddJobPositionPopup(props: IAddJobPositionPopupProps) {
    const closePopup = useClosePopup();
    const openPopup = usePopup();
    const createNotification = useCreateNotification();

    const addJobPositionCall = useServiceCallPro(jobPositionsSvc.addJobPosition);

    const nameFormControl = useFormControl<string>({
        validators: [validateWhiteSpaceStrings()],
        enableAutoValidate: true,
    });

    const goalFormControl = useFormControl<string>({
        validators: [validateWhiteSpaceStrings()],
        enableAutoValidate: true,
    });

    const descriptionFormControl = useFormControl<string>({
        validators: [validateWhiteSpaceStrings()],
        enableAutoValidate: true,
    });

    const qualificationFormControl = useFormControl<string>({
        validators: [validateWhiteSpaceStrings()],
        enableAutoValidate: true,
    });

    const [validationAlert, setValidationAlert] = useState("");


    const handleAddJobPosition = useCallback(() => {
        if (!AllValid(nameFormControl.validate(), goalFormControl.validate(), descriptionFormControl.validate(), qualificationFormControl.validate())) return;

        let request: PositionCreateDTO = {
            name: trimString(nameFormControl.value),
            objective: trimString(goalFormControl.value),
            description: trimString(descriptionFormControl.value),
            qualification: trimString(qualificationFormControl.value),
        };

        addJobPositionCall
            .invoke(props.locationId, props.businessLineId, props.roleId, request)
            .then(() => {
                props.onCompletedOperations();
                createNotification(
                    <Notification
                        type="success"
                        title={translate("COMMON.SYSTEMPOPUPS.Success")}
                        text={translate("BUSINESSLINES.ROLES.JOBPOSITIONS.POPUP.AddJobPositionSuccess")}
                    />
                );
                closePopup();
            })
            .catch((error) => {
                if (!error) return;

                const errorMessage = error?.response?.data?.message || translate("ERRORS.UnknownError");
                const enumCode = error?.response?.data?.enumCode;

                if (enumCode === ExceptionType.ERROR_VALIDATION_NAME_ALREADY_EXISTS) {
                    setValidationAlert(errorMessage);
                } else {
                    openPopup(<ErrorPopup>{errorMessage}</ErrorPopup>);
                }
            });
    }, [props.businessLineId, props.locationId, props.roleId, props.onCompletedOperations, nameFormControl, goalFormControl, descriptionFormControl, qualificationFormControl, addJobPositionCall, closePopup, createNotification, openPopup]);


    return (
        <PopupContainer className="popup-create-role">
            {addJobPositionCall.isLoading ? <FullScreenLoader /> : null}

            <PopupHeader title={translate("BUSINESSLINES.ROLES.JOBPOSITIONS.POPUP.AddJobPosition")} />
            <PopupContent>
                <FormSection>
                    <FormFieldTextInput
                        formControl={nameFormControl}
                        label={translate("BUSINESSLINES.ROLES.JOBPOSITIONS.INFOANDFORM.Name")}
                        placeholder={translate("BUSINESSLINES.ROLES.JOBPOSITIONS.INFOANDFORM.NamePlaceholder")}
                    />
                    <FormFieldTextArea
                        formControl={goalFormControl}
                        label={translate("BUSINESSLINES.ROLES.JOBPOSITIONS.INFOANDFORM.Goal")}
                    // placeholder={translate("BUSINESSLINES.ROLES.JOBPOSITIONS.INFOANDFORM.GoalPlaceholder")}
                    />
                    <FormFieldTextArea
                        formControl={descriptionFormControl}
                        label={translate("BUSINESSLINES.ROLES.JOBPOSITIONS.INFOANDFORM.Description")}
                    // placeholder={translate("BUSINESSLINES.ROLES.JOBPOSITIONS.INFOANDFORM.DescriptionPlaceholder")}
                    />
                    <FormFieldTextArea
                        formControl={qualificationFormControl}
                        label={translate("BUSINESSLINES.ROLES.JOBPOSITIONS.INFOANDFORM.Qualification")}
                    // placeholder={translate("BUSINESSLINES.ROLES.JOBPOSITIONS.INFOANDFORM.QualificationPlaceholder")}
                    />
                </FormSection>
                
                {validationAlert && <>
                        <Spacer mode={"vertical"} px="30" />
                        <MessageBar type="error" text={validationAlert} />
                </>}

                <Spacer mode={"vertical"} px="30" />
                <PopupActionButtons
                    buttons={[
                        {
                            text: translate("COMMON.Cancel"),
                            type: "tertiary",
                            onClick: () => closePopup(),
                        },
                        {
                            text: translate("COMMON.Add"),
                            type: "primary",
                            onClick: handleAddJobPosition,
                        },
                    ]}
                />
            </PopupContent>
        </PopupContainer>
    );
}
