import { AllValid } from "../../../common/validators/ValidateFormControls";
import { FormFieldTextArea } from "../../../lib/components/form/form-field/FormFieldTextArea";
import { PopupActionButtons } from "../../../lib/layouts/containers/popup-buttons/PopupActionButtons";
import { PopupContainer } from "../../../lib/layouts/containers/popup-container/PopupContainer";
import { PopupContent } from "../../../lib/layouts/containers/popup-content/PopupContent";
import { PopupHeader } from "../../../lib/layouts/containers/popup-header/PopupHeader";
import { Spacer } from "../../../lib/components/separator/Spacer";
import { translate } from "../../../lib/infrastructure/i18n/InternationalizationService";
import { useCallback } from "react";
import { useClosePopup } from "../../../lib/infrastructure/ui/UIServices";
import { useFormControl } from "../../../lib/components/form/Form";
import { validateWhiteSpaceStrings } from "../../../lib/validators/ValidateWhiteSpaceStrings";

interface IAddChecklistItemPopupProps {
    onCompletedOperations: (description: string) => void;
    locationId: string | undefined;
    businessLineId: string | undefined;
    matrixId?: string | undefined;
}

export function AddChecklistItemPopup(props: IAddChecklistItemPopupProps) {
    const closePopup = useClosePopup();

    const descriptionFormControl = useFormControl<string>({
        validators: [validateWhiteSpaceStrings()],
        enableAutoValidate: true,
    });


    const handleAddClicked = useCallback(() => {
        if (!AllValid(descriptionFormControl.validate())) return;

        props.onCompletedOperations(descriptionFormControl.value as string);
        closePopup();
    }, [props.onCompletedOperations, closePopup, descriptionFormControl]);


    return (
        <PopupContainer className="popup-add-checklist-item">
            <PopupHeader title={translate("BUSINESSLINES.MATRIX.POPUP.AddChecklistItem")} />
            <PopupContent>
                <FormFieldTextArea
                    formControl={descriptionFormControl}
                    label={translate("BUSINESSLINES.MATRIX.INFOANDFORM.Description")}
                    placeholder={translate("BUSINESSLINES.MATRIX.INFOANDFORM.DescriptionPlaceholder")}
                />
                <Spacer mode={"vertical"} px="30" />
                <PopupActionButtons
                    buttons={[
                        {
                            text: translate("COMMON.Cancel"),
                            type: "tertiary",
                            onClick: () => closePopup(),
                        },
                        {
                            text: translate("COMMON.Add"),
                            type: "primary",
                            onClick: handleAddClicked,
                        },
                    ]}
                />
            </PopupContent>
        </PopupContainer>
    );
}
