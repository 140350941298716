import "./LocationsContainer.css";

import { useEffect, useMemo } from "react";

import { ReactComponent as BusinessLinesIcon } from "../../../lib/assets/icons/business-lines.svg";
import { ReactComponent as GroupsAccessIcon } from "../../../lib/assets/icons/groups-access.svg";
import { LocationService } from "../locations/services/LocationServices";
import { MobileSubSidebar } from "../../../lib/components/side-bar/MobileSubSidebar";
import { ReactComponent as SkillsIcon } from "../../../lib/assets/icons/skills.svg";
import { SubSidebar } from "../../../lib/components/side-bar/SubSidebar";
import { translate } from "../../../lib/infrastructure/i18n/InternationalizationService";
import { useParams } from "react-router-dom";
import { useUpdatePageData } from '../../../lib/infrastructure/navigation/hooks/useUpdatePageData';
import { useWindowResize } from "../../../lib/infrastructure/ui/UIServices";

var locationService = new LocationService();
interface ILocationsContainerProps {
  children: React.ReactNode;
}


export function LocationsContainer(props: ILocationsContainerProps) {
  const windowResize = useWindowResize();


  const subSideBarButtons = useMemo(() => [
    {
      icon: <GroupsAccessIcon />,
      navigateTo: "groupaccess",
      name: translate("GLOBALADMIN.LOCATIONS.AccessGroups"),
    },
    {
      icon: <BusinessLinesIcon />,
      navigateTo: "businesslines",
      name: translate("GLOBALADMIN.LOCATIONS.BUSINESSLINES.BusinessLines"),
    },
    {
      icon: <SkillsIcon />,
      navigateTo: "skills",
      name: translate("GLOBALADMIN.LOCATIONS.SKILLS.SkillsCategories"),
    },
  ], []);


  const renderSubSideBar = useMemo(() => {
    return windowResize > 425 ? <SubSidebar buttons={subSideBarButtons} /> : <MobileSubSidebar buttons={subSideBarButtons} />;
  }, [windowResize, subSideBarButtons]);


  const { id } = useParams();
  const updateRouteData = useUpdatePageData();

  useEffect(() => {
    locationService
      .getLocationById(id || "")
      .then((response) => {
        updateRouteData(["##LOCATION##", response.name]);
      })
      .catch((error) => { });
  }, []);

  return (
    <div className="locations-content-container">
      {renderSubSideBar}
      {props.children}
    </div>
  );
}
