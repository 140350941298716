import "./EmployeesPage.css";

import { useCallback, useEffect, useMemo, useState } from "react";

import { ColumnDefinition } from "../../../lib/components/table/TableInterfaces";
import { EmployeeSummaryDTO } from "../models/dtos/EmployeeSummaryDTO";
import { EmployeesService } from "../services/EmployeesService";
import { ErrorPopup } from "../../../lib/components/popup/ErrorPopup";
import { FormFieldTextInput } from "../../../lib/components/form/form-field/FormFieldTextInput";
import { PageLayout } from "../../../lib/layouts/main-content/PageLayout";
import { QueryOptions } from "../../../models/api/QueryOptions";
import { ResponsiveDataTable } from "../../../lib/components/table/ResponsiveDataTable";
import { ReactComponent as SearchIcon } from "../../../lib/assets/icons/search-icon.svg";
import axios from "axios";
import { translate } from "../../../lib/infrastructure/i18n/InternationalizationService";
import { useFormControl } from "../../../lib/components/form/Form";
import { useGlobalLocation } from "../../../lib/infrastructure/location/LocationServices";
import { useNavigate } from "react-router-dom";
import { usePopup } from "../../../lib/infrastructure/ui/UIServices";
import { useServiceCallPro } from "../../../lib/hooks/useServiceCall";

var svc = new EmployeesService();


export function EmployeesPage() {

    const locationID = useGlobalLocation();
    const locationIDAsString = useMemo(() => "" + locationID, [locationID])

    const navigate = useNavigate();
    const openPopup = usePopup();

    const [employees, setEmployees] = useState<EmployeeSummaryDTO[]>();
    const [totalItems, setTotalItems] = useState<number>();

    const [itemsPerPage, setItemsPerPage] = useState(10);
    const [currentpage, setCurrentPage] = useState(0);

    const [timer, setTimer] = useState<NodeJS.Timeout>();
    const [searchWord, setSearchWord] = useState<string>();

    const getEmployeesCall = useServiceCallPro(svc.getEmployees);

    var searchboxController = useFormControl<string>({
        isDisabled: false,
        enableAutoValidate: false,
    });


    /****************************
    * DATA REQUESTS
    *****************************/

    const getEmployees = useCallback((controller: AbortController | undefined) => {
        if (!locationID) return;

        var queryOpts: QueryOptions = {
            searchWord: searchWord,
            page: currentpage,
            pageLength: itemsPerPage
        };

        getEmployeesCall.invoke(locationIDAsString, queryOpts, controller?.signal)
            .then((data) => {
                if (data) {
                    setEmployees(data.employees);
                    setTotalItems(data.total)
                }
            })
            .catch((error) => {
                if (!error || axios.isCancel(error)) return;
                openPopup(<ErrorPopup>{error.response.data.message}</ErrorPopup>);
            });
    }, [locationIDAsString, locationID, getEmployeesCall.invoke, openPopup, currentpage, itemsPerPage, searchWord]);


    /****************************
     * DATA MANIPULATION EFFECTS
     *****************************/

    useEffect(() => {
        const controller = new AbortController();
        getEmployees(controller);
        return () => { controller.abort() };
    }, [currentpage, itemsPerPage, searchWord]);

    useEffect(() => {
        if (timer) {
            clearTimeout(timer);
        }

        var responseTimeOut = setTimeout(() => {
            if (timer)
                setSearchWord(searchboxController.value);
            setCurrentPage(0);
        }, 1000);

        setTimer(responseTimeOut);
    }, [searchboxController.value]);


    /****************************
     * USER ACTIONS
     *****************************/

    const handleOnPageAndItemsChanged = useCallback(
        (items: number, currentPage: number) => {
            setItemsPerPage(items);
            setCurrentPage(currentPage);
        },
        [setItemsPerPage, setCurrentPage]
    );


    /****************************
     * CSS & HTML
     *****************************/

    const columns: ColumnDefinition<EmployeeSummaryDTO>[] = useMemo(
        () => [
            {
                columnKey: `employeeName`,
                cellRenderProp: (v) =>
                    <div className="employee-info">
                        <div className="small-copy employee-name">
                            {v.displayName}
                        </div>
                        <div className="caption employee-id">{v.valmetId ?? "-"}</div>
                    </div>,
                width: "30%",
                headerRender: translate("BUSINESSLINES.EVALUATIONS.INFOANDFORM.Name"),
                isMobilePrimaryCell: true,
            },

            {
                cellRenderProp: (v) => v.email,
                headerRender: translate("EMPLOYEES.INFOANDFORM.Email"),
                width: "55%",
            },
            {
                cellRenderProp: (v) => v.managerDisplayName,
                headerRender: translate("EMPLOYEES.INFOANDFORM.Manager"),
                width: "20%",
            }
        ], []);


    return <PageLayout
        tabTitle={translate("EMPLOYEES.Employees")}
        pageTitle={translate("EMPLOYEES.Employees")}
        className="employees-page"
    >
        <FormFieldTextInput
            formControl={searchboxController}
            icon={<SearchIcon />}
            placeholder={translate("COMMON.FORM.SearchPlaceholder")}
            className="search-box"
        />
        <ResponsiveDataTable
            columnDefinitions={columns}
            items={employees || []}
            totalitems={totalItems || 0}
            paginationOptions={{ itemsPerPage: itemsPerPage, itemsPerPageOptions: [10, 20, 30] }}
            currentpage={currentpage}
            isLoading={getEmployeesCall.isLoading}
            onPageAndItemsChanged={handleOnPageAndItemsChanged}
            onClickRow={(employee) => navigate(`${employee.valmetId}`)}
        />
    </PageLayout>;
}