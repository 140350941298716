export enum EvaluationPeriodState {
    Registered = 1,
    Canceled = 2,
    InProgress = 3,
    Completed = 4
}


export const EvaluationPeriodStateTranslationTerms: { [key: string]: string } = {
    1: "COMMON.STATUS.Registered",
    2: "COMMON.STATUS.Canceled",
    3: "COMMON.STATUS.InProgress",
    4: "COMMON.STATUS.Completed",
}


export const EvaluationPeriodStateAsEnum: { [key: number]: EvaluationPeriodState } = {
    1: EvaluationPeriodState.Registered,
    2: EvaluationPeriodState.Canceled,
    3: EvaluationPeriodState.InProgress,
    4: EvaluationPeriodState.Completed,
};