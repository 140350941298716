import "./EditEmployeeCertificationPopup.css";

import { EmployeeCertificationStateAsEnum, EmployeeCertificationStateInfo, EmployeeCertificationStateTranslationTerms, getEmployeeCertificationStateOptions } from "../../../../../models/enums/EmployeeCertificationState";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useClosePopup, useCreateNotification, usePopup } from "../../../../../lib/infrastructure/ui/UIServices";

import { AllValid } from "../../../../../common/validators/ValidateFormControls";
import { AttachmentDetailsDTO } from "../../../models/dtos/AttachmentDetailsDTO";
import { ColumnDefinition } from "../../../../../lib/components/table/TableInterfaces";
import { ConditionalRender } from "../../../../../lib/functional/ConditionalRender";
import { DateTime } from "luxon";
import { ReactComponent as DeleteIcon } from "../../../../../lib/assets/icons/remover.svg";
import { EmployeeCertificationUpdateDTO } from "../../../models/dtos/EmployeeCertificationUpdateDTO";
import { EmployeeCertificationsService } from "../../../services/EmployeeCertificationsService";
import { EmployeeCertificationsSummaryDTO } from "../../../models/dtos/EmployeeCertificationsSummaryDTO";
import { ErrorPopup } from "../../../../../lib/components/popup/ErrorPopup";
import { FileUploadButton } from "../../../../../lib/components/file/FileUploadButton";
import { FormFieldDatePicker } from "../../../../../lib/components/form/form-field/FormFieldDatePicker";
import { FormFieldSelectSingle } from "../../../../../lib/components/form/form-field/FormFieldSelectSingle";
import { FormFieldTextInput } from "../../../../../lib/components/form/form-field/FormFieldTextInput";
import { FormSection } from "../../../../../lib/components/form/section/FormSection";
import { FullScreenLoader } from "../../../../../lib/components/loader/FullScreenLoader";
import { ReactComponent as ImportIcon } from "../../../../../lib/assets/icons/import.svg";
import { Notification } from "../../../../../lib/components/notifications/Notification";
import { PopupActionButtons } from "../../../../../lib/layouts/containers/popup-buttons/PopupActionButtons";
import { PopupContainer } from "../../../../../lib/layouts/containers/popup-container/PopupContainer";
import { PopupContent } from "../../../../../lib/layouts/containers/popup-content/PopupContent";
import { PopupHeader } from "../../../../../lib/layouts/containers/popup-header/PopupHeader";
import { ResponsiveDataTable } from "../../../../../lib/components/table/ResponsiveDataTable";
import { ScalableIconButton } from "../../../../../lib/components/buttons/ScalableIconButton";
import { Spacer } from "../../../../../lib/components/separator/Spacer";
import { hasOneElementSelected } from "../../../../../lib/validators/ValidateSelectSingle";
import { translate } from "../../../../../lib/infrastructure/i18n/InternationalizationService";
import { useFormControl } from "../../../../../lib/components/form/Form";
import { useGlobalLocation } from "../../../../../lib/infrastructure/location/LocationServices";
import { useServiceCallPro } from "../../../../../lib/hooks/useServiceCall";
import { validateDateTime } from "../../../../../lib/validators/ValidateDateTime";
import { validateWhiteSpaceStrings } from "../../../../../lib/validators/ValidateWhiteSpaceStrings";

interface IEditEmployeeCertificationPopupProps {
    employeeId: string | undefined;
    certificationId: number;
    onCompletedOperations: () => void;
}

var svc = new EmployeeCertificationsService();

const labelCertificateStateSelector = (item: EmployeeCertificationStateInfo) => item.label;
const idCertificateStateSelector = (item: EmployeeCertificationStateInfo) => item.id;


export function EditEmployeeCertificationPopup(props: IEditEmployeeCertificationPopupProps) {

    const closePopup = useClosePopup();
    const openPopup = usePopup();
    const createNotification = useCreateNotification();

    const { employeeId, certificationId } = props;

    const locationID = useGlobalLocation();
    const locationIDAsString = useMemo(() => "" + locationID, [locationID])

    const [certificationStateOptions] = useState<EmployeeCertificationStateInfo[]>(getEmployeeCertificationStateOptions());

    const [attachmentFile, setAttachmentFile] = useState<File>();
    const [attachmentInfo, setAttachmentInfo] = useState<AttachmentDetailsDTO>();
    const [employeeCertification, setEmployeeCertification] = useState<EmployeeCertificationsSummaryDTO>();


    const getEmployeeCertificationSummaryByIdCall = useServiceCallPro(svc.getEmployeeCertificationSummaryById);
    const editEmployeeCertificationCall = useServiceCallPro(svc.editEmployeeCertification);
    const uploadEmployeeCertificationAttachmentCall = useServiceCallPro(svc.uploadEmployeeCertificationAttachment);


    const nameFormControl = useFormControl<string>({
        validators: [validateWhiteSpaceStrings()],
        enableAutoValidate: true,
    });

    const stateFormControl = useFormControl<EmployeeCertificationStateInfo>({
        validators: [hasOneElementSelected()],
        enableAutoValidate: false,
    });

    const startDateFormControl = useFormControl<DateTime>({
        validators: [validateDateTime()],
        enableAutoValidate: false
    });

    const endDateFormControl = useFormControl<DateTime>({
        validators: [validateDateTime()],
        enableAutoValidate: false
    });




    /****************************
    * DATA REQUESTS
    *****************************/

    const getCertificationSummaryById = useCallback(() => {
        if (!employeeId || !certificationId) return;

        getEmployeeCertificationSummaryByIdCall.invoke(locationIDAsString, employeeId, certificationId.toString())
            .then((data) => {
                if (!data) return;

                setEmployeeCertification(data);

                nameFormControl.setValue(data.certificationName)
                stateFormControl.setValue({ id: "" + data.state, label: translate(EmployeeCertificationStateTranslationTerms[data.state]) })

                if (data.startDate) { startDateFormControl.setValue(DateTime.fromISO(data.startDate)); }
                if (data.endDate) { endDateFormControl.setValue(DateTime.fromISO(data.endDate)); }

                if (data.attachmentFilename && data.attachmentId) {
                    setAttachmentInfo({
                        fileId: data.attachmentId,
                        filename: data.attachmentFilename
                    });
                }
            })
            .catch((error) => {
                if (!error) return;
                openPopup(<ErrorPopup>{error.response.data.message}</ErrorPopup>);
            });
    }, [employeeId, certificationId, getEmployeeCertificationSummaryByIdCall, locationIDAsString, nameFormControl, stateFormControl, startDateFormControl, endDateFormControl, openPopup]);



    const uploadCertificationAttachment = useCallback(() => {

        if (!employeeId || !certificationId || !attachmentFile) return;


    }, [attachmentFile,
        certificationId,
        closePopup,
        createNotification,
        employeeId,
        openPopup,
        props.onCompletedOperations,
        uploadEmployeeCertificationAttachmentCall.invoke]);



    const editEmployeeCertification = useCallback(() => {
        if (!employeeId || !certificationId) return;

        if (!AllValid(nameFormControl.validate(), startDateFormControl.validate(), stateFormControl.validate())) return;

        if (!nameFormControl.value || !stateFormControl.value) return;

        let request: EmployeeCertificationUpdateDTO = {
            certificationName: nameFormControl.value,
            startDate: startDateFormControl.value?.toISO() || null,
            endDate: endDateFormControl.value?.toISO() || null,
            state: EmployeeCertificationStateAsEnum[parseInt(stateFormControl.value?.id)],
            obsoleteCertificateId: employeeCertification?.attachmentId && !attachmentInfo?.fileId ? "" + employeeCertification?.attachmentId : null
        };

        editEmployeeCertificationCall
            .invoke(locationIDAsString, employeeId, "" + certificationId, request)
            .then((_) => {
                if (attachmentFile) {
                    uploadEmployeeCertificationAttachmentCall
                        .invoke(locationIDAsString, "" + employeeId, "" + certificationId, attachmentFile)
                        .then((_) => {
                            createNotification(<Notification
                                type="success"
                                title={translate("COMMON.SYSTEMPOPUPS.Success")}
                                text={translate("EMPLOYEES.CERTIFICATIONS.POPUPS.EditCertificationSuccess")}
                            />);
                            closePopup();
                            props.onCompletedOperations();
                        }).catch(error => {
                            if (!error) return;
                            openPopup(<ErrorPopup>{translate("EMPLOYEES.CERTIFICATIONS.POPUPS.AddCertificationUploadError")}</ErrorPopup>);
                        });
                }
                else {
                    createNotification(<Notification
                        type="success"
                        title={translate("COMMON.SYSTEMPOPUPS.Success")}
                        text={translate("EMPLOYEES.CERTIFICATIONS.POPUPS.EditCertificationSuccess")}
                    />);
                    closePopup();
                    props.onCompletedOperations();
                }
            })
            .catch((error) => {
                if (!error) return;
                openPopup(<ErrorPopup>{error.response.data.message}</ErrorPopup>);
            });
    }, [employeeId, certificationId, nameFormControl, startDateFormControl, stateFormControl, endDateFormControl.value, employeeCertification?.attachmentId, attachmentInfo?.fileId, editEmployeeCertificationCall, locationIDAsString, attachmentFile, uploadEmployeeCertificationAttachmentCall, createNotification, closePopup, props, openPopup]);






    /****************************
    * DATA MANIPULATION EFFECTS
    *****************************/

    useEffect(() => {
        if (!props.employeeId || !props.certificationId) return;
        getCertificationSummaryById();
    }, [props.employeeId, props.certificationId]);


    /****************************
    * USER ACTIONS
    *****************************/
    const handleFileImport = useCallback((file: File) => {
        setAttachmentFile(file);
    }, [setAttachmentFile]);


    const handleDeleteAttachmentBtnClicked = useCallback(() => {
        setAttachmentInfo(undefined);
    }, [setAttachmentInfo]);


    const handleSaveClicked = useCallback(() => {

        editEmployeeCertification();
    }, [editEmployeeCertification]);


    /****************************
     * CSS & HTML
     *****************************/


    return <PopupContainer className="popup-edit-employee-certification">
        {editEmployeeCertificationCall.isLoading || uploadEmployeeCertificationAttachmentCall.isLoading ? <FullScreenLoader /> : null}
        <PopupHeader title={translate("EMPLOYEES.CERTIFICATIONS.POPUPS.EditCertification")} />
        <PopupContent isLoading={getEmployeeCertificationSummaryByIdCall.isLoading}>
            <FormSection isInputGap childrenFlexDirColumn>
                <FormFieldTextInput
                    formControl={nameFormControl}
                    label={translate("EMPLOYEES.CERTIFICATIONS.INFOANDFORM.Name")}
                    placeholder={translate("EMPLOYEES.CERTIFICATIONS.INFOANDFORM.NamePlaceholder")}
                />
                <FormFieldSelectSingle
                    formControl={stateFormControl}
                    label={translate("EMPLOYEES.CAREER.INFOANDFORM.BusinessLines")}
                    placeholder={translate("EMPLOYEES.CAREER.INFOANDFORM.BusinessLinesPlaceholder")}
                    options={certificationStateOptions}
                    labelSelector={labelCertificateStateSelector}
                    idSelector={idCertificateStateSelector}
                    maxHeightOptions={"16rem"}
                />
                <FormFieldDatePicker
                    formControl={startDateFormControl}
                    label={translate("EMPLOYEES.CERTIFICATIONS.INFOANDFORM.StartDate")}
                    placeholder={translate("EMPLOYEES.CERTIFICATIONS.INFOANDFORM.DatePlaceholder")}
                    maxDate={endDateFormControl.value}
                />
                <FormFieldDatePicker
                    formControl={endDateFormControl}
                    label={translate("EMPLOYEES.CERTIFICATIONS.INFOANDFORM.EndDate")}
                    placeholder={translate("EMPLOYEES.CERTIFICATIONS.INFOANDFORM.DatePlaceholder")}
                    minDate={startDateFormControl.value}
                />

                {employeeCertification &&
                    <div className="attachment-container">
                        {!attachmentInfo?.fileId ?
                            <div className="new-attachment-container">
                                <Spacer mode={"vertical"} px="10" />
                                <FileUploadButton
                                    text={translate("COMMON.AddAttachment")}
                                    type="secondary"
                                    onFileSelected={handleFileImport}
                                    icon={<ImportIcon />}
                                />
                                <Spacer mode={"vertical"} px="10" />
                                {attachmentFile ? <div className="attachment-name">{attachmentFile.name}</div> : null}
                            </div>
                            :
                            <div className="current-attachment-container">
                                <div className="small-copy separator">{translate("EMPLOYEES.CERTIFICATIONS.INFOANDFORM.Attachment")}</div>
                                <Spacer mode="vertical" px={10} />

                                <div className="attachment-info">
                                    <div className="attachment-name">{attachmentInfo.filename}</div>
                                    <ScalableIconButton
                                        icon={<DeleteIcon />}
                                        onClick={handleDeleteAttachmentBtnClicked}
                                        size={22}
                                    />
                                </div>
                            </div>}
                    </div>
                }

            </FormSection>
            <Spacer mode={"vertical"} px="30" />
            <PopupActionButtons
                buttons={[
                    {
                        text: translate("COMMON.Cancel"),
                        type: "tertiary",
                        onClick: () => closePopup(),
                    },
                    {
                        text: translate("COMMON.Save"),
                        type: "primary",
                        onClick: handleSaveClicked,
                    },
                ]}
            />
        </PopupContent>
    </PopupContainer>;
}