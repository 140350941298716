import { APIBusinessLineDetailsDTOToDomainMapper, APIBusinessLinesToDomainMapper } from "../models/mappers/APIBusinessLinesToDomainMapper";
import { BusinessLineCreateDTO } from "../models/dtos/BusinessLineCreateDTO";
import { BusinessLineDTO } from "../models/dtos/BusinessLineDTO";
import { BusinessLinesQueryOptions } from "../models/dtos/BusinessLinesQueryOptions";
import { BusinessLinesResponseDTO } from "../models/dtos/BusinessLinesResponseDTO";
import { ConfigurationProvider } from "../../../../lib/infrastructure/configuration/ConfigurationProvider";
import { HttpClient } from "../../../../lib/infrastructure/http/HttpClient";
import { BusinessLineUpdateDTO } from "../models/dtos/UpdateBusinessLineRequestDTO";
import qs from "qs";
import { BusinessLinesResponse } from "../models/domain/BusinessLinesResponse";
import { BusinessLineDetailsDTO } from "../models/dtos/BusinessLineDetailsDTO";
import { BusinessLineUpdateStateDTO } from "../models/dtos/BusinessLineUpdateStateDTO";

const Route = (path: string) => {
    return ConfigurationProvider.getConfiguration().App.BackendUrl + path;
};

export class BusinessLinesService {

    getBusinessLines(locationId: string | number, queryOptions?: BusinessLinesQueryOptions): Promise<BusinessLinesResponse> {

        return HttpClient.sessionRequest<BusinessLinesResponseDTO>({
            method: "GET",
            url: Route(`/api/v1/Locations/${locationId}/BusinessLines`),
            params: queryOptions,
            paramsSerializer: (params) => {
                return qs.stringify(params, { arrayFormat: "repeat" });
            },
        })
            .then((response) => {
                return ({
                    businessLines: response.data.businessLines.map(c => APIBusinessLinesToDomainMapper(c)),
                    totalItems: response.data.totalItems
                })
            })
            .catch((error) => {
                throw error;
            });
    }



    getBusinessLineById(locationId: number | string, businessLineId: number | string): Promise<BusinessLineDTO> {
        return HttpClient.sessionRequest<BusinessLineDetailsDTO>({
            method: "GET",
            url: Route(`/api/v1/Locations/${locationId}/BusinessLines/${businessLineId}`),
        })
            .then((response) => { 
                return APIBusinessLineDetailsDTOToDomainMapper(response.data); 
            })
            .catch((error) => { throw error; });
    }


    

    createBusinessLine(locationId: string | number, requestDto: BusinessLineCreateDTO): Promise<void> {
        return HttpClient.sessionRequest({
            method: "POST",
            url: Route(`/api/v1/Locations/${locationId}/BusinessLines`),
            data: requestDto,
        })
            .then((_) => { })
            .catch((error) => {
                throw error;
            });
    }



    updateBusinessLineName(locationId: string, businessLineId: string, businessLineName: string): Promise<void> {
        var request: BusinessLineUpdateDTO = {
            name: businessLineName
        };

        return HttpClient.sessionRequest({
            method: "PUT",
            url: Route(`/api/v1/Locations/${locationId}/BusinessLines/${businessLineId}`),
            data: request
        })
            .then((_) => {
            })
            .catch((error) => {
                throw error;
            });
    }



    
    updateBusinessLineState(locationId: string, businessLineId: string, newBusinessLineState: boolean): Promise<void> {
        var request: BusinessLineUpdateStateDTO = {
            state: newBusinessLineState
        };

        return HttpClient.sessionRequest({
            method: "PUT",
            url: Route(`/api/v1/Locations/${locationId}/BusinessLines/${businessLineId}/State`),
            data: request
        })
            .then((_) => {
            })
            .catch((error) => {
                throw error;
            });
    }

}