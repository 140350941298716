import { translate } from "../../lib/infrastructure/i18n/InternationalizationService";

export enum EmployeeCertificationState {
    REGISTERED = 0,
    IN_PROGRESS = 1,
    CONCLUDED = 2,
    NOT_CONCLUDED = 3
}


export const EmployeeCertificationStateTranslationTerms: { [key: string]: string } = {
    0: "COMMON.STATUS.Registered",
    1: "COMMON.STATUS.InProgress",
    2: "COMMON.STATUS.Concluded",
    3: "COMMON.STATUS.NotConcluded",
}


export const EmployeeCertificationStateAsEnum: { [key: number]: EmployeeCertificationState } = {
    0: EmployeeCertificationState.REGISTERED,
    1: EmployeeCertificationState.IN_PROGRESS,
    2: EmployeeCertificationState.CONCLUDED,
    3: EmployeeCertificationState.NOT_CONCLUDED,
};


export interface EmployeeCertificationStateInfo {
    id: string;
    label: string;
}

export function getEmployeeCertificationStateOptions() {
    const stateOptions: EmployeeCertificationStateInfo[] = Object.values(EmployeeCertificationState)
        .filter(value => typeof value === "number")
        .map((enumValue) => {
            var followUpItemsStatus: EmployeeCertificationStateInfo = {
                id: "" + enumValue,
                label: translate(EmployeeCertificationStateTranslationTerms[enumValue]) || "-unknown-"
            };
            return followUpItemsStatus;
        });

    return stateOptions;
}