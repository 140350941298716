import { BusinessLine } from "../domain/BusinessLine";
import { BusinessLineDetailsDTO } from "../dtos/BusinessLineDetailsDTO";
import { BusinessLineSummaryDTO } from "../dtos/BusinessLineSummaryDTO";

export function APIBusinessLinesToDomainMapper(apiDto: BusinessLineSummaryDTO): BusinessLine {
    return {
        id: apiDto.businesLineId,
        name: apiDto.name,
        isDisabled: apiDto.isDisabled
    }
}



export function APIBusinessLineDetailsDTOToDomainMapper(apiDto: BusinessLineDetailsDTO): BusinessLine {
    return {
        id: apiDto.businessLineId,
        name: apiDto.name,
        isDisabled: apiDto.isDisabled
    }
}