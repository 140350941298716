import "./PageLayout.css";

import {
  Breadcrumb
} from "../../components/breadcrumb/Breadcrumb";
import { CssClassnameBuilder } from "../../utils/CssClassnameBuilder";
import { Helmet } from "react-helmet";
import { Spacer } from "../../components/separator/Spacer";
import { useBreadcrumb } from "../../infrastructure/navigation/hooks/useBreadcrumb";
import { useMemo } from "react";
import { useWindowResize } from "../../infrastructure/ui/UIServices";

interface IMainContentLayountProps {
  className: string;
  pageTitle: string;
  tabTitle?: string;
  actions?: React.ReactNode;
  children: React.ReactNode;
  showBreadcrumb?: boolean;
}

export function PageLayout(props: IMainContentLayountProps) {

  const windowResize = useWindowResize();
  const breadcrumb = useBreadcrumb();


  const pageLayoutCss = useMemo(() => {
    return CssClassnameBuilder.new()
      .add("page-layout")
      .add(props.className)
      .build();
  }, [props.className]);

  return (
    <div className={pageLayoutCss}>
      <Helmet>
        <title>{props.tabTitle}</title>
      </Helmet>
      <div className="page-layout-header">
        {props.showBreadcrumb && windowResize > 1024 ? (
          <div>
            <Breadcrumb items={breadcrumb.breadcrumb} />
            <div className="title">{props.pageTitle}</div>
          </div>
        ) : (
          <div className="title">{props.pageTitle}</div>
        )}
        <div className="page-layout-header-action-buttons">{props.actions}</div>
      </div>
      <div className="page-content">{props.children}</div>
      <Spacer px={30} mode="vertical" />
    </div>
  );
}
