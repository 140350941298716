import "./EvaluationPeriodAbsencesPage.css";

import { useCallback, useEffect, useMemo, useState } from "react";
import { useCreateNotification, usePopup } from "../../../lib/infrastructure/ui/UIServices";

import { AbsenceSummaryDTO } from "../models/dtos/AbsenceSummaryDTO";
import { AbsenteeismCodeService } from "../services/AbsenteeismCodeService";
import { ColumnDefinition } from "../../../lib/components/table/TableInterfaces";
import { ConfigurationProvider } from "../../../lib/infrastructure/configuration/ConfigurationProvider";
import { ErrorPopup } from "../../../lib/components/popup/ErrorPopup";
import { FormFieldTextInput } from "../../../lib/components/form/form-field/FormFieldTextInput";
import { FullScreenLoader } from "../../../lib/components/loader/FullScreenLoader";
import { ReactComponent as ImportIcon } from "../../../lib/assets/icons/import.svg";
import { ImportPopup } from "../../../lib/components/popup/ImportPopup";
import { Notification } from "../../../lib/components/notifications/Notification";
import { PageLayout } from "../../../lib/layouts/main-content/PageLayout";
import { Permission } from "../../../models/api/gate/Permission";
import { QueryOptions } from "../../../models/api/QueryOptions";
import { ResponsiveButton } from "../../../lib/components/buttons/ResponsiveButton";
import { ResponsiveDataTable } from "../../../lib/components/table/ResponsiveDataTable";
import { ReactComponent as SearchIcon } from "../../../lib/assets/icons/search-icon.svg";
import axios from "axios";
import { translate } from "../../../lib/infrastructure/i18n/InternationalizationService";
import { useDebounce } from "../../../lib/hooks/useDebounce";
import { useFormControl } from "../../../lib/components/form/Form";
import { useGlobalLocation } from "../../../lib/infrastructure/location/LocationServices";
import { useHasPermissions } from "../../../lib/infrastructure/authorization/useHasLocationPermissions";
import { useParams } from "react-router-dom";
import { useServiceCallPro } from "../../../lib/hooks/useServiceCall";

export interface IOrderInfo {
  direction: "ASC" | "DESC" | "NONE";
  columnKey: string;
}

const topPermissions = [
  Permission.GLOBAL_ADMIN,
  Permission.LOCATION_ADMIN,
  Permission.HUMAN_RESOURCES
];

var svc = new AbsenteeismCodeService();

interface IEvaluationPeriodAbsencesPageProps { }

export function EvaluationPeriodAbsencesPage(props: IEvaluationPeriodAbsencesPageProps) {
  const openPopup = usePopup();
  const createNotification = useCreateNotification();
  const locationID = useGlobalLocation();
  const locationIDAsString = useMemo(() => "" + locationID, [locationID])
  const { id: businessLineID, periodId } = useParams();

  const { isLoadingPermissions, hasPermission } = useHasPermissions(topPermissions);

  const [absences, setAbsences] = useState<AbsenceSummaryDTO[]>();
  const [totalAbsences, setTotalAbsences] = useState<number>();

  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [currentpage, setCurrentPage] = useState(0);
  const [searchWord, setSearchWord] = useState<string>();

  var searchFormControl = useFormControl<string>({
    isDisabled: false,
    enableAutoValidate: false,
  });

  const debouncedSearchTerm = useDebounce(searchFormControl.value, 1000);

  const getAbsencesCall = useServiceCallPro(svc.getAbsences);
  const importAbsencesCall = useServiceCallPro(svc.importAbsences);


  /****************************
 * DATA REQUESTS
 *****************************/

  const getAbsences = useCallback((controller: AbortController | undefined) => {
    if (!locationIDAsString || !businessLineID || !periodId) return;

    var queryOptions: QueryOptions = {
      page: currentpage,
      pageLength: itemsPerPage,
      searchWord: searchWord,
    };

    getAbsencesCall.invoke(locationIDAsString, businessLineID, periodId, queryOptions, controller?.signal)
      .then((data) => {
        if (data) {
          setAbsences(data.absenteeisms);
          setTotalAbsences(data.totalItems);
        }
      })
      .catch((error) => {
        if (!error || axios.isCancel(error)) return;
        openPopup(<ErrorPopup>{error.response?.data?.message}</ErrorPopup>);
      });
  }, [locationIDAsString, businessLineID, periodId, setAbsences, setTotalAbsences, getAbsencesCall.invoke, openPopup, currentpage, itemsPerPage, searchWord]);


  const handleFileImport = useCallback((file: File) => {
    if (!locationIDAsString || !businessLineID || !periodId) return;

    importAbsencesCall.invoke(locationIDAsString, businessLineID, periodId, file)
      .then((res) => {
        getAbsences(undefined);
        if (res && res.invalidAbsenteeismsCount > 0) {
          openPopup(<ErrorPopup>{translate("BUSINESSLINES.EVALUATIONPERIODS.ABSENCES.POPUPS.InvalidImport")}</ErrorPopup>)
        }
        createNotification(
          <Notification
            type="success"
            title={translate("COMMON.SYSTEMPOPUPS.Success")}
            text={translate("BUSINESSLINES.EVALUATIONPERIODS.ABSENCES.POPUPS.ImportSuccess")}
          />
        );
      })
      .catch((error) => {
        if (!error || axios.isCancel(error)) return;
        openPopup(<ErrorPopup>{error.response.data.message}</ErrorPopup>);
      });

  }, [locationIDAsString, businessLineID, periodId, importAbsencesCall, getAbsences, openPopup, createNotification]);


  /****************************
  * DATA MANIPULATION EFFECTS
  *****************************/

  useEffect(() => {
    const controller = new AbortController();
    getAbsences(controller);
    return () => { controller.abort() };
  }, [currentpage, itemsPerPage, searchWord]);

  useEffect(() => {
    setCurrentPage(0);
    setSearchWord(debouncedSearchTerm);
  }, [debouncedSearchTerm]);


  /****************************
  * USER ACTIONS
  *****************************/

  const handleOnPageAndItemsChanged = useCallback((items: number, currentPage: number) => {
    setItemsPerPage(items);
    setCurrentPage(currentPage);
  }, [setItemsPerPage, setCurrentPage]);


  const handleClickImportBtn = useCallback(() => {
    openPopup(
      <ImportPopup
        warningMessage={translate("BUSINESSLINES.EVALUATIONPERIODS.ABSENCES.POPUPS.ImportAbsencesConfirmText")}
        onClickDownloadTemplateBtn={() => window.open(ConfigurationProvider.getConfiguration().App.BackendUrl + "/templates/VLMT_CareerLadder_PeriodAbsenteeism_Template.xlsx", "_blank")}
        onClickImportBtn={handleFileImport} />
    );
  }, [handleFileImport, openPopup]);


  /****************************
   * CSS & HTML
  *****************************/

  const columns: ColumnDefinition<AbsenceSummaryDTO>[] = useMemo(
    () => [
      {
        cellRenderProp: (v) => <div className="employee-info">
          <div className="small-copy employee-name">
            {v.employee.userDisplayName}
          </div>
          <div className="caption employee-id">{v.employee.userEmployeeID ?? ""}</div>
        </div>,
        headerRender: translate("BUSINESSLINES.EVALUATIONPERIODS.ABSENCES.INFOANDFORM.EmployeeName"),
        isMobilePrimaryCell: true,
      },
      {
        cellRenderProp: (v) => v.absenceDays,
        headerRender: translate("BUSINESSLINES.EVALUATIONPERIODS.ABSENCES.INFOANDFORM.UnjustifiedAbsences"),
        isMobilePrimaryCell: true,
      },
    ], []);


  const renderPageBtns = useMemo(() => {
    if (!isLoadingPermissions && hasPermission) {
      return <ResponsiveButton
        text={translate("COMMON.Import")}
        type="secondary"
        icon={<ImportIcon />}
        onClick={handleClickImportBtn}
      />
    }
  }, [isLoadingPermissions, hasPermission, handleClickImportBtn]);


  return <PageLayout
    tabTitle={translate("BUSINESSLINES.EVALUATIONPERIODS.ABSENCES.AbsencesMap")}
    showBreadcrumb
    pageTitle={translate("BUSINESSLINES.EVALUATIONPERIODS.ABSENCES.AbsencesMap")}
    className="absences-page"
    actions={renderPageBtns}
  >
    {importAbsencesCall.isLoading && <FullScreenLoader />}
    <FormFieldTextInput
      formControl={searchFormControl}
      icon={<SearchIcon />}
      placeholder={translate("COMMON.FORM.SearchPlaceholder")}
      className="search-box"
    />
    <ResponsiveDataTable
      columnDefinitions={columns}
      items={absences || []}
      totalitems={totalAbsences || 0}
      paginationOptions={{ itemsPerPage: itemsPerPage, itemsPerPageOptions: [10, 20, 30] }}
      currentpage={currentpage}
      isLoading={getAbsencesCall.isLoading}
      onPageAndItemsChanged={handleOnPageAndItemsChanged}
    />
  </PageLayout>;
}