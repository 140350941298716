import { ConfigurationProvider } from "../../../lib/infrastructure/configuration/ConfigurationProvider";
import { EmployeeBonusDetailsDTO } from "../models/dtos/EmployeeBonusDetailsDTO";
import { EmployeeBonusEvaluationDetailsDTO } from "../models/dtos/EmployeeBonusEvaluationDetailsDTO";
import { HttpClient } from "../../../lib/infrastructure/http/HttpClient";
import { EmployeeBonusResponseDTO } from "../models/dtos/EmployeeBonusReponseDTO";
import { QueryOptions } from "../../../models/api/QueryOptions";

import qs from "qs";

const Route = (path: string) => {
    return ConfigurationProvider.getConfiguration().App.BackendUrl + path;
};

export class EmployeeBonusService {

    getBonusDetailsById(locationId: string, employeeId: string, bonusId: string): Promise<EmployeeBonusDetailsDTO | null> {
        return HttpClient.sessionRequest<EmployeeBonusEvaluationDetailsDTO>({
            method: "GET",
            url: Route(`/api/v1/Locations/${locationId}/Employees/${employeeId}/Bonus/${bonusId}`),
        })
            .then((res): EmployeeBonusDetailsDTO | null => {
                if (!res.data) return null;
                return {
                    evaluationPeriodStartDate: res.data.periodStartDate,
                    evaluationPeriodEndDate: res.data.periodEndDate,
                    evaluationPeriodName: res.data.periodName,
                    evaluationId: res.data.bonusEvaluationId,
                    id: res.data.bonusEvaluationId,
                    companyCriterias: res.data.companyFactors.map(b => (
                        {
                            name: b.name,
                            achievementPercentage: b.score,
                        })),
                    employeeCriterias: res.data.colaboratorFactors.map(b => (
                        {
                            factorName: b.name,
                            evaluationValue: b.score,
                        })),
                    employeeScoreTotalPercentage: res.data.score,
                    finalScoreWithCutPercentage: res.data.score,
                    feedback: res.data.observations
                };
            })
            .catch((error) => {
                throw error;
            });
    }



    getEmployeeBonusEvaluations(locationId: string, employeeId: string, options: QueryOptions): Promise<EmployeeBonusResponseDTO | null> {

        return HttpClient.sessionRequest<EmployeeBonusResponseDTO>({
            method: "GET",
            url: Route(`/api/v1/Locations/${locationId}/Employees/${employeeId}/Bonus`),
            params: options,
            paramsSerializer: (params) => {
                return qs.stringify(params, { arrayFormat: "repeat" });
            },
        })
            .then((response) => {

                if (!response.data) return null;
                return response.data;
            })
            .catch((error) => {
                throw error;
            });
    }


}
