import { useCallback, useEffect, useState } from "react";
import { useClosePopup, useCreateNotification, usePopup } from "../../../../lib/infrastructure/ui/UIServices";

import { AllValid } from "../../../../common/validators/ValidateFormControls";
import { CriteriasService } from "../../services/CriteriasService";
import { ErrorPopup } from "../../../../lib/components/popup/ErrorPopup";
import { FormFieldTextArea } from "../../../../lib/components/form/form-field/FormFieldTextArea";
import { FormFieldTextInput } from "../../../../lib/components/form/form-field/FormFieldTextInput";
import { FormSection } from "../../../../lib/components/form/section/FormSection";
import { FullScreenLoader } from "../../../../lib/components/loader/FullScreenLoader";
import { Notification } from "../../../../lib/components/notifications/Notification";
import { PopupActionButtons } from "../../../../lib/layouts/containers/popup-buttons/PopupActionButtons";
import { PopupContainer } from "../../../../lib/layouts/containers/popup-container/PopupContainer";
import { PopupContent } from "../../../../lib/layouts/containers/popup-content/PopupContent";
import { PopupHeader } from "../../../../lib/layouts/containers/popup-header/PopupHeader";
import { Spacer } from "../../../../lib/components/separator/Spacer";
import { translate } from "../../../../lib/infrastructure/i18n/InternationalizationService";
import { trimString } from "../../../../lib/utils/TrimString";
import { useFormControl } from "../../../../lib/components/form/Form";
import { useServiceCallPro } from "../../../../lib/hooks/useServiceCall";
import { validateWhiteSpaceStrings } from "../../../../lib/validators/ValidateWhiteSpaceStrings";
import { ConditionalRender } from "../../../../lib/functional/ConditionalRender";
import { MessageBar } from "../../../../lib/components/message-bar/MessageBar";
import { ValidationErrorMessage } from "../../../../lib/infrastructure/http/APIError";
import { ExceptionType } from "../../../../lib/infrastructure/http/ExceptionType";

var criteriasSvc = new CriteriasService();

interface IEditCriteriaPopupProps {
  businessLineId: string;
  locationId: string;
  roleId: string;
  criteriaId: number;
  onCompleted: () => void;
}

export function EditCriteriaPopup(props: IEditCriteriaPopupProps) {
  const closePopup = useClosePopup();
  const openPopup = usePopup();

  const createNotification = useCreateNotification();

  const getCriteriaByIdCall = useServiceCallPro(criteriasSvc.getCriteriaByID);
  const updateCriteriaCall = useServiceCallPro(criteriasSvc.editCriteria);

  const nameFormControl = useFormControl<string>({
    validators: [validateWhiteSpaceStrings()],
    enableAutoValidate: true,
  });

  const descriptionFormControl = useFormControl<string>({
    validators: [validateWhiteSpaceStrings()],
    enableAutoValidate: true,
  });


  const [validationAlert, setValidationAlert] = useState<ValidationErrorMessage>({ showAlert: false });

  useEffect(() => {
    if (props.locationId && props.businessLineId && props.roleId && props.criteriaId) {
      getCriteriaByIdCall
        .invoke(props.locationId, props.businessLineId, props.roleId, "" + props.criteriaId)
        .then((response) => {
          nameFormControl.setValue(response?.name);
          descriptionFormControl.setValue(response?.description);
        })
        .catch((_) => {
          openPopup(<ErrorPopup>{translate("BUSINESSLINES.ROLES.CRITERIAS.POPUP.LoadingCriteriaError")}</ErrorPopup>);
        });
    }
  }, []);


  const editCriteria = useCallback(() => {
    if (!props.locationId || !props.businessLineId || !props.roleId || !props.criteriaId || !AllValid(nameFormControl.validate(), descriptionFormControl.validate())) return;

    updateCriteriaCall
      .invoke(props.locationId, props.businessLineId, props.roleId, props.criteriaId, { name: trimString(nameFormControl.value), description: trimString(descriptionFormControl.value) })
      .then((_) => {
        props.onCompleted();
        closePopup();
        createNotification(
          <Notification
            type="success"
            title={translate("COMMON.SYSTEMPOPUPS.Success")}
            text={translate("BUSINESSLINES.ROLES.CRITERIAS.POPUP.EditCriteriaSuccess")}
          />
        );
      })
      .catch((error) => {
        const errorMessage = error?.response?.data?.message || translate("ERRORS.UnknownError");
        const enumCode = error?.response?.data?.enumCode;

        if (enumCode === ExceptionType.ERROR_VALIDATION_NAME_ALREADY_EXISTS) {
          setValidationAlert({
            showAlert: true,
            message: errorMessage,
          });
        } else {
          openPopup(<ErrorPopup>{errorMessage}</ErrorPopup>);
        }
      });
  }, [props.locationId, props.businessLineId, props.roleId,
  props.criteriaId, props.onCompleted, nameFormControl,
    descriptionFormControl, closePopup, openPopup,
    createNotification, updateCriteriaCall]);




    
  return (
    <PopupContainer className="popup-edit-criteria">
      {updateCriteriaCall.isLoading ? <FullScreenLoader /> : null}

      <PopupHeader
        title={translate("BUSINESSLINES.ROLES.CRITERIAS.POPUP.EditCriteria")}
      />
      <PopupContent isLoading={getCriteriaByIdCall.isLoading}>
        <FormSection>
          <div className="role-criteria-name-container">
            <FormFieldTextInput
              formControl={nameFormControl}
              label={translate("BUSINESSLINES.ROLES.CRITERIAS.INFOANDFORM.Name")}
              placeholder={translate("BUSINESSLINES.ROLES.CRITERIAS.INFOANDFORM.NamePlaceholder")}
            />
            <ConditionalRender if={validationAlert.showAlert}>
              <Spacer mode="vertical" px="10" />
              <div className="name-already-exists">
                <MessageBar type="error" text={"" + validationAlert.message} />
              </div>
            </ConditionalRender>
          </div>

          <FormFieldTextArea
            formControl={descriptionFormControl}
            label={translate("BUSINESSLINES.ROLES.CRITERIAS.INFOANDFORM.Description")}
          // placeholder={translate("BUSINESSLINES.ROLES.CRITERIAS.INFOANDFORM.DescriptionPlaceholder")}
          />
        </FormSection>
        <Spacer mode={"vertical"} px="30" />
        <PopupActionButtons
          buttons={[
            {
              text: translate("COMMON.Cancel"),
              type: "tertiary",
              onClick: () => closePopup(),
            },
            {
              text: translate("COMMON.Save"),
              type: "primary",
              onClick: editCriteria,
            },
          ]}
        />
      </PopupContent>
    </PopupContainer>
  );
}
