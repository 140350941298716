import "./LocationsHomePage.css";

import { useCallback, useEffect, useMemo, useState } from "react";
import { useClosePopup, useCreateNotification, usePopup, useWindowResize } from "../../../../lib/infrastructure/ui/UIServices";

import { ReactComponent as AddIcon } from "../../../../lib/assets/icons/add.svg";
import { AddLocationPopup } from "./popups/AddLocationPopup";
import { Button } from "../../../../lib/components/buttons/Button";
import { ColumnDefinition } from "../../../../lib/components/table/TableInterfaces";
import { ReactComponent as EditIcon } from "../../../../lib/assets/icons/editar.svg";
import { EditLocationPopup } from "./popups/EditLocationPopup";
import { ErrorPopup } from "../../../../lib/components/popup/ErrorPopup";
import { IconButton } from "../../../../lib/components/buttons/IconButton";
import { LocationDTO } from "../models/dtos/LocationDTO";
import { LocationService } from "../services/LocationServices";
import { LocationsResponseDTO } from "../models/dtos/LocationsResponseDTO";
import { Notification } from "../../../../lib/components/notifications/Notification";
import { PageLayout } from "../../../../lib/layouts/main-content/PageLayout";
import { Permission } from "../../../../models/api/gate/Permission";
import { ResponsiveDataTable } from "../../../../lib/components/table/ResponsiveDataTable";
import { ScalableIconButton } from "../../../../lib/components/buttons/ScalableIconButton";
import { Toggle } from "../../../../lib/components/form/toggle/Toggle";
import { UpdateNavbarLocationsEvent } from "../../../../events/UpdateNavbarLocationsEvent";
import { WarningPopup } from "../../../../lib/components/popup/WarningPopup";
import { translate } from "../../../../lib/infrastructure/i18n/InternationalizationService";
import { useHasPermissions } from "../../../../lib/infrastructure/authorization/useHasLocationPermissions";
import { useNavigate } from "react-router-dom";
import { useServiceCallPro } from "../../../../lib/hooks/useServiceCall";

var locationService = new LocationService();

const permissions = [Permission.GLOBAL_ADMIN];

export function LocationsHome() {
  const createNotification = useCreateNotification();
  const windowResize = useWindowResize();
  const openPopup = usePopup();
  const closePopup = useClosePopup();
  const navigate = useNavigate();
  const { isLoadingPermissions, hasPermission } = useHasPermissions(permissions);

  const [locations, setLocations] = useState<LocationsResponseDTO>();

  const getLocationsCall = useServiceCallPro(locationService.getLocations);


  useEffect(() => {
    getLocations();
  }, []);


  const getLocations = useCallback(() => {
    getLocationsCall
      .invoke()
      .then((response) => {
        setLocations(response);
      })
      .catch((error) => {
        if (!error) return;
        openPopup(<ErrorPopup>{error.response.data.message}</ErrorPopup>)
      });
  }, [openPopup, getLocationsCall]);


  const deactivateLocation = useCallback(
    (locationId: number) => {
      locationService
        .disableLocation(locationId)
        .then((_) => {
          getLocations();
          UpdateNavbarLocationsEvent.fireUpdateNavbarLocations();
          createNotification(<Notification type="success" title={translate("COMMON.SYSTEMPOPUPS.Success")} text={translate("GLOBALADMIN.LOCATIONS.POPUP.EditLocationSuccess")} />);
        })
        .catch((error) => {
          if (!error) return;
          openPopup(<ErrorPopup>{error.response.data.message}</ErrorPopup>);
        });
    },
    [getLocations, openPopup, createNotification]
  );


  const activateLocation = useCallback(
    (locationId: number) => {
      locationService
        .activateLocation(locationId + "")
        .then((_) => {
          getLocations();
          UpdateNavbarLocationsEvent.fireUpdateNavbarLocations();
          createNotification(<Notification type="success" title={translate("COMMON.SYSTEMPOPUPS.Success")} text={translate("GLOBALADMIN.LOCATIONS.POPUP.ActivateLocationSuccess")} />);
        })
        .catch((error) => {
          if (!error) return;
          openPopup(<ErrorPopup>{error.response.data.message}</ErrorPopup>);
        });
    },
    [getLocations, openPopup, createNotification]
  );


  const columns: ColumnDefinition<LocationDTO>[] = useMemo(
    () => [
      /*       {
              cellRenderProp: (v) => v.locationId,
              width: "15%",
              headerRender: "ID",
              columnKey: "Name",
              isSortable: true,
              isMobilePrimaryCell: true,
            }, */
      {
        cellRenderProp: (v) => v.name,
        headerRender: translate("GLOBALADMIN.LOCATIONS.INFO.Name"),
        columnKey: "options",
        isSortable: false,
        isMobilePrimaryCell: true,
      },
      {
        cellRenderProp: (v) => {
          if (!isLoadingPermissions && hasPermission)
            return (<div className="toggle-div">
              <Toggle value={!v.isDisabled} />
              <div
                className="toggle-div-overlay"
                onClick={(ev) => {
                  ev.stopPropagation();
                  if (!v.isDisabled) {
                    openPopup(
                      <WarningPopup
                        className="disable-location-popup"
                        onDismissClicked={() => {
                          closePopup();
                        }}
                        onConfirmClicked={() => {
                          deactivateLocation(v.locationId);
                        }}
                        dismissText={translate("COMMON.Cancel")}
                        confirmText={translate(
                          "GLOBALADMIN.LOCATIONS.POPUP.Deactivate"
                        )}
                      >
                        {translate(
                          "GLOBALADMIN.LOCATIONS.POPUP.DeactivateConfirmText"
                        )}
                      </WarningPopup>
                    );
                  }
                  else {
                    if (v.locationId) {
                      openPopup(
                        <WarningPopup
                          onDismissClicked={() => {
                            closePopup();
                          }}
                          onConfirmClicked={() => {
                            activateLocation(v.locationId);
                          }}
                          dismissText={translate("COMMON.Cancel")}
                          confirmText={translate("GLOBALADMIN.LOCATIONS.POPUP.Activate")}
                        >
                          {translate("GLOBALADMIN.LOCATIONS.POPUP.ActivateConfirmText")}
                        </WarningPopup>
                      );
                    }
                  }
                }}
              />
            </div>)
        },
        width: "5%",
        columnKey: "stats",
        headerRender: translate("COMMON.Status"),
        isSortable: false,
        isMobileHeaderIcon: true
      },
      {
        cellRenderProp: (v) => {
          if (!isLoadingPermissions && hasPermission)
            return (
              <ScalableIconButton
                icon={<EditIcon />}
                onClick={() =>
                  openPopup(
                    <EditLocationPopup
                      locationId={v.locationId}
                      onCompleted={() => {
                        getLocations();
                      }}
                    />
                  )
                }
                size={30}
              />)
          return null;
        },
        width: "5%",
        columnKey: "stats",
        isSortable: false,
        isMobileHeaderIcon: true
      },
    ],
    [openPopup, getLocations, isLoadingPermissions, hasPermission, activateLocation, closePopup, deactivateLocation]
  );


  const handleClickCreateBtn = useCallback(() => {
    openPopup(
      <AddLocationPopup
        onChangesMade={() => {
          getLocations();
        }}
      />
    );
  }, [getLocations, openPopup])


  const createLocationResponsiveButton = useMemo(() => {
    if (!isLoadingPermissions && hasPermission) {
      if (windowResize > 768)
        return (
          <Button
            text={translate("GLOBALADMIN.LOCATIONS.CreateLocation")}
            type="primary"
            onClick={handleClickCreateBtn}
          />
        );
      else
        return (
          <IconButton
            icon={<AddIcon />}
            type="primary"
            onClick={handleClickCreateBtn}
          />
        );
    }
  }, [windowResize, isLoadingPermissions, hasPermission, handleClickCreateBtn]);


  return (
    <PageLayout
      tabTitle={`${translate("GLOBALADMIN.LOCATIONS.Locations")}`}
      pageTitle={translate("GLOBALADMIN.LOCATIONS.Locations")}
      className="locations-home"
      actions={createLocationResponsiveButton}
    >
      <ResponsiveDataTable
        columnDefinitions={columns}
        items={locations?.locations || []}
        totalitems={locations?.locations.length || 0}
        isLoading={getLocationsCall.isLoading}
        onClickRow={(location: LocationDTO) => {
          navigate(`${location.locationId}/groupaccess`);
        }}
      />
    </PageLayout>
  );
}
