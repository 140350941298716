import { useCallback, useMemo, useState } from "react";
import { useCreateNotification, usePopup } from "../../../../lib/infrastructure/ui/UIServices";

import { AddCareerLadderNodePopup } from "../../popups/AddCareerLadderNodePopup";
import { CareerLadderService } from "../../services/CareerLadderService";
import { CareerLadderStepTreeNodeDTO } from "../../models/dtos/CareerLadderStepTreeNodeDTO";
import { CareerLadderTreeNode } from "./CareerLadderTreeNode";
import { ErrorPopup } from "../../../../lib/components/popup/ErrorPopup";
import { Notification } from "../../../../lib/components/notifications/Notification";
import { translate } from "../../../../lib/infrastructure/i18n/InternationalizationService";
import { useServiceCallPro } from "../../../../lib/hooks/useServiceCall";

interface ICareerLadderTreeProps {
    locationId: string | undefined;
    businessLineId: string | undefined;
    careerLadderNodes: CareerLadderStepTreeNodeDTO[];
    onCompleteOperations: () => void;
    isNextRoleCareerLadder?: boolean;
    handleNextRoleSelected?: (roleId: number) => void;
}

var careerLadderSvc = new CareerLadderService();

export function CareerLadderTree(props: ICareerLadderTreeProps) {
    const openPopup = usePopup();
    const createNotification = useCreateNotification();

    const [selectedNode, setSelectedNode] = useState<number>();

    const removeCareerLadderNodeCall = useServiceCallPro(careerLadderSvc.removeNode);


    /****************************
     * USER ACTIONS
     *****************************/

    const handleNextRoleSelected = useCallback((roleId: number, nodeId: number) => {
        setSelectedNode(nodeId);
        props.handleNextRoleSelected && props.handleNextRoleSelected(roleId)
    }, [props.handleNextRoleSelected]);


    const handleAddRoleNode = useCallback((parentId: number, child: Omit<CareerLadderStepTreeNodeDTO, 'children'>) => {
        openPopup(<AddCareerLadderNodePopup locationId={props.locationId} businessLineId={props.businessLineId} nodeId={parentId} onCompletedOperations={props.onCompleteOperations} />);
    }, [openPopup, props.businessLineId, props.locationId, props.onCompleteOperations]);


    const handleRemoveRoleNode = useCallback((nodeId: number) => {
        const handleSuccess = () => {
            props.onCompleteOperations();
            createNotification(
                <Notification
                    type="success"
                    title={translate("COMMON.SYSTEMPOPUPS.Success")}
                    text={translate("BUSINESSLINES.CAREER.POPUP.RemoveRoleSuccess")}
                />
            );
        };

        const handleError = (error: any) => {
            if (!error) return;
            const errorMessage = error?.response?.data?.message || translate("ERRORS.UnknownError");
            openPopup(<ErrorPopup>{errorMessage}</ErrorPopup>);
        };

        removeCareerLadderNodeCall
            .invoke("" + props.locationId, "" + props.businessLineId, "" + nodeId)
            .then(handleSuccess)
            .catch(handleError);
    }, [createNotification, openPopup, props, removeCareerLadderNodeCall.invoke]);


    /****************************
     * CSS & HTML
     *****************************/

    const renderTree = useMemo(() => {
        return props.careerLadderNodes.map(node => <CareerLadderTreeNode key={node.careerLadderStepId} node={node} handleAddRoleNode={handleAddRoleNode} handleRemoveRoleNode={handleRemoveRoleNode} isRoot={true} isNextRoleCareerLadder={props.isNextRoleCareerLadder} handleNextRoleSelected={handleNextRoleSelected} selectedNodeId={selectedNode} />)
    }, [handleAddRoleNode, props.careerLadderNodes, handleRemoveRoleNode, props.isNextRoleCareerLadder, handleNextRoleSelected, selectedNode])


    return (
        <div>
            {renderTree}
        </div>
    );
};
