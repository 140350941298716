import { DateTime } from "luxon";
import { InternationalizationService } from "../../infrastructure/i18n/InternationalizationService";

export class DateTimeHelper {


    static formatDateTimeLocale(date?: string | null) {
        const dateTime = date ? DateTime.fromISO(date) : null;
        return dateTime?.isValid ? dateTime.toFormat("dd LLL yyyy", { locale: InternationalizationService.getLocale() }) : "-"
    }

    static formatDateTime(date?: string | null) {
        return date && DateTime.fromISO(date).toFormat("dd/MM/yyyy HH:mm")
    }

    static formatDate(date?: string | null) {
        return date && DateTime.fromISO(date).toFormat("dd/MM/yyyy")
    }



    static outputJSAsIso(date?: Date) {
        return date && DateTime.fromJSDate(date).toISO();
    }

    static outputAsIso(date?: DateTime) {
        return date && date.setZone(DateTime.local().zone).toISO();
    }
}
