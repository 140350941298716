import * as qs from "qs";

import { CareerLadderStepCreateDTO } from "../models/dtos/CareerLadderStepCreateDTO";
import { CareerLadderStepDTO } from "../models/dtos/CareerLadderStepDTO";
import { CareerLadderTreeResponseDTO } from "../models/dtos/CareerLadderTreeResponseDTO";
import { ConfigurationProvider } from "../../../lib/infrastructure/configuration/ConfigurationProvider";
import { HttpClient } from "../../../lib/infrastructure/http/HttpClient";

const nextRoleCareerLadderMock: CareerLadderTreeResponseDTO = {
    nodes: [{
        careerLadderStepId: 38,
        children: [
            {
                careerLadderStepId: 39,
                children: [],
                parentCareerLadderStepId: 38,
                role: { roleId: 9, description: 'Operador Logistica II', jobGrade: 4, isDisabled: false, numberOfPositions: 0 },
                roleId: 9,
            },
            {
                careerLadderStepId: 40,
                children: [],
                parentCareerLadderStepId: 38,
                role: { roleId: 10, description: 'Operador Logistica III', jobGrade: 5, isDisabled: false, numberOfPositions: 0 },
                roleId: 10,
            }
        ],
        parentCareerLadderStepId: null,
        role: { roleId: 1, description: 'Operador Logistica I', jobGrade: 3, isDisabled: false, numberOfPositions: 4 },
        roleId: 1,
    }]
}

const Route = (path: string) => {
    return ConfigurationProvider.getConfiguration().App.BackendUrl + path;
};

export class CareerLadderService {

    getCareerLadder(locationId: string, businessLineId: string): Promise<CareerLadderTreeResponseDTO | null> {
        return HttpClient.sessionRequest<CareerLadderTreeResponseDTO>({
            method: "GET",
            url: Route(`/api/v1/Locations/${locationId}/BusinessLines/${businessLineId}/CareerLadder/AsTree`),
            paramsSerializer: (params) => {
                return qs.stringify(params, { arrayFormat: "repeat" });
            },
        })
            .then((response) => {
                if (!response.data) return null;
                return response.data
            })
            .catch((error) => {
                throw error;
            });
    }

    getCareerLadderByRoleId(locationId: string, businessLineId: string, roleId: string): Promise<CareerLadderTreeResponseDTO | null> {
        return new Promise((resolve) => {
            setTimeout(() => {
                resolve(nextRoleCareerLadderMock);
            }, 1000);
        });

        // return HttpClient.sessionRequest<CareerLadderTreeResponseDTO>({
        //     method: "GET",
        //     url: Route(`/api/v1/Locations/${locationId}/BusinessLines/${businessLineId}/CareerLadder/${roleId}`),
        //     paramsSerializer: (params) => {
        //         return qs.stringify(params, { arrayFormat: "repeat" });
        //     },
        // })
        //     .then((response) => {
        //         if (!response.data) return null;
        //         return response.data
        //     })
        //     .catch((error) => {
        //         throw error;
        //     });
    }


    addNode(locationId: string, businessLineId: string, requestDto: CareerLadderStepCreateDTO): Promise<void> {
        return HttpClient.sessionRequest<CareerLadderStepDTO>({
            method: "POST",
            url: Route(`/api/v1/Locations/${locationId}/BusinessLines/${businessLineId}/CareerLadder`),
            data: requestDto,
        })
            .then((_) => { })
            .catch((error) => {
                throw error;
            });
    }

    removeNode(locationId: string, businessLineId: string, nodeId: string): Promise<void> {
        return HttpClient.sessionRequest({
            method: "DELETE",
            url: Route(`/api/v1/Locations/${locationId}/BusinessLines/${businessLineId}/CareerLadder/${nodeId}`),
        })
            .then((_) => { })
            .catch((error) => {
                throw error;
            });
    }
}