import "./ChecklistCard.css";

import { useCallback, useEffect, useMemo, useState } from "react";
import { useClosePopup, usePopup } from "../../../lib/infrastructure/ui/UIServices";

import { AddChecklistItemPopup } from "../popups/AddChecklistItemPopup";
import { ReactComponent as AddIcon } from "../../../lib/assets/icons/add.svg";
import { Button } from "../../../lib/components/buttons/Button";
import { CardContainer } from "../../../lib/layouts/containers/card/CardContainer";
import { ColumnDefinition } from "../../../lib/components/table/TableInterfaces";
import { ConditionalRender } from "../../../lib/functional/ConditionalRender";
import { ReactComponent as DeleteIcon } from "../../../lib/assets/icons/remover.svg";
import { ErrorPopup } from "../../../lib/components/popup/ErrorPopup";
import { EvaluationMatricesService } from "../services/EvaluationMatricesService";
import { IconButton } from "../../../lib/components/buttons/IconButton";
import { ResponsiveDataTable } from "../../../lib/components/table/ResponsiveDataTable";
import { ScalableIconButton } from "../../../lib/components/buttons/ScalableIconButton";
import { Spacer } from "../../../lib/components/separator/Spacer";
import { WarningPopup } from "../../../lib/components/popup/WarningPopup";
import { translate } from "../../../lib/infrastructure/i18n/InternationalizationService";
import { useServiceCallPro } from "../../../lib/hooks/useServiceCall";

interface IChecklistCardProps {
    locationId: string;
    isEditionModeEnabled: boolean;
    businessLineId: string;
    matrixId?: string;
    matrixVersionId?: string;
    onChange: (checklist: string[]) => void;
}

var matrixesSvc = new EvaluationMatricesService();

export function ChecklistCard(props: IChecklistCardProps) {
    const openPopup = usePopup();
    const closePopup = useClosePopup();

    const [checklist, setChecklist] = useState<string[]>([]);

    const getMatrixChecklistItemsCall = useServiceCallPro(matrixesSvc.getMatrixChecklistItemsByVersionId);


    useEffect(() => {

        if (!props.locationId || !props.matrixId || !props.matrixVersionId || !props.businessLineId) return;

        getMatrixChecklistItemsCall.invoke("" + props.locationId, "" + props.businessLineId, props.matrixId, props.matrixVersionId)
            .then((res) => {

                if (!res) return;
                const checklistItems = res.items.map(item => item.description);
                setChecklist(checklistItems);
            })

            .catch((error) => {
                if (!error) return;
                openPopup(<ErrorPopup>{error.response.data.message}</ErrorPopup>);
            });

    }, [props.locationId, props.matrixId, props.matrixVersionId, props.businessLineId]);




    useEffect(() => {
        props.onChange(checklist)
    }, [checklist])


    const handleAddChecklistItem = useCallback((description: string) => {
        setChecklist([...checklist, description]);
    }, [checklist]);


    const handleRemoveChecklistItem = useCallback((index: number) => {
        setChecklist(prevArray => {
            const newChecklist = [...prevArray];
            newChecklist.splice(index, 1);
            return newChecklist;
        });
    }, [setChecklist]);


    const checklistColumns: ColumnDefinition<string>[] = useMemo(() => [
        {
            cellRenderProp: (item) => item,
            headerRender: translate("BUSINESSLINES.MATRIX.INFOANDFORM.Description"),
            isMobilePrimaryCell: true,
        },
        {
            cellRenderProp: (item) => {
                const index = checklist.indexOf(item);
                if (!props.isEditionModeEnabled) return null;
                if (props.locationId && index !== -1)
                    return (
                        <ScalableIconButton
                            icon={<DeleteIcon />}
                            onClick={() => openPopup(
                                <WarningPopup
                                    className="delete-checklist-item-popup"
                                    onDismissClicked={() => closePopup()}
                                    onConfirmClicked={() => handleRemoveChecklistItem(index)}
                                    dismissText={translate("COMMON.Cancel")}
                                    confirmText={translate("COMMON.Delete")}
                                >
                                    {translate("BUSINESSLINES.MATRIX.POPUP.DeleteChecklistItemConfirmText")}
                                </WarningPopup>
                            )}
                            size={24}
                        />)
                return null;
            },
            columnKey: "delete",
            width: "24px",
            isMobileHeaderIcon: true,
        },
    ], [checklist, props.isEditionModeEnabled, props.locationId, openPopup, closePopup, handleRemoveChecklistItem]);


    return <CardContainer className="checklist-card">
        <div className="card-header">
            <div className="subtitle">{translate("BUSINESSLINES.MATRIX.INFOANDFORM.Checklist")}</div>
            {checklist.length && props.isEditionModeEnabled ? <IconButton
                icon={<AddIcon />}
                type="secondary"
                onClick={() => props.locationId && props.businessLineId ? openPopup(<AddChecklistItemPopup locationId={props.locationId} businessLineId={props.businessLineId} onCompletedOperations={handleAddChecklistItem} />) : null}
            /> : null}
        </div>
        {checklist.length ?
            <ResponsiveDataTable
                columnDefinitions={checklistColumns}
                items={checklist}
                totalitems={checklist.length}
                isLoading={getMatrixChecklistItemsCall.isLoading}
            /> :
            <div className="add-checklist-item">
                <Spacer mode="vertical" px={20} />
                <ConditionalRender if={props.isEditionModeEnabled}>
                    <Button
                        text={translate("BUSINESSLINES.MATRIX.AddChecklistItem")}
                        type="secondary"
                        onClick={() => props.locationId && props.businessLineId ? openPopup(<AddChecklistItemPopup locationId={props.locationId} businessLineId={props.businessLineId} onCompletedOperations={handleAddChecklistItem} />) : null}
                    />
                </ConditionalRender>
                <Spacer mode="vertical" px={20} />
            </div>}
    </CardContainer>;
}