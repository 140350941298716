import "./SkillsCategoryCard.css"

import { SkillLevelDTO, SkillsCategoryDTO } from "../pages/CreateEvaluationMatrixPage";
import { useCallback, useEffect, useMemo, useState } from "react";

import { ReactComponent as AddIcon } from "../../../lib/assets/icons/add.svg";
import { CardContainer } from "../../../lib/layouts/containers/card/CardContainer";
import { ColumnDefinition } from "../../../lib/components/table/TableInterfaces";
import { ConditionalRender } from "../../../lib/functional/ConditionalRender";
import { EvaluationInput } from "./EvaluationInput";
import { IconButton } from "../../../lib/components/buttons/IconButton";
import { ReactComponent as ManageIcon } from "../../../lib/assets/icons/administracao.svg";
import { ManageSkillsPopup } from "../popups/ManageSkillsPopup";
import { ResponsiveDataTable } from "../../../lib/components/table/ResponsiveDataTable";
import { RoleSummary } from "../../roles/models/domain/RoleSummary";
import { SkillDomainDTO } from "../../admin/skills/models/domain/SkillDomainDTO";
import { translate } from "../../../lib/infrastructure/i18n/InternationalizationService";
import { usePopup } from "../../../lib/infrastructure/ui/UIServices";

export interface IFieldWrapperProps {
    children: React.ReactNode;
    value: string | number | undefined;
    isLocked: boolean;
}

export function FieldWrapper(props: IFieldWrapperProps) {
    if (props.isLocked) {
        return <>{props.value}</>
    }
    return <>{props.children}</>
}



interface ISkillsCategoryCardProps {
    locationId: string;
    isEditionModeEnabled: boolean;
    businessLineId: string;
    matrixId?: string;
    roles: RoleSummary[] | undefined;
    skillsCategories: SkillsCategoryDTO[];
    skillsLevels: SkillLevelDTO[] | undefined;
    handleLevelInputChange: (skillLevels: SkillLevelDTO[]) => void;
    handleManageSkills: (selectedSkills: SkillsCategoryDTO[]) => void;
}




export function SkillsCategoryCard(props: ISkillsCategoryCardProps) {
    const openPopup = usePopup();

    const [skillLevels, setSkillLevels] = useState<SkillLevelDTO[]>(props.skillsLevels || []);

    const syncSkillLevels = useCallback(() => {
        if (props.roles && props.skillsCategories) {
            const validRoleIds = props.roles.map(role => role.roleId);
            const validSkillIds = props.skillsCategories.flatMap(category => category.skills.map(skill => skill.id));
            const updatedSkillLevels = skillLevels.filter(
                skillLevel => validRoleIds.includes(skillLevel.roleId) && validSkillIds.includes(skillLevel.skillId)
            );

            props.roles.forEach(role => {
                validSkillIds.forEach(skillId => {
                    if (!updatedSkillLevels.some(skillLevel => skillLevel.roleId === role.roleId && skillLevel.skillId === skillId)) {
                        updatedSkillLevels.push({
                            skillId: skillId,
                            roleId: role.roleId,
                            competenceLevel: '',
                            isValid: false
                        });
                    }
                });
            });

            setSkillLevels(updatedSkillLevels);
        }
    }, [props.roles, props.skillsCategories, skillLevels]);

    useEffect(() => {
        syncSkillLevels();
    }, [props.roles, props.skillsCategories]);


    useEffect(() => {
        props.handleLevelInputChange(skillLevels);
    }, [skillLevels])


    const handleInputChange = useCallback((skillId: number, roleId: number, value: string, isValid: boolean) => {
        setSkillLevels(prevState => {
            const filteredState = prevState.filter(level => !(level.skillId === skillId && level.roleId === roleId));
            return [...filteredState, { skillId, roleId, competenceLevel: value, isValid: isValid }];
        });
    }, [setSkillLevels]);





    const skillsColumns: ColumnDefinition<SkillDomainDTO>[] = useMemo(() => {
        const columns: ColumnDefinition<SkillDomainDTO>[] = [
            {
                cellRenderProp: (skill) => skill.name,
                headerRender: translate('BUSINESSLINES.MATRIX.INFOANDFORM.Skill'),
                isMobilePrimaryCell: true,
            },
        ];

        if (props.roles && props.roles.length) {
            props.roles.forEach((role) => {
                columns.push({
                    cellRenderProp: (skill) => {
                        const level = skillLevels.find(a => a.roleId === role.roleId && a.skillId === skill.id);

                        return (
                            <FieldWrapper value={level?.competenceLevel} isLocked={!props?.isEditionModeEnabled} key={`${level?.skillId}-${level?.roleId}`}>
                                <EvaluationInput
                                    skillId={skill.id}
                                    roleId={role.roleId}
                                    initialValue={level ? level.competenceLevel.toString() : ""}
                                    onChange={handleInputChange}
                                />
                            </FieldWrapper>
                        )
                    },
                    headerRender: role.description,
                    width: "58px"
                });
            });
        }
        return columns;
    }, [props.roles, props?.isEditionModeEnabled, handleInputChange, skillLevels]);



    const handleManageSkills = useCallback((categoryId: number, selectedSkills: SkillDomainDTO[]) => {
        let updatedCategories: SkillsCategoryDTO[] = props.skillsCategories.map(category => {
            if (category.id === categoryId) {
                return { ...category, skills: selectedSkills };
            }
            return category;
        });

        props.handleManageSkills(updatedCategories);

    }, [props.handleManageSkills, props.skillsCategories])


    return <>
        {props.skillsCategories.map((category) => (
            <CardContainer key={category.id} className="skills-category-card">
                <div className="card-header">
                    <div className="subtitle">{category.name}</div>
                    <ConditionalRender if={props.isEditionModeEnabled}>
                        <IconButton icon={<ManageIcon />} type="secondary" onClick={() => openPopup(<ManageSkillsPopup locationId={props.locationId} matrixId={props.matrixId} categoryId={category.id} selectedSkills={category.skills} onCompletedOperations={(selectedSkills: SkillDomainDTO[]) => handleManageSkills(category.id, selectedSkills)} />)} />
                    </ConditionalRender>
                </div>
                <ResponsiveDataTable
                    columnDefinitions={skillsColumns}
                    items={category.skills}
                    totalitems={category.skills.length}
                />
            </CardContainer>
        ))}
    </>
}