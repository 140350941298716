import "./EmployeeEvaluationDetailPage.css";

import { EmployeeEvaluationCategory, EmployeeEvaluationSkill, SkillsCatEmployeeEvaluationsCard } from "../components/SkillsCatEmployeeEvaluationsCard";
import { useCallback, useEffect, useMemo, useState } from "react";

import { BonusEmployeeEvaluationCard } from "../components/BonusEmployeeEvaluationCard";
import { CertificatesEmployeeEvaluationCard } from "../components/CertificatesEmployeeEvaluationCard";
import { ChecklistEmployeeEvaluationCard } from "../components/ChecklistEmployeeEvaluationCard";
import { ReactComponent as CommentsIcon } from "../../../../../lib/assets/icons/comments.svg";
import { ConsultEmployeeEvaluationHistoryPopup } from "../popups/ConsultEmployeeEvaluationHistoryPopup";
import { ContextualMenu } from "../../../../../lib/components/contextual-menu/ContextualMenu";
import { EmployeeActionItemSummaryDTO } from "../../../models/dtos/EmployeeActionItemSummaryDTO";
import { EmployeeBonusResponseDTO } from "../../../models/dtos/EmployeeBonusReponseDTO";
import { EmployeeCertificationsService } from "../../../services/EmployeeCertificationsService";
import { EmployeeEvaluationCommentsPopup } from "../popups/EmployeeEvaluationCommentsPopup";
import { EmployeeEvaluationDetailsDTO } from "../../../models/dtos/EmployeeEvaluationDetailsDTO";
import { EmployeeEvaluationMatrixResponseDTO } from "../../../models/dtos/EmployeeEvaluationMatrixResponseDTO";
import { EmployeeEvaluationsService } from "../../../services/EmployeeEvaluationsService";
import { ErrorPopup } from "../../../../../lib/components/popup/ErrorPopup";
import { EvaluationCertificateDTO } from "../../../../evaluations/models/EvaluationCertificateDTO";
import { EvaluationChecklistDTO } from "../../../../evaluations/models/EvaluationChecklistDTO";
import { EvaluationStatusTranslationTerms } from "../../../../evaluations/models/EvaluationStatus";
import { FollowUpEmployeeEvaluationCard } from "../components/FollowUpEmployeeEvaluationCard";
import { FormSection } from "../../../../../lib/components/form/section/FormSection";
import { ReactComponent as HistoryIcon } from "../../../../../lib/assets/icons/history.svg";
import { InfoBlock } from "../../../../../lib/components/info-block/InfoBlock";
import { ReactComponent as MoreActionsIcon } from "../../../../../lib/assets/icons/more-actions.svg";
import { PageLayout } from "../../../../../lib/layouts/main-content/PageLayout";
import { PagePermissions } from "../../../../../lib/infrastructure/authorization/PagePermissions";
import { Permission } from "../../../../../models/api/gate/Permission";
import { ScalableIconButton } from "../../../../../lib/components/buttons/ScalableIconButton";
import { StatusCard } from "../../../../../lib/components/cards/stats-card/StatusCard";
import { Tag } from "../../../../../lib/components/tag/Tag";
import { TextArea } from "../../../../../lib/components/form/text-area/TextArea";
import { TitledCardContainer } from "../../../../../lib/layouts/containers/card/TitledCardContainer";
import { getEvaluationStateCss } from "../../../../../common/helpers/getEvaluationStateCss";
import { translate } from "../../../../../lib/infrastructure/i18n/InternationalizationService";
import { useGlobalLocation } from "../../../../../lib/infrastructure/location/LocationServices";
import { useHasPermissions } from "../../../../../lib/infrastructure/authorization/useHasLocationPermissions";
import { useParams } from "react-router-dom";
import { usePopup } from "../../../../../lib/infrastructure/ui/UIServices";
import { useServiceCallPro } from "../../../../../lib/hooks/useServiceCall";
import { useUpdatePageData } from "../../../../../lib/infrastructure/navigation/hooks/useUpdatePageData";

var svc = new EmployeeEvaluationsService();
var employeeCertificationsSvc = new EmployeeCertificationsService();

const basePermissions = [Permission.GLOBAL_ADMIN, Permission.LOCATION_ADMIN, Permission.HUMAN_RESOURCES, Permission.LOCATION_MANAGER];
const topPermissions = [Permission.GLOBAL_ADMIN, Permission.LOCATION_ADMIN, Permission.HUMAN_RESOURCES];

export function EmployeeEvaluationDetailPage() {

    const openPopup = usePopup();

    const { evaluationId, employeeId } = useParams();

    const locationID = useGlobalLocation();
    const locationIDAsString = useMemo(() => "" + locationID, [locationID])

    const { isLoadingPermissions: loadingTopPermissions, hasPermission: hasTopPermissions } = useHasPermissions(topPermissions);

    const getEvaluationDetailsByIdCall = useServiceCallPro(svc.getEmployeeEvaluationDetailsById);
    const getEvaluationMatrixCall = useServiceCallPro(svc.getEmployeeEvaluationMatrix);

    const getEvaluationFollowUpItemsCall = useServiceCallPro(svc.getEmployeeEvaluationActionItems);
    const getUnjustifiedAbsencesCall = useServiceCallPro(svc.getEmployeeUnjustifiedAbsences);

    const getEmployeeCertificationsCall = useServiceCallPro(employeeCertificationsSvc.getEmployeeCertifications);
    const getEvaluationBonusCall = useServiceCallPro(svc.getEvaluationBonus);

    const [evaluationDetails, setEvaluationDetails] = useState<EmployeeEvaluationDetailsDTO>();
    const [evaluationMatrixDetails, setEvaluationMatrixDetails] = useState<EmployeeEvaluationMatrixResponseDTO>();

    const [evaluationTotal, setEvaluationTotal] = useState<number>(0);
    const [roleReferenceValue, setRoleReferenceValue] = useState<number>(0);
    const [extraEvaluationTotal, setExtraEvaluationTotal] = useState<number>(0);
    const [unjustifiedAbsences, setUnjustifiedAbsences] = useState<string>();

    const [managerFeedback, setManagerFeedback] = useState<string>();
    const [employeeFeedback, setEmployeeFeedback] = useState<string>();


    const [evaluationCategories, setEvaluationCategories] = useState<EmployeeEvaluationCategory[]>([]);
    const [evaluationChecklistItems, setEvaluationChecklistItems] = useState<EvaluationChecklistDTO[]>([]);
    const [lastEmployeeCertifications, setLastEmployeeCertifications] = useState<EvaluationCertificateDTO[]>([]);
    const [evaluationBonus, setEvalutionBonus] = useState<EmployeeBonusResponseDTO>();


    const [followUpItems, setFollowUpItems] = useState<EmployeeActionItemSummaryDTO[]>([]);


    /****************************
    * DATA REQUESTS
    *****************************/

    const getEvaluationDetailsById = useCallback(() => {
        if (!employeeId || !evaluationId) return;

        getEvaluationDetailsByIdCall.invoke(locationIDAsString, employeeId, evaluationId)
            .then((data) => {
                if (data) {
                    setEvaluationDetails(data);
                }
            })
            .catch((error) => {
                if (!error) return;
                openPopup(<ErrorPopup>{error.response.data.message}</ErrorPopup>);
            });
    }, [employeeId, evaluationId, getEvaluationDetailsByIdCall, locationIDAsString, openPopup]);


    const getEvaluationMatrix = useCallback(() => {
        if (!employeeId || !evaluationId) return;

        getEvaluationMatrixCall.invoke(locationIDAsString, employeeId, evaluationId)
            .then((data) => {
                if (data) {
                    setEvaluationMatrixDetails(data);
                }
            })
            .catch((error) => {
                if (!error) return;
                openPopup(<ErrorPopup>{error.response.data.message}</ErrorPopup>);
            });
    }, [employeeId, evaluationId, getEvaluationMatrixCall, locationIDAsString, openPopup])



    const getUnjustifiedAbsences = useCallback(() => {
        if (!employeeId || !evaluationId) return;

        getUnjustifiedAbsencesCall.invoke(locationIDAsString, employeeId, evaluationId)
            .then((res) => {
                if (!res) return;
                setUnjustifiedAbsences(`${res.absenceDays}` || "");
            })
            .catch((error) => {
                if (!error) return;
                openPopup(<ErrorPopup>{error.response.data.message}</ErrorPopup>);
            });
    }, [employeeId, evaluationId, getUnjustifiedAbsencesCall, locationIDAsString, openPopup]);


    const getEvaluationFollowUpItems = useCallback(() => {
        if (!employeeId || !evaluationId) return;

        getEvaluationFollowUpItemsCall.invoke(locationIDAsString, employeeId, evaluationId)
            .then((res) => {
                if (!res) return;
                setFollowUpItems(res.items || []);
            })
            .catch((error) => {
                if (!error) return;
                openPopup(<ErrorPopup>{error.response.data.message}</ErrorPopup>);
            });
    }, [employeeId, evaluationId, getEvaluationFollowUpItemsCall, locationIDAsString, openPopup]);




    const getEmployeeCertifications = useCallback(() => {

        if (!evaluationDetails?.targetEmployee.userEmployeeID) return;


        getEmployeeCertificationsCall.invoke(locationIDAsString, evaluationDetails?.targetEmployee.userEmployeeID, { page: 0, pageLength: 2 }, undefined)
            .then((response) => {

                if (response?.totalItems === 0) return;

                const mappedCertifications = response?.employeeCertifications.map((c) => {

                    var cert: EvaluationCertificateDTO = {
                        id: c.employeeCertificationId,
                        certificateName: c.certificationName,
                        startDate: c.startDate ?? null,
                        endDate: c.endDate ?? null,
                        state: c.state
                    };

                    return cert;
                });

                setLastEmployeeCertifications(mappedCertifications || []);

            })
            .catch((error) => {
                if (!error) return;
                openPopup(<ErrorPopup>{error.response.data.message}</ErrorPopup>);
            });

    }, [evaluationDetails?.targetEmployee.userEmployeeID, getEmployeeCertificationsCall, locationIDAsString, openPopup]);


    const getEvaluationBonus = useCallback(() => {

        if (!locationIDAsString || !employeeId || !evaluationId) return;

        getEvaluationBonusCall.invoke(locationIDAsString, employeeId, evaluationId)
            .then((data) => {
                if (!data) return;
                setEvalutionBonus(data);
            })
            .catch((error) => {
                if (!error) return;
                openPopup(<ErrorPopup>{error.response.data.message}</ErrorPopup>);
            });
    }, [locationIDAsString, employeeId, evaluationId, getEvaluationBonusCall.invoke, openPopup]);


    /****************************
     * DATA MANIPULATION EFFECTS
     *****************************/

    const checklistSumValue = useMemo(() => {
        if (!evaluationChecklistItems || evaluationMatrixDetails?.evaluationMatrixChecklistItems === undefined) {
            return "-";
        }

        return `${evaluationChecklistItems.filter(item => item.isChecked).length}/${evaluationMatrixDetails?.evaluationMatrixChecklistItems.length}`;


    }, [evaluationChecklistItems, evaluationMatrixDetails?.evaluationMatrixChecklistItems]);


    useEffect(() => {
        getEvaluationDetailsById();
        getUnjustifiedAbsences();
        getEvaluationFollowUpItems();
        getEvaluationMatrix();
        getEvaluationBonus();
    }, [employeeId, evaluationId]);





    useEffect(() => {
        if (evaluationDetails?.targetEmployee.userEmployeeID) {
            getEmployeeCertifications();
        }
    }, [evaluationDetails?.targetEmployee.userEmployeeID]);



    useEffect(() => {
        if (!evaluationDetails) {
            return;
        }

        setManagerFeedback(evaluationDetails.feedbackFromManager || "");
        setEmployeeFeedback(evaluationDetails.feedbackFromEmployee || "");
    }, [evaluationDetails]);



    useEffect(() => {
        if (!evaluationCategories) return;

        var sum = evaluationCategories.flatMap(ev => ev.skills).reduce((accumulator, currentObj) => {
            if (!currentObj.value || !currentObj.competenceLevel) return accumulator;

            return accumulator + (parseInt(currentObj.value) || 0);
        }, 0);

        setEvaluationTotal(sum);


        var sumReference = evaluationCategories.flatMap(ev => ev.skills).reduce((accumulator, currentObj) => {
            if (currentObj.competenceLevel == null) return accumulator;

            return accumulator + currentObj.competenceLevel;
        }, 0);

        setRoleReferenceValue(sumReference);


        var sumWithoutCompetence = evaluationCategories.flatMap(ev => ev.skills).reduce((accumulator, currentObj) => {
            if (currentObj.value && !currentObj.competenceLevel) {
                return accumulator + (parseInt(currentObj.value) || 0);
            }
            return accumulator;
        }, 0);

        setExtraEvaluationTotal(sumWithoutCompetence);

    }, [evaluationCategories]);


    useEffect(() => {

        if (!evaluationDetails || !evaluationMatrixDetails) return;

        var evaluationCategoriesMapped: EmployeeEvaluationCategory[] = [];

        for (var category of evaluationMatrixDetails.skillCategoryDetails) {

            var evaluationCategory: EmployeeEvaluationCategory = {
                name: category.name,
                skills: category.skills.map((skill) => {

                    const evaluationMatrixEntry = evaluationMatrixDetails.evaluationMatrixEntries.find(entry => entry.skillId === skill.skillID);
                    const evaluationValue = evaluationDetails.evaluationValues.find(evaValue => evaValue.targetEvaluationMatrixEntryId === evaluationMatrixEntry?.id);

                    const mSkill: EmployeeEvaluationSkill = {
                        isValid: true,
                        name: skill.name,
                        skillId: skill.skillID,
                        // skill is Disabled???
                        targetEvaluationMatrixEntryId: evaluationMatrixEntry?.id || 0,
                        competenceLevel: evaluationMatrixEntry?.competenceLevel ?? null,
                        value: evaluationValue?.value !== undefined ? `${evaluationValue?.value}` : undefined
                    };

                    return mSkill;
                })
            }

            evaluationCategoriesMapped.push(evaluationCategory)
        }

        setEvaluationCategories(evaluationCategoriesMapped)

    }, [evaluationDetails?.evaluationValues, evaluationMatrixDetails])



    useEffect(() => {

        if (!evaluationDetails?.evaluationChecklistItems || !evaluationMatrixDetails?.evaluationMatrixChecklistItems) return;

        const mappedItems = evaluationMatrixDetails?.evaluationMatrixChecklistItems.map((matrixItem, idx) => {
            const matchedItem = evaluationDetails?.evaluationChecklistItems.find(
                ({ targetChecklistItemId }) => targetChecklistItemId === matrixItem.evaluationMatrixChecklistItemId
            );

            return {
                targetMatrixChecklistItemId: matrixItem.evaluationMatrixChecklistItemId,
                checklistItemDescription: matrixItem.description,
                isChecked: matchedItem?.isChecked ?? false,
                id: idx
            };
        });

        setEvaluationChecklistItems(mappedItems);

    }, [evaluationDetails?.evaluationValues, evaluationMatrixDetails?.evaluationMatrixChecklistItems])





    /****************************
     * USER ACTIONS
     *****************************/

    const handleHistoryClicked = useCallback(() => {
        openPopup(
            <ConsultEmployeeEvaluationHistoryPopup locationId={locationIDAsString} employeeId={"" + employeeId} evaluationId={"" + evaluationId} />
        )
    }, [employeeId, evaluationId, openPopup])



    const handleCommentsClicked = useCallback(() => {
        openPopup(
            <EmployeeEvaluationCommentsPopup locationId={locationIDAsString} employeeId={"" + employeeId} evaluationId={"" + evaluationId} />
        );
    }, [employeeId, evaluationId, openPopup])

    /****************************
     * CSS & HTML
     *****************************/

    const renderPageActionsBtns = useMemo(() => {
        if (!evaluationDetails?.state || !evaluationId) return;
        if (loadingTopPermissions) return;

        // const historyBtn: React.ReactNode = <ResponsiveButton type="secondary" showButtonIcon={true} icon={<HistoryIcon />} text={undefined} onClick={handleHistoryClicked} />;
        // const commentsBtn: React.ReactNode = <ResponsiveButton type="secondary" showButtonIcon={true} icon={<CommentsIcon />} text={undefined} onClick={handleCommentsClicked} />;

        return <ContextualMenu items={
            [
                { icon: <CommentsIcon />, label: translate("BUSINESSLINES.EVALUATIONS.COMMENTS.Comments"), onClick: handleCommentsClicked, navigateTo: "" },
                { icon: <HistoryIcon />, label: translate("BUSINESSLINES.EVALUATIONS.INFOANDFORM.History"), onClick: handleHistoryClicked, navigateTo: "" },
            ]
        } >
            <ScalableIconButton size={30} icon={<MoreActionsIcon />} />
        </ContextualMenu>

    }, [evaluationDetails?.state, evaluationId, loadingTopPermissions, handleHistoryClicked, handleCommentsClicked])



    const renderAdditionalInfoCards = useMemo(() => {
        if (evaluationId) {
            return <div className="additional-info">
                <BonusEmployeeEvaluationCard isLoading={getEvaluationBonusCall.isLoading} items={evaluationBonus} />
                <StatusCard isLoading={getUnjustifiedAbsencesCall.isLoading}
                    title={translate("BUSINESSLINES.EVALUATIONS.INFOANDFORM.UnjustifiedAbsences")}
                    value={unjustifiedAbsences ?? "-"}
                    backgroundColor="status-dark-grey"
                    className="absences-status-card" />
                <CertificatesEmployeeEvaluationCard locationId={locationIDAsString} employeeValmetId={"" + employeeId}
                    certificates={lastEmployeeCertifications || []}
                    isLoading={getEvaluationDetailsByIdCall.isLoading && getEmployeeCertificationsCall.isLoading} />
            </div>
        }
    }, [evaluationBonus, getEvaluationBonusCall.isLoading, evaluationId, employeeId, getUnjustifiedAbsencesCall.isLoading, unjustifiedAbsences, locationIDAsString, lastEmployeeCertifications, getEvaluationDetailsByIdCall.isLoading, getEmployeeCertificationsCall.isLoading]);


    const renderChecklistCard = useMemo(() => {
        if (evaluationDetails?.evaluationChecklistItems && evaluationMatrixDetails?.evaluationMatrixChecklistItems) {
            return <ChecklistEmployeeEvaluationCard
                items={evaluationChecklistItems || []}
                isLoading={getEvaluationDetailsByIdCall.isLoading || getEvaluationMatrixCall.isLoading} />;
        }
    }, [evaluationDetails?.evaluationChecklistItems, evaluationMatrixDetails?.evaluationMatrixChecklistItems, getEvaluationDetailsByIdCall.isLoading, getEvaluationMatrixCall.isLoading, evaluationChecklistItems]);


    return (
        <PagePermissions permissions={basePermissions} >
            <PageLayout
                tabTitle={translate("BUSINESSLINES.EVALUATIONS.Evaluation")}
                showBreadcrumb
                pageTitle={translate("BUSINESSLINES.EVALUATIONS.Evaluation")}
                className="evaluation-details"
                actions={renderPageActionsBtns}
            >
                <div className="evaluation-details-summary">
                    <TitledCardContainer className="employee-data"
                        extraTitle={
                            <Tag text={evaluationDetails?.state ? translate(EvaluationStatusTranslationTerms[evaluationDetails.state]) : "-"}
                                backgroundColor={getEvaluationStateCss(evaluationDetails?.state ?? -1)} />
                        }
                        title={translate("BUSINESSLINES.EVALUATIONS.INFOANDFORM.EmployeeData")} isLoading={getEvaluationDetailsByIdCall.isLoading}>
                        <FormSection unsetFlexGrow>
                            <InfoBlock label={translate("BUSINESSLINES.EVALUATIONS.INFOANDFORM.Name")}
                                value={evaluationDetails?.targetEmployee.userDisplayName || "-"} />
                            <InfoBlock label={translate("BUSINESSLINES.EVALUATIONS.INFOANDFORM.Role")}
                                value={evaluationDetails?.roleName || "-"} />
                        </FormSection>
                    </TitledCardContainer>
                    <div className="evaluation-details-summary-cards">
                        <StatusCard isLoading={getEvaluationDetailsByIdCall.isLoading} title={translate("BUSINESSLINES.EVALUATIONS.INFOANDFORM.Evaluation")} value={`${evaluationTotal}/${roleReferenceValue} (${extraEvaluationTotal})`} backgroundColor="status-dark-grey" />
                        <StatusCard isLoading={getEvaluationDetailsByIdCall.isLoading}
                            title={translate("BUSINESSLINES.EVALUATIONS.INFOANDFORM.Checklist")}
                            value={checklistSumValue ?? "-"}
                            backgroundColor="status-dark-grey" />
                    </div>
                </div>

                <div className="skills-categories">
                    <SkillsCatEmployeeEvaluationsCard
                        roleName={evaluationDetails?.roleName || ""}
                        evaluationId={evaluationId}
                        values={evaluationCategories}
                    />
                </div>

                {renderAdditionalInfoCards}
                {renderChecklistCard}

                <div className="feedback-cards">
                    <TitledCardContainer title={translate("BUSINESSLINES.EVALUATIONS.INFOANDFORM.FeedbackManager")}>
                        <TextArea
                            value={managerFeedback}
                            placeholder={translate("COMMON.FORM.DefaultPlaceholder")}
                            //isDisabled={props.formControl.isDisabled}
                            alternativeStyle
                        ></TextArea>
                    </TitledCardContainer>
                    <TitledCardContainer title={translate("BUSINESSLINES.EVALUATIONS.INFOANDFORM.FeedbackEmployee")}>
                        <TextArea
                            value={employeeFeedback}
                            placeholder={translate("COMMON.FORM.DefaultPlaceholder")}
                            //isDisabled={props.formControl.isDisabled}
                            alternativeStyle
                        ></TextArea>
                    </TitledCardContainer>
                </div>

                <FollowUpEmployeeEvaluationCard
                    items={followUpItems}
                    isLoading={getEvaluationFollowUpItemsCall.isLoading} />
            </PageLayout >
        </PagePermissions>
    );
}

