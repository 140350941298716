import "./SelectSingle.css";

import { useCallback, useEffect, useMemo, useRef, useState } from "react";

import { ReactComponent as ArrowDownSVG } from "../../../assets/icons/arrowDown.svg";
import { CssClassnameBuilder } from "../../../utils/CssClassnameBuilder";
import { Portal } from "../../../layouts/portal/AppPortal";
import React from "react";
import { ReactComponent as SelectedOptionSVG } from "../../../assets/icons/check.svg";
import { TextButton } from "../../buttons/TextButton";
import fuzzysearch from "fuzzysearch-ts";
import removeAccents from "remove-accents";
import { translate } from "../../../infrastructure/i18n/InternationalizationService";
import { useOutsideComponentClickTrigger } from "../../../hooks/useOutsideComponentClickTrigger";

interface ISelectSingleProps<T> {
  value?: T; // TODO: Hadle Initial Value
  options: T[];
  labelSelector: (value: T) => string;
  idSelector: (value: T) => string | number;
  className?: string;
  placeholder?: string;
  isDisabled?: boolean;
  hasError?: boolean;
  maxHeightOptions?: string;
  onChange?: (value?: T) => void;
  onFocus?: React.FocusEventHandler<HTMLInputElement>;
  icon?: React.ReactNode;
  isClearSelectionAvailable?: boolean;
  alternativeStyle?: boolean;
}

interface ISelectSingleOption<T> {
  value?: T;
  id: string | number;
  label: string;
  normalizedLabel: string;
}

export function SelectSingle<T>(props: ISelectSingleProps<T>) {
  const nativeInputRef = useRef<HTMLInputElement>(null);
  const componentRef = useRef<HTMLDivElement>(null);
  const portalRef = useRef<HTMLDivElement>(null);

  const [inputValue, setInputValue] = useState<string>();
  const [isPanelOpen, setIsPanelOpen] = useState(false);
  const [selectedId, setSelectedId] = useState<string | number>();
  const [selectedLabel, setSelectedLabel] = useState<string>();
  const [mode, setMode] = useState<"value" | "search">("value");
  const [highlightedOption, setHighlightedOption] = useState<number>(-1);
  const [dropdownPosition, setDropdownPosition] = useState({ top: 0, left: 0, width: 0 });


  /****************************
   * DATA MANIPULATION EFFECTS
   *****************************/

  const placeHolder = useMemo(
    () =>
      props.placeholder !== undefined
        ? props.placeholder
        : translate("COMMON.FORM.DefaultPlaceholder"),
    [props.placeholder]
  );

  const options = useMemo(() => {
    let mappedOptions = props.options.map(
      (opt: T): ISelectSingleOption<T> => ({
        value: opt,
        label: props.labelSelector(opt),
        id: props.idSelector(opt),
        normalizedLabel: removeAccents(props.labelSelector(opt).toLowerCase()),
      })
    );
    return mappedOptions;
  }, [props.options, props.idSelector, props.labelSelector]);

  const filteredOptions = useMemo(() => {
    let target = removeAccents(inputValue?.toLowerCase() || "");
    return target
      ? options.filter((opt) => fuzzysearch(target, opt.normalizedLabel))
      : options;
  }, [options, inputValue]);

  useEffect(() => {
    setHighlightedOption(-1);
    if (mode === "search") {
      nativeInputRef.current?.focus();
      setInputValue("");
      setIsPanelOpen(true);
    } else {
      setIsPanelOpen(false);
    }
  }, [mode]);

  useEffect(() => {
    let id = props.value && props.idSelector(props.value);

    if (id !== selectedId) {
      let label = props.value && props.labelSelector(props.value);
      setSelectedId(id);
      setSelectedLabel(label);
      setInputValue(label);
    }
  }, [options, props.value]);

  /****************************
   * USER ACTIONS
   *****************************/

  const handleSelectSingleClicked = useCallback(() => {
    if (!props.isDisabled) setMode("search");
  }, [setMode, props.isDisabled]);

  const handleItemSelected = useCallback(
    (
      ev: React.MouseEvent<HTMLDivElement, MouseEvent> | null,
      optionId: string | number,
      option: ISelectSingleOption<T>
    ) => {
      ev?.stopPropagation();

      setSelectedId(optionId);
      setSelectedLabel(option.label);
      setInputValue(option.label);

      props.onChange && props.onChange(option.value);
      setMode("value");
    },
    [props.onChange, setMode, setSelectedId, setSelectedLabel, setInputValue]
  );

  const handleClearSelectionClicked = useCallback(
    (ev: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      ev?.stopPropagation();

      setSelectedId(undefined);
      setSelectedLabel(undefined);
      setInputValue("");

      props.onChange && props.onChange(undefined);
      setMode("value");
    },
    [setMode, props.onChange, setSelectedId, setSelectedLabel, setInputValue]
  );

  const handleKeyDown = useCallback(
    (ev: React.KeyboardEvent<HTMLInputElement>) => {
      if (ev.defaultPrevented) return;

      switch (ev.key) {
        case "Escape":
        case "Tab":
          setMode("value");
          nativeInputRef.current?.blur();
          break;

        case "ArrowDown":
          if (highlightedOption < filteredOptions.length - 1)
            setHighlightedOption(highlightedOption + 1);
          break;

        case "ArrowUp":
          if (highlightedOption > 0)
            setHighlightedOption(highlightedOption - 1);
          break;

        case "Enter":
          if (highlightedOption >= 0) {
            let option = filteredOptions.at(highlightedOption);
            if (option)
              handleItemSelected(null, option?.id || highlightedOption, option);
            setMode("value");
            nativeInputRef.current?.blur();
          }
          break;
      }
    },
    [
      setMode,
      highlightedOption,
      setHighlightedOption,
      filteredOptions,
      handleItemSelected,
      nativeInputRef,
    ]
  );

  useOutsideComponentClickTrigger(portalRef, () => {
    setInputValue(selectedLabel);
    setMode("value");
  });

  /****************************
   * CSS & HTML
   *****************************/

  useEffect(() => {
    if (isPanelOpen && componentRef.current) {
      const rect = componentRef.current.getBoundingClientRect();
      setDropdownPosition({
        top: rect.bottom + window.scrollY,
        left: rect.left + window.scrollX,
        width: rect.width
      });
    }
  }, [isPanelOpen]);

  // const maxHeightOptions = useMemo((): React.CSSProperties | undefined => {
  //   if (props.maxHeightOptions) {
  //     return { maxHeight: props.maxHeightOptions };
  //   } else return undefined;
  // }, [props.maxHeightOptions]);

  const selectSingleCss = useMemo(() => {
    return CssClassnameBuilder.new()
      .add("select-single")
      .addConditional(props.alternativeStyle, "alternative-style")
      .addConditional(props.className, props.className)
      .addConditional(props.hasError, "error")
      .addConditional(props.isDisabled, "disabled")
      .addConditional(props.icon, "has-icon")
      .build();
  }, [
    props.className,
    props.hasError,
    props.isDisabled,
    props.icon,
    props.alternativeStyle,
  ]);

  const optionsHTML = useMemo(
    () =>
      filteredOptions.map((opt, idx) => {
        const id = opt.id;

        const isSelected = selectedId === id;

        const css = CssClassnameBuilder.new()
          .add("single-select-option")
          .addConditional(isSelected, "selected")
          .addConditional(highlightedOption === idx, "hover")
          .build();

        return (
          <div
            key={id}
            className={css}
            onClick={(ev) => handleItemSelected(ev, id, opt)}
          >
            <span>{opt.label}</span>

            {isSelected ? <SelectedOptionSVG className="check-option" /> : null}
          </div>
        );
      }),
    [handleItemSelected, selectedId, filteredOptions, highlightedOption]
  );

  return (
    <div
      ref={componentRef}
      className={selectSingleCss}
      onClick={handleSelectSingleClicked}
    >
      {props.icon ? (
        <div className="select-single-icon">{props.icon}</div>
      ) : null}
      <input
        ref={nativeInputRef}
        className="native-input"
        placeholder={placeHolder}
        disabled={props.isDisabled}
        type="text"
        value={inputValue}
        onChange={(ev) => setInputValue(ev.target.value)}
        onKeyDown={handleKeyDown}
      ></input>
      <ArrowDownSVG className="arrow-down-icon" />

      {isPanelOpen ? (
        <Portal portalRef={portalRef}>
          <div className="select-single-options-panel" style={{ ...dropdownPosition, maxHeight: props.maxHeightOptions }}>
            {props.isClearSelectionAvailable ? (
              <div className="single-select-actions">
                <TextButton
                  text={translate("COMMON.Clear")}
                  className="mini-text-button"
                  onClick={handleClearSelectionClicked}
                />
              </div>
            ) : null}
            {optionsHTML}
          </div>
        </Portal>
      ) : null}
    </div>
  );
}

