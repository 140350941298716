import "./ViewEvaluationMatricesPage.css";

import { useCallback, useEffect, useMemo, useState } from "react";
import { useClosePopup, useCreateNotification, usePopup, useWindowResize } from "../../../lib/infrastructure/ui/UIServices";
import { useNavigate, useParams } from "react-router-dom";

import { ReactComponent as AddIcon } from "../../../lib/assets/icons/add.svg";
import { Button } from "../../../lib/components/buttons/Button";
import { ColumnDefinition } from "../../../lib/components/table/TableInterfaces";
import { ErrorPopup } from "../../../lib/components/popup/ErrorPopup";
import { EvaluationMatricesQueryOptions } from "../models/dtos/EvaluationMatricesQueryOptions";
import { EvaluationMatricesService } from "../services/EvaluationMatricesService";
import { EvaluationMatrixSummaryDTO } from "../models/dtos/EvaluationMatrixSummaryDTO";
import { FormFieldTextInput } from "../../../lib/components/form/form-field/FormFieldTextInput";
import { IconButton } from "../../../lib/components/buttons/IconButton";
import { PageLayout } from "../../../lib/layouts/main-content/PageLayout";
import { PagePermissions } from "../../../lib/infrastructure/authorization/PagePermissions";
import { Permission } from "../../../models/api/gate/Permission";
import { ResponsiveDataTable } from "../../../lib/components/table/ResponsiveDataTable";
import { ReactComponent as SearchIcon } from "../../../lib/assets/icons/search-icon.svg";
import { Tag } from "../../../lib/components/tag/Tag";
import axios from "axios";
import { translate } from "../../../lib/infrastructure/i18n/InternationalizationService";
import { useFormControl } from "../../../lib/components/form/Form";
import { useGlobalBusinessLine } from "../../../lib/infrastructure/business-line/BusinessLineServices";
import { useGlobalLocation } from "../../../lib/infrastructure/location/LocationServices";
import { useHasPermissions } from "../../../lib/infrastructure/authorization/useHasLocationPermissions";
import { useServiceCallPro } from "../../../lib/hooks/useServiceCall";

var evaluationMatricesSvc = new EvaluationMatricesService();

export interface IOrderInfo {
  direction: "ASC" | "DESC" | "NONE";
  columnKey: string;
}

const topPermissions = [Permission.GLOBAL_ADMIN, Permission.LOCATION_ADMIN, Permission.HUMAN_RESOURCES];

export function ViewEvaluationMatricesPage() {

  const { isLoadingPermissions, hasPermission } = useHasPermissions(topPermissions);
  const navigate = useNavigate();

  const { id: businessLineID } = useParams();

  const windowResize = useWindowResize();

  const openPopup = usePopup();
  const locationID = useGlobalLocation();

  const [evaluationMatrices, setEvaluationMatrices] = useState<EvaluationMatrixSummaryDTO[]>([]);
  const [totalItems, setTotalItems] = useState<number>();

  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [currentpage, setCurrentPage] = useState(0);

  const [timer, setTimer] = useState<NodeJS.Timeout>();
  const [searchWord, setSearchWord] = useState<string>();

  var searchboxController = useFormControl<string>({
    isDisabled: false,
    enableAutoValidate: false,
  });


  /*****************
   * SERVICE CALLS 
   ****************/

  const getEvaluationMatricesSummariesCall = useServiceCallPro(evaluationMatricesSvc.getEvaluationMatricesSummaries);


  const useSelectedBusinessLine = useGlobalBusinessLine();





  useEffect(() => {

    const controller = new AbortController();
    getEvaluationMatricesSummaries(controller);
    return () => { controller.abort() };

  }, [currentpage, itemsPerPage, searchWord]);





  const getEvaluationMatricesSummaries = useCallback((controller: AbortController | undefined) => {

    if (!locationID && !businessLineID) return;

    var queryOpts: EvaluationMatricesQueryOptions = {
      searchWord: searchWord,
      page: currentpage,
      pageLength: itemsPerPage
    };

    getEvaluationMatricesSummariesCall.invoke(`${locationID}`, "" + businessLineID, queryOpts, controller?.signal)
      .then((data) => {
        setEvaluationMatrices(data?.evaluationMatrices || []);
        setTotalItems(data?.totalItems);
      })
      .catch((error) => {
        if (!error || axios.isCancel(error)) return;
        openPopup(<ErrorPopup>{error.response.data.message}</ErrorPopup>);
      });
  }, [locationID, businessLineID, searchWord, currentpage, itemsPerPage, getEvaluationMatricesSummariesCall, openPopup]);


  useEffect(() => {
    if (timer)
      clearTimeout(timer);

    var responseTimeOut = setTimeout(() => {
      if (timer)
        setSearchWord(searchboxController.value);
      setCurrentPage(0);
    }, 1000);

    setTimer(responseTimeOut);

  }, [searchboxController.value]);


  const handleOnPageAndItemsChanged = useCallback(
    (items: number, currentPage: number) => {
      setItemsPerPage(items);
      setCurrentPage(currentPage);
    },
    [setItemsPerPage, setCurrentPage]
  );


  const columns: ColumnDefinition<EvaluationMatrixSummaryDTO>[] = useMemo(
    () => [
      {
        cellRenderProp: (v) => v.description,
        headerRender: translate("BUSINESSLINES.MATRIX.INFOANDFORM.Area"),
        isMobilePrimaryCell: true,
        width: "40%",
      },
      {
        cellRenderProp: (v) => v.manager.userDisplayName ?? "",
        headerRender: translate("BUSINESSLINES.MATRIX.INFOANDFORM.Manager"),
        width: "60%",
      },
      {
        cellRenderProp: (v) => < Tag
          text={v.isDisabled ? translate("COMMON.Disabled") : translate("COMMON.Enabled")}
          backgroundColor={v.isDisabled ? "status-light-grey" : "status-green"}
          isTiny={false}
        />,
        headerRender: translate("COMMON.Status"),
        isStatusCell: true
      }
    ], []);



  const renderCreateEvaluationMatrixBtn = useMemo(() => {
    if (isLoadingPermissions || !hasPermission) return null;
    if (windowResize > 768)
      return (
        <Button
          text={translate("BUSINESSLINES.MATRIX.CreateMatrix")}
          type="primary"
          onClick={() => locationID && businessLineID ? navigate("create") : null}
        />
      );
    else
      return (
        <IconButton
          icon={<AddIcon />}
          type="primary"
          onClick={() => locationID && businessLineID ? navigate("create") : null}
        />
      );
  }, [isLoadingPermissions, hasPermission, windowResize, locationID, businessLineID, /*openPopup,*/ navigate]);



  return (
    <PagePermissions permissions={topPermissions} >
      <PageLayout
        tabTitle={translate("BUSINESSLINES.MATRIX.EvaluationMatrices")}
        pageTitle={translate("BUSINESSLINES.MATRIX.EvaluationMatrices")}
        className="evaluation-matrices"
        showBreadcrumb
        actions={useSelectedBusinessLine && !useSelectedBusinessLine.isDisabled && renderCreateEvaluationMatrixBtn}
      >

        <FormFieldTextInput
          formControl={searchboxController}
          icon={<SearchIcon />}
          placeholder={translate("COMMON.FORM.SearchPlaceholder")}
          className="search-box"
        />
        <ResponsiveDataTable
          columnDefinitions={columns}
          items={evaluationMatrices || []}
          totalitems={totalItems || 0}
          paginationOptions={{ itemsPerPage: itemsPerPage, itemsPerPageOptions: [10, 20, 30] }}
          currentpage={currentpage}
          isLoading={getEvaluationMatricesSummariesCall.isLoading}
          onPageAndItemsChanged={handleOnPageAndItemsChanged}
          onClickRow={(matrix) => navigate(`${matrix.id}`)}
        />
      </PageLayout>
    </PagePermissions>

  );
}
