import "./Criterias.css";

import { useCallback, useEffect, useMemo, useState } from "react";
import { useClosePopup, useCreateNotification, usePopup } from '../../../lib/infrastructure/ui/UIServices';

import { AddCriteriaPopup } from "./popups/AddCriteriaPopup";
import { ReactComponent as AddIcon } from "../../../lib/assets/icons/add.svg";
import { ColumnDefinition } from "../../../lib/components/table/TableInterfaces";
import { ConditionalRender } from "../../../lib/functional/ConditionalRender";
import { CriteriaSummaryDTO } from "../models/dtos/CriteriaSummaryDTO";
import { CriteriasService } from "../services/CriteriasService";
import { ReactComponent as DeleteIcon } from "../../../lib/assets/icons/remover.svg";
import { EditCriteriaPopup } from "./popups/EditCriteriaPopup";
import { ReactComponent as EditIcon } from "../../../lib/assets/icons/editar.svg";
import { ErrorPopup } from "../../../lib/components/popup/ErrorPopup";
import { FullScreenLoader } from "../../../lib/components/loader/FullScreenLoader";
import { IconButton } from "../../../lib/components/buttons/IconButton";
import { Notification } from "../../../lib/components/notifications/Notification";
import { ResponsiveDataTable } from "../../../lib/components/table/ResponsiveDataTable";
import { ScalableIconButton } from "../../../lib/components/buttons/ScalableIconButton";
import { WarningPopup } from "../../../lib/components/popup/WarningPopup";
import { translate } from "../../../lib/infrastructure/i18n/InternationalizationService";
import { useGlobalBusinessLine } from "../../../lib/infrastructure/business-line/BusinessLineServices";
import { useServiceCallPro } from "../../../lib/hooks/useServiceCall";

const criteriasSvc = new CriteriasService();

export interface ICriteriasProps {
    roleId: string;
    locationId: string;
    businessLineId: string;
    loadingTopPerms: boolean;
    hasTopPerms: boolean;
    isRoleDisabled: boolean;
}

export function Criterias(props: ICriteriasProps) {
    const closePopup = useClosePopup();
    const openPopup = usePopup();
    const createNotification = useCreateNotification();
    const useSelectedBusinessLine = useGlobalBusinessLine();

    const getCriteriasCall = useServiceCallPro(criteriasSvc.getCriterias);
    const deleteJobCriteriaStateCall = useServiceCallPro(criteriasSvc.deleteCriteria);
    const [criterias, setCriterias] = useState<CriteriaSummaryDTO[]>([]);


    /****************************
    * DATA REQUESTS
    *****************************/

    const getCriterias = useCallback(() => {
        if (!props.locationId || !props.businessLineId || !props.roleId) {
            console.log("## Cannot GET Criterias based on invalid values ##",
                { "Location_ID": props.locationId, "BL_ID": props.businessLineId, "Role ID": props.roleId });
            return;
        }

        getCriteriasCall
            .invoke(props.locationId, props.businessLineId, props.roleId)
            .then((res) => {
                setCriterias(res?.criterias || []);
            })
            .catch((error) => {
                if (!error) return;
                openPopup(<ErrorPopup>{error.response.data.message}</ErrorPopup>);
            });
    }, [props.locationId, props.businessLineId, props.roleId, openPopup, setCriterias, getCriteriasCall.invoke]);


    const deleteCriteria = useCallback((criteriaId: number) => {
        if (!props.locationId || !props.businessLineId || !props.roleId || !criteriaId) {
            console.log("## Cannot Delete Criteria based on invalid values ##",
                { "Location_ID": props.locationId, "BL_ID": props.businessLineId, "Role ID": props.roleId, "Criteria_ID": criteriaId });
            return;
        }

        deleteJobCriteriaStateCall.invoke(props.locationId, props.businessLineId, props.roleId, criteriaId)
            .then((_) => {
                getCriterias();
                createNotification(<Notification
                    type="success"
                    title={translate("COMMON.SYSTEMPOPUPS.Success")}
                    text={translate("BUSINESSLINES.ROLES.CRITERIAS.POPUP.DeleteCriteriaSuccess")} />);
            })
            .catch((error) => {
                if (!error) return;
                openPopup(<ErrorPopup>{error.response.data.message}</ErrorPopup>)
            });

    }, [getCriterias, openPopup, deleteJobCriteriaStateCall, props.locationId, props.businessLineId, props.roleId, createNotification]);


    /****************************
     * DATA MANIPULATION EFFECTS
     *****************************/

    useEffect(() => {
        getCriterias();
    }, []);


    const canEditCriterias = useMemo(() => {
        if (useSelectedBusinessLine && useSelectedBusinessLine.isDisabled) return false;
        if (!props.locationId || !props.businessLineId) return false;
        if (!props.hasTopPerms || props.loadingTopPerms) return false;
        if (props.isRoleDisabled) return false;

        return true;
    }, [props.businessLineId, props.hasTopPerms, props.isRoleDisabled, props.loadingTopPerms, props.locationId, useSelectedBusinessLine]);


    /****************************
     * CSS & HTML
     *****************************/

    const columns: ColumnDefinition<CriteriaSummaryDTO>[] = useMemo(
        () => [
            {
                cellRenderProp: (v) => v.name,
                headerRender: translate("BUSINESSLINES.ROLES.CRITERIAS.INFOANDFORM.Name"),
                width: "20%",
            },
            {
                cellRenderProp: (v) => v.description,
                headerRender: translate("BUSINESSLINES.ROLES.CRITERIAS.INFOANDFORM.Description"),
                isMobilePrimaryCell: true,
            },
            {
                cellRenderProp: (v) => {
                    if (!canEditCriterias) return null;
                    return (
                        <ScalableIconButton
                            icon={<EditIcon />}
                            onClick={() =>
                                openPopup(
                                    <EditCriteriaPopup
                                        criteriaId={v.roleCriteriaId}
                                        roleId={props.roleId}
                                        businessLineId={props.businessLineId}
                                        locationId={props.locationId}
                                        onCompleted={() => getCriterias()}
                                    />
                                )
                            }
                            size={24}
                        />)
                },
                columnKey: "edit",
                width: "24px",
                isMobileHeaderIcon: true,
            },
            {
                cellRenderProp: (v) => {
                    if (!canEditCriterias)
                        return null;
                    return (
                        <ScalableIconButton
                            icon={<DeleteIcon />}
                            onClick={(ev) => {
                                ev.stopPropagation();
                                openPopup(
                                    <WarningPopup
                                        className="delete-criteria-popup"
                                        onDismissClicked={() => closePopup()}
                                        onConfirmClicked={() => deleteCriteria(v.roleCriteriaId)}
                                        dismissText={translate("COMMON.Cancel")}
                                        confirmText={translate("BUSINESSLINES.ROLES.CRITERIAS.POPUP.Delete")}
                                    >
                                        {translate("BUSINESSLINES.ROLES.CRITERIAS.POPUP.DeleteCriteriaConfirmText")}
                                    </WarningPopup>
                                );
                            }}
                            size={24}
                        />)
                },
                columnKey: "delete",
                width: "24px",
                isMobileHeaderIcon: true,
            },
        ], [canEditCriterias, openPopup, props.roleId, props.businessLineId, props.locationId, getCriterias, closePopup, deleteCriteria]);


    return (
        <div className="criterias">

            <div className="criterias-header">

                <div className="subtitle">
                    {translate("BUSINESSLINES.ROLES.CRITERIAS.Criterias")}
                </div>

                <div className="criterias-header-actions">

                    {useSelectedBusinessLine && useSelectedBusinessLine?.isDisabled ? null : (

                        <ConditionalRender if={!props.isRoleDisabled}>
                            {props.locationId && props.businessLineId && props.roleId ?
                                <IconButton icon={<AddIcon />} type="secondary"
                                    onClick={() =>
                                        openPopup(<AddCriteriaPopup locationId={props.locationId}
                                            businessLineId={props.businessLineId} roleId={props.roleId}
                                            onCompletedOperations={() => getCriterias()} />)} />
                                : null}

                        </ConditionalRender>)}
                </div>
            </div>

            {deleteJobCriteriaStateCall.isLoading && <FullScreenLoader />}

            <ResponsiveDataTable
                items={criterias || []}
                columnDefinitions={columns}
                totalitems={0}
                isLoading={getCriteriasCall.isLoading}
            />
        </div>)
}
