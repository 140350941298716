import { ConfigurationProvider } from "../../../lib/infrastructure/configuration/ConfigurationProvider";
import { EmployeeActionItemsResponseDTO } from "../../employees/models/dtos/EmployeeActionItemsResponseDTO";
import { EmployeeCertificationsResponseDTO } from "../../employees/models/dtos/EmployeeCertificationsResponseDTO";
import { EmployeeEvaluationsResponseDTO } from "../../employees/models/dtos/EmployeeEvaluationsResponseDTO";
import { EmployeePositionRecordsResponseDTO } from "../../employees/models/dtos/EmployeePositionRecordsResponseDTO";
import { HttpClient } from "../../../lib/infrastructure/http/HttpClient";
import { QueryOptions } from "../../../models/api/QueryOptions";
import qs from "qs";
import { SignURLRequestDTO } from "../../employees/models/dtos/SignURLRequestDTO";
import { SignURLResponseDTO } from "../../employees/models/dtos/SignURLResponseDTO";
import { EmployeeEvaluationDetailsDTO } from "../../employees/models/dtos/EmployeeEvaluationDetailsDTO";
import { EmployeeEvaluationMatrixResponseDTO } from "../../employees/models/dtos/EmployeeEvaluationMatrixResponseDTO";
import { EmployeeEvaluationAbsencesDTO } from "../../employees/models/dtos/EmployeeEvaluationAbsencesDTO";
import { EmployeeBonusResponseDTO } from "../../employees/models/dtos/EmployeeBonusReponseDTO";

const Route = (path: string) => {
    return ConfigurationProvider.getConfiguration().App.BackendUrl + path;
};

export class MyService {

    getMyPositionRecords(locationId: string): Promise<EmployeePositionRecordsResponseDTO> {
        return HttpClient.sessionRequest<EmployeePositionRecordsResponseDTO>({
            method: "GET",
            url: Route(`/api/v1/Locations/${locationId}/Me/PositionRecords`)
        })
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                throw error;
            });
    }

    getMyEvaluations(locationId: string, query: QueryOptions, abortSignal: AbortSignal | undefined): Promise<EmployeeEvaluationsResponseDTO | null> {
        return HttpClient.sessionRequest<EmployeeEvaluationsResponseDTO>({
            method: "GET",
            url: Route(`/api/v1/Locations/${locationId}/Me/Evaluations`),
            signal: abortSignal,
            params: query,
            paramsSerializer: (params) => {
                return qs.stringify(params, { arrayFormat: "repeat" });
            },
        })
            .then((response) => {
                if (!response.data) return null;
                return {
                    evaluations: response.data.evaluations,
                    totalItems: response.data.totalItems
                }
            })
            .catch((error) => {
                throw error;
            });
    }

    getMyCertifications(locationId: string, query: QueryOptions | null, abortSignal: AbortSignal | undefined): Promise<EmployeeCertificationsResponseDTO | null> {
        return HttpClient.sessionRequest<EmployeeCertificationsResponseDTO>({
            method: "GET",
            url: Route(`/api/v1/Locations/${locationId}/Me/Certifications`),
            signal: abortSignal,
            params: query,
            paramsSerializer: (params) => {
                return qs.stringify(params, { arrayFormat: "repeat" });
            },
        })
            .then((response) => {
                if (!response.data) return null;
                return {
                    employeeCertifications: response.data.employeeCertifications,
                    totalItems: response.data.totalItems
                }
            })
            .catch((error) => {
                throw error;
            });
    }



    getMyActionItems(locationId: string, query: QueryOptions | null, abortSignal: AbortSignal | undefined): Promise<EmployeeActionItemsResponseDTO | null> {
        return HttpClient.sessionRequest<EmployeeActionItemsResponseDTO>({
            method: "GET",
            url: Route(`/api/v1/Locations/${locationId}/Me/ActionItems`),
            signal: abortSignal,
            params: query,
            paramsSerializer: (params) => {
                return qs.stringify(params, { arrayFormat: "repeat" });
            },
        })
            .then((response) => {
                if (!response.data) return null;
                return {
                    items: response.data.items,
                    total: response.data.total
                }
            })
            .catch((error) => {
                throw error;
            });
    }


    getUrlDownloadAttachment(locationId: string, certificationId: number, attachmentId: number): Promise<string> {

        var requestDTO: SignURLRequestDTO = {
            url: Route(`/api/v1/Locations/${locationId}/Me/Certifications/${certificationId}/Attachments/${attachmentId}`)
        }

        return HttpClient.sessionRequest<SignURLResponseDTO>({
            method: "POST",
            url: Route(`/api/v1/Locations/${locationId}/Me/Certifications/SignURL`),
            data: requestDTO
        })
            .then((res) => {
                return res.data.url;
            })
            .catch((error) => {
                throw error;
            });
    }


    getMyEvaluationDetails(locationId: string, evaluationId: string): Promise<EmployeeEvaluationDetailsDTO> {

        return HttpClient.sessionRequest<EmployeeEvaluationDetailsDTO>({
            method: "GET",
            url: Route(`/api/v1/Locations/${locationId}/Me/Evaluations/${evaluationId}`),
        })
            .then((response) => {
                return response.data
            })
            .catch((error) => {
                throw error;
            });
    }



    getMyEvaluationMatrix(locationId: string, evaluationId: string): Promise<EmployeeEvaluationMatrixResponseDTO> {

        return HttpClient.sessionRequest<EmployeeEvaluationMatrixResponseDTO>({
            method: "GET",
            url: Route(`/api/v1/Locations/${locationId}/Me/Evaluations/${evaluationId}/Matrix`),
        })
            .then((response) => {
                return response.data
            })
            .catch((error) => {
                throw error;
            });
    }



    getMyEvaluationActionItems(locationId: string, evaluationId: string): Promise<EmployeeActionItemsResponseDTO> {
        return HttpClient.sessionRequest({
            method: "GET",
            url: Route(`/api/v1/Locations/${locationId}/Me/Evaluations/${evaluationId}/ActionItems`),
        })
            .then((response) => {
                return response.data
            })
            .catch((error) => {
                throw error;
            });
    }



    getMyEvaluationUnjustifiedAbsences(locationId: string, evaluationId: string): Promise<EmployeeEvaluationAbsencesDTO> {
        return HttpClient.sessionRequest({
            method: "GET",
            url: Route(`/api/v1/Locations/${locationId}/Me/Evaluations/${evaluationId}/Absenteeism`),
        })
            .then((response) => {
                return response.data
            })
            .catch((error) => {
                throw error;
            });
    }



    getMyEvaluationBonus(locationId: string, evaluationId: string): Promise<EmployeeBonusResponseDTO> {
        return HttpClient.sessionRequest<EmployeeBonusResponseDTO>({
            method: "GET",
            url: Route(`/api/v1/Locations/${locationId}/Me/Evaluations/${evaluationId}/Bonus`),
        })
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                throw error;
            });
    }


}
