import "./TitledCardContainer.css";

import { useEffect, useMemo, useState } from "react";

import { ReactComponent as ArrowDownIcon } from "../../../../lib/assets/icons/arrowDown-dark.svg";
import { ReactComponent as ArrowUpIcon } from "../../../../lib/assets/icons/arrowUp-dark.svg";
import { CardContainer } from "./CardContainer";
import { CssClassnameBuilder } from "../../../utils/CssClassnameBuilder";
import { Loader } from "../../../components/loader/Loader";
import { ScalableIconButton } from "../../../components/buttons/ScalableIconButton";

interface ITitledCardContainerProps {
  children: React.ReactNode;
  className?: string;
  isLoading?: boolean;
  title?: string;
  extraTitle?: React.ReactNode;
  loadingCardMinHeigth?: "small" | "medium" | "large";
  isCollapsible?: boolean;
}


export function TitledCardContainer(props: ITitledCardContainerProps) {

  const [isCollapsed, setIsCollapsed] = useState<boolean>();

  /****************************
     * DATA MANIPULATION EFFECTS
     *****************************/

  useEffect(() => {
    if (props.isCollapsible) {
      setIsCollapsed(true);
    }
  }, [props.isCollapsible]);


  /****************************
     * CSS & HTML
     *****************************/

  const popupContainerCss = useMemo(() => {
    return CssClassnameBuilder.new()
      .add("titled")
      .addConditional(props.className, props.className)
      .addConditional(props.isLoading, "loading")
      .addConditional(props.loadingCardMinHeigth && props.isLoading, props.loadingCardMinHeigth)
      .build();
  }, [props.className, props.isLoading, props.loadingCardMinHeigth]);


  const renderCollapseIcons = useMemo(() => {
    if (!props.isCollapsible) return null;

    if (isCollapsed) {
      return <ScalableIconButton icon={<ArrowDownIcon />} size={20} />;
    }
    else {
      return <ScalableIconButton icon={<ArrowUpIcon />} size={20} />
    }
  }, [props.isCollapsible, isCollapsed]);


  const renderChildrenContent = useMemo(() => {
    if (isCollapsed) return null;

    if (props.isLoading) {
      return <Loader />;
    }
    else {
      return props.children;
    }
  }, [props.children, props.isLoading, isCollapsed]);


  return (
    <CardContainer className={popupContainerCss}>
      <div className="header-container" onClick={() => props.isCollapsible && setIsCollapsed(!isCollapsed)}>
        <div className="title-left">
          {props.title}</div>
        <div className="title-right">
          {props.extraTitle}
          {renderCollapseIcons}
        </div>
      </div>
      {renderChildrenContent}
    </CardContainer>);
}



