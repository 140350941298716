import "./ManageSkillsPopup.css";

import { useCallback, useEffect, useMemo, useState } from "react";
import { useClosePopup, usePopup } from "../../../lib/infrastructure/ui/UIServices";

import { Checkbox } from "../../../lib/components/checkbox/Checkbox";
import { ConditionalRender } from "../../../lib/functional/ConditionalRender";
import { ErrorPopup } from "../../../lib/components/popup/ErrorPopup";
import { MessageBar } from "../../../lib/components/message-bar/MessageBar";
import { PopupActionButtons } from "../../../lib/layouts/containers/popup-buttons/PopupActionButtons";
import { PopupContainer } from "../../../lib/layouts/containers/popup-container/PopupContainer";
import { PopupContent } from "../../../lib/layouts/containers/popup-content/PopupContent";
import { PopupHeader } from "../../../lib/layouts/containers/popup-header/PopupHeader";
import { SkillDomainDTO } from "../../admin/skills/models/domain/SkillDomainDTO";
import { SkillsService } from "../../admin/skills/services/SkillsService";
import { Spacer } from "../../../lib/components/separator/Spacer";
import { ValidationErrorMessage } from "../../../lib/infrastructure/http/APIError";
import { translate } from "../../../lib/infrastructure/i18n/InternationalizationService";
import { useServiceCallPro } from "../../../lib/hooks/useServiceCall";

interface IManageSkillsPopupProps {
    locationId: string | undefined;
    matrixId?: string | undefined;
    categoryId: number;
    selectedSkills: SkillDomainDTO[];
    onCompletedOperations: (selectedSkills: SkillDomainDTO[]) => void;
}


var skillsSvc = new SkillsService();

export function ManageSkillsPopup(props: IManageSkillsPopupProps) {
    const closePopup = useClosePopup();
    const openPopup = usePopup();

    const getSkillsByCategoryCall = useServiceCallPro(skillsSvc.getSkills);

    const [categorySkills, setCategorySkills] = useState<SkillDomainDTO[]>([]);
    const [newSelectedSkills, setNewSelectedSkills] = useState<SkillDomainDTO[]>(props.selectedSkills);
    const [validationAlert, setValidationAlert] = useState<ValidationErrorMessage>({ showAlert: false });


    useEffect(() => {
        getSkillsByCategory();
    }, [])


    useEffect(() => {
        if (!newSelectedSkills.length)
            setValidationAlert({
                showAlert: true,
                message: translate("BUSINESSLINES.MATRIX.ERRORS.NoSkillsSelected"),
            });
        else
            setValidationAlert({ showAlert: false });
    }, [newSelectedSkills])


    const getSkillsByCategory = useCallback(() => {
        if (!props.locationId && (props.matrixId && !props.matrixId) && props.categoryId) return;

        getSkillsByCategoryCall.invoke(`${props.locationId}`, "" + props.categoryId)
            .then((data) => {
                setCategorySkills(data.skills);
            })
            .catch((error) => {
                if (!error) return;
                openPopup(<ErrorPopup>{error.response.data.message}</ErrorPopup>);
            });
    }, [props.locationId, props.matrixId, props.categoryId, getSkillsByCategoryCall, openPopup]);


    const handleCheckboxChange = useCallback((isChecked: boolean, skill: SkillDomainDTO) => {
        if (isChecked) {
            setNewSelectedSkills([...newSelectedSkills, skill]);
        } else {
            setNewSelectedSkills(newSelectedSkills.filter(selectedSkill => selectedSkill.id !== skill.id));
        }
    }, [newSelectedSkills]);

    const handleSelectClicked = useCallback(() => {
        const sortedSkills = newSelectedSkills.sort((a, b) => {
            if (a.name.toLowerCase() < b.name.toLowerCase()) {
                return -1;
            }
            if (a.name.toLowerCase() > b.name.toLowerCase()) {
                return 1;
            }
            return 0;
        });
        props.onCompletedOperations(sortedSkills);
        closePopup();
    }, [newSelectedSkills, props.onCompletedOperations, closePopup])

    const renderSkillsCheckboxes = useMemo(() => {
        return <div className="skills-list">{categorySkills.map(skill => {
            return <Checkbox
                key={skill.id}
                text={skill.name}
                isChecked={newSelectedSkills.some(selectedSkill => selectedSkill.id === skill.id)}
                onClick={(isChecked) => handleCheckboxChange(isChecked, skill)}
            />;
        })}
        </div>
    }, [newSelectedSkills, handleCheckboxChange, categorySkills]);

    return (
        <PopupContainer className="popup-manage-skills">
            <PopupHeader title={translate("BUSINESSLINES.MATRIX.POPUP.ManageSkills")} />
            <PopupContent isLoading={getSkillsByCategoryCall.isLoading}>
                <Spacer mode={"vertical"} px="12" />
                {renderSkillsCheckboxes}
                <ConditionalRender if={validationAlert.showAlert}>
                    <Spacer mode="vertical" px="10" />
                    <MessageBar type="error" text={"" + validationAlert.message} />
                </ConditionalRender>
                <Spacer mode={"vertical"} px="30" />
                <PopupActionButtons
                    buttons={[
                        {
                            text: translate("COMMON.Cancel"),
                            type: "tertiary",
                            onClick: () => closePopup(),
                        },
                        {
                            text: translate("COMMON.Select"),
                            type: "primary",
                            onClick: handleSelectClicked,
                            isDisabled: validationAlert.showAlert
                        },
                    ]}
                />
            </PopupContent>
        </PopupContainer>
    );
}
