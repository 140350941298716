import { ConfigurationProvider } from "../../lib/infrastructure/configuration/ConfigurationProvider";
import { translate } from "../../lib/infrastructure/i18n/InternationalizationService";

export interface SelectOption {
    id: string;
    description: string;
}

export function getActionItemOptions(): SelectOption[] {
    const translationKeys = ConfigurationProvider.getConfiguration().BusinessConfigurations.EmployeeActionItemTranslationsTerms;
    if (!translationKeys) return [];

    const actionItems: SelectOption[] = [];

    for (const property in translationKeys) {
        const translation = translate(translationKeys[property as keyof typeof translationKeys]);
        actionItems.push({ id: property, description: translation });
    }

    return actionItems;
}



export function getActionItemByKey(key: string): SelectOption {
    const translationKeys = ConfigurationProvider.getConfiguration().BusinessConfigurations.EmployeeActionItemTranslationsTerms;

    if (!translationKeys || !(translationKeys.hasOwnProperty(key))) {
        return { id: "", description: "" };
    }

    const translation = translate(translationKeys[key as keyof typeof translationKeys]);
    return { id: key, description: translation };
}



export function getActionItemTranslationByKey(key: string): string {
    const translationKeys = ConfigurationProvider.getConfiguration().BusinessConfigurations.EmployeeActionItemTranslationsTerms;

    if (!translationKeys || !(translationKeys.hasOwnProperty(key))) return key;

    return translate(translationKeys[key as keyof typeof translationKeys]);
}