import { ConfigurationProvider } from "../../../lib/infrastructure/configuration/ConfigurationProvider";
import { PeriodMatrixEvaluationsSummary } from "../models/dtos/PeriodMatrixEvaluationsSummaryDTO";
import { QueryOptions } from "../../../models/api/QueryOptions";
import { EvaluationsResponseDTO } from "../models/dtos/EvaluationsResponseDTO";
import { HttpClient } from "../../../lib/infrastructure/http/HttpClient";

import qs from "qs";
import { EvaluationPeriodMatrixSummaryDTO } from "../models/dtos/EvaluationPeriodMatrixSummaryDTO";




const Route = (path: string) => {
    return ConfigurationProvider.getConfiguration().App.BackendUrl + path;
};


export class EvaluationPeriodMatrixService {


    getPeriodMatrixEvaluations(locationId: string, businessLineId: string, evaluationPeriodId: string, evaluationPeriodMatrixId: string, query: QueryOptions): Promise<EvaluationsResponseDTO> {

        return HttpClient.sessionRequest<EvaluationsResponseDTO>({
            method: "GET",
            url: Route(`/api/v1/Locations/${locationId}/BusinessLines/${businessLineId}/EvaluationPeriods/${evaluationPeriodId}/EvaluationPeriodMatrices/${evaluationPeriodMatrixId}/Evaluations`),
            params: query,
            paramsSerializer: (params) => {
                return qs.stringify(params, { arrayFormat: "repeat" });
            },
        })
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                throw error;
            });
    }





    getPeriodMatrixEvaluationsSummary(locationId: string, businessLineId: string, evaluationPeriodId: string, evaluationPeriodMatrixId: string): Promise<EvaluationPeriodMatrixSummaryDTO> {
        
        return HttpClient.sessionRequest<EvaluationPeriodMatrixSummaryDTO>({
            method: "GET",
            url: Route(`/api/v1/Locations/${locationId}/BusinessLines/${businessLineId}/EvaluationPeriods/${evaluationPeriodId}/EvaluationPeriodMatrices/${evaluationPeriodMatrixId}`),
        })
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                throw error;
            });
    }







}

