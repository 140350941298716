import { translate } from "../../../../lib/infrastructure/i18n/InternationalizationService";

export enum EmployeeActionItemState {
    REGISTERED = 1,
    IN_PROGRESS = 2,
    CANCELED = 3,
    COMPLETED = 4,
    NOT_COMPLETED = 5
  }


export const EmployeeActionItemStateTranslationTerms: { [key: string]: string } = {
    1: "COMMON.STATUS.Registered",
    2: "COMMON.STATUS.InProgress",
    3: "COMMON.STATUS.Canceled",
    4: "COMMON.STATUS.Concluded",
    5: "COMMON.STATUS.NotConcluded"
}


export const EvaluationActionItemStateAsEnum: { [key: number]: EmployeeActionItemState } = {
  1: EmployeeActionItemState.REGISTERED,
  2: EmployeeActionItemState.IN_PROGRESS,
  3: EmployeeActionItemState.CANCELED,
  4: EmployeeActionItemState.COMPLETED,
  5: EmployeeActionItemState.NOT_COMPLETED
};


export interface EmployeeActionItemStateInfo {
  id: string;
  description: string;
}


export function getActionItemStates() {
  const actionItemsStateArray: EmployeeActionItemStateInfo[] = Object.values(EmployeeActionItemState)
      .filter(value => typeof value === "number")
      .map((enumValue) => {
          var followUpItemsStatus: EmployeeActionItemStateInfo = {
              id: "" + enumValue,
              description: translate(EmployeeActionItemStateTranslationTerms[enumValue]) || "-unknown-"
          };
          return followUpItemsStatus;
      });

  return actionItemsStateArray;
}


