import { ConfigurationProvider } from "../../../lib/infrastructure/configuration/ConfigurationProvider";
import { HttpClient } from "../../../lib/infrastructure/http/HttpClient";
import { PositionCreateDTO } from "../models/dtos/PositionCreateDTO";
import { PositionDetailsDTO } from "../models/dtos/PositionDetailsDTO";
import { PositionUpdateDTO } from "../models/dtos/PositionUpdadeDTO";
import { PositionUpdateStateDTO } from "../models/dtos/PositionUpdateStateDTO";
import { PositionsResponseDTO } from "../models/dtos/PositionsResponseDTO";

const Route = (path: string) => {
    return ConfigurationProvider.getConfiguration().App.BackendUrl + path;
};

export class JobPositionsService {

    getJobPositions(locationId: string, businessLineId: string, roleId: string): Promise<PositionsResponseDTO | null> {
 
        return HttpClient.sessionRequest<PositionsResponseDTO>({
            method: "GET",
            url: Route(`/api/v1/Locations/${locationId}/BusinessLines/${businessLineId}/Roles/${roleId}/RolePositions`),
        })
            .then((response) => {

                if (!response.data) return null;

                return (response.data)
            })
            .catch((error) => {
                throw error;
            });
    }

    getJobPositionByID(locationId: string, businessLineId: string, roleId: string, jobPositionId: string): Promise<PositionDetailsDTO | null> {
         return HttpClient.sessionRequest<PositionDetailsDTO>({
            method: "GET",
            url: Route(`/api/v1/Locations/${locationId}/BusinessLines/${businessLineId}/Roles/${roleId}/RolePositions/${jobPositionId}`),
        })
            .then((response) => {
                if (!response.data) return null;
                return response.data;
            })
            .catch((error) => {
                throw error;
            });
    }

    addJobPosition(locationId: string, businessLineId: string, roleId: string, requestDto: PositionCreateDTO): Promise<void> {
        return HttpClient.sessionRequest({
            method: "POST",
            url: Route(`/api/v1/Locations/${locationId}/BusinessLines/${businessLineId}/Roles/${roleId}/RolePositions`),
            data: requestDto,
        })
        .then((_) => { })
        .catch((error) => {
            throw error;
        });
    }

    editJobPosition(locationID: string, businessLineId: string, roleId: string, jobPositionId: number, requestDto: PositionUpdateDTO): Promise<void> {
        return HttpClient.sessionRequest({
            method: "PUT",
            url: Route(`/api/v1/Locations/${locationID}/BusinessLines/${businessLineId}/Roles/${roleId}/RolePositions/${jobPositionId}`),
            data: requestDto
        })
            .then((_) => { })
            .catch((error) => {
                throw error;
            });
    }

    updateJobPositionState(locationId: string, businessLineId: string, roleId: string, jobPositionId: number,requestDto: PositionUpdateStateDTO): Promise<string> {
        return HttpClient.sessionRequest<string>({
            method: "PUT",
            url: Route(`/api/v1/Locations/${locationId}/BusinessLines/${businessLineId}/Roles/${roleId}/RolePositions/${jobPositionId}/State`),
            data: requestDto
        })
            .then((response) => {
                return response.status?.toString()
            })
            .catch((error) => {
                throw error;
            });
    }
}
