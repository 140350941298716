import "./ChecklistEmployeeEvaluationCard.css";

import { Checkbox } from "../../../../../lib/components/checkbox/Checkbox";
import { ColumnDefinition } from "../../../../../lib/components/table/TableInterfaces";
import { EvaluationChecklistDTO } from "../../../../evaluations/models/EvaluationChecklistDTO";
import { ResponsiveDataTable } from "../../../../../lib/components/table/ResponsiveDataTable";
import { TitledCardContainer } from "../../../../../lib/layouts/containers/card/TitledCardContainer";
import { translate } from "../../../../../lib/infrastructure/i18n/InternationalizationService";
import { useMemo } from "react";

interface IChecklistEmployeeEvaluationCardProps {
    items: EvaluationChecklistDTO[];
    isLoading: boolean;
}

export function ChecklistEmployeeEvaluationCard(props: IChecklistEmployeeEvaluationCardProps) {


    /****************************
     * DATA MANIPULATION EFFECTS
     *****************************/

    /****************************
     * USER ACTIONS
     *****************************/

    /****************************
     * CSS & HTML
     *****************************/
    const checklistColumns: ColumnDefinition<EvaluationChecklistDTO>[] = useMemo(() => [
        {
            cellRenderProp: (item) => item.checklistItemDescription,
            headerRender: translate("BUSINESSLINES.MATRIX.INFOANDFORM.Description"),
            isMobilePrimaryCell: true,
        },
        {
            cellRenderProp: (item) => (
                <div key={item.id}>
                    <Checkbox
                        text={""}
                        isChecked={item.isChecked}
                    />
                </div>
            ),
            columnKey: "delete",
            width: "24px",
            isMobileHeaderIcon: true,
        },
    ], []);


    return <TitledCardContainer className="checklist-evaluation-card" title={translate("BUSINESSLINES.EVALUATIONS.INFOANDFORM.Checklist")}>
        <ResponsiveDataTable
            columnDefinitions={checklistColumns}
            items={props.items}
            totalitems={props.items.length}
            isLoading={props.isLoading}
        />
    </TitledCardContainer>;
}