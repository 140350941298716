import { ConfigurationProvider } from "../../../lib/infrastructure/configuration/ConfigurationProvider";
import { EmployeeActionItemsResponseDTO } from "../models/dtos/EmployeeActionItemsResponseDTO";
import { EmployeeEvaluationAbsencesDTO } from "../models/dtos/EmployeeEvaluationAbsencesDTO";
import { EmployeeEvaluationBonusResponseDTO } from "../models/dtos/EmployeeEvaluationBonusResponseDTO";
import { EmployeeEvaluationCommentsResponseDTO } from "../models/dtos/EmployeeEvaluationCommentsResponseDTO";
import { EmployeeEvaluationDetailsDTO } from "../models/dtos/EmployeeEvaluationDetailsDTO";
import { EmployeeEvaluationHistoryResponseDTO } from "../models/dtos/EmployeeEvaluationHistoryResponseDTO";
import { EmployeeEvaluationMatrixResponseDTO } from "../models/dtos/EmployeeEvaluationMatrixResponseDTO";
import { EmployeeEvaluationPeriodsResponseDTO } from "../models/dtos/EmployeeEvaluationPeriodsResponseDTO";
import { EmployeeEvaluationsResponseDTO } from "../models/dtos/EmployeeEvaluationsResponseDTO";
import { HttpClient } from "../../../lib/infrastructure/http/HttpClient";
import { QueryOptions } from "../../../models/api/QueryOptions";
import qs from "qs";
import { EmployeeBonusResponseDTO } from "../models/dtos/EmployeeBonusReponseDTO";

const employeeEvaluationPeriods: EmployeeEvaluationPeriodsResponseDTO = {
    items: [
        {
            id: 10,
            name: "2023",
            employeeId: "1",
            employeeRole: "Operador Logística",
            evaluationTotal: 10,
        }
    ],
    totalItems: 1,
};

const Route = (path: string) => {
    return ConfigurationProvider.getConfiguration().App.BackendUrl + path;
};

export class EmployeeEvaluationsService {

    //TODO: this needs to be removed
    getEmployeeEvaluationPeriods(locationId: string, employeeId: string, query: QueryOptions, abortSignal: AbortSignal | undefined): Promise<EmployeeEvaluationPeriodsResponseDTO | null> {
        return new Promise((resolve) => {
            setTimeout(() => {
                resolve({
                    items: employeeEvaluationPeriods.items,
                    totalItems: employeeEvaluationPeriods.totalItems
                });
            }, 1000);
        });
    }

    getEmployeeEvaluations(locationId: string, employeeId: string, query: QueryOptions, abortSignal: AbortSignal | undefined): Promise<EmployeeEvaluationsResponseDTO | null> {
        return HttpClient.sessionRequest<EmployeeEvaluationsResponseDTO>({
            method: "GET",
            url: Route(`/api/v1/Locations/${locationId}/Employees/${employeeId}/Evaluations`),
            signal: abortSignal,
            params: query,
            paramsSerializer: (params) => {
                return qs.stringify(params, { arrayFormat: "repeat" });
            },
        })
            .then((response) => {
                if (!response.data) return null;
                return {
                    evaluations: response.data.evaluations,
                    totalItems: response.data.totalItems
                }
            })
            .catch((error) => {
                throw error;
            });
    }

    getEmployeeEvaluationDetailsById(locationId: string, employeeId: string, evaluationId: string): Promise<EmployeeEvaluationDetailsDTO> {

        return HttpClient.sessionRequest<EmployeeEvaluationDetailsDTO>({
            method: "GET",
            url: Route(`/api/v1/Locations/${locationId}/Employees/${employeeId}/Evaluations/${evaluationId}`),
        })
            .then((response) => {
                return response.data
            })
            .catch((error) => {
                throw error;
            });
    }

    getEmployeeEvaluationMatrix(locationId: string, employeeId: string, evaluationId: string): Promise<EmployeeEvaluationMatrixResponseDTO> {

        return HttpClient.sessionRequest<EmployeeEvaluationMatrixResponseDTO>({
            method: "GET",
            url: Route(`/api/v1/Locations/${locationId}/Employees/${employeeId}/Evaluations/${evaluationId}/Matrix`),
        })
            .then((response) => {
                return response.data
            })
            .catch((error) => {
                throw error;
            });
    }

    //TODO
    getEmployeeEvaluationBonus(locationId: string, employeeId: string, evaluationId: string): Promise<EmployeeEvaluationBonusResponseDTO> {
        return new Promise((resolve) => {
            setTimeout(() => {
                resolve({
                    items: [
                        {
                            id: 1,
                            periodName: "1º Trimestre 2023",
                            evaluationValue: 4
                        },
                        {
                            id: 2,
                            periodName: "2º Trimestre 2023",
                            evaluationValue: 4
                        },
                    ],
                    totalItems: 2
                });
            }, 1000);
        });
    }

    getEmployeeUnjustifiedAbsences(locationId: string, employeeId: string, evaluationId: string): Promise<EmployeeEvaluationAbsencesDTO> {
        return HttpClient.sessionRequest({
            method: "GET",
            url: Route(`/api/v1/Locations/${locationId}/Employees/${employeeId}/Evaluations/${evaluationId}/Absenteeism`),
        })
            .then((response) => {
                return response.data
            })
            .catch((error) => {
                throw error;
            });
    }

    getEmployeeEvaluationHistory(locationId: string, employeeId: string, evaluationId: string): Promise<EmployeeEvaluationHistoryResponseDTO> {
        return HttpClient.sessionRequest({
            method: "GET",
            url: Route(`/api/v1/Locations/${locationId}/Employees/${employeeId}/Evaluations/${evaluationId}/History`),
        })
            .then((response) => {
                return response.data
            })
            .catch((error) => {
                throw error;
            });
    }

    getEmployeeEvaluationActionItems(locationId: string, employeeId: string, evaluationId: string): Promise<EmployeeActionItemsResponseDTO> {
        return HttpClient.sessionRequest({
            method: "GET",
            url: Route(`/api/v1/Locations/${locationId}/Employees/${employeeId}/Evaluations/${evaluationId}/ActionItems`),
        })
            .then((response) => {
                return response.data
            })
            .catch((error) => {
                throw error;
            });
    }

    getEmployeeEvaluationComments(locationId: string, employeeId: string, evaluationId: string): Promise<EmployeeEvaluationCommentsResponseDTO> {
        return HttpClient.sessionRequest({
            method: "GET",
            url: Route(`/api/v1/Locations/${locationId}/Employees/${employeeId}/Evaluations/${evaluationId}/Comments`),
        })
            .then((response) => {
                return response.data
            })
            .catch((error) => {
                throw error;
            });
    }


    getEvaluationBonus(locationId: string, employeeId: string, evaluationId: string): Promise<EmployeeBonusResponseDTO> {
        return HttpClient.sessionRequest<EmployeeBonusResponseDTO>({
            method: "GET",
            url: Route(`/api/v1/Locations/${locationId}/Employees/${employeeId}/Evaluations/${evaluationId}/Bonus`),
        })
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                throw error;
            });
    }
}