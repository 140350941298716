import { useCallback, useEffect, useState } from "react";
import { useClosePopup, useCreateNotification, usePopup } from "../../../../../lib/infrastructure/ui/UIServices";

import { Checkbox } from "../../../../../lib/components/checkbox/Checkbox";
import { ConditionalRender } from "../../../../../lib/functional/ConditionalRender";
import { DateTime } from "luxon";
import { EmployeePositionRecordUpdateDTO } from "../../../models/dtos/EmployeePositionRecordUpdateDTO";
import { EmployeePositionRecordsService } from "../../../services/EmployeePositionRecordsService";
import { ErrorPopup } from "../../../../../lib/components/popup/ErrorPopup";
import { ExceptionType } from "../../../../../lib/infrastructure/http/ExceptionType";
import { FormFieldDatePicker } from "../../../../../lib/components/form/form-field/FormFieldDatePicker";
import { FormFieldTextInput } from "../../../../../lib/components/form/form-field/FormFieldTextInput";
import { FormSection } from "../../../../../lib/components/form/section/FormSection";
import { FullScreenLoader } from "../../../../../lib/components/loader/FullScreenLoader";
import { MessageBar } from "../../../../../lib/components/message-bar/MessageBar";
import { Notification } from "../../../../../lib/components/notifications/Notification";
import { PopupActionButtons } from "../../../../../lib/layouts/containers/popup-buttons/PopupActionButtons";
import { PopupContainer } from "../../../../../lib/layouts/containers/popup-container/PopupContainer";
import { PopupContent } from "../../../../../lib/layouts/containers/popup-content/PopupContent";
import { PopupHeader } from "../../../../../lib/layouts/containers/popup-header/PopupHeader";
import { Spacer } from "../../../../../lib/components/separator/Spacer";
import { ValidationErrorMessage } from "../../../../../lib/infrastructure/http/APIError";
import { translate } from "../../../../../lib/infrastructure/i18n/InternationalizationService";
import { useFormControl } from "../../../../../lib/components/form/Form";
import { useServiceCallPro } from "../../../../../lib/hooks/useServiceCall";
import { validateDateTime } from "../../../../../lib/validators/ValidateDateTime";

interface IEditEmployeePositionRecordPopupProps {
    employeeId: string;
    locationId: string;
    positionRecordId: number;
    onCompletedOperations: () => void;
}


var svc = new EmployeePositionRecordsService();

export function EditEmployeePositionRecordPopup(props: IEditEmployeePositionRecordPopupProps) {
    const closePopup = useClosePopup();
    const openPopup = usePopup();
    const createNotification = useCreateNotification();

    const editEmployeePositionRecordCall = useServiceCallPro(svc.editEmployeePositionRecord);
    const getEmployeePositionRecordByIdCall = useServiceCallPro(svc.getEmployeePositionRecordById);

    const [validationAlert, setValidationAlert] = useState<ValidationErrorMessage>({ showAlert: false });

    const roleNameForm = useFormControl<string>({
        isDisabled: true
    });

    const jobGradeForm = useFormControl<string>({
        isDisabled: true
    });

    const startDateForm = useFormControl<DateTime>({
        validators: [validateDateTime()],
        enableAutoValidate: false
    });

    const endDateForm = useFormControl<DateTime>({
        enableAutoValidate: false
    });

    const [isDeprecatedRoleSelected, setIsDeprecatedRoleSelected] = useState<boolean>(false);



    /****************************
    * DATA REQUESTS
    *****************************/


    const getEmployeePositionRecordById = useCallback(() => {

        if (!props.employeeId || !props.positionRecordId) return;

        getEmployeePositionRecordByIdCall.invoke(props.locationId, props.employeeId, "" + props.positionRecordId)
            .then((data) => {
                if (!data) return;

                if (data.roleId) {
                    setIsDeprecatedRoleSelected(false);
                } else {
                    setIsDeprecatedRoleSelected(true);
                }
                if (data.startDate) {
                    startDateForm.setValue(DateTime.fromISO(data.startDate));
                }
                if (data.endDate) {
                    endDateForm.setValue(DateTime.fromISO(data.endDate));
                }

                roleNameForm.setValue(data?.roleName ?? "-");
                if(data.jobGrade)
                jobGradeForm.setValue(data?.jobGrade ?? "N/A")

            })
            .catch((error) => {
                if (!error) return;
                openPopup(<ErrorPopup>{error.response.data.message}</ErrorPopup>);
            });
    }, [props.employeeId, props.positionRecordId, props.locationId, getEmployeePositionRecordByIdCall, roleNameForm, jobGradeForm, startDateForm, endDateForm, openPopup]);


    /****************************
    * DATA MANIPULATION EFFECTS
    *****************************/

    useEffect(() => {
        getEmployeePositionRecordById();
    }, [props.employeeId, props.positionRecordId])



    useEffect(() => {

        if (validationAlert.showAlert) {
            setValidationAlert({
                showAlert: false,
                message: undefined,
            });
        }

    }, [startDateForm.value, endDateForm.value])




    /****************************
    * USER ACTIONS
    *****************************/

    const handleEditRoleBtnClicked = useCallback(() => {

        if (!props.employeeId || !props.positionRecordId) return;

        if (!endDateForm.validate()) return;

        let request: EmployeePositionRecordUpdateDTO = {
            endDate: endDateForm.value?.toISO() || null,
            startDate: startDateForm.value?.toISO() || ""

        }


        const handleSuccess = () => {
            closePopup();
            props.onCompletedOperations();
            createNotification(
                <Notification
                    type="success"
                    title={translate("COMMON.SYSTEMPOPUPS.Success")}
                    text={translate("BUSINESSLINES.roles.POPUP.EditRoleSuccess")}
                />
            );
        };


        const handleError = (error: any) => {
            if (!error) return;

            const errorMessage = error?.response?.data?.message || translate("ERRORS.UnknownError");
            const enumCode = error?.response?.data?.enumCode;

            if (enumCode === ExceptionType.ERROR_POSITION_RECORD_DATETIME_INTERVAL) {
                setValidationAlert({
                    showAlert: true,
                    message: errorMessage,
                });
            } else {
                openPopup(<ErrorPopup>{errorMessage}</ErrorPopup>);
            }
        };


        editEmployeePositionRecordCall
            .invoke(props.locationId, props.employeeId, "" + props.positionRecordId, request)
            .then(handleSuccess)
            .catch(handleError);

    }, [props, endDateForm, startDateForm.value, editEmployeePositionRecordCall, closePopup, createNotification, openPopup]);




    return <PopupContainer className="popup-add-employee-career-ladder-role">
        <PopupHeader title={translate("EMPLOYEES.CAREER.POPUPS.AddRole")} />
        {editEmployeePositionRecordCall.isLoading && <FullScreenLoader />}
        <PopupContent isLoading={getEmployeePositionRecordByIdCall.isLoading}>
            <FormSection isInputGap childrenFlexDirColumn>

                <Checkbox
                    isChecked={isDeprecatedRoleSelected}
                    text={translate("BUSINESSLINES.CAREER.INFOANDFORM.DeprecatedFunction")}
                    isDisabled
                />
                <FormFieldTextInput
                    formControl={roleNameForm}
                    label={translate("BUSINESSLINES.CAREER.INFOANDFORM.RoleName")}
                    placeholder={translate("BUSINESSLINES.CAREER.INFOANDFORM.RoleNamePlaceholder")}
                />
                <FormFieldTextInput
                    formControl={jobGradeForm}
                    label={translate("BUSINESSLINES.CAREER.INFOANDFORM.JobGrade") + " (" + translate("COMMON.Optional") + ")"}
                    placeholder={translate("BUSINESSLINES.CAREER.INFOANDFORM.JobGradePlaceholder")}
                />

                <FormFieldDatePicker
                    formControl={startDateForm}
                    label={translate("EMPLOYEES.CAREER.INFOANDFORM.StartDate")}
                    placeholder={translate("EMPLOYEES.CAREER.INFOANDFORM.StartDatePlaceholder")}
                    maxDate={endDateForm.value}
                />
                <FormFieldDatePicker
                    formControl={endDateForm}
                    label={translate("EMPLOYEES.CAREER.INFOANDFORM.EndDate") + " (" + translate("COMMON.Optional") + ")"}
                    placeholder={translate("EMPLOYEES.CAREER.INFOANDFORM.EndDatePlaceholder")}
                    minDate={startDateForm.value}
                />
            </FormSection>

            <ConditionalRender if={validationAlert.showAlert}>
                <Spacer mode="vertical" px="10" />
                <MessageBar type="error" text={"" + validationAlert.message} />
            </ConditionalRender>

            <Spacer mode={"vertical"} px="30" />

            <PopupActionButtons
                buttons={[
                    {
                        text: translate("COMMON.Cancel"),
                        type: "tertiary",
                        onClick: () => closePopup(),
                    },
                    {
                        text: translate("COMMON.Edit"),
                        type: "primary",
                        onClick: handleEditRoleBtnClicked,
                    },
                ]}
            />
        </PopupContent>
    </PopupContainer>;
}