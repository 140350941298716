import "./EditEvaluationPeriodPopup.css";

import { useCallback, useEffect } from "react";
import { useClosePopup, useCreateNotification, usePopup } from "../../../../lib/infrastructure/ui/UIServices";

import { AllValid } from "../../../../common/validators/ValidateFormControls";
import { DateTime } from "luxon";
import { ErrorPopup } from "../../../../lib/components/popup/ErrorPopup";
import { EvaluationPeriodUpdateDTO } from "../../models/dtos/EvaluationPeriodUpdateDTO";
import { EvaluationPeriodsService } from "../../services/EvaluationPeriodsService";
import { FormContainer } from "../../../../lib/layouts/containers/form/FormContainer";
import { FormFieldDatePicker } from "../../../../lib/components/form/form-field/FormFieldDatePicker";
import { FormFieldTextInput } from "../../../../lib/components/form/form-field/FormFieldTextInput";
import { FormSection } from "../../../../lib/components/form/section/FormSection";
import { FullScreenLoader } from "../../../../lib/components/loader/FullScreenLoader";
import { Notification } from "../../../../lib/components/notifications/Notification";
import { PopupActionButtons } from "../../../../lib/layouts/containers/popup-buttons/PopupActionButtons";
import { PopupContainer } from "../../../../lib/layouts/containers/popup-container/PopupContainer";
import { PopupContent } from "../../../../lib/layouts/containers/popup-content/PopupContent";
import { PopupHeader } from "../../../../lib/layouts/containers/popup-header/PopupHeader";
import { Spacer } from "../../../../lib/components/separator/Spacer";
import { translate } from "../../../../lib/infrastructure/i18n/InternationalizationService";
import { trimString } from "../../../../lib/utils/TrimString";
import { useFormControl } from "../../../../lib/components/form/Form";
import { useServiceCallPro } from "../../../../lib/hooks/useServiceCall";
import { validateDateTime } from "../../../../lib/validators/ValidateDateTime";
import { validateExternalDateTimeControl } from "../../../../common/validators/ValidateDateTimeFormControls";
import { validateWhiteSpaceStrings } from "../../../../lib/validators/ValidateWhiteSpaceStrings";

var evaluationPeriodsSvc = new EvaluationPeriodsService();

export interface EditEvaluationPeriodProps {
    locationID: string;
    businessLineId: string;
    periodID: string;
    onCompletedOperations: () => void;
}

export function EditEvaluationPeriodPopup(props: EditEvaluationPeriodProps) {
    const closePopup = useClosePopup();
    const openPopup = usePopup();
    const createNotification = useCreateNotification();

    const nameFormControl = useFormControl<string>({
        validators: [validateWhiteSpaceStrings()],
        enableAutoValidate: true,
    });

    const initialDateFormControl = useFormControl<DateTime>({
        validators: [validateDateTime()],
        enableAutoValidate: false
    });

    const finalDateFormControl = useFormControl<DateTime>({
        validators: [validateDateTime()],
        enableAutoValidate: false
    });

    const evaluationsStartDayControl = useFormControl<DateTime>({
        validators: [],
        enableAutoValidate: false
    });

    const evaluationsFinalDayControl = useFormControl<DateTime>({
        validators: [validateExternalDateTimeControl(evaluationsStartDayControl)],
        enableAutoValidate: false,
        isDisabled: true
    });

    const calibrationsStartDayControl = useFormControl<DateTime>({
        validators: [],
        enableAutoValidate: false
    });

    const calibrationsFinalDayControl = useFormControl<DateTime>({
        validators: [validateExternalDateTimeControl(calibrationsStartDayControl)],
        enableAutoValidate: false,
        isDisabled: true
    });


    const getPeriodSummaryInfoCall = useServiceCallPro(evaluationPeriodsSvc.getEvaluationPeriodByID);
    const editPeriodInfoCall = useServiceCallPro(evaluationPeriodsSvc.editEvaluationPeriod);



    /****************************
    * DATA REQUESTS
    *****************************/

    const getPeriodSummaryInfo = useCallback(() => {

        if (!props.locationID || !props.periodID) return;

        getPeriodSummaryInfoCall.invoke(`${props.locationID}`, props.businessLineId, props.periodID)
            .then((data) => {
                if (!data) return;

                if (data.startDate && DateTime.fromISO(data.startDate))
                    initialDateFormControl.setValue(DateTime.fromISO(data.startDate));

                if (data.endDate && DateTime.fromISO(data.endDate))
                    finalDateFormControl.setValue(DateTime.fromISO(data.endDate));

                nameFormControl.setValue(data.name);

                if (data.startEvaluationsDate && DateTime.fromISO(data.startEvaluationsDate))
                    evaluationsStartDayControl.setValue(DateTime.fromISO(data.startEvaluationsDate));

                if (data.finalEvaluationsDate && DateTime.fromISO(data.finalEvaluationsDate))
                    evaluationsFinalDayControl.setValue(DateTime.fromISO(data.finalEvaluationsDate));

                if (data.startCalibrationsDate && DateTime.fromISO(data.startCalibrationsDate))
                    calibrationsStartDayControl.setValue(DateTime.fromISO(data.startCalibrationsDate));

                if (data.finalCalibrationsDate && DateTime.fromISO(data.finalCalibrationsDate))
                    calibrationsFinalDayControl.setValue(DateTime.fromISO(data.finalCalibrationsDate));
            })
            .catch((error) => {
                if (!error) return;
                openPopup(<ErrorPopup>{error.response.data.message}</ErrorPopup>);
            });
    }, [calibrationsFinalDayControl, calibrationsStartDayControl, evaluationsFinalDayControl, evaluationsStartDayControl, finalDateFormControl, getPeriodSummaryInfoCall.invoke, initialDateFormControl, nameFormControl, openPopup, props.businessLineId, props.locationID, props.periodID]);


    const editPeriodInfo = useCallback(() => {
        if (!props.locationID || !props.periodID) return;
        if (!AllValid(nameFormControl.validate(), calibrationsFinalDayControl.validate(), evaluationsFinalDayControl.validate())) return;

        var requestDto: EvaluationPeriodUpdateDTO = {
            startDate: initialDateFormControl.value?.toISO() || undefined,
            endDate: finalDateFormControl.value?.toISO() || undefined,
            name: trimString(nameFormControl.value) || "",

            evaluationsStartDay: evaluationsStartDayControl.value && evaluationsFinalDayControl.value ? evaluationsStartDayControl.value?.toISO() : undefined,
            evaluationsFinalDay: evaluationsStartDayControl.value && evaluationsFinalDayControl.value ? evaluationsFinalDayControl.value?.toISO() : undefined,

            calibrationsStartDay: calibrationsStartDayControl.value && calibrationsFinalDayControl.value ? calibrationsStartDayControl.value?.toISO() : undefined,
            calibrationsFinalDay: calibrationsStartDayControl.value && calibrationsFinalDayControl.value ? calibrationsFinalDayControl.value?.toISO() : undefined,
        };

        editPeriodInfoCall.invoke(`${props.locationID}`, props.businessLineId, props.periodID, requestDto)
            .then((_) => {
                props.onCompletedOperations();
                createNotification(
                    <Notification
                        type="success"
                        title={translate("COMMON.SYSTEMPOPUPS.Success")}
                        text={translate("BUSINESSLINES.EVALUATIONPERIODS.MESSAGES.EditPeriodSuccess")}
                    />
                );
                closePopup();
            })
            .catch((error) => {
                if (!error) return;
                console.error("Error:", error);
                openPopup(<ErrorPopup>{error.response.data.message}</ErrorPopup>);
            })

    }, [calibrationsFinalDayControl, calibrationsStartDayControl.value, closePopup, createNotification, editPeriodInfoCall.invoke, evaluationsFinalDayControl, evaluationsStartDayControl.value, finalDateFormControl.value, initialDateFormControl.value, nameFormControl, openPopup, props]);


    /****************************
     * DATA MANIPULATION EFFECTS
     *****************************/

    useEffect(() => {
        getPeriodSummaryInfo();
    }, [props.locationID, props.businessLineId, props.periodID]);


    useEffect(() => {
        if (evaluationsStartDayControl.value) {
            evaluationsFinalDayControl.setIsDisabled(false);
        } else {
            evaluationsFinalDayControl.setIsDisabled(true);
            evaluationsFinalDayControl.setValue(undefined);
        }

        if (evaluationsFinalDayControl.value) {
            calibrationsStartDayControl.setIsDisabled(false);
        } else {
            calibrationsStartDayControl.setIsDisabled(true);
            calibrationsStartDayControl.setValue(undefined);
        }

        if (calibrationsStartDayControl.value) {
            calibrationsFinalDayControl.setIsDisabled(false);
        } else {
            calibrationsFinalDayControl.setIsDisabled(true);
            calibrationsFinalDayControl.setValue(undefined);
        }
    }, [evaluationsStartDayControl.value, evaluationsFinalDayControl.value, calibrationsStartDayControl.value]);


    /****************************
     * USER ACTIONS
     *****************************/

    const handleEditPeriodButtonClicked = useCallback(() => {

        editPeriodInfo();

    }, [editPeriodInfo]);


    /****************************
     * CSS & HTML
     *****************************/


    return (
        <PopupContainer className="popup-edit-evaluation-period">
            {editPeriodInfoCall.isLoading ? <FullScreenLoader /> : null}

            <PopupHeader title={translate("BUSINESSLINES.EVALUATIONPERIODS.EditEvaluationPeriod")} />

            <PopupContent isLoading={getPeriodSummaryInfoCall.isLoading}>

                <FormContainer>
                    <FormSection isInputGap>
                        <FormFieldTextInput
                            formControl={nameFormControl}
                            label={translate("BUSINESSLINES.EVALUATIONPERIODS.INFOANDFORM.Name")}
                            placeholder={translate("BUSINESSLINES.EVALUATIONPERIODS.INFOANDFORM.NamePlaceholder")}
                        />
                    </FormSection>

                    <FormSection isInputGap>
                        <FormFieldDatePicker formControl={initialDateFormControl}
                            placeholder={translate("BUSINESSLINES.EVALUATIONPERIODS.INFOANDFORM.StartDatePlaceholder")}
                            label={translate("BUSINESSLINES.EVALUATIONPERIODS.INFOANDFORM.StartDate")} maxDate={finalDateFormControl.value} />

                        <FormFieldDatePicker formControl={finalDateFormControl}
                            placeholder={translate("BUSINESSLINES.EVALUATIONPERIODS.INFOANDFORM.EndDatePlaceholder")}
                            label={translate("BUSINESSLINES.EVALUATIONPERIODS.INFOANDFORM.EndDate")} minDate={initialDateFormControl.value} />
                    </FormSection>

                    <Spacer mode="vertical" px="5" />

                    <div className="separator">{translate("BUSINESSLINES.EVALUATIONPERIODS.INFOANDFORM.DatesToNotificateManagersByEmail")}</div>

                    <FormSection isInputGap>
                        <FormFieldDatePicker
                            formControl={evaluationsStartDayControl}
                            placeholder={translate("COMMON.POPUPS.SelectDate")}
                            label={translate("BUSINESSLINES.EVALUATIONPERIODS.INFOANDFORM.InitialDayToEvaluate")}
                            maxDate={evaluationsFinalDayControl.value ?? calibrationsStartDayControl.value ?? calibrationsFinalDayControl.value} />
                        <FormFieldDatePicker
                            formControl={evaluationsFinalDayControl}
                            placeholder={translate("COMMON.POPUPS.SelectDate")}
                            label={translate("BUSINESSLINES.EVALUATIONPERIODS.INFOANDFORM.FinalDayToEvaluate")}
                            minDate={evaluationsStartDayControl.value}
                            maxDate={calibrationsStartDayControl.value ?? calibrationsFinalDayControl.value}
                        />
                    </FormSection>

                    <FormSection isInputGap>
                        <FormFieldDatePicker
                            formControl={calibrationsStartDayControl}
                            placeholder={translate("COMMON.POPUPS.SelectDate")}
                            label={translate("BUSINESSLINES.EVALUATIONPERIODS.INFOANDFORM.InitialDayToCalibrate")}
                            minDate={evaluationsFinalDayControl.value ?? evaluationsStartDayControl.value}
                            maxDate={calibrationsFinalDayControl.value}
                        />
                        <FormFieldDatePicker
                            formControl={calibrationsFinalDayControl}
                            placeholder={translate("COMMON.POPUPS.SelectDate")}
                            label={translate("BUSINESSLINES.EVALUATIONPERIODS.INFOANDFORM.FinalDayToCalibrate")}
                            minDate={calibrationsStartDayControl.value}
                        />
                    </FormSection>

                </FormContainer>
                <Spacer mode={"vertical"} px="30"></Spacer>
                <PopupActionButtons
                    buttons={[
                        {
                            text: translate("COMMON.Cancel"),
                            type: "tertiary",
                            onClick: () => closePopup(),
                        },
                        {
                            text: translate("COMMON.Edit"),
                            type: "primary",
                            onClick: handleEditPeriodButtonClicked,
                        },
                    ]}
                />
            </PopupContent>
        </PopupContainer>
    );
}