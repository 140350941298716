
export function getEvaluationPeriodStateCss(statusId: number) {
    switch (statusId) {
        case 1:
            return "status-light-grey";
        case 2:
            return "status-red";
        case 3:
            return "status-yellow";
        case 4:      
            return "status-green";
        default:
            return undefined;
    }
}









