import "./ChecklistEvaluationCard.css";

import { useCallback, useEffect, useMemo, useState } from "react";

import { Checkbox } from "../../../../lib/components/checkbox/Checkbox";
import { ColumnDefinition } from "../../../../lib/components/table/TableInterfaces";
import { EvaluationChecklistDTO } from "../../models/EvaluationChecklistDTO";
import { ResponsiveDataTable } from "../../../../lib/components/table/ResponsiveDataTable";
import { TitledCardContainer } from "../../../../lib/layouts/containers/card/TitledCardContainer";
import { translate } from "../../../../lib/infrastructure/i18n/InternationalizationService";

interface IChecklistEvaluationCardProps {
    isEditionModeEnabled: boolean;
    items: EvaluationChecklistDTO[];
    isLoading: boolean;
    onChange: (checklist: EvaluationChecklistDTO[]) => void;
}

export function ChecklistEvaluationCard(props: IChecklistEvaluationCardProps) {


    /****************************
     * DATA MANIPULATION EFFECTS
     *****************************/


    /****************************
     * USER ACTIONS
     *****************************/

    const handleCheckboxClicked = useCallback((isChecked: boolean, id: number) => {
        props.onChange(props.items.map((item) => item.id === id ? { ...item, isChecked } : item));
    }, [props.onChange, props.items]);






    /****************************
     * CSS & HTML
     *****************************/
    const checklistColumns: ColumnDefinition<EvaluationChecklistDTO>[] = useMemo(() => [
        {
            cellRenderProp: (item) => item.checklistItemDescription,
            headerRender: translate("BUSINESSLINES.MATRIX.INFOANDFORM.Description"),
            isMobilePrimaryCell: true,
        },
        {
            cellRenderProp: (item) => (
                <div key={item.id}>
                    <Checkbox
                        text={""}
                        isChecked={item.isChecked}
                        onClick={(isChecked) => handleCheckboxClicked(isChecked, item.id)}
                        isDisabled={!props.isEditionModeEnabled}
                    />
                </div>
            ),
            columnKey: "delete",
            width: "24px",
            isMobileHeaderIcon: true,
        },
    ], [handleCheckboxClicked, props.isEditionModeEnabled]);


    return <TitledCardContainer className="checklist-evaluation-card" title={translate("BUSINESSLINES.EVALUATIONS.INFOANDFORM.Checklist")}>
        <ResponsiveDataTable
            columnDefinitions={checklistColumns}
            items={props.items}
            totalitems={props.items.length}
            isLoading={props.isLoading}
        />
    </TitledCardContainer>;
}