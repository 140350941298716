import "./EmployeeBonusDetailsPage.css";

import { useCallback, useEffect, useMemo, useState } from "react";

import { CardContainer } from "../../../../lib/layouts/containers/card/CardContainer";
import { ColumnDefinition } from "../../../../lib/components/table/TableInterfaces";
import { DateTimeHelper } from "../../../../lib/helpers/datetime/DateTime";
import { EmployeeBonusDetailsDTO } from "../../models/dtos/EmployeeBonusDetailsDTO";
import { EmployeeBonusService } from "../../services/EmployeeBonusService";
import { ErrorPopup } from "../../../../lib/components/popup/ErrorPopup";
import { FormSection } from "../../../../lib/components/form/section/FormSection";
import { InfoBlock } from "../../../../lib/components/info-block/InfoBlock";
import { PageLayout } from "../../../../lib/layouts/main-content/PageLayout";
import { ResponsiveDataTable } from "../../../../lib/components/table/ResponsiveDataTable";
import { Spacer } from "../../../../lib/components/separator/Spacer";
import { StatusCard } from "../../../../lib/components/cards/stats-card/StatusCard";
import { finalBonusEvaluationPerformance } from "../../../../common/helpers/finalBonusEvaluationPerformance";
import { finalBonusScoreFromPercentage } from "../../../../common/helpers/finalBonusScoreFromPercentage";
import { translate } from "../../../../lib/infrastructure/i18n/InternationalizationService";
import { useGlobalLocation } from "../../../../lib/infrastructure/location/LocationServices";
import { useParams } from "react-router-dom";
import { usePopup } from "../../../../lib/infrastructure/ui/UIServices";
import { useServiceCallPro } from "../../../../lib/hooks/useServiceCall";
import { useUpdatePageData } from "../../../../lib/infrastructure/navigation/hooks/useUpdatePageData";

var svc = new EmployeeBonusService();

interface IEmployeeBonusDetailsPageProps { }

export interface CompanyCriteria {
    name: string;
    achievementPercentage: number;
}

export interface EmployeeCriteria {
    factorName: string;
    evaluationValue: number | undefined;
}

export function EmployeeBonusDetailsPage(props: IEmployeeBonusDetailsPageProps) {
    const updateRouteDate = useUpdatePageData();
    const openPopup = usePopup();
    const locationID = useGlobalLocation();
    const locationIDAsString = useMemo(() => "" + locationID, [locationID])
    const { employeeId, periodId } = useParams();

    const [bonusDetails, setBonusDetails] = useState<EmployeeBonusDetailsDTO>();

    const getBonusDetailsByIdCall = useServiceCallPro(svc.getBonusDetailsById);


    /****************************
    * DATA REQUESTS
    *****************************/

    const getBonusDetailsById = useCallback(() => {
        if (!locationIDAsString || !employeeId || !periodId) return;

        getBonusDetailsByIdCall
            .invoke(locationIDAsString, employeeId, periodId)
            .then((data) => {
                if (data) {
                    setBonusDetails(data);
                    updateRouteDate(["##BONUS##", data.evaluationPeriodName]);
                }
            })
            .catch((error) => {
                if (!error) return;
                openPopup(<ErrorPopup>{error.response.data.message}</ErrorPopup>);
            });
    }, [locationIDAsString, employeeId, periodId, getBonusDetailsByIdCall.invoke, openPopup, updateRouteDate]);


    /****************************
    * DATA MANIPULATION EFFECTS
    *****************************/

    useEffect(() => {
        getBonusDetailsById();
    }, []);


    /****************************
     * CSS & HTML
     *****************************/

    const companyCriteriaColumns: ColumnDefinition<CompanyCriteria>[] = useMemo(
        () => [
            {
                columnKey: "criteria",
                cellRenderProp: (v) => v.name,
                headerRender: translate("EMPLOYEES.BONUS.INFOANDFORM.Factor"),
                isMobilePrimaryCell: true,
            },
            {
                columnKey: "description",
                cellRenderProp: (v) => v.achievementPercentage ? (v.achievementPercentage * 100).toFixed(2) : "-",
                headerRender: translate("EMPLOYEES.BONUS.INFOANDFORM.Evaluation") + " (%)",
                width: "20%",
                isMobilePrimaryCell: true,
            },
        ], []);


    const employeeCriteriaColumns: ColumnDefinition<EmployeeCriteria>[] = useMemo(
        () => [
            {
                columnKey: "criteria",
                cellRenderProp: (v) => v.factorName,
                headerRender: translate("EMPLOYEES.BONUS.INFOANDFORM.Factor"),
                isMobilePrimaryCell: true,
            },
            {
                columnKey: "description",
                cellRenderProp: (v) => v.evaluationValue ? finalBonusScoreFromPercentage(v.evaluationValue) : "-",
                headerRender: translate("EMPLOYEES.BONUS.INFOANDFORM.Evaluation"),
                width: "20%",
                isMobilePrimaryCell: true,
            },
        ], []);


    const renderFeedbackCard = useMemo(() => {
        if (bonusDetails && bonusDetails.feedback)
            return <CardContainer className="evaluation-observations">
                <div className="card-header">
                    <div className="subtitle">
                        {translate("EMPLOYEES.BONUS.INFOANDFORM.Feedback")}
                    </div>
                </div>
                <div>{bonusDetails.feedback}</div>
            </CardContainer>
    }, [bonusDetails]);


    return (
        <PageLayout
            tabTitle={translate("EMPLOYEES.BONUS.Bonus")}
            pageTitle={translate("EMPLOYEES.BONUS.Bonus")}
            className="bonus-details-page"
            showBreadcrumb
        >
            {bonusDetails ?
                <div className="top-section">
                    <CardContainer className="period-data">
                        <div className="card-header">
                            <div className="subtitle">
                                {translate("EMPLOYEES.BONUS.INFOANDFORM.EvaluationPeriodData")}
                            </div>
                        </div>

                        <FormSection unsetFlexGrow>
                            <InfoBlock label={translate("EMPLOYEES.BONUS.INFOANDFORM.Name")} value={bonusDetails.evaluationPeriodName} />
                            <InfoBlock label={translate("EMPLOYEES.BONUS.INFOANDFORM.StartDate")} value={bonusDetails.evaluationPeriodStartDate ? DateTimeHelper.formatDateTimeLocale(bonusDetails.evaluationPeriodStartDate) : "-"} />
                            <InfoBlock label={translate("EMPLOYEES.BONUS.INFOANDFORM.EndDate")} value={bonusDetails.evaluationPeriodEndDate ? DateTimeHelper.formatDateTimeLocale(bonusDetails.evaluationPeriodEndDate) : "-"} />
                        </FormSection>
                    </CardContainer>
                    <StatusCard title={translate("EMPLOYEES.BONUS.INFOANDFORM.Evaluation")} value={bonusDetails?.employeeScoreTotalPercentage ? "" + finalBonusScoreFromPercentage(bonusDetails?.employeeScoreTotalPercentage * 100) : "-"} className="evaluation-status-card" />
                </div> : null}

            <div className="criterias-section">
                <CardContainer className="company-criteria">
                    <div className="card-header">
                        <div className="subtitle">
                            {translate("EMPLOYEES.BONUS.INFOANDFORM.CompanyEvaluationFactors")}
                        </div>
                    </div>
                    <ResponsiveDataTable
                        items={bonusDetails?.companyCriterias || []}
                        columnDefinitions={companyCriteriaColumns}
                        totalitems={bonusDetails?.companyCriterias.length || 0}
                        isLoading={getBonusDetailsByIdCall.isLoading}
                    />
                </CardContainer>
                <CardContainer className="employee-criteria">
                    <div className="card-header">
                        <div className="subtitle">
                            {translate("EMPLOYEES.BONUS.INFOANDFORM.EmployeeEvaluationFactors")}
                        </div>
                        <Spacer mode="vertical" px={6} />
                        <div className="employee-performance-text">
                            {bonusDetails?.employeeScoreTotalPercentage ? translate("EMPLOYEES.BONUS.PERFORMANCE.Performance") + ": " + finalBonusEvaluationPerformance(bonusDetails.employeeScoreTotalPercentage) : null}
                        </div>
                    </div>
                    <ResponsiveDataTable
                        items={bonusDetails?.employeeCriterias || []}
                        columnDefinitions={employeeCriteriaColumns}
                        totalitems={bonusDetails?.employeeCriterias.length || 0}
                        isLoading={getBonusDetailsByIdCall.isLoading}
                    />
                </CardContainer>
            </div>
            {renderFeedbackCard}
        </PageLayout >
    );
}