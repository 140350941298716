import { useCallback, useEffect, useState } from "react";
import { useClosePopup, useCreateNotification, usePopup } from "../../../lib/infrastructure/ui/UIServices";

import { AllValid } from "../../../common/validators/ValidateFormControls";
import { CareerLadderService } from "../services/CareerLadderService";
import { CareerLadderStepCreateDTO } from "../models/dtos/CareerLadderStepCreateDTO";
import { ConditionalRender } from "../../../lib/functional/ConditionalRender";
import { ErrorPopup } from "../../../lib/components/popup/ErrorPopup";
import { ExceptionType } from "../../../lib/infrastructure/http/ExceptionType";
import { FormFieldSelectSingle } from "../../../lib/components/form/form-field/FormFieldSelectSingle";
import { MessageBar } from "../../../lib/components/message-bar/MessageBar";
import { Notification } from "../../../lib/components/notifications/Notification";
import { PopupActionButtons } from "../../../lib/layouts/containers/popup-buttons/PopupActionButtons";
import { PopupContainer } from "../../../lib/layouts/containers/popup-container/PopupContainer";
import { PopupContent } from "../../../lib/layouts/containers/popup-content/PopupContent";
import { PopupHeader } from "../../../lib/layouts/containers/popup-header/PopupHeader";
import { RoleSummary } from "../../roles/models/domain/RoleSummary";
import { RolesService } from "../../roles/services/RolesService";
import { Spacer } from "../../../lib/components/separator/Spacer";
import { ValidationErrorMessage } from "../../../lib/infrastructure/http/APIError";
import { translate } from "../../../lib/infrastructure/i18n/InternationalizationService";
import { useFormControl } from "../../../lib/components/form/Form";
import { useServiceCallPro } from "../../../lib/hooks/useServiceCall";

interface IAddCareerLadderNodePopupProps {
    locationId: string | undefined;
    businessLineId: string | undefined;
    careerLadderId?: string | undefined;
    nodeId?: number;
    onCompletedOperations: () => void;
}

const labelRoleSelector = (item: RoleSummary) => item.description;
const idRoleSelector = (item: RoleSummary) => item.roleId;

var rolesSvc = new RolesService();
var careerLadderSvc = new CareerLadderService();

export function AddCareerLadderNodePopup(props: IAddCareerLadderNodePopupProps) {
    const closePopup = useClosePopup();
    const openPopup = usePopup();
    const createNotification = useCreateNotification();

    const getActiveRolesCall = useServiceCallPro(rolesSvc.getRoles);
    const addCareerLadderNodeCall = useServiceCallPro(careerLadderSvc.addNode);

    const [roles, setRoles] = useState<RoleSummary[]>([]);
    const [validationAlert, setValidationAlert] = useState<ValidationErrorMessage>({ showAlert: false });


    const rolesFormControl = useFormControl<RoleSummary>({});


    useEffect(() => {
        getActiveRoles();
    }, [])


    const getActiveRoles = useCallback(() => {
        if (!props.locationId && !props.businessLineId) return;

        getActiveRolesCall.invoke("" + props.locationId, "" + props.businessLineId, { filterByIsActive: true }, undefined)
            .then((data) => {
                setRoles(data?.roles || []);
            })
            .catch((error) => {
                if (!error) return;
                openPopup(<ErrorPopup>{error.response.data.message}</ErrorPopup>);
            });
    }, [props.locationId, props.businessLineId, getActiveRolesCall, openPopup]);


    const handleSelectClicked = useCallback(() => {
        if (!AllValid(rolesFormControl.validate())) return;

        if (!rolesFormControl.value) return;
        let request: CareerLadderStepCreateDTO = {
            roleId: rolesFormControl.value?.roleId,
            parentStepId: props.nodeId ? props.nodeId : null,
        };


        const handleSuccess = () => {
            closePopup();
            props.onCompletedOperations();
            createNotification(
                <Notification
                    type="success"
                    title={translate("COMMON.SYSTEMPOPUPS.Success")}
                    text={translate("BUSINESSLINES.CAREER.POPUP.AddRoleSuccess")}
                />
            );
        };


        const handleError = (error: any) => {
            if (!error) return;

            const errorMessage = error?.response?.data?.message || translate("ERRORS.UnknownError");
            const enumCode = error?.response?.data?.enumCode;

            if (enumCode === ExceptionType.ERROR_VALIDATION_NAME_ALREADY_EXISTS) {
                setValidationAlert({
                    showAlert: true,
                    message: errorMessage,
                });
            } else {
                openPopup(<ErrorPopup>{errorMessage}</ErrorPopup>);
            }
        };


        addCareerLadderNodeCall
            .invoke("" + props.locationId, "" + props.businessLineId, request)
            .then(handleSuccess)
            .catch(handleError);
    }, [closePopup, rolesFormControl, createNotification, openPopup, props, addCareerLadderNodeCall.invoke]);


    return <PopupContainer className="popup-add-career-ladder-roles">
        <PopupHeader title={translate("BUSINESSLINES.CAREER.POPUP.AddRole")} />
        <PopupContent isLoading={getActiveRolesCall.isLoading}>
            <FormFieldSelectSingle
                formControl={rolesFormControl}
                label={translate("BUSINESSLINES.CAREER.INFOANDFORM.Roles")}
                placeholder={translate("BUSINESSLINES.CAREER.INFOANDFORM.RolesPlaceholder")}
                options={roles}
                labelSelector={labelRoleSelector}
                idSelector={idRoleSelector}
                maxHeightOptions={"16rem"}
            />
            <ConditionalRender if={validationAlert.showAlert}>
                <Spacer mode="vertical" px="10" />
                <MessageBar type="error" text={"" + validationAlert.message} />
            </ConditionalRender>
            <Spacer mode={"vertical"} px="30" />
            <PopupActionButtons
                buttons={[
                    {
                        text: translate("COMMON.Cancel"),
                        type: "tertiary",
                        onClick: () => closePopup(),
                    },
                    {
                        text: translate("COMMON.Add"),
                        type: "primary",
                        onClick: handleSelectClicked,
                    },
                ]}
            />
        </PopupContent>
    </PopupContainer>;
}