import "./MyEvaluationDetailsPage.css";

import { EmployeeEvaluationCategory, EmployeeEvaluationSkill, SkillsCatEmployeeEvaluationsCard } from "../../../employees/pages/evaluations/components/SkillsCatEmployeeEvaluationsCard";
import { useCallback, useEffect, useMemo, useState } from "react";

import { BonusEmployeeEvaluationCard } from "../../../employees/pages/evaluations/components/BonusEmployeeEvaluationCard";
import { CertificatesEmployeeEvaluationCard } from "../../../employees/pages/evaluations/components/CertificatesEmployeeEvaluationCard";
import { ChecklistEmployeeEvaluationCard } from "../../../employees/pages/evaluations/components/ChecklistEmployeeEvaluationCard";
import { EmployeeActionItemSummaryDTO } from "../../../employees/models/dtos/EmployeeActionItemSummaryDTO";
import { EmployeeBonusResponseDTO } from "../../../employees/models/dtos/EmployeeBonusReponseDTO";
import { EmployeeEvaluationDetailsDTO } from "../../../employees/models/dtos/EmployeeEvaluationDetailsDTO";
import { EmployeeEvaluationMatrixResponseDTO } from "../../../employees/models/dtos/EmployeeEvaluationMatrixResponseDTO";
import { ErrorPopup } from "../../../../lib/components/popup/ErrorPopup";
import { EvaluationCertificateDTO } from "../../../evaluations/models/EvaluationCertificateDTO";
import { EvaluationChecklistDTO } from "../../../evaluations/models/EvaluationChecklistDTO";
import { EvaluationStatusTranslationTerms } from "../../../evaluations/models/EvaluationStatus";
import { FollowUpEmployeeEvaluationCard } from "../../../employees/pages/evaluations/components/FollowUpEmployeeEvaluationCard";
import { FormSection } from "../../../../lib/components/form/section/FormSection";
import { InfoBlock } from "../../../../lib/components/info-block/InfoBlock";
import { MyService } from "../../services/MyService";
import { PageLayout } from "../../../../lib/layouts/main-content/PageLayout";
import { StatusCard } from "../../../../lib/components/cards/stats-card/StatusCard";
import { Tag } from "../../../../lib/components/tag/Tag";
import { TextArea } from "../../../../lib/components/form/text-area/TextArea";
import { TitledCardContainer } from "../../../../lib/layouts/containers/card/TitledCardContainer";
import { getEvaluationStateCss } from "../../../../common/helpers/getEvaluationStateCss";
import { translate } from "../../../../lib/infrastructure/i18n/InternationalizationService";
import { useGlobalLocation } from "../../../../lib/infrastructure/location/LocationServices";
import { useParams } from "react-router-dom";
import { usePopup } from "../../../../lib/infrastructure/ui/UIServices";
import { useServiceCallPro } from "../../../../lib/hooks/useServiceCall";

var svc = new MyService();

export function MyEvaluationDetailsPage() {

    const openPopup = usePopup();
    const locationID = useGlobalLocation();
    const locationIDAsString = useMemo(() => "" + locationID, [locationID])
    const { evaluationId } = useParams();


    const getEvaluationDetailsByIdCall = useServiceCallPro(svc.getMyEvaluationDetails);
    const getEvaluationMatrixCall = useServiceCallPro(svc.getMyEvaluationMatrix);

    const getEvaluationFollowUpItemsCall = useServiceCallPro(svc.getMyEvaluationActionItems);
    const getUnjustifiedAbsencesCall = useServiceCallPro(svc.getMyEvaluationUnjustifiedAbsences);

    const getEmployeeCertificationsCall = useServiceCallPro(svc.getMyCertifications);

    const getEvaluationBonusCall = useServiceCallPro(svc.getMyEvaluationBonus);



    const [evaluationDetails, setEvaluationDetails] = useState<EmployeeEvaluationDetailsDTO>();
    const [evaluationMatrixDetails, setEvaluationMatrixDetails] = useState<EmployeeEvaluationMatrixResponseDTO>();

    const [evaluationTotal, setEvaluationTotal] = useState<number>(0);
    const [roleReferenceValue, setRoleReferenceValue] = useState<number>(0);
    const [extraEvaluationTotal, setExtraEvaluationTotal] = useState<number>(0);
    const [unjustifiedAbsences, setUnjustifiedAbsences] = useState<string>();

    const [managerFeedback, setManagerFeedback] = useState<string>();
    const [employeeFeedback, setEmployeeFeedback] = useState<string>();

    const [evaluationCategories, setEvaluationCategories] = useState<EmployeeEvaluationCategory[]>([]);
    const [evaluationChecklistItems, setEvaluationChecklistItems] = useState<EvaluationChecklistDTO[]>([]);
    const [lastEmployeeCertifications, setLastEmployeeCertifications] = useState<EvaluationCertificateDTO[]>([]);
    const [evaluationBonus, setEvalutionBonus] = useState<EmployeeBonusResponseDTO>();

    const [followUpItems, setFollowUpItems] = useState<EmployeeActionItemSummaryDTO[]>([]);


    /****************************
    * DATA REQUESTS
    *****************************/

    const getEvaluationDetailsById = useCallback(() => {
        if (!evaluationId || !locationIDAsString) return;

        getEvaluationDetailsByIdCall.invoke(locationIDAsString, evaluationId)
            .then((data) => {
                if (data) {
                    setEvaluationDetails(data);
                }
            })
            .catch((error) => {
                if (!error) return;
                openPopup(<ErrorPopup>{error.response.data.message}</ErrorPopup>);
            });
    }, [evaluationId, getEvaluationDetailsByIdCall.invoke, locationIDAsString, openPopup, setEvaluationDetails]);



    const getEvaluationMatrix = useCallback(() => {
        if (!evaluationId || !locationIDAsString) return;

        getEvaluationMatrixCall.invoke(locationIDAsString, evaluationId)
            .then((data) => {
                if (data) {
                    setEvaluationMatrixDetails(data);
                }
            })
            .catch((error) => {
                if (!error) return;
                openPopup(<ErrorPopup>{error.response.data.message}</ErrorPopup>);
            });
    }, [evaluationId, getEvaluationMatrixCall.invoke, locationIDAsString, openPopup, setEvaluationMatrixDetails])



    const getUnjustifiedAbsences = useCallback(() => {
        if (!evaluationId || !locationIDAsString) return;

        getUnjustifiedAbsencesCall.invoke(locationIDAsString, evaluationId)
            .then((res) => {
                if (!res) return;
                setUnjustifiedAbsences(`${res.absenceDays}` || "");
            })
            .catch((error) => {
                if (!error) return;
                openPopup(<ErrorPopup>{error.response.data.message}</ErrorPopup>);
            });
    }, [evaluationId, getUnjustifiedAbsencesCall.invoke, locationIDAsString, openPopup, setUnjustifiedAbsences]);



    const getEvaluationFollowUpItems = useCallback(() => {
        if (!evaluationId || !locationIDAsString) return;


        getEvaluationFollowUpItemsCall.invoke(locationIDAsString, evaluationId)
            .then((res) => {
                if (!res) return;
                setFollowUpItems(res.items || []);
            })
            .catch((error) => {
                if (!error) return;
                openPopup(<ErrorPopup>{error.response.data.message}</ErrorPopup>);
            });
    }, [evaluationId, getEvaluationFollowUpItemsCall.invoke, locationIDAsString, openPopup, setFollowUpItems]);



    const getEmployeeCertifications = useCallback(() => {

        if (!locationIDAsString) return;

        getEmployeeCertificationsCall.invoke(locationIDAsString, { page: 0, pageLength: 2 }, undefined)
            .then((response) => {

                if (response?.totalItems === 0) return;

                const mappedCertifications = response?.employeeCertifications.map((c) => {

                    var cert: EvaluationCertificateDTO = {
                        id: c.employeeCertificationId,
                        certificateName: c.certificationName,
                        startDate: c.startDate ?? null,
                        endDate: c.endDate ?? null,
                        state: c.state
                    };

                    return cert;
                });

                setLastEmployeeCertifications(mappedCertifications || []);

            })
            .catch((error) => {
                if (!error) return;
                openPopup(<ErrorPopup>{error.response.data.message}</ErrorPopup>);
            });

    }, [getEmployeeCertificationsCall.invoke, locationIDAsString, openPopup, setLastEmployeeCertifications]);



    const getEvaluationBonus = useCallback(() => {

        if (!locationIDAsString || !evaluationId) return;

        getEvaluationBonusCall.invoke(locationIDAsString, evaluationId)
            .then((data) => {
                if (!data) return;
                setEvalutionBonus(data);
            })
            .catch((error) => {
                if (!error) return;
                openPopup(<ErrorPopup>{error.response.data.message}</ErrorPopup>);
            });
    }, [locationIDAsString, evaluationId, getEvaluationBonusCall.invoke, openPopup]);



    /****************************
     * DATA MANIPULATION EFFECTS
     *****************************/

    const checklistSumValue = useMemo(() => {

        if (!evaluationChecklistItems || evaluationMatrixDetails?.evaluationMatrixChecklistItems === undefined) {
            return "-";
        }

        return `${evaluationChecklistItems.filter(item => item.isChecked).length}/${evaluationMatrixDetails?.evaluationMatrixChecklistItems.length}`;

    }, [evaluationChecklistItems, evaluationMatrixDetails?.evaluationMatrixChecklistItems]);


    useEffect(() => {

        if (!locationIDAsString) return;

        getEvaluationDetailsById();
        getUnjustifiedAbsences();
        getEvaluationFollowUpItems();
        getEvaluationMatrix();
        getEvaluationBonus();

    }, [evaluationId]);



    useEffect(() => {
        if (!locationIDAsString) return;
        getEmployeeCertifications();

    }, [locationIDAsString]);



    useEffect(() => {
        if (!evaluationDetails) return;

        setManagerFeedback(evaluationDetails.feedbackFromManager || "");
        setEmployeeFeedback(evaluationDetails.feedbackFromEmployee || "");
    }, [evaluationDetails]);



    useEffect(() => {
        if (!evaluationCategories) return;

        var sum = evaluationCategories.flatMap(ev => ev.skills).reduce((accumulator, currentObj) => {
            if (!currentObj.value || !currentObj.competenceLevel) return accumulator;

            return accumulator + (parseInt(currentObj.value) || 0);
        }, 0);

        setEvaluationTotal(sum);


        var sumReference = evaluationCategories.flatMap(ev => ev.skills).reduce((accumulator, currentObj) => {
            if (currentObj.competenceLevel == null) return accumulator;

            return accumulator + currentObj.competenceLevel;
        }, 0);

        setRoleReferenceValue(sumReference);


        var sumWithoutCompetence = evaluationCategories.flatMap(ev => ev.skills).reduce((accumulator, currentObj) => {
            if (currentObj.value && !currentObj.competenceLevel) {
                return accumulator + (parseInt(currentObj.value) || 0);
            }
            return accumulator;
        }, 0);

        setExtraEvaluationTotal(sumWithoutCompetence);

    }, [evaluationCategories]);



    useEffect(() => {

        if (!evaluationDetails || !evaluationMatrixDetails) return;

        var evaluationCategoriesMapped: EmployeeEvaluationCategory[] = [];

        for (var category of evaluationMatrixDetails.skillCategoryDetails) {

            var evaluationCategory: EmployeeEvaluationCategory = {
                name: category.name,
                skills: category.skills.map((skill) => {

                    const evaluationMatrixEntry = evaluationMatrixDetails.evaluationMatrixEntries.find(entry => entry.skillId === skill.skillID);
                    const evaluationValue = evaluationDetails.evaluationValues.find(evaValue => evaValue.targetEvaluationMatrixEntryId === evaluationMatrixEntry?.id);

                    const mSkill: EmployeeEvaluationSkill = {
                        isValid: true,
                        name: skill.name,
                        skillId: skill.skillID,
                        targetEvaluationMatrixEntryId: evaluationMatrixEntry?.id || 0,
                        competenceLevel: evaluationMatrixEntry?.competenceLevel ?? null,
                        value: evaluationValue?.value !== undefined ? `${evaluationValue?.value}` : undefined
                    };

                    return mSkill;
                })
            }

            evaluationCategoriesMapped.push(evaluationCategory)
        }

        setEvaluationCategories(evaluationCategoriesMapped)

    }, [evaluationDetails?.evaluationValues, evaluationMatrixDetails])



    useEffect(() => {

        if (!evaluationDetails?.evaluationChecklistItems || !evaluationMatrixDetails?.evaluationMatrixChecklistItems) return;

        const mappedItems = evaluationMatrixDetails?.evaluationMatrixChecklistItems.map((matrixItem, idx) => {
            const matchedItem = evaluationDetails?.evaluationChecklistItems.find(
                ({ targetChecklistItemId }) => targetChecklistItemId === matrixItem.evaluationMatrixChecklistItemId
            );

            return {
                targetMatrixChecklistItemId: matrixItem.evaluationMatrixChecklistItemId,
                checklistItemDescription: matrixItem.description,
                isChecked: matchedItem?.isChecked ?? false,
                id: idx
            };
        });

        setEvaluationChecklistItems(mappedItems);

    }, [evaluationDetails?.evaluationValues, evaluationMatrixDetails?.evaluationMatrixChecklistItems])



    /****************************
     * USER ACTIONS
     *****************************/



    /****************************
     * CSS & HTML
     *****************************/

    const renderAdditionalInfoCards = useMemo(() => {

        if (!evaluationId) return;

        return <div className="additional-info">
            <BonusEmployeeEvaluationCard isLoading={getEvaluationBonusCall.isLoading} items={evaluationBonus} />
            <StatusCard isLoading={getUnjustifiedAbsencesCall.isLoading}
                title={translate("BUSINESSLINES.EVALUATIONS.INFOANDFORM.UnjustifiedAbsences")}
                value={unjustifiedAbsences ?? "-"}
                backgroundColor="status-dark-grey"
                className="absences-status-card" />
            <CertificatesEmployeeEvaluationCard locationId={locationIDAsString} employeeValmetId={"" + evaluationDetails?.targetEmployee.userEmployeeID}
                certificates={lastEmployeeCertifications || []}
                isLoading={getEvaluationDetailsByIdCall.isLoading && getEmployeeCertificationsCall.isLoading} />
        </div>
    }, [getEvaluationBonusCall.isLoading, evaluationBonus, evaluationId, evaluationDetails?.targetEmployee.userEmployeeID, getUnjustifiedAbsencesCall.isLoading, unjustifiedAbsences, locationIDAsString, lastEmployeeCertifications, getEvaluationDetailsByIdCall.isLoading, getEmployeeCertificationsCall.isLoading]);



    const renderChecklistCard = useMemo(() => {
        if (evaluationDetails?.evaluationChecklistItems && evaluationMatrixDetails?.evaluationMatrixChecklistItems) {
            return <ChecklistEmployeeEvaluationCard
                items={evaluationChecklistItems || []}
                isLoading={getEvaluationDetailsByIdCall.isLoading || getEvaluationMatrixCall.isLoading} />;
        }
    }, [evaluationDetails?.evaluationChecklistItems, evaluationMatrixDetails?.evaluationMatrixChecklistItems, getEvaluationDetailsByIdCall.isLoading, getEvaluationMatrixCall.isLoading, evaluationChecklistItems]);


    return (
        <PageLayout
            tabTitle={translate("BUSINESSLINES.EVALUATIONS.Evaluation")}
            showBreadcrumb
            pageTitle={translate("BUSINESSLINES.EVALUATIONS.Evaluation")}
            className="evaluation-details"
        >
            <div className="evaluation-details-summary">
                <TitledCardContainer className="employee-data"
                    extraTitle={
                        <Tag text={evaluationDetails?.state ? translate(EvaluationStatusTranslationTerms[evaluationDetails.state]) : "-"}
                            backgroundColor={getEvaluationStateCss(evaluationDetails?.state ?? -1)} />
                    }
                    title={translate("BUSINESSLINES.EVALUATIONS.INFOANDFORM.EmployeeData")} isLoading={getEvaluationDetailsByIdCall.isLoading}>
                    <FormSection unsetFlexGrow>
                        <InfoBlock label={translate("BUSINESSLINES.EVALUATIONS.INFOANDFORM.Name")}
                            value={evaluationDetails?.targetEmployee.userDisplayName || "-"} />
                        <InfoBlock label={translate("BUSINESSLINES.EVALUATIONS.INFOANDFORM.Role")}
                            value={evaluationDetails?.roleName || "-"} />
                    </FormSection>
                </TitledCardContainer>
                <div className="evaluation-details-summary-cards">

                    <StatusCard isLoading={getEvaluationDetailsByIdCall.isLoading} title={translate("BUSINESSLINES.EVALUATIONS.INFOANDFORM.Evaluation")} value={`${evaluationTotal}/${roleReferenceValue} (${extraEvaluationTotal})`} backgroundColor="status-dark-grey" />
                    <StatusCard isLoading={getEvaluationDetailsByIdCall.isLoading}
                        title={translate("BUSINESSLINES.EVALUATIONS.INFOANDFORM.Checklist")}
                        value={checklistSumValue ?? "-"}
                        backgroundColor="status-dark-grey" />
                </div>
            </div>

            <div className="skills-categories">
                <SkillsCatEmployeeEvaluationsCard
                    roleName={evaluationDetails?.roleName || ""}
                    evaluationId={evaluationId}
                    values={evaluationCategories}
                />
            </div>

            {renderAdditionalInfoCards}
            {renderChecklistCard}

            <div className="feedback-cards">
                <TitledCardContainer title={translate("BUSINESSLINES.EVALUATIONS.INFOANDFORM.FeedbackManager")}>
                    <TextArea
                        value={managerFeedback}
                        placeholder={translate("COMMON.FORM.DefaultPlaceholder")}
                        //isDisabled={props.formControl.isDisabled}
                        alternativeStyle
                    ></TextArea>
                </TitledCardContainer>
                <TitledCardContainer title={translate("BUSINESSLINES.EVALUATIONS.INFOANDFORM.FeedbackEmployee")}>
                    <TextArea
                        value={employeeFeedback}
                        placeholder={translate("COMMON.FORM.DefaultPlaceholder")}
                        //isDisabled={props.formControl.isDisabled}
                        alternativeStyle
                    ></TextArea>
                </TitledCardContainer>
            </div>

            <FollowUpEmployeeEvaluationCard
                items={followUpItems}
                isLoading={getEvaluationFollowUpItemsCall.isLoading} />
        </PageLayout >
    );
}