import "./EvaluationComment.css";

import { Avatar } from '../../../../lib/components/avatar/Avatar';

export interface CommentInfo {
    createdBy: string;
    createdDate: string;
    text: string;
}

interface IEvaluationCommentProps {
    comment: CommentInfo;
}

export function EvaluationComment(props: IEvaluationCommentProps) {
    return <div className="comment">
        <Avatar className="comment-avatar" name={props.comment.createdBy} />
        <div className="comment-info">
            <div className="small-copy commented-by">{`${props.comment.createdBy} ${props.comment.createdDate}`}</div>
            <div className="comment-text">{props.comment.text}</div>
        </div>
    </div>;
}