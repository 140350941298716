import { regexOnlyZeroAndPositiveNumbersOrEmpty, validateRegexOrEmpty } from "../../../../../lib/validators/ValidateRegex";
import { useCallback, useEffect, useState } from "react";
import { useClosePopup, useCreateNotification, usePopup } from "../../../../../lib/infrastructure/ui/UIServices";

import { AllValid } from "../../../../../common/validators/ValidateFormControls";
import { BusinessLine } from "../../../../admin/business-lines/models/domain/BusinessLine";
import { BusinessLinesService } from "../../../../admin/business-lines/services/BusinessLinesService";
import { Checkbox } from "../../../../../lib/components/checkbox/Checkbox";
import { ConditionalRender } from "../../../../../lib/functional/ConditionalRender";
import { DateTime } from "luxon";
import { EmployeePositionRecordCreateDTO } from "../../../models/dtos/EmployeePositionRecordCreateDTO";
import { EmployeePositionRecordsService } from "../../../services/EmployeePositionRecordsService";
import { ErrorPopup } from "../../../../../lib/components/popup/ErrorPopup";
import { ExceptionType } from "../../../../../lib/infrastructure/http/ExceptionType";
import { FormFieldDatePicker } from "../../../../../lib/components/form/form-field/FormFieldDatePicker";
import { FormFieldSelectSingle } from "../../../../../lib/components/form/form-field/FormFieldSelectSingle";
import { FormFieldTextInput } from "../../../../../lib/components/form/form-field/FormFieldTextInput";
import { FormSection } from "../../../../../lib/components/form/section/FormSection";
import { FullScreenLoader } from "../../../../../lib/components/loader/FullScreenLoader";
import { MessageBar } from "../../../../../lib/components/message-bar/MessageBar";
import { Notification } from "../../../../../lib/components/notifications/Notification";
import { PopupActionButtons } from "../../../../../lib/layouts/containers/popup-buttons/PopupActionButtons";
import { PopupContainer } from "../../../../../lib/layouts/containers/popup-container/PopupContainer";
import { PopupContent } from "../../../../../lib/layouts/containers/popup-content/PopupContent";
import { PopupHeader } from "../../../../../lib/layouts/containers/popup-header/PopupHeader";
import { RoleSummary } from "../../../../roles/models/domain/RoleSummary";
import { RolesService } from "../../../../roles/services/RolesService";
import { Spacer } from "../../../../../lib/components/separator/Spacer";
import { ValidationErrorMessage } from "../../../../../lib/infrastructure/http/APIError";
import { hasOneElementSelected } from "../../../../../lib/validators/ValidateSelectSingle";
import { translate } from "../../../../../lib/infrastructure/i18n/InternationalizationService";
import { useFormControl } from "../../../../../lib/components/form/Form";
import { useServiceCallPro } from "../../../../../lib/hooks/useServiceCall";
import { validateDateTime } from "../../../../../lib/validators/ValidateDateTime";
import { validateWhiteSpaceStrings } from "../../../../../lib/validators/ValidateWhiteSpaceStrings";

interface IAddEmployeeCareerLadderNodePopupProps {
    locationId: string;
    /*     businessLineId: string | undefined; */
    employeeId: string | undefined;
    onCompletedOperations: () => void;
}

const labelRoleSelector = (item: RoleSummary) => item.description;
const idRoleSelector = (item: RoleSummary) => item.roleId;

const labelBusinessLineSelector = (item: BusinessLine) => item.name;
const idBusinessLineSelector = (item: BusinessLine) => item.id;

var rolesSvc = new RolesService();
var businessLineSvc = new BusinessLinesService();
var employeeCareerLadderSvc = new EmployeePositionRecordsService();



export function AddEmployeePositionRecordPopup(props: IAddEmployeeCareerLadderNodePopupProps) {
    const closePopup = useClosePopup();
    const openPopup = usePopup();
    const createNotification = useCreateNotification();

    const getBusinessLineRolesCall = useServiceCallPro(rolesSvc.getRoles);
    const getBusinessLinesCall = useServiceCallPro(businessLineSvc.getBusinessLines);
    const createEmployeePositionRecordCall = useServiceCallPro(employeeCareerLadderSvc.createEmployeePositionRecord);

    const [roles, setRoles] = useState<RoleSummary[]>([]);
    const [businessLines, setBusinessLines] = useState<BusinessLine[]>([]);

    const [validationAlert, setValidationAlert] = useState<ValidationErrorMessage>({ showAlert: false });

    const businessLinesForm = useFormControl<BusinessLine>({
        validators: [hasOneElementSelected()],
        enableAutoValidate: true,
    });

    const rolesFormControl = useFormControl<RoleSummary>({
        validators: [hasOneElementSelected()],
        enableAutoValidate: true,
    });

    const startDateForm = useFormControl<DateTime>({
        validators: [validateDateTime()],
        enableAutoValidate: false
    });

    const endDateForm = useFormControl<DateTime>({
        validators: [],
        enableAutoValidate: false
    });

    const roleNameForm = useFormControl<string>({
        validators: [validateWhiteSpaceStrings()],
        enableAutoValidate: true,
    });

    const jobGradeForm = useFormControl<string>({
        validators: [validateRegexOrEmpty(regexOnlyZeroAndPositiveNumbersOrEmpty, false, "")],
        enableAutoValidate: true,
    });

    const [isDeprecatedRoleSelected, setIsDeprecatedRoleSelected] = useState<boolean>(false);
    const [filterByActiveRolesSelected, setFilterByActiveRolesSelected] = useState<boolean>(true);


    /****************************
    * DATA REQUESTS
    *****************************/

    const getBusinessLines = useCallback(() => {
        if (!props.locationId) return;

        getBusinessLinesCall.invoke(props.locationId)
            .then((data) => {
                setBusinessLines(data?.businessLines || []);
            })
            .catch((error) => {
                if (!error) return;
                openPopup(<ErrorPopup>{error.response.data.message}</ErrorPopup>);
            });
    }, [props.locationId,
    getBusinessLinesCall.invoke,
        setBusinessLines,
        openPopup]);




    const getBusinessLineRoles = useCallback(() => {
        if (!props.locationId || !businessLinesForm.value?.id) return;

        getBusinessLineRolesCall.invoke(props.locationId, "" + businessLinesForm.value?.id, { filterByIsActive: filterByActiveRolesSelected }, undefined)
            .then((data) => {
                setRoles(data?.roles || []);
            })
            .catch((error) => {
                if (!error) return;
                openPopup(<ErrorPopup>{error.response.data.message}</ErrorPopup>);
            });
    }, [props.locationId,
    businessLinesForm.value?.id,
    getBusinessLineRolesCall.invoke,
        filterByActiveRolesSelected,
        setRoles,
        openPopup]);





    /****************************
    * DATA MANIPULATION EFFECTS
    *****************************/

    useEffect(() => {
        getBusinessLines();
    }, [props.locationId]);


    useEffect(() => {
        if (!businessLinesForm.value?.id) return;
        getBusinessLineRoles();
    }, [businessLinesForm.value?.id, filterByActiveRolesSelected]);



    useEffect(() => {

        if (validationAlert.showAlert) {
            setValidationAlert({
                showAlert: false,
                message: undefined,
            });
        }
    }, [startDateForm.value, endDateForm.value])



    useEffect(() => {
        if (!isDeprecatedRoleSelected && !businessLinesForm.value?.id) {
            rolesFormControl.setIsDisabled(true);
        }
        if (!isDeprecatedRoleSelected && businessLinesForm.value?.id) {
            rolesFormControl.setIsDisabled(false);
        }

    }, [isDeprecatedRoleSelected, businessLinesForm.value?.id]);


    /****************************
    * USER ACTIONS
    *****************************/

    const handleAddRoleBtnClicked = useCallback(() => {

        if (!props.employeeId) return;

        let request: EmployeePositionRecordCreateDTO;

        if (isDeprecatedRoleSelected) {
            if (!AllValid(roleNameForm.validate(), jobGradeForm.validate(), startDateForm.validate())) return;

            request = {
                roleId: null,
                startDate: startDateForm.value?.toISO() || "",
                endDate: endDateForm.value?.toISO() || null,
                jobGrade: jobGradeForm.value ?? null,
                roleName: roleNameForm.value ?? null
            };
        }
        else {
            if (!AllValid(businessLinesForm.validate(), rolesFormControl.validate(), startDateForm.validate(), endDateForm.validate())) return;

            if (!rolesFormControl.value?.roleId) return;

            request = {
                roleId: rolesFormControl.value.roleId,
                startDate: startDateForm.value?.toISO() || "",
                endDate: endDateForm.value?.toISO() || null,
                jobGrade: null,
                roleName: null
            };
        }


        const handleSuccess = () => {
            closePopup();
            props.onCompletedOperations();
            createNotification(
                <Notification
                    type="success"
                    title={translate("COMMON.SYSTEMPOPUPS.Success")}
                    text={translate("BUSINESSLINES.CAREER.POPUP.AddRoleSuccess")}
                />
            );
        };



        const handleError = (error: any) => {
            if (!error) return;

            const errorMessage = error?.response?.data?.message || translate("ERRORS.UnknownError");
            const enumCode = error?.response?.data?.enumCode;

            if (enumCode === ExceptionType.ERROR_POSITION_RECORD_DATETIME_INTERVAL) {
                setValidationAlert({
                    showAlert: true,
                    message: errorMessage,
                });
            } else {
                openPopup(<ErrorPopup>{errorMessage}</ErrorPopup>);
            }
        };


        createEmployeePositionRecordCall
            .invoke(props.locationId, props.employeeId, request)
            .then(handleSuccess)
            .catch(handleError);

    }, [props.onCompletedOperations, props.employeeId, props.locationId,
        closePopup, createNotification, openPopup,
        isDeprecatedRoleSelected,
    createEmployeePositionRecordCall.invoke,
    roleNameForm.validate,
    jobGradeForm.validate,
    startDateForm.validate,
    endDateForm.validate,
    rolesFormControl.validate,
    businessLinesForm.validate,
    roleNameForm.value,
    jobGradeForm.value,
    startDateForm.value,
    endDateForm.value,
    rolesFormControl.value]);




    return <PopupContainer className="popup-add-employee-career-ladder-role">
        <PopupHeader title={translate("EMPLOYEES.CAREER.POPUPS.AddRole")} />

        {createEmployeePositionRecordCall.isLoading && <FullScreenLoader />}

        <PopupContent isLoading={getBusinessLineRolesCall.isLoading || getBusinessLinesCall.isLoading}>
            <FormSection isInputGap childrenFlexDirColumn>

                <Checkbox
                    isChecked={isDeprecatedRoleSelected}
                    text={translate("BUSINESSLINES.CAREER.INFOANDFORM.DeprecatedFunction")}
                    onClick={() => setIsDeprecatedRoleSelected(!isDeprecatedRoleSelected)} />

                <ConditionalRender if={!isDeprecatedRoleSelected}>
                    <Checkbox
                        isChecked={filterByActiveRolesSelected}
                        text={translate("BUSINESSLINES.CAREER.INFOANDFORM.FilterByActiveRoles")}
                        onClick={() => setFilterByActiveRolesSelected(!filterByActiveRolesSelected)} />
                </ConditionalRender>

                <ConditionalRender if={!isDeprecatedRoleSelected} >
                    <FormFieldSelectSingle
                        formControl={businessLinesForm}
                        label={translate("EMPLOYEES.CAREER.INFOANDFORM.BusinessLines")}
                        placeholder={translate("EMPLOYEES.CAREER.INFOANDFORM.BusinessLinesPlaceholder")}
                        options={businessLines}
                        labelSelector={labelBusinessLineSelector}
                        idSelector={idBusinessLineSelector}
                        maxHeightOptions={"16rem"}
                    />
                </ConditionalRender>

                <ConditionalRender if={!isDeprecatedRoleSelected} >
                    <FormFieldSelectSingle
                        formControl={rolesFormControl}
                        label={translate("EMPLOYEES.CAREER.INFOANDFORM.Roles")}
                        placeholder={translate("EMPLOYEES.CAREER.INFOANDFORM.RolesPlaceholder")}
                        options={roles}
                        labelSelector={labelRoleSelector}
                        idSelector={idRoleSelector}
                        maxHeightOptions={"16rem"}
                    />
                </ConditionalRender>

                <ConditionalRender if={isDeprecatedRoleSelected} >
                    <FormFieldTextInput
                        formControl={roleNameForm}
                        label={translate("BUSINESSLINES.CAREER.INFOANDFORM.RoleName")}
                        placeholder={translate("BUSINESSLINES.CAREER.INFOANDFORM.RoleNamePlaceholder")}
                    />
                </ConditionalRender>

                <ConditionalRender if={isDeprecatedRoleSelected} >
                    <FormFieldTextInput
                        formControl={jobGradeForm}
                        label={translate("BUSINESSLINES.CAREER.INFOANDFORM.JobGrade") + " (" + translate("COMMON.Optional") + ")"}
                        placeholder={translate("BUSINESSLINES.CAREER.INFOANDFORM.JobGradePlaceholder")}
                    />
                </ConditionalRender>

                <FormFieldDatePicker
                    formControl={startDateForm}
                    label={translate("EMPLOYEES.CAREER.INFOANDFORM.StartDate")}
                    placeholder={translate("EMPLOYEES.CAREER.INFOANDFORM.StartDatePlaceholder")}
                    maxDate={endDateForm.value}
                />
                <FormFieldDatePicker
                    formControl={endDateForm}
                    label={translate("EMPLOYEES.CAREER.INFOANDFORM.EndDate") + " (" + translate("COMMON.Optional") + ")"}
                    placeholder={translate("EMPLOYEES.CAREER.INFOANDFORM.EndDatePlaceholder")}
                    minDate={startDateForm.value}
                />
            </FormSection>

            <ConditionalRender if={validationAlert.showAlert}>
                <Spacer mode="vertical" px="10" />
                <MessageBar type="error" text={"" + validationAlert.message} />
            </ConditionalRender>

            <Spacer mode={"vertical"} px="30" />

            <PopupActionButtons
                buttons={[
                    {
                        text: translate("COMMON.Cancel"),
                        type: "tertiary",
                        onClick: () => closePopup(),
                    },
                    {
                        text: translate("COMMON.Add"),
                        type: "primary",
                        onClick: handleAddRoleBtnClicked,
                    },
                ]}
            />
        </PopupContent>
    </PopupContainer >;
}