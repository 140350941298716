import { regexOnlyPositiveIntegersExcludingZeroAndAllowNull, validateRegexOrEmpty } from "../../../lib/validators/ValidateRegex";
import { useCallback, useEffect } from "react";

import { FormFieldTextInput } from "../../../lib/components/form/form-field/FormFieldTextInput";
import { translate } from "../../../lib/infrastructure/i18n/InternationalizationService";
import { useFormControl } from "../../../lib/components/form/Form";

interface IEvaluationInputProps {
    skillId: number;
    roleId: number;
    initialValue?: string;
    onChange: (skillId: number, roleId: number, value: string, isValid: boolean) => void;
}

export function EvaluationInput(props: IEvaluationInputProps) {

    const levelFormControl = useFormControl<string>({
        initialValue: props.initialValue && props.initialValue.toString(),
        validators: [validateRegexOrEmpty(regexOnlyPositiveIntegersExcludingZeroAndAllowNull, false, "")],
    });

    const handleChange = useCallback(() => {
        props.onChange(props.skillId, props.roleId, levelFormControl.value as string, levelFormControl.validate());
    }, [levelFormControl.value, props.skillId, props.roleId, props.onChange, levelFormControl.validate]);

    useEffect(() => {
        handleChange();
    }, [levelFormControl.value]);



    return (
        <FormFieldTextInput
            formControl={levelFormControl}
            placeholder={translate("BUSINESSLINES.MATRIX.INFOANDFORM.Level")}
            alternativeStyle
        />
    );

};