import "./CollaboratorContainer.css";

import { ReactComponent as CareerLadderIcon } from "../../../lib/assets/icons/career-ladder.svg";
import { ReactComponent as CertificationsIcon } from "../../../lib/assets/icons/certifications.svg";
import { ReactComponent as FollowUpItemsIcon } from "../../../lib/assets/icons/follow-up-items.svg";
import { MobileSubSidebar } from "../../../lib/components/side-bar/MobileSubSidebar";
import { ReactComponent as PersonalEvaluationIcon } from "../../../lib/assets/icons/personal-evaluation.svg";
import { SubSidebar } from "../../../lib/components/side-bar/SubSidebar";
import { translate } from "../../../lib/infrastructure/i18n/InternationalizationService";
import { useMemo } from "react";
import { useWindowResize } from "../../../lib/infrastructure/ui/UIServices";

interface ICollaboratorContainerProps {
    children: React.ReactNode;
}

export function CollaboratorContainer(props: ICollaboratorContainerProps) {
    const windowResize = useWindowResize();


    /****************************
     * CSS & HTML
     *****************************/

    const subSideBarButtons = useMemo(() => [
        {
            icon: <CareerLadderIcon />,
            navigateTo: "careerladder",
            name: translate("EMPLOYEES.CAREER.CareerLadder"),
        },
        {
            icon: <PersonalEvaluationIcon />,
            navigateTo: "evaluations",
            name: translate("EMPLOYEES.EVALUATIONS.Evaluations"),
        },
        {
            icon: <CertificationsIcon />,
            navigateTo: "certifications",
            name: translate("EMPLOYEES.CERTIFICATIONS.Certifications"),
        },
        {
            icon: <FollowUpItemsIcon />,
            navigateTo: "items",
            name: translate("EMPLOYEES.ITEMS.FollowUpItems"),
        },
    ], []);


    const renderSubSideBar = useMemo(() => {
        return windowResize > 425 ? <SubSidebar buttons={subSideBarButtons} /> : <MobileSubSidebar buttons={subSideBarButtons} />;
    }, [windowResize, subSideBarButtons]);


    return (
        <div className="employees-content-container">
            {renderSubSideBar}
            {props.children}
        </div>
    );
}