import "./JobPositions.css";

import { useCallback, useEffect, useMemo, useState } from "react";
import { useClosePopup, useCreateNotification, usePopup } from '../../../lib/infrastructure/ui/UIServices';

import { ReactComponent as AddIcon } from "../../../lib/assets/icons/add.svg";
import { AddJobPositionPopup } from "./popups/AddJobPositionPopup";
import { ColumnDefinition } from "../../../lib/components/table/TableInterfaces";
import { ReactComponent as EditIcon } from "../../../lib/assets/icons/editar.svg";
import { EditJobPositionPopup } from "./popups/EditJobPositionPopup";
import { ErrorPopup } from "../../../lib/components/popup/ErrorPopup";
import { IconButton } from "../../../lib/components/buttons/IconButton";
import { JobPositionDetailsPopup } from "./popups/JobPositionDetailsPopup";
import { JobPositionsService } from "../services/JobPositionsService";
import { Notification } from "../../../lib/components/notifications/Notification";
import { PositionSummaryDTO } from "../models/dtos/PositionSummaryDTO";
import { PositionUpdateStateDTO } from "../models/dtos/PositionUpdateStateDTO";
import { ResponsiveDataTable } from "../../../lib/components/table/ResponsiveDataTable";
import { ScalableIconButton } from "../../../lib/components/buttons/ScalableIconButton";
import { Toggle } from "../../../lib/components/form/toggle/Toggle";
import { WarningPopup } from "../../../lib/components/popup/WarningPopup";
import { translate } from "../../../lib/infrastructure/i18n/InternationalizationService";
import { useServiceCallPro } from "../../../lib/hooks/useServiceCall";
import { FullScreenLoader } from "../../../lib/components/loader/FullScreenLoader";
import { ConditionalRender } from "../../../lib/functional/ConditionalRender";
import { useGlobalBusinessLine } from "../../../lib/infrastructure/business-line/BusinessLineServices";

const jobPositionsSvc = new JobPositionsService();

export interface IJobPositionsProps {
    roleId: string;
    locationId: string;
    businessLineId: string;
    loadingTopPerms: boolean;
    hasTopPerms: boolean;
    isRoleDisabled: boolean;
}



export function JobPositions(props: IJobPositionsProps) {
    const closePopup = useClosePopup();
    const openPopup = usePopup();
    const createNotification = useCreateNotification();
    const useSelectedBusinessLine = useGlobalBusinessLine();

    const getJobPositionsCall = useServiceCallPro(jobPositionsSvc.getJobPositions);
    const updateJobPositionStateCall = useServiceCallPro(jobPositionsSvc.updateJobPositionState);

    const [jobPositions, setJobPositions] = useState<PositionSummaryDTO[]>([]);


    /****************************
    * DATA REQUESTS
    *****************************/
    const getJobPositions = useCallback(() => {
        if (!props.locationId || !props.businessLineId || !props.roleId) {
            console.log("## Cannot GET JobPositions based on invalid values ##", {
                "Location_ID": props.locationId,
                "BL_ID": props.businessLineId,
                "Role_ID": props.roleId
            });
            return;
        }

        getJobPositionsCall
            .invoke(props.locationId, props.businessLineId, props.roleId)
            .then((res) => {
                setJobPositions(res?.positions || []);
            })
            .catch((error) => {
                if (!error) return;
                openPopup(<ErrorPopup>{error.response.data.message}</ErrorPopup>);
            });
    }, [props.locationId, props.businessLineId, props.roleId, openPopup, setJobPositions, getJobPositionsCall]);



    const updateJobPositionState = useCallback((jobPositionId: number, isDisabled: boolean) => {

        if (!props.locationId || !props.businessLineId || !props.roleId) {
            console.log("## Cannot GET JobPositions based on invalid values ##", {
                "Location_ID": props.locationId,
                "BL_ID": props.businessLineId,
                "Role_ID": props.roleId
            });
            return;
        }


        updateJobPositionStateCall.invoke(props.locationId, props.businessLineId, props.roleId, jobPositionId, { isDisabled: isDisabled })
            .then((_) => {
                getJobPositions();
                createNotification(
                    <Notification
                        type="success"
                        title={translate("COMMON.SYSTEMPOPUPS.Success")}
                        text={translate(
                            isDisabled ? "BUSINESSLINES.ROLES.JOBPOSITIONS.POPUP.DeactivateJobPositionSuccess"
                                : "BUSINESSLINES.ROLES.JOBPOSITIONS.POPUP.ActivateJobPositionSuccess")}
                    />);
            })
            .catch((error) => {
                if (!error) return;
                openPopup(<ErrorPopup>{error.response.data.message}</ErrorPopup>)
            });
    },
        [props.locationId, props.businessLineId, props.roleId, updateJobPositionStateCall, getJobPositions, createNotification, openPopup]);


    /****************************
     * DATA MANIPULATION EFFECTS
     *****************************/
    useEffect(() => {
        getJobPositions();
    }, []);



    /****************************
     * USER ACTIONS
     *****************************/


    /****************************
     * CSS & HTML
     *****************************/


    const columns: ColumnDefinition<PositionSummaryDTO>[] = useMemo(
        () => [
            {
                cellRenderProp: (v) => v.name,
                headerRender: translate("BUSINESSLINES.ROLES.JOBPOSITIONS.INFOANDFORM.Name"),
                isMobilePrimaryCell: true,
            },
            {
                cellRenderProp: (v) => (
                    <div className="toggle-div">
                        {useSelectedBusinessLine && !useSelectedBusinessLine.isDisabled ?

                            <ConditionalRender if={!props.isRoleDisabled}>
                                <Toggle value={!v.isDisabled} />
                                <div
                                    className="toggle-div-overlay"
                                    onClick={(ev) => {
                                        ev.stopPropagation();
                                        if (!v.isDisabled) {
                                            openPopup(
                                                <WarningPopup
                                                    className="disable-job-position-popup"
                                                    onDismissClicked={() => closePopup()}
                                                    onConfirmClicked={() => updateJobPositionState(v.rolePositionId, true)}
                                                    dismissText={translate("COMMON.Cancel")}
                                                    confirmText={translate("BUSINESSLINES.ROLES.JOBPOSITIONS.POPUP.Deactivate")}
                                                >
                                                    {translate("BUSINESSLINES.ROLES.JOBPOSITIONS.POPUP.DeactivateJobPositionConfirmText")}
                                                </WarningPopup>
                                            );
                                        }
                                        else {
                                            if (v.rolePositionId) {
                                                openPopup(
                                                    <WarningPopup
                                                        onDismissClicked={() => closePopup()}
                                                        onConfirmClicked={() => updateJobPositionState(v.rolePositionId, false)}
                                                        dismissText={translate("COMMON.Cancel")}
                                                        confirmText={translate("BUSINESSLINES.ROLES.JOBPOSITIONS.POPUP.Activate")}
                                                    >
                                                        {translate("BUSINESSLINES.ROLES.JOBPOSITIONS.POPUP.ActivateJobPositionConfirmText")}
                                                    </WarningPopup>
                                                );
                                            }
                                        }
                                    }}
                                />
                            </ConditionalRender> : null}
                    </div>
                ),
                headerRender: <></>,
                columnKey: "status",
                width: "5%",
                isSortable: false,
                isMobilePrimaryCell: true,
            },
            {
                cellRenderProp: (v) => {
                    if (useSelectedBusinessLine && useSelectedBusinessLine.isDisabled) return null;
                    if (props.locationId && props.businessLineId && props.roleId && !props.loadingTopPerms && props.hasTopPerms && !props.isRoleDisabled)
                        return (
                            <ScalableIconButton
                                icon={<EditIcon />}
                                onClick={() =>
                                    openPopup(
                                        <EditJobPositionPopup
                                            jobPositionId={v.rolePositionId}
                                            roleId={props.roleId}
                                            businessLineId={props.businessLineId}
                                            locationId={props.locationId}
                                            onCompleted={() => getJobPositions()}
                                        />
                                    )
                                }
                                size={24}
                            />)
                    return null;
                },
                columnKey: "edit",
                width: "24px",
                isMobileHeaderIcon: true,
            },
        ], [useSelectedBusinessLine, props.isRoleDisabled, openPopup, props.locationId, props.businessLineId, props.roleId, props.loadingTopPerms, props.hasTopPerms, closePopup, updateJobPositionState, getJobPositions]);


    return (
        <div className="job-positions">
            <div className="job-positions-header">
                <div className="subtitle">
                    {translate("BUSINESSLINES.ROLES.JOBPOSITIONS.JobPositions")}
                </div>
                <div className="job-positions-header-actions">
                    {useSelectedBusinessLine && useSelectedBusinessLine?.isDisabled ? null : (
                        <ConditionalRender if={!props.isRoleDisabled}>
                            <IconButton
                                icon={<AddIcon />}
                                type="secondary"
                                onClick={() =>
                                    props.locationId && props.businessLineId && props.roleId ?
                                        openPopup(
                                            <AddJobPositionPopup
                                                locationId={props.locationId}
                                                businessLineId={props.businessLineId}
                                                roleId={props.roleId}
                                                onCompletedOperations={() => getJobPositions()} />)
                                        : null}
                            />
                        </ConditionalRender>)}
                </div>
            </div>

            {updateJobPositionStateCall.isLoading && <FullScreenLoader />}


            <ResponsiveDataTable
                items={jobPositions || []}
                columnDefinitions={columns}
                totalitems={1}
                isLoading={getJobPositionsCall.isLoading}
                onClickRow={(j) => openPopup(<JobPositionDetailsPopup
                    businessLineId={props.businessLineId}
                    positionId={j.rolePositionId}
                    roleId={props.roleId}
                />)}
            />
        </div>)
}
