import './EmployeeCertificatesPopup.css';

import { useCallback, useEffect, useMemo, useState } from 'react';

import { CardContainer } from '../../../../lib/layouts/containers/card/CardContainer';
import { ColumnDefinition } from '../../../../lib/components/table/TableInterfaces';
import { DateTimeHelper } from '../../../../lib/helpers/datetime/DateTime';
import { EmployeeCertificationStateTranslationTerms } from '../../../../models/enums/EmployeeCertificationState';
import { EmployeeCertificationsService } from '../../../employees/services/EmployeeCertificationsService';
import { ErrorPopup } from '../../../../lib/components/popup/ErrorPopup';
import { EvaluationCertificateDTO } from '../../models/EvaluationCertificateDTO';
import { InfoBlock } from '../../../../lib/components/info-block/InfoBlock';
import { PopupContainer } from "../../../../lib/layouts/containers/popup-container/PopupContainer";
import { PopupContent } from "../../../../lib/layouts/containers/popup-content/PopupContent";
import { PopupHeader } from "../../../../lib/layouts/containers/popup-header/PopupHeader";
import { ResponsiveDataTable } from '../../../../lib/components/table/ResponsiveDataTable';
import { Spacer } from '../../../../lib/components/separator/Spacer';
import { Tag } from '../../../../lib/components/tag/Tag';
import { getEmployeeCertificateStateCss } from '../../../../common/helpers/getEmployeeCertificateStateCss';
import { translate } from "../../../../lib/infrastructure/i18n/InternationalizationService";
import { usePopup } from '../../../../lib/infrastructure/ui/UIServices';
import { useServiceCallPro } from '../../../../lib/hooks/useServiceCall';

var employeeCertificationsSvc = new EmployeeCertificationsService();

export interface EmployeeCertificatesPopupProps {
    employeeValmetID: string;
    locationId: string;
}


export function EmployeeCertificatesPopup(props: EmployeeCertificatesPopupProps) {

    const openPopup = usePopup();
    const getEmployeeCertificationsCall = useServiceCallPro(employeeCertificationsSvc.getEmployeeCertifications);

    const [itemsPerPage, setItemsPerPage] = useState(3);
    const [currentPage, setCurrentPage] = useState(0);


    const [totalItems, setTotalItems] = useState<number>();
    const [employeeCertificates, setEmployeeCertificates] = useState<EvaluationCertificateDTO[]>([]);



    /****************************
      * DATA REQUESTS
      *****************************/

    const getEmployeeCertifications = useCallback(() => {

        if (!props.employeeValmetID) return;

        getEmployeeCertificationsCall.invoke(props.locationId, props.employeeValmetID, { page: currentPage, pageLength: itemsPerPage }, undefined)
            .then((response) => {

                if (!response) return;

                const mappedCertifications = response?.employeeCertifications.map((c) => {

                    var cert: EvaluationCertificateDTO = {
                        id: c.employeeCertificationId,
                        certificateName: c.certificationName,
                        startDate: c.startDate ?? null,
                        endDate: c.endDate ?? null,
                        state: c.state
                    };

                    return cert;
                });

                setTotalItems(response.totalItems);
                setEmployeeCertificates(mappedCertifications || []);

            })
            .catch((error) => {
                if (!error) return;
                openPopup(<ErrorPopup>{error.response.data.message}</ErrorPopup>);
            });

    }, [currentPage,
        itemsPerPage,
        setTotalItems,
        setEmployeeCertificates,
        props.employeeValmetID,
        props.locationId,
        getEmployeeCertificationsCall.invoke,
        openPopup]);





    /****************************
     * DATA MANIPULATION EFFECTS
     *****************************/

    useEffect(() => {
        getEmployeeCertifications();
    }, [currentPage, itemsPerPage]);






    /****************************
     * USER ACTIONS
     *****************************/

    const handleOnPageAndItemsClicked = useCallback((items: number, currentPage: number) => {

        setItemsPerPage(items);
        setCurrentPage(currentPage);

    }, [setItemsPerPage, setCurrentPage]);





    /****************************
     * CSS & HTML
     *****************************/

    const columns: ColumnDefinition<EvaluationCertificateDTO>[] = useMemo(() => [
        {
            cellRenderProp: (item) => item.certificateName,
            headerRender: translate("BUSINESSLINES.EVALUATIONS.INFOANDFORM.CertificateName"),
            isMobilePrimaryCell: true,
        },
        {
            cellRenderProp: (item) => item.startDate ? DateTimeHelper.formatDate(item.startDate) : "-",
            headerRender: translate("BUSINESSLINES.EVALUATIONS.INFOANDFORM.CertificateInitialDate"),
            width: "120px",
        },
        {
            cellRenderProp: (item) => item.endDate ? DateTimeHelper.formatDate(item.endDate) : "-",
            headerRender: translate("BUSINESSLINES.EVALUATIONS.INFOANDFORM.CertificateConclusionDate"),
            width: "120px",
        },
        {
            cellRenderProp: (v) => (
                <Tag
                    text={translate(EmployeeCertificationStateTranslationTerms[v.state])}
                    backgroundColor={getEmployeeCertificateStateCss(v.state)}
                    isTiny
                />
            ),
            headerRender: translate("COMMON.Status"),
            width: "100px",
            isStatusCell: true
        },
    ], []);





    return (
        <PopupContainer className="popup-employee-certificates">

            <PopupHeader title={translate("BUSINESSLINES.EVALUATIONS.INFOANDFORM.Certificates")} />

            <CardContainer >
                <InfoBlock label='Valmet ID' value={props.employeeValmetID ?? "-"} />
            </CardContainer>

            <Spacer mode='vertical' px={20} />

            <PopupContent >
                <ResponsiveDataTable
                    columnDefinitions={columns}
                    items={employeeCertificates || []}
                    totalitems={totalItems || 0}
                    isLoading={getEmployeeCertificationsCall.isLoading}
                    currentpage={currentPage}
                    paginationOptions={{ itemsPerPage: itemsPerPage, itemsPerPageOptions: [3, 5] }}
                    onPageAndItemsChanged={handleOnPageAndItemsClicked}
                />
            </PopupContent>
        </PopupContainer>)
}