import "./SkillsCatEvaluationsCard.css"

import { ReactComponent as AddRoundIcon } from "../../../../lib/assets/icons/add-round-v2.svg";
import { ColumnDefinition } from "../../../../lib/components/table/TableInterfaces";
import { EvaluationInput } from "../../../matrices/components/EvaluationInputV2";
import { ReactComponent as InfoIcon } from "../../../../lib/assets/icons/info.svg";
import { NextRoleDetailsDTO } from "../../models/NextRoleDetailsDTO";
import { ReactComponent as RemoveIcon } from "../../../../lib/assets/icons/reject-dark.svg";
import { ResponsiveDataTable } from "../../../../lib/components/table/ResponsiveDataTable";
import { ScalableIconButton } from "../../../../lib/components/buttons/ScalableIconButton";
import { TextButton } from "../../../../lib/components/buttons/TextButton";
import { TitledCardContainer } from "../../../../lib/layouts/containers/card/TitledCardContainer";
import { Tooltip } from "../../../../lib/components/tooltip/Tooltip";
import { translate } from "../../../../lib/infrastructure/i18n/InternationalizationService";
import { useCallback } from "react";

export interface IFieldWrapperProps {
    children: React.ReactNode;
    value: string | number | undefined;
    isLocked: boolean;
}

export function FieldWrapper(props: IFieldWrapperProps) {
    if (props.isLocked) {
        return <>{props.value}</>
    }
    return <>{props.children}</>
}



export interface EvaluationCategory {
    name: string;
    skills: EvaluationSkill[];
    isOptional: boolean;
}

export interface EvaluationSkill {
    name: string;
    targetEvaluationMatrixEntryId: number;
    competenceLevel: number | null;
    value: string | undefined;
    isValid: boolean;
    skillId: number;
    description?: string;
}

interface ISkillsCatEvaluationsCardProps {
    businessLineId?: string;
    roleName: string;
    isEditionModeEnabled: boolean;
    values: EvaluationCategory[]
    OnEvaluationValueChanged: (values: EvaluationCategory[]) => void;
    nextRoleId?: number;
    nextRoleDetails?: NextRoleDetailsDTO
    OnSuggestNextRoleClicked?: () => void
    OnRemoveSuggestedRole: () => void
}



export function SkillsCatEvaluationsCard(props: ISkillsCatEvaluationsCardProps) {



    /****************************
     * DATA MANIPULATION EFFECTS
     *****************************/


    /****************************
     * USER ACTIONS
     *****************************/
    const handleInputChange = useCallback((value: string, isValid: boolean, targetSkill: EvaluationSkill) => {
        const updatedSkillsEvaluations = props.values.map(category => {

            const updatedSkills = category.skills.map((skill) => {
                if (skill.targetEvaluationMatrixEntryId === targetSkill.targetEvaluationMatrixEntryId) {
                    const newSkillValue: EvaluationSkill = {
                        ...skill,
                        value: value,
                        isValid: isValid
                    };
                    return newSkillValue;
                }
                return skill;
            });

            const updatedCatgory: EvaluationCategory = {
                ...category,
                skills: updatedSkills
            }
            return updatedCatgory;

        });

        props.OnEvaluationValueChanged(updatedSkillsEvaluations)
    }, [props.OnEvaluationValueChanged, props.values]);


    const handleRemoveRoleSuggestion = useCallback(() => {
        props.OnRemoveSuggestedRole()
    }, [props.OnRemoveSuggestedRole]);


    /****************************
     * CSS & HTML
     *****************************/
    const getColumns = useCallback((showTextBtn: boolean): ColumnDefinition<EvaluationSkill>[] => [
        {
            cellRenderProp: (skill) => {
                return (
                    <div className="skill-summary">
                        <div className="skill-name">{skill.name}</div>
                        {skill.description &&
                            <Tooltip text={skill.description}>
                                <InfoIcon />
                            </Tooltip>}
                    </div>)
            },
            headerRender: translate('BUSINESSLINES.MATRIX.INFOANDFORM.Skill'),
            isMobilePrimaryCell: true,
        },
        {
            cellRenderProp: (skill) => {
                return (
                    <FieldWrapper value={skill.value} isLocked={!props.isEditionModeEnabled} key={skill.targetEvaluationMatrixEntryId}>
                        <EvaluationInput
                            initialValue={skill.value}
                            onChange={(value, isValid) => handleInputChange(value, isValid, skill)}
                        />
                    </FieldWrapper>
                );
            },
            headerRender: translate("BUSINESSLINES.EVALUATIONS.INFOANDFORM.Evaluation"),
            width: "100px"
        },
        {
            cellRenderProp: (skill) => props.businessLineId ? skill.competenceLevel : null,
            headerRender: props.businessLineId ? props.roleName : <></>,
            width: props.businessLineId ? "100px" : "0px"
        },
        {
            cellRenderProp: (skill) => props.businessLineId &&
                <div className="next-role-column">{props.nextRoleDetails && props.nextRoleDetails.skills.find(level => level.skillId === skill.skillId)?.competenceLevel}</div>,

            headerRender: props.businessLineId ? (!props.nextRoleId && props.isEditionModeEnabled && showTextBtn ?
                <TextButton
                    text={translate("BUSINESSLINES.EVALUATIONS.INFOANDFORM.SuggestNextRole")}
                    type="primary"
                    icon={<AddRoundIcon />} iconBefore
                    onClick={props.OnSuggestNextRoleClicked}
                    isDisabled={false}
                /> :
                <div className="next-role-column">
                    {props.nextRoleId && props.isEditionModeEnabled && showTextBtn && <Tooltip text={translate("BUSINESSLINES.EVALUATIONS.INFOANDFORM.RemoveSuggestedNextRole")}>
                        <ScalableIconButton icon={<RemoveIcon />} size={16} onClick={handleRemoveRoleSuggestion} />
                    </Tooltip>}
                    {props.nextRoleDetails && props.nextRoleDetails.roleDescription}
                </div>) : <></>,
            width: props.businessLineId ? "12%" : "0%",
        },
    ], [props.businessLineId, handleInputChange, props.isEditionModeEnabled, props.OnSuggestNextRoleClicked, props.nextRoleDetails, props.nextRoleId, props.roleName, handleRemoveRoleSuggestion]);


    return <>
        {props.values
            .sort((a, b) => {
                return a.isOptional === b.isOptional ? 0 : a.isOptional ? 1 : -1;
            })
            .map((category, index) => (
                <TitledCardContainer key={category.name} title={category.name + (category.isOptional ? " (" + translate("GLOBALADMIN.LOCATIONS.SKILLS.INFOANDFORM.Multivalences") + ")" : "")} className="skills-cat-evaluations-card" isCollapsible={category.isOptional}>
                    {/* <div className="card-header">
                    <div className="subtitle">{category.name}</div>
                </div> */}
                    <ResponsiveDataTable
                        columnDefinitions={getColumns(index === 0)}
                        items={category.skills}
                        totalitems={category.skills.length}
                    />
                </TitledCardContainer>
            ))}
    </>
}