import "./CertificatesEmployeeEvaluationCard.css";

import { ReactComponent as ArrowRightIcon } from "../../../../../lib/assets/icons/arrowRight.svg";
import { ColumnDefinition } from "../../../../../lib/components/table/TableInterfaces";
import { DateTimeHelper } from "../../../../../lib/helpers/datetime/DateTime";
import { EmployeeCertificatesPopup } from "../../../../evaluations/pages/popups/EmployeeCertificatesPopup";
import { EmployeeCertificationStateTranslationTerms } from "../../../../../models/enums/EmployeeCertificationState";
import { EvaluationCertificateDTO } from "../../../../evaluations/models/EvaluationCertificateDTO";
import { ResponsiveDataTable } from "../../../../../lib/components/table/ResponsiveDataTable";
import { Tag } from "../../../../../lib/components/tag/Tag";
import { TextButton } from "../../../../../lib/components/buttons/TextButton";
import { TitledCardContainer } from "../../../../../lib/layouts/containers/card/TitledCardContainer";
import { getEmployeeCertificateStateCss } from "../../../../../common/helpers/getEmployeeCertificateStateCss";
import { translate } from "../../../../../lib/infrastructure/i18n/InternationalizationService";
import { useMemo } from "react";
import { usePopup } from "../../../../../lib/infrastructure/ui/UIServices";

interface ICertificatesEvaluationCardProps {
    certificates: EvaluationCertificateDTO[];
    employeeValmetId: string;
    locationId: string;
    isLoading: boolean;
}


export function CertificatesEmployeeEvaluationCard(props: ICertificatesEvaluationCardProps) {

    const openPopup = usePopup();


    /****************************
     * CSS & HTML
     *****************************/
    const columns: ColumnDefinition<EvaluationCertificateDTO>[] = useMemo(() => [
        {
            cellRenderProp: (item) => item.certificateName,
            headerRender: translate("BUSINESSLINES.EVALUATIONS.INFOANDFORM.CertificateName"),
            isMobilePrimaryCell: true,
        },
        {
            cellRenderProp: (item) => item.startDate ? DateTimeHelper.formatDate(item.startDate) : "-",
            headerRender: translate("BUSINESSLINES.EVALUATIONS.INFOANDFORM.CertificateInitialDate"),
            width: "120px",
        },
        {
            cellRenderProp: (item) => item.endDate ? DateTimeHelper.formatDate(item.endDate) : "-",
            headerRender: translate("BUSINESSLINES.EVALUATIONS.INFOANDFORM.CertificateConclusionDate"),
            width: "120px",
        },
        {
            cellRenderProp: (v) => (
                <Tag
                    text={translate(EmployeeCertificationStateTranslationTerms[v.state])}
                    backgroundColor={getEmployeeCertificateStateCss(v.state)}
                    isTiny
                />
            ),
            headerRender: translate("COMMON.Status"),
            width: "100px",
            isStatusCell: true
        },
    ], []);

    return (
        <TitledCardContainer
            className="certificates-evaluation-card"
            title={translate("BUSINESSLINES.EVALUATIONS.INFOANDFORM.Certificates")}
            extraTitle={
                props.certificates.length > 0 &&
                <TextButton
                    className="small-copy"
                    text={translate("COMMON.ViewAll")}
                    type="primary"
                    icon={<ArrowRightIcon />}
                    onClick={() => { openPopup(<EmployeeCertificatesPopup locationId={props.locationId} employeeValmetID={props.employeeValmetId} />); }} />
            }
        >
            <ResponsiveDataTable
                hideEmptyMessage
                columnDefinitions={columns}
                items={(props.certificates || []).slice(0, 2)}
                totalitems={props.certificates.length || 0}
                isLoading={props.isLoading}
            />
        </TitledCardContainer>
    );
}
