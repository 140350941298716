import "./CareerLadderTreeNode.css";

import { useCallback, useMemo } from "react";

import { ReactComponent as AddIcon } from "../../../../lib/assets/icons/add-round.svg";
import { CareerLadderStepTreeNodeDTO } from "../../models/dtos/CareerLadderStepTreeNodeDTO";
import { CssClassnameBuilder } from "../../../../lib/utils/CssClassnameBuilder";
import { ReactComponent as DeleteIcon } from "../../../../lib/assets/icons/remover.svg";
import { ScalableIconButton } from "../../../../lib/components/buttons/ScalableIconButton";
import { Spacer } from "../../../../lib/components/separator/Spacer";
import { translate } from "../../../../lib/infrastructure/i18n/InternationalizationService";

interface ICareerLadderTreeNodeProps {
    node: CareerLadderStepTreeNodeDTO;
    handleAddRoleNode: (parentId: number, child: Omit<CareerLadderStepTreeNodeDTO, 'children'>) => void;
    handleRemoveRoleNode: (nodeId: number) => void;
    isRoot: boolean;
    isParentLastChild?: boolean;
    isFirstChild?: boolean;
    isLastChild?: boolean;
    isNextRoleCareerLadder?: boolean;
    handleNextRoleSelected: (roleId: number, nodeId: number) => void;
    selectedNodeId?: number;
}

export function CareerLadderTreeNode(props: ICareerLadderTreeNodeProps) {

    /****************************
     * USER ACTIONS
     *****************************/

    const handleNextRoleSelected = useCallback(() => {
        if (!props.node.roleId) return;
        props.handleNextRoleSelected(props.node.roleId, props.node.careerLadderStepId)
    }, [props.handleNextRoleSelected, props.node.roleId]);


    const handleRemoveRoleNode = useCallback(() => {
        props.handleRemoveRoleNode(props.node.careerLadderStepId)
    }, [props.handleRemoveRoleNode, props.node.careerLadderStepId]);


    const handleAddRoleNode = useCallback(() => {
        if (!props.node.role) return;
        props.handleAddRoleNode(props.node.careerLadderStepId, { careerLadderStepId: Math.random(), roleId: props.node.roleId, role: { roleId: props.node.role.roleId, description: props.node.role.description, jobGrade: props.node.role.jobGrade, isDisabled: props.node.role.isDisabled, numberOfPositions: props.node.role.numberOfPositions }, parentCareerLadderStepId: props.node.parentCareerLadderStepId })
    }, [props.node.roleId]);


    /****************************
     * CSS & HTML
     *****************************/

    const nodeContentCss = useMemo(() => {
        return CssClassnameBuilder.new()
            .add("role-node-content")
            .addConditional(props.isRoot && props.isNextRoleCareerLadder, "next-role-root-node")
            .addConditional(!props.isRoot && props.isNextRoleCareerLadder, "selection-enabled")
            .addConditional(!props.isRoot && props.isNextRoleCareerLadder && props.selectedNodeId === props.node.careerLadderStepId, "selected")
            .build();
    }, [props.isRoot, props.isNextRoleCareerLadder, props.node.careerLadderStepId, props.selectedNodeId]);


    return (
        <div className='role-node'>
            <div className="role-node-wrapper">
                {!props.isRoot && <div className="lines"><div className={"first-line horizontal-line " + (!props.isFirstChild && "vertical-line")}></div><div className={"second-line " + (!props.isLastChild && "vertical-line")}></div></div>}
                <div className='role-node-content-wrapper'>
                    <div className={nodeContentCss} onClick={handleNextRoleSelected}>
                        <div className="role-node-header-info">
                            <div className='small-copy'>{props.node.role?.description}</div>
                            {props.isNextRoleCareerLadder !== true && <ScalableIconButton icon={<DeleteIcon />} size={18} onClick={handleRemoveRoleNode} />}
                        </div>
                        <div className="role-node-additional-info">
                            <div className='small-copy job-grade'>{translate("BUSINESSLINES.CAREER.INFOANDFORM.JobGrade")}</div>
                            <div className='small-copy'>{props.node.role?.jobGrade}</div>
                        </div>
                    </div>
                    {props.isNextRoleCareerLadder !== true ?
                        <div className="add-node-icon"><ScalableIconButton icon={<AddIcon />} size={24} onClick={handleAddRoleNode} /></div>
                        : <Spacer mode="vertical" px={14} />}
                </div>
                <div className="lines"><div className={"first-line " + (props.node.children.length && "horizontal-line")}></div></div>
            </div>
            <div>
                {props.node.children.map((child: CareerLadderStepTreeNodeDTO, index: number) => (
                    <CareerLadderTreeNode
                        key={child.careerLadderStepId}
                        node={child}
                        handleAddRoleNode={props.handleAddRoleNode}
                        handleRemoveRoleNode={props.handleRemoveRoleNode}
                        isRoot={false}
                        isFirstChild={index === 0}
                        isLastChild={index === props.node.children.length - 1}
                        isNextRoleCareerLadder={props.isNextRoleCareerLadder}
                        handleNextRoleSelected={props.handleNextRoleSelected}
                        selectedNodeId={props.selectedNodeId}
                    />
                ))}
            </div>
        </div>
    );
};
