import "./EmployeeProgressionLadder.css";

import { useCallback, useMemo } from "react";
import { useClosePopup, useCreateNotification, usePopup } from "../../../../../../lib/infrastructure/ui/UIServices";

import { DateTimeHelper } from "../../../../../../lib/helpers/datetime/DateTime";
import { ReactComponent as DeleteIcon } from "../../../../../../lib/assets/icons/remover.svg";
import { EditEmployeePositionRecordPopup } from "../../popups/EditEmployeePositionRecordPopup";
import { ReactComponent as EditIcon } from "../../../../../../lib/assets/icons/editar.svg";
import { EmployeePositionRecordDTO } from "../../../../models/dtos/EmployeePositionRecordDTO";
import { EmployeePositionRecordsService } from "../../../../services/EmployeePositionRecordsService";
import { ErrorPopup } from "../../../../../../lib/components/popup/ErrorPopup";
import { Notification } from "../../../../../../lib/components/notifications/Notification";
import { ScalableIconButton } from "../../../../../../lib/components/buttons/ScalableIconButton";
import { Spacer } from "../../../../../../lib/components/separator/Spacer";
import { WarningPopup } from "../../../../../../lib/components/popup/WarningPopup";
import { translate } from "../../../../../../lib/infrastructure/i18n/InternationalizationService";
import { useServiceCallPro } from "../../../../../../lib/hooks/useServiceCall";

interface IEmployeeProgressionLadderProps {
    locationId: string;
    employeeId: string | undefined;
    employeePositionRecords: EmployeePositionRecordDTO[];
    onCompleteOperations: () => void;
    isLoadingPermissions: boolean;
    hasPermission: boolean;
}

var svc = new EmployeePositionRecordsService();


export function EmployeeProgressionLadder(props: IEmployeeProgressionLadderProps) {

    const openPopup = usePopup();
    const closePopup = useClosePopup();
    const createNotification = useCreateNotification();

    const removeEmployeePositionRecordCall = useServiceCallPro(svc.deleteEmployeePositionRecord);



    const removePositionRecord = useCallback((positionRecordId: number) => {
        if (!props.employeeId || !positionRecordId) return;

        removeEmployeePositionRecordCall
            .invoke(props.locationId, "" + props.employeeId, "" + positionRecordId)
            .then(() => {
                props.onCompleteOperations();
                createNotification(
                    <Notification
                        type="success"
                        title={translate("COMMON.SYSTEMPOPUPS.Success")}
                        text={translate("EMPLOYEES.CAREER.POPUPS.RemoveRoleSuccess")}
                    />
                );
            }
            )
            .catch((error) => {
                if (!error) return;
                const errorMessage = error?.response?.data?.message || translate("ERRORS.UnknownError");
                openPopup(<ErrorPopup>{errorMessage}</ErrorPopup>);
            });
    }, [props, removeEmployeePositionRecordCall, createNotification, openPopup]);




    /****************************
     * USER ACTIONS
     *****************************/


    const handleEditPositionRecordClicked = useCallback((positionRecordId: number) => {

        openPopup(<EditEmployeePositionRecordPopup
            locationId={props.locationId}
            employeeId={"" + props.employeeId}
            positionRecordId={positionRecordId}
            onCompletedOperations={props.onCompleteOperations} />);
    }, [openPopup, props.employeeId, props.locationId, props.onCompleteOperations]);




    const handleRemovePositionRecordBtnClicked = useCallback((positionRecordId: number) => {
        openPopup(
            <WarningPopup
                className="cancel-evaluation-period-popup"
                onDismissClicked={() => { closePopup(); }}
                onConfirmClicked={() => { removePositionRecord(positionRecordId); }}
                dismissText={translate("COMMON.Cancel")}
                confirmText={translate("COMMON.Remove")}
            >
                {translate("BUSINESSLINES.CAREER.POPUP.RemovePositionRecordConfirmText")}
            </WarningPopup>
        );
    }, [closePopup, openPopup, removePositionRecord]);



    /****************************
     * CSS & HTML
     *****************************/

    const renderTree = useMemo(() => {
        return props.employeePositionRecords.map((position: EmployeePositionRecordDTO, index: number) =>
            <div key={position.employeePositionRecordId} className='employe-role-node'>
                <div className="role-node-wrapper">
                    {index !== 0 && <div className="lines"><div className="first-line horizontal-line"></div><div className="second-line"></div></div>}
                    <div className='role-node-content-wrapper'>
                        <div className="role-node-content">
                            <div className="role-node-header-info">
                                <div className='small-copy'>{position.roleName ?? "-"}</div>
                                <div className="act-btns">
                                    {props.employeeId && (props.isLoadingPermissions || props.hasPermission) && <ScalableIconButton icon={<DeleteIcon />} size={18} onClick={() => handleRemovePositionRecordBtnClicked(position.employeePositionRecordId)} />}
                                    {props.employeeId && (props.isLoadingPermissions || props.hasPermission) && <ScalableIconButton icon={<EditIcon />} size={18} onClick={() => handleEditPositionRecordClicked(position.employeePositionRecordId)} />}

                                </div> </div>
                            <div className="role-node-additional-info">
                                <div className="role-node-additional-info-block">
                                    <div className='small-copy label'>{translate("EMPLOYEES.CAREER.INFOANDFORM.JobGrade")}</div>
                                    <div className='small-copy'>{position?.jobGrade || "N/A"}</div>
                                </div>
                                <div className="role-node-additional-info-block">
                                    <div className='small-copy label'>{translate("EMPLOYEES.CAREER.INFOANDFORM.StartDate")}</div>
                                    <div className='small-copy'>{DateTimeHelper.formatDateTimeLocale(position.startDate)}</div>
                                </div>
                                <div className="role-node-additional-info-block">
                                    <div className='small-copy label'>{translate("EMPLOYEES.CAREER.INFOANDFORM.EndDate")}</div>
                                    <div className='small-copy'>{DateTimeHelper.formatDateTimeLocale(position.endDate)}</div>
                                </div>
                            </div>
                        </div>
                        <Spacer mode="vertical" px={20} />
                    </div>
                    <div className="lines"><div className={"first-line " + (index !== props.employeePositionRecords.length - 1 && "horizontal-line")}></div></div>
                </div>
            </div>)
    }, [props.employeePositionRecords,
    props.employeeId,
    props.isLoadingPermissions,
    props.hasPermission,
        removePositionRecord,
        handleEditPositionRecordClicked])


    return (
        <div className="employee-career-ladder-tree">
            {renderTree}
        </div>
    );
};
