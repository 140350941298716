import "./MyProgressionLadder.css";

import { DateTimeHelper } from "../../../../../../lib/helpers/datetime/DateTime";
import { EmployeePositionRecordDTO } from "../../../../../employees/models/dtos/EmployeePositionRecordDTO";
import { Spacer } from "../../../../../../lib/components/separator/Spacer";
import { translate } from "../../../../../../lib/infrastructure/i18n/InternationalizationService";
import { useMemo } from "react";

interface IMyProgressionLadderProps {
    employeePositionRecords: EmployeePositionRecordDTO[];
}


export function MyProgressionLadder(props: IMyProgressionLadderProps) {

    /****************************
     * CSS & HTML
     *****************************/

    const renderTree = useMemo(() => {
        return props.employeePositionRecords.map((position: EmployeePositionRecordDTO, index: number) =>
            <div key={position.employeePositionRecordId} className='my-role-node'>
                <div className="role-node-wrapper">
                    {index !== 0 && <div className="lines"><div className="first-line horizontal-line"></div><div className="second-line"></div></div>}
                    <div className='role-node-content-wrapper'>
                        <div className="role-node-content">
                            <div className="role-node-header-info">
                                <div className='small-copy'>{position.roleName ?? "-"}</div>
                            </div>
                            <div className="role-node-additional-info">
                                <div className="role-node-additional-info-block">
                                    <div className='small-copy label'>{translate("EMPLOYEES.CAREER.INFOANDFORM.JobGrade")}</div>
                                    <div className='small-copy'>{position?.jobGrade || "N/A"}</div>
                                </div>
                                <div className="role-node-additional-info-block">
                                    <div className='small-copy label'>{translate("EMPLOYEES.CAREER.INFOANDFORM.StartDate")}</div>
                                    <div className='small-copy'>{DateTimeHelper.formatDateTimeLocale(position.startDate)}</div>
                                </div>
                                <div className="role-node-additional-info-block">
                                    <div className='small-copy label'>{translate("EMPLOYEES.CAREER.INFOANDFORM.EndDate")}</div>
                                    <div className='small-copy'>{DateTimeHelper.formatDateTimeLocale(position.endDate)}</div>
                                </div>
                            </div>
                        </div>
                        <Spacer mode="vertical" px={20} />
                    </div>
                    <div className="lines"><div className={"first-line " + (index !== props.employeePositionRecords.length - 1 && "horizontal-line")}></div></div>
                </div>
            </div>)
    }, [props.employeePositionRecords])


    return (
        <div className="my-career-ladder-tree">
            {renderTree}
        </div>
    );
};
