import "./RoleDetailsPage.css";

import { useCallback, useEffect, useState } from "react";
import { usePopup, useWindowResize } from "../../../lib/infrastructure/ui/UIServices";

import { CardContainer } from "../../../lib/layouts/containers/card/CardContainer";
import { Criterias } from "./Criterias";
import { ErrorPopup } from "../../../lib/components/popup/ErrorPopup";
import { FormSection } from "../../../lib/components/form/section/FormSection";
import { InfoBlock } from "../../../lib/components/info-block/InfoBlock";
import { JobPositions } from "./JobPositions";
import { PageLayout } from "../../../lib/layouts/main-content/PageLayout";
import { PagePermissions } from "../../../lib/infrastructure/authorization/PagePermissions";
import { Permission } from "../../../models/api/gate/Permission";
import { RoleSummary } from "../models/domain/RoleSummary";
import { RolesService } from "../services/RolesService";
import { Tag } from "../../../lib/components/tag/Tag";
import { translate } from "../../../lib/infrastructure/i18n/InternationalizationService";
import { useGlobalLocation } from "../../../lib/infrastructure/location/LocationServices";
import { useHasPermissions } from "../../../lib/infrastructure/authorization/useHasLocationPermissions";
import { useParams } from "react-router-dom";
import { useServiceCallPro } from "../../../lib/hooks/useServiceCall";
import { useUpdatePageData } from "../../../lib/infrastructure/navigation/hooks/useUpdatePageData";

var rolesSvc = new RolesService();

export interface IOrderInfo {
  direction: "ASC" | "DESC" | "NONE";
  columnKey: string;
}

const basePermissions = [Permission.GLOBAL_ADMIN, Permission.LOCATION_ADMIN, Permission.HUMAN_RESOURCES, Permission.LOCATION_MANAGER];
const topPermissions = [Permission.GLOBAL_ADMIN, Permission.LOCATION_ADMIN, Permission.HUMAN_RESOURCES];


export function RoleDetailsPage() {

  const { roleId, id: businessLineId } = useParams();
  const windowResize = useWindowResize();
  const updateRouteDate = useUpdatePageData();
  const openPopup = usePopup();
  const locationID = useGlobalLocation();
  const [roleSummary, setRoleSummary] = useState<RoleSummary>();


  /*****************
   * SERVICE CALLS 
   ****************/
  const { isLoadingPermissions: loadingTopPerms, hasPermission: hasTopPerms } = useHasPermissions(topPermissions);

  const getRoleDetailsCall = useServiceCallPro(rolesSvc.getRoleDetailsById);


  useEffect(() => {
    getRoleSummary();
  }, []);




  const getRoleSummary = useCallback(() => {

    if (!locationID || !businessLineId || !roleId) {
      console.log("## Cannot GET Role_Summary based on invalid values ##", {
        "Location_ID": locationID,
        "BL_ID": businessLineId,
        "Role_ID": roleId
      });
      return;
    }

    getRoleDetailsCall.invoke("" + locationID, businessLineId, roleId)
      .then((data) => {
        if (data) {
          setRoleSummary(data);
          updateRouteDate(["##ROLE##", data.description]);
        }
      })
      .catch((error) => {
        if (error) {
          openPopup(<ErrorPopup>{error.response.data.message}</ErrorPopup>);
        }
      });
  }, [locationID, businessLineId, roleId, getRoleDetailsCall, openPopup, setRoleSummary, updateRouteDate]);




  return (
    <PagePermissions permissions={basePermissions} >
      <PageLayout
        tabTitle={translate("BUSINESSLINES.ROLES.RoleDetails")}
        showBreadcrumb
        pageTitle={translate("BUSINESSLINES.ROLES.RoleDetails")}
        className="role-details"
      >
        <CardContainer className="role-data">
          <div className="header">
            <div className="subtitle">
              {translate("BUSINESSLINES.ROLES.RoleData")}
            </div>
            {roleSummary ?
              <Tag
                text={roleSummary && roleSummary.isDisabled ? translate("COMMON.Disabled") : translate("COMMON.Enabled")}
                backgroundColor={roleSummary.isDisabled ? "status-light-grey" : "status-green"}
                isTiny={windowResize < 1024}
              /> : null}
          </div>
          <FormSection unsetFlexGrow>
            <InfoBlock label={translate("BUSINESSLINES.ROLES.INFOANDFORM.Name")} value={roleSummary?.description ? "" + roleSummary.description : "-"} />
            <InfoBlock label={translate("BUSINESSLINES.ROLES.INFOANDFORM.JobGrade")} value={roleSummary?.jobGrade ? "" + roleSummary.jobGrade : "N/A"} />
          </FormSection>
        </CardContainer>

        <div className="role-data-content">
          <JobPositions
            isRoleDisabled={roleSummary?.isDisabled ?? true}
            locationId={"" + locationID}
            businessLineId={"" + businessLineId}
            roleId={"" + roleId}
            loadingTopPerms={loadingTopPerms}
            hasTopPerms={hasTopPerms} />
          <Criterias
            isRoleDisabled={roleSummary?.isDisabled ?? true}
            locationId={"" + locationID}
            businessLineId={"" + businessLineId}
            roleId={"" + roleId}
            loadingTopPerms={loadingTopPerms}
            hasTopPerms={hasTopPerms} />
        </div>
      </PageLayout>
    </PagePermissions>
  );
}
