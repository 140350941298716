import "./CareerLadderPage.css"

import { useCallback, useEffect, useMemo, useState } from "react";

import { AddCareerLadderNodePopup } from "../popups/AddCareerLadderNodePopup";
import { ReactComponent as AddIcon } from "../../../lib/assets/icons/add.svg";
import { CareerLadderService } from "../services/CareerLadderService";
import { CareerLadderStepTreeNodeDTO } from "../models/dtos/CareerLadderStepTreeNodeDTO";
import { CareerLadderTree } from "../components/tree-node/CareerLadderTree";
import { ErrorPopup } from "../../../lib/components/popup/ErrorPopup";
import { FullScreenLoader } from "../../../lib/components/loader/FullScreenLoader";
import { PageLayout } from "../../../lib/layouts/main-content/PageLayout";
import { PagePermissions } from "../../../lib/infrastructure/authorization/PagePermissions";
import { Permission } from "../../../models/api/gate/Permission";
import { RoundIconButton } from "../../../lib/components/buttons/RoundIconButton";
import { translate } from "../../../lib/infrastructure/i18n/InternationalizationService";
import { useGlobalLocation } from "../../../lib/infrastructure/location/LocationServices";
import { useParams } from "react-router-dom";
import { usePopup } from "../../../lib/infrastructure/ui/UIServices";
import { useServiceCallPro } from "../../../lib/hooks/useServiceCall";

const topPermissions = [Permission.GLOBAL_ADMIN, Permission.LOCATION_ADMIN, Permission.HUMAN_RESOURCES];

const careerLadderSvc = new CareerLadderService();

interface ICareerLadderPageProps { }

export function CareerLadderPage(props: ICareerLadderPageProps) {
    const locationID = useGlobalLocation();
    const locationIDAsString = useMemo(() => "" + locationID, [locationID])

    const { id: businessLineID } = useParams();
    const openPopup = usePopup();

    const [careerLadderNodes, setCareerLadderNodes] = useState<CareerLadderStepTreeNodeDTO[] | undefined>([])

    const getCareerLadderCall = useServiceCallPro(careerLadderSvc.getCareerLadder);


    /****************************
    * DATA REQUESTS
    *****************************/

    const getCareerLadder = useCallback(() => {
        if (!locationIDAsString && !businessLineID) return;

        getCareerLadderCall.invoke(locationIDAsString, "" + businessLineID)
            .then((data) => {
                if (data) {
                    setCareerLadderNodes(data.nodes.map(node => node));
                }
            })
            .catch((error) => {
                if (!error) return;
                openPopup(<ErrorPopup>{error.response.data.message}</ErrorPopup>);
            });
    }, [locationIDAsString, businessLineID, getCareerLadderCall.invoke, openPopup]);


    /****************************
    * DATA MANIPULATION EFFECTS
    *****************************/

    useEffect(() => {
        getCareerLadder();
    }, []);


    /****************************
    * CSS & HTML
    *****************************/

    const renderCareerLadderTree = useMemo(() => {
        if (careerLadderNodes) {
            return <CareerLadderTree careerLadderNodes={careerLadderNodes} locationId={locationIDAsString} businessLineId={"" + businessLineID} onCompleteOperations={getCareerLadder} />
        }
    }, [locationIDAsString, businessLineID, careerLadderNodes, getCareerLadder])


    return <PagePermissions permissions={topPermissions} >
        <PageLayout
            tabTitle={translate("BUSINESSLINES.CAREER.CareerLadder")}
            pageTitle={translate("BUSINESSLINES.CAREER.CareerLadder")}
            className="career-ladder"
            showBreadcrumb
        >
            {getCareerLadderCall.isLoading && <FullScreenLoader />}
            <RoundIconButton
                className="create-career-ladder"
                icon={<AddIcon />}
                onClick={() => openPopup(<AddCareerLadderNodePopup locationId={locationIDAsString} businessLineId={"" + businessLineID} onCompletedOperations={getCareerLadder} />)}
            />
            {renderCareerLadderTree}
        </PageLayout>
    </PagePermissions>;
}