import "./CalibrateEvaluationPopup.css";

import { useCallback } from "react";
import { useClosePopup, useCreateNotification, usePopup } from "../../../../lib/infrastructure/ui/UIServices";

import { CardContainer } from "../../../../lib/layouts/containers/card/CardContainer";
import { ConditionalRender } from "../../../../lib/functional/ConditionalRender";
import { ErrorPopup } from "../../../../lib/components/popup/ErrorPopup";
import { EvaluationDetailsDTO_V2 } from "../../models/EvaluationDetailsDTO_V2";
import { EvaluationState } from "../../models/EvaluationStatus";
import { EvaluationsService } from "../../services/EvaluationsService";
import { FullScreenLoader } from "../../../../lib/components/loader/FullScreenLoader";
import { Notification } from "../../../../lib/components/notifications/Notification";
import { PopupActionButtons } from "../../../../lib/layouts/containers/popup-buttons/PopupActionButtons";
import { PopupContainer } from "../../../../lib/layouts/containers/popup-container/PopupContainer";
import { PopupContent } from "../../../../lib/layouts/containers/popup-content/PopupContent";
import { PopupHeader } from "../../../../lib/layouts/containers/popup-header/PopupHeader";
import { Spacer } from "../../../../lib/components/separator/Spacer";
import { translate } from "../../../../lib/infrastructure/i18n/InternationalizationService";
import { useServiceCallPro } from "../../../../lib/hooks/useServiceCall";
import { Tag } from "../../../../lib/components/tag/Tag";
import { ReactComponent as WarningIcon } from "../../../../lib/assets/icons/warning-icon-v2.svg";

export interface ICalibrateEvaluationPopupProps {
    locationId?: string,
    businessLineID?: string,
    evaluationPeriodID?: string,
    matrixId?: string,
    evaluationId? : string,
    employeeName?: string,
    onCalibrateEvaluation: (freshEvaluation: EvaluationDetailsDTO_V2) => void; 
}

const evaluationsService = new EvaluationsService();


export function CalibrateEvaluationPopup(props: ICalibrateEvaluationPopupProps) {
    const openPopup = usePopup();
    const closePopup = useClosePopup();
    const createNotification = useCreateNotification();



    const updateEvaluationStatusCall = useServiceCallPro(evaluationsService.updateEvaluationStatus);
    
    /****************************
    * DATA REQUESTS
    *****************************/
    const updateEvaluationStatus = useCallback((state: EvaluationState, reason: string) => {
        if (!props.businessLineID ||
            !props.evaluationPeriodID ||
            !props.matrixId ||
            !props.evaluationId) return;


        updateEvaluationStatusCall.invoke(`${props.locationId}`, props.businessLineID, props.evaluationPeriodID, props.matrixId, props.evaluationId, {
            nextStatus: state,
            statusReason: reason
        })
            .then((res) => {
                props.onCalibrateEvaluation(res);
                closePopup();
                createNotification(<Notification
                    type="success"
                    title={translate("COMMON.SYSTEMPOPUPS.Success")}
                    text={translate("BUSINESSLINES.EVALUATIONS.NOTIFICATIONS.CalibrateEvaluationSuccessMessage")}
                />);
            })
            .catch((error) => {
                if (!error) return;
                openPopup(<ErrorPopup>{error.response.data.message}</ErrorPopup>);
            });
    }, [props.businessLineID,
        props.evaluationPeriodID,
        props.matrixId,
        props.evaluationId,
        props.locationId,
        props.onCalibrateEvaluation,
        updateEvaluationStatusCall.invoke,
        createNotification,
        openPopup])



    /****************************
     * DATA MANIPULATION EFFECTS
     *****************************/

    /****************************
     * USER ACTIONS
     *****************************/

    const handleOnClickCalibrate = useCallback(() => {
        if (!props.businessLineID ||
            !props.evaluationPeriodID ||
            !props.matrixId ||
            !props.evaluationId) return;

        updateEvaluationStatus(EvaluationState.CALIBRATED, "")

    }, [props.businessLineID,
        props.evaluationPeriodID,
        props.matrixId,
        props.evaluationId,
        props.locationId,
    ]);


    
    /****************************
     * CSS & HTML
     *****************************/
    
    return (
        <PopupContainer className="popup-calibrate-evaluation">
            <PopupHeader title={translate("BUSINESSLINES.EVALUATIONS.POPUPS.CalibrateEvaluation")} />
            <ConditionalRender if={updateEvaluationStatusCall.isLoading}> <FullScreenLoader/></ConditionalRender>
            <PopupContent>
                <div className="calibrate-evaluation">
                    <CardContainer className="employee-card">
                        <div className="small-copy employee-label">{translate("BUSINESSLINES.EVALUATIONS.INFOANDFORM.Employee")}</div>
                        <div className="employee-value">{props.employeeName}</div>
                    </CardContainer>
                </div>
                <Spacer mode={"vertical"} px="30" />

                <Tag text={translate("BUSINESSLINES.EVALUATIONS.POPUPS.MESSAGES.CalibrateEvaluationWarningMessage")} icon={<WarningIcon />} backgroundColor="status-yellow" />

                <Spacer mode={"vertical"} px="30" />
                <PopupActionButtons
                    buttons={[
                        {
                            text: translate("COMMON.Cancel"),
                            type: "tertiary",
                            onClick: () => closePopup(),
                        },
                        {
                            text: translate("COMMON.Calibrate"),
                            className: "calibrate-btn",
                            type: "primary",
                            onClick: handleOnClickCalibrate,
                        },
                    ]}
                />
            </PopupContent>
        </PopupContainer>
    );
}