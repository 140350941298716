import "./EmployeesContainer.css";

import { SubSidebar, SubSidebarUser } from "../../../lib/components/side-bar/SubSidebar";
import { useCallback, useEffect, useMemo, useState } from "react";
import { usePopup, useWindowResize } from "../../../lib/infrastructure/ui/UIServices";

import { ReactComponent as BonusIcon } from "../../../lib/assets/icons/bonus.svg";
import { ReactComponent as CareerLadderIcon } from "../../../lib/assets/icons/career-ladder.svg";
import { ReactComponent as CertificationsIcon } from "../../../lib/assets/icons/certifications.svg";
import { DotsLoader } from "../../../lib/components/loader/DotsLoader";
import { EmployeesService } from "../../../features/employees/services/EmployeesService";
import { ErrorPopup } from "../../../lib/components/popup/ErrorPopup";
import { ReactComponent as FollowUpItemsIcon } from "../../../lib/assets/icons/follow-up-items.svg";
import { MobileSubSidebar } from "../../../lib/components/side-bar/MobileSubSidebar";
import { ReactComponent as PersonalEvaluationIcon } from "../../../lib/assets/icons/personal-evaluation.svg";
import { translate } from "../../../lib/infrastructure/i18n/InternationalizationService";
import { useGlobalLocation } from "../../../lib/infrastructure/location/LocationServices";
import { useParams } from "react-router-dom";
import { useServiceCallPro } from "../../../lib/hooks/useServiceCall";
import { useUpdatePageData } from "../../../lib/infrastructure/navigation/hooks/useUpdatePageData";

var svc = new EmployeesService();
interface IEmployeesContainerProps {
    children: React.ReactNode;
}

export function EmployeesContainer(props: IEmployeesContainerProps) {
    const windowResize = useWindowResize();
    const openPopup = usePopup();
    const locationID = useGlobalLocation();
    const updateRouteDate = useUpdatePageData();

    const locationIDAsString = useMemo(() => "" + locationID, [locationID])
    const { employeeId } = useParams();

    const getEmployeeSummaryCall = useServiceCallPro(svc.getEmployeeSummary);

    const [employeeSummary, setEmployeeSummary] = useState<SubSidebarUser>();


    /****************************
    * DATA REQUESTS
    *****************************/

    const getEmployeeSummary = useCallback(() => {
        if (!employeeId) return;

        getEmployeeSummaryCall.invoke(locationIDAsString, employeeId)
            .then((data) => {
                if (data) {
                    setEmployeeSummary({
                        name: data.displayName ?? "-",
                        jobTitle: "-"
                    });
                }
            })
            .catch((error) => {
                if (!error) return;
                openPopup(<ErrorPopup>{error.response.data.message}</ErrorPopup>);
            });
    }, [locationIDAsString, employeeId, getEmployeeSummaryCall.invoke, openPopup]);


    /****************************
    * DATA MANIPULATION EFFECTS
    *****************************/

    useEffect(() => {
        getEmployeeSummary();
    }, [employeeId]);

    useEffect(() => {
         if(!employeeId) updateRouteDate(["##EMPLOYEE##", ""]);
         updateRouteDate(["##EMPLOYEE##", employeeSummary?.name || ""]);
    }, [employeeId, employeeSummary]);


    /****************************
     * CSS & HTML
     *****************************/

    const subSideBarButtons = useMemo(() => [
        {
            icon: <CareerLadderIcon />,
            navigateTo: "career",
            name: translate("EMPLOYEES.CAREER.CareerLadder"),
        },
        {
            icon: <PersonalEvaluationIcon />,
            navigateTo: "evaluations",
            name: translate("EMPLOYEES.EVALUATIONS.Evaluations"),
        },
        {
            icon: <BonusIcon />,
            navigateTo: "bonus",
            name: translate("EMPLOYEES.BONUS.Bonus"),
        },
        {
            icon: <CertificationsIcon />,
            navigateTo: "certifications",
            name: translate("EMPLOYEES.CERTIFICATIONS.Certifications"),
        },
        {
            icon: <FollowUpItemsIcon />,
            navigateTo: "items",
            name: translate("EMPLOYEES.ITEMS.FollowUpItems"),
        },
    ], []);


    const renderSubSideBar = useMemo(() => {
        return windowResize > 425 ? <SubSidebar buttons={subSideBarButtons} user={employeeSummary} /> : <MobileSubSidebar buttons={subSideBarButtons} user={employeeSummary} />;
    }, [windowResize, subSideBarButtons, employeeSummary]);


    return (
        <div className="employees-content-container">
            {renderSubSideBar}
            {props.children}
        </div>
    );
}
