import { SkillsCategory } from "../domain/SkillsCategory";
import { SkillsCategorySummaryDTO } from "../dtos/SkillsCategorySummaryDTO";

export function APISkillsCategoryToDomainMapper(apiDto: SkillsCategorySummaryDTO): SkillsCategory {
    return {
        id: apiDto.skillCategoryId,
        categoryName: apiDto.name,
        isDisabled: apiDto.isDisabled,
        numberOfSkills: apiDto.numberOfSkills,
        isOptional: apiDto.isOptional
    }
}