import "./SkillsCatEmployeeEvaluationsCard.css"

import { CardContainer } from "../../../../../lib/layouts/containers/card/CardContainer";
import { ColumnDefinition } from "../../../../../lib/components/table/TableInterfaces";
import { ResponsiveDataTable } from "../../../../../lib/components/table/ResponsiveDataTable";
import { translate } from "../../../../../lib/infrastructure/i18n/InternationalizationService";
import { useCallback } from "react";

export interface IFieldWrapperProps {
    children: React.ReactNode;
    value: string | number | undefined;
    isLocked: boolean;
}

export function FieldWrapper(props: IFieldWrapperProps) {
    if (props.isLocked) {
        return <>{props.value}</>
    }
    return <>{props.children}</>
}



export interface EmployeeEvaluationCategory {
    name: string;
    skills: EmployeeEvaluationSkill[];
}

export interface EmployeeEvaluationSkill {
    name: string;
    targetEvaluationMatrixEntryId: number;
    competenceLevel: number | null;
    value: string | undefined;
    isValid: boolean;
    skillId: number;
}

interface ISkillsCatEmployeeEvaluationsCardProps{
    evaluationId?: string;
    roleName: string;
    values: EmployeeEvaluationCategory[]
}



export function SkillsCatEmployeeEvaluationsCard(props: ISkillsCatEmployeeEvaluationsCardProps) {

    /****************************
     * DATA MANIPULATION EFFECTS
     *****************************/


    /****************************
     * USER ACTIONS
     *****************************/

    /****************************
     * CSS & HTML
     *****************************/
    const getColumns = useCallback((): ColumnDefinition<EmployeeEvaluationSkill>[] => [
        {
            cellRenderProp: (skill) => skill.name,
            headerRender: translate('BUSINESSLINES.MATRIX.INFOANDFORM.Skill'),
            isMobilePrimaryCell: true,
        },
        {
            cellRenderProp: (skill) => skill.value,
            headerRender: translate("BUSINESSLINES.EVALUATIONS.INFOANDFORM.Evaluation"),
            width: "100px"
        },
        {
            cellRenderProp: (skill) => props.evaluationId ? skill.competenceLevel : null,
            headerRender: props.evaluationId ? props.roleName : <></>,
            width: props.evaluationId ? "100px" : "0px"
        }
    ], [props.evaluationId, props.roleName]);


    return <>
        {props.values.map((category, index) => (
            <CardContainer key={category.name} className="skills-cat-evaluations-card">
                <div className="card-header">
                    <div className="subtitle">{category.name}</div>
                </div>
                <ResponsiveDataTable
                    columnDefinitions={getColumns()}
                    items={category.skills}
                    totalitems={category.skills.length}
                />
            </CardContainer>
        ))}
    </>
}