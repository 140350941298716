import "./EvaluationCommentsPopup.css";
import { useCallback, useEffect, useMemo, useState } from "react";
import { DateTime } from "luxon";
import { useClosePopup, useCreateNotification, usePopup } from "../../../../lib/infrastructure/ui/UIServices";
import { useFormControl } from "../../../../lib/components/form/Form";
import { validateNullStrings } from "../../../../common/validators/ValidateNullStrings";
import { useServiceCallPro2 } from "../../../../lib/hooks/useServiceCall";
import { ErrorPopup } from "../../../../lib/components/popup/ErrorPopup";
import { Notification } from "../../../../lib/components/notifications/Notification";
import { PopupContainer } from "../../../../lib/layouts/containers/popup-container/PopupContainer";
import { FullScreenLoader } from "../../../../lib/components/loader/FullScreenLoader";
import { PopupHeader } from "../../../../lib/layouts/containers/popup-header/PopupHeader";
import { translate } from "../../../../lib/infrastructure/i18n/InternationalizationService";
import { PopupContent } from "../../../../lib/layouts/containers/popup-content/PopupContent";
import { FormContainer } from "../../../../lib/layouts/containers/form/FormContainer";
import { FormFieldTextArea } from "../../../../lib/components/form/form-field/FormFieldTextArea";
import { Spacer } from "../../../../lib/components/separator/Spacer";
import { PopupActionButtons } from "../../../../lib/layouts/containers/popup-buttons/PopupActionButtons";
import { CommentInfo, EvaluationComment } from "../components/EvaluationComment";
import { EvaluationsService } from "../../services/EvaluationsService";


export interface IEvaluationCommentsPopupProps {
    locationId: string;
    businessLineId: string;
    periodId: string;
    matrixId: string;
    evaluationId: string;
    isEvaluationStateClosed: boolean;
}

var evaluationsSvc = new EvaluationsService()

export function EvaluationCommentsPopup(props: IEvaluationCommentsPopupProps) {
    const openPopup = usePopup();
    const closePopup = useClosePopup();
    const createNotification = useCreateNotification();


    const [comments, setComments] = useState<CommentInfo[]>();

    const commentFormControl = useFormControl<string>({
        validators: [validateNullStrings()],
        enableAutoValidate: true,
    });

    const [getItemCommentsCall, getItemCommentsCallIsLoading] = useServiceCallPro2(evaluationsSvc, evaluationsSvc.getEvaluationComments);
    const [createItemCommentCall, createItemCommentCallIsLoading] = useServiceCallPro2(evaluationsSvc, evaluationsSvc.createEvaluationComment);



    useEffect(() => {
        if (!props.locationId || !props.businessLineId || !props.periodId || !props.matrixId || !props.evaluationId) return;

        getItemCommentsCall(props.locationId, props.businessLineId, props.periodId, props.matrixId, props.evaluationId)
            .then((r) => {

                var mappedComments: CommentInfo[] = r.comments.map((c) => ({
                    text: c.comment,
                    createdBy: c.createdBy?.userDisplayName ?? "-",
                    createdDate: c.createdDate ? DateTime.fromISO(c.createdDate).toFormat("dd/MM/yyyy HH'h'mm") : ""
                }));

                setComments(mappedComments);

            })
            .catch((error) => {
                if (!error) return;
                openPopup(<ErrorPopup>{error.response.data.message}</ErrorPopup>);
            })
    }, [props.locationId, props.businessLineId, props.periodId, props.matrixId, props.evaluationId]);



    const handleAddCommentBtnClicked = useCallback(() => {

        if (!commentFormControl.validate()) return;

        if (!props.locationId || !props.businessLineId || !props.periodId || !props.matrixId || !props.evaluationId) return;

        createItemCommentCall(props.locationId, props.businessLineId, props.periodId, props.matrixId, props.evaluationId, { comment: commentFormControl.value ?? "" })
            .then((_) => {

                closePopup();
                createNotification(<Notification
                    type="success"
                    title={translate("COMMON.SYSTEMPOPUPS.Success")}
                    text={translate("BUSINESSLINES.EVALUATIONS.COMMENTS.MESSAGES.CommentAddedSuccess")}
                ></Notification>
                );
            })
            .catch((error) => {
                if (!error) return;
                openPopup(<ErrorPopup>{error.response.data.message}</ErrorPopup>);
            });

    }, [closePopup, createItemCommentCall, createNotification, openPopup, props.businessLineId,
        props.evaluationId, props.locationId, props.matrixId, props.periodId,
        commentFormControl.validate, commentFormControl.value]);




    const renderComments = useMemo(() => {
        if (comments && comments?.length > 0)
            return <>
                <div className="comments">
                    {comments?.map((comment, idx) => <EvaluationComment key={idx} comment={comment} />)}
                </div>
                <Spacer mode="vertical" px={20} />
            </>
    }, [comments])


    return (
        <PopupContainer className="popup-evaluation-comments">
            {createItemCommentCallIsLoading ? <FullScreenLoader /> : null}
            <PopupHeader
                title={!props.isEvaluationStateClosed ? translate("BUSINESSLINES.EVALUATIONS.COMMENTS.AddComment") : translate("BUSINESSLINES.EVALUATIONS.COMMENTS.ConsultComments")}
            />
            <PopupContent isLoading={getItemCommentsCallIsLoading}>

                {renderComments}
                {!props.isEvaluationStateClosed ?
                    <>
                        <FormContainer>
                            <FormFieldTextArea
                                formControl={commentFormControl}
                                label={translate("BUSINESSLINES.EVALUATIONS.COMMENTS.Comment")}
                                placeholder={translate("BUSINESSLINES.EVALUATIONS.COMMENTS.CommentPlaceholder")}
                            />
                        </FormContainer>
                        <Spacer mode={"vertical"} px="30"></Spacer>
                        <PopupActionButtons
                            buttons={[
                                {
                                    text: translate("COMMON.Cancel"),
                                    type: "tertiary",
                                    onClick: () => closePopup(),
                                },
                                {
                                    text: translate("COMMON.Add"),
                                    type: "primary",
                                    onClick: handleAddCommentBtnClicked
                                },
                            ]}
                        />
                    </> : null}
            </PopupContent>
        </PopupContainer>
    );
}