import { translate } from "../../lib/infrastructure/i18n/InternationalizationService";

export function finalBonusEvaluationPerformance(percentage: number | undefined): string {
    if (percentage === undefined) { return ""; }
    if (percentage < .26) {
        return translate("EMPLOYEES.BONUS.PERFORMANCE.ToImprove");
    } else if (percentage < .51) {
        return translate("EMPLOYEES.BONUS.PERFORMANCE.Reasonable");
    } else if (percentage < .71) {
        return translate("EMPLOYEES.BONUS.PERFORMANCE.Good");
    } else if (percentage < .90) {
        return translate("EMPLOYEES.BONUS.PERFORMANCE.VeryGood");
    }
    return translate("EMPLOYEES.BONUS.PERFORMANCE.Exceptional");
}