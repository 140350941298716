import "./NavBar.css";

import { AccountMenu, IAccountMenuProps } from "../account-menu/AccountMenu";
import {
  ContextualMenu,
  ContextualMenuItems,
} from "../contextual-menu/ContextualMenu";
import {
  InternationalizationProvider,
  LanguageLocale,
} from "../../infrastructure/i18n/InternationalizationProvider";
import {
  InternationalizationService,
  translate,
} from "../../infrastructure/i18n/InternationalizationService";
import { useEffect, useMemo, useState } from "react";

import { ReactComponent as Logo } from "../../assets/logo/valmet-logo-white.svg";
import { useNavigate } from "react-router-dom";
import { ConfigurationProvider } from "../../infrastructure/configuration/ConfigurationProvider";
import { Spacer } from "../separator/Spacer";
import { useGlobalBusinessLine } from "../../infrastructure/business-line/BusinessLineServices";

export interface AccountInfo {
  info: IAccountMenuProps;
  menuItems?: ContextualMenuItems[];
}

interface INavBar {
  additionalOptions?: React.ReactNode;
  accountInfo?: AccountInfo;
  languages?: ContextualMenuItems[];
}

export function NavBar(props: INavBar) {
  const navigate = useNavigate();
  const useSelectedBusinessLine = useGlobalBusinessLine();

  const renderAdditionalOptions = useMemo(() => {
    if (props.additionalOptions) {
      return (
        <div className="additional-options-container">
          <div className="selected-business-line">
            {useSelectedBusinessLine && useSelectedBusinessLine?.name ? useSelectedBusinessLine.name : ""}
          </div>
          <div className="additional-options">{props.additionalOptions}</div>
        </div>
      );
    }
  }, [useSelectedBusinessLine, props.additionalOptions]);


  
  const renderOptions = useMemo(() => {
    if (props.accountInfo || props.languages) {
      return (
        <>
          <div className="options">
            {props.accountInfo ? (
              <ContextualMenu items={props.accountInfo.menuItems || []}>
                <AccountMenu
                  name={props.accountInfo.info.name}
                  email={props.accountInfo.info.email}
                  personaStatus={props.accountInfo.info.personaStatus}
                />
              </ContextualMenu>
            ) : null}
          </div>
          {props.languages ? (
            <div className="language">
              <ContextualMenu items={props.languages}>
                {InternationalizationService.getLocale().split("-")[1]}
              </ContextualMenu>
            </div>
          ) : null}
        </>
      );
    }
  }, [props.accountInfo, props.languages]);

  return (
    <div className="navbar">
      <div className="navbar-logo-project">
        <div className="navbar-logo" onClick={() => navigate("/")}>
          <Logo />
        </div>
        <div className="subtitle project">
          {translate("COMMON.ProjectName") + " "}
          <div className="app-version">{ConfigurationProvider.getConfiguration().App.AppVersion}</div>
        </div>
      </div>
      <div className="navbar-options">
        {renderAdditionalOptions}
        {renderOptions}
      </div>
    </div>
  );
}
