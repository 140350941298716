import { useCallback, useEffect, useMemo, useState } from "react";
import { ImportPopup } from "../../../lib/components/popup/ImportPopup";
import { translate } from "../../../lib/infrastructure/i18n/InternationalizationService";
import { ConfigurationProvider } from "../../../lib/infrastructure/configuration/ConfigurationProvider";
import { ResponsiveButton } from "../../../lib/components/buttons/ResponsiveButton";
import { ReactComponent as ImportIcon } from "../../../lib/assets/icons/import.svg";
import { useCreateNotification, usePopup } from "../../../lib/infrastructure/ui/UIServices";
import { useGlobalLocation } from "../../../lib/infrastructure/location/LocationServices";
import { useParams } from "react-router-dom";
import { useHasPermissions } from "../../../lib/infrastructure/authorization/useHasLocationPermissions";
import { useFormControl } from "../../../lib/components/form/Form";
import { useDebounce } from "../../../lib/hooks/useDebounce";
import { Permission } from "../../../models/api/gate/Permission";
import { useServiceCallPro } from "../../../lib/hooks/useServiceCall";
import { PageLayout } from "../../../lib/layouts/main-content/PageLayout";

const topPermissions = [
    Permission.GLOBAL_ADMIN,
    Permission.LOCATION_ADMIN,
    Permission.HUMAN_RESOURCES
];


export function EvaluationPeriodCollaboratorsPage() {
    const openPopup = usePopup();
    const createNotification = useCreateNotification();
    const locationID = useGlobalLocation();
    const locationIDAsString = useMemo(() => "" + locationID, [locationID])
    const { id: businessLineID, periodId } = useParams();

    const { isLoadingPermissions, hasPermission } = useHasPermissions(topPermissions);

    const [collaborators, setCollaborators] = useState<[]>();
    const [totalCollaborators, setTotalCollaborators] = useState<number>();

    const [itemsPerPage, setItemsPerPage] = useState(10);
    const [currentpage, setCurrentPage] = useState(0);
    const [searchWord, setSearchWord] = useState<string>();

    var searchFormControl = useFormControl<string>({
        isDisabled: false,
        enableAutoValidate: false,
    });

    const debouncedSearchTerm = useDebounce(searchFormControl.value, 1000);

/*     const getAbsencesCall = useServiceCallPro(svc.getAbsences);
    const importAbsencesCall = useServiceCallPro(svc.importAbsences); */


    /****************************
    * DATA REQUESTS
    *****************************/

    /* const getAbsences = useCallback((controller: AbortController | undefined) => {
      if (!locationIDAsString || !businessLineID || !periodId) return;
    
      var queryOptions: QueryOptions = {
        page: currentpage,
        pageLength: itemsPerPage,
        searchWord: searchWord,
      };
    
      getAbsencesCall.invoke(locationIDAsString, businessLineID, periodId, queryOptions, controller?.signal)
        .then((data) => {
          if (data) {
            setAbsences(data.absenteeisms);
            setTotalAbsences(data.totalItems);
          }
        })
        .catch((error) => {
          if (!error || axios.isCancel(error)) return;
          openPopup(<ErrorPopup>{error.response?.data?.message}</ErrorPopup>);
        });
    }, [locationIDAsString, businessLineID, periodId, setAbsences, setTotalAbsences, getAbsencesCall.invoke, openPopup, currentpage, itemsPerPage, searchWord]); */


    const handleFileImport = useCallback((file: File) => {
        /*   if (!locationIDAsString || !businessLineID || !periodId) return;
      
          importAbsencesCall.invoke(locationIDAsString, businessLineID, periodId, file)
              .then((res) => {
                  getAbsences(undefined);
                  if (res && res.invalidAbsenteeismsCount > 0) {
                      openPopup(<ErrorPopup>{translate("BUSINESSLINES.EVALUATIONPERIODS.ABSENCES.POPUPS.InvalidImport")}</ErrorPopup>)
                  }
                  createNotification(
                      <Notification
                          type="success"
                          title={translate("COMMON.SYSTEMPOPUPS.Success")}
                          text={translate("BUSINESSLINES.EVALUATIONPERIODS.ABSENCES.POPUPS.ImportSuccess")}
                      />
                  );
              })
              .catch((error) => {
                  if (!error || axios.isCancel(error)) return;
                  openPopup(<ErrorPopup>{error.response.data.message}</ErrorPopup>);
              }); */

    }, [locationIDAsString, businessLineID, periodId,, openPopup, createNotification]);


    /****************************
    * DATA MANIPULATION EFFECTS
    *****************************/



    useEffect(() => {
        setCurrentPage(0);
        setSearchWord(debouncedSearchTerm);
    }, [debouncedSearchTerm]);


    /****************************
    * USER ACTIONS
    *****************************/

    const handleOnPageAndItemsChanged = useCallback((items: number, currentPage: number) => {
        setItemsPerPage(items);
        setCurrentPage(currentPage);
    }, [setItemsPerPage, setCurrentPage]);


    const handleClickImportBtn = useCallback(() => {
        openPopup(
            <ImportPopup
                warningMessage={translate("BUSINESSLINES.EVALUATIONPERIODS.COLLABORATORS.POPUPS.ImportCollaboratorsConfirmText")}
                onClickDownloadTemplateBtn={() => window.open(ConfigurationProvider.getConfiguration().App.BackendUrl + "/templates/VLMT_Skills_Import_Collaborators_Template_V2.xlsx", "_blank")}
                onClickImportBtn={handleFileImport} />
        );
    }, [handleFileImport, openPopup]);


    /****************************
     * CSS & HTML
    *****************************/

    /* const columns: ColumnDefinition<AbsenceSummaryDTO>[] = useMemo(
        () => [
            {
                cellRenderProp: (v) => <div className="employee-info">
                    <div className="small-copy employee-name">
                        {v.employee.userDisplayName}
                    </div>
                    <div className="caption employee-id">{v.employee.userEmployeeID ?? ""}</div>
                </div>,
                headerRender: translate("BUSINESSLINES.EVALUATIONPERIODS.ABSENCES.INFOANDFORM.EmployeeName"),
                isMobilePrimaryCell: true,
            },
            {
                cellRenderProp: (v) => v.absenceDays,
                headerRender: translate("BUSINESSLINES.EVALUATIONPERIODS.ABSENCES.INFOANDFORM.UnjustifiedAbsences"),
                isMobilePrimaryCell: true,
            },
        ], []);
 */

    const renderPageBtns = useMemo(() => {
        if (!isLoadingPermissions && hasPermission) {
            return <ResponsiveButton
                text={translate("COMMON.Import")}
                type="secondary"
                icon={<ImportIcon />}
                onClick={handleClickImportBtn}
            />
        }
    }, [isLoadingPermissions, hasPermission, handleClickImportBtn]);


    return <PageLayout
        tabTitle={translate("BUSINESSLINES.EVALUATIONPERIODS.ABSENCES.AbsencesMap")}
        showBreadcrumb
        pageTitle={translate("BUSINESSLINES.EVALUATIONPERIODS.ABSENCES.AbsencesMap")}
        className="absences-page"
        actions={renderPageBtns}
    >
        <></>
        {/* {importAbsencesCall.isLoading && <FullScreenLoader />}
        <FormFieldTextInput
            formControl={searchFormControl}
            icon={<SearchIcon />}
            placeholder={translate("COMMON.FORM.SearchPlaceholder")}
            className="search-box"
        />
        <ResponsiveDataTable
            columnDefinitions={columns}
            items={collaborators || []}
            totalitems={totalCollaborators || 0}
            paginationOptions={{ itemsPerPage: itemsPerPage, itemsPerPageOptions: [10, 20, 30] }}
            currentpage={currentpage}
            isLoading={getAbsencesCall.isLoading}
            onPageAndItemsChanged={handleOnPageAndItemsChanged}
        /> */}
    </PageLayout>;
}