import { ConfigurationProvider } from "../../../lib/infrastructure/configuration/ConfigurationProvider";
import { EmployeeCertificationCreateDTO } from "../models/dtos/EmployeeCertificationCreateDTO";
import { EmployeeCertificationState } from "../../../models/enums/EmployeeCertificationState";
import { EmployeeCertificationUpdateDTO } from "../models/dtos/EmployeeCertificationUpdateDTO";
import { EmployeeCertificationsResponseDTO } from "../models/dtos/EmployeeCertificationsResponseDTO";
import { EmployeeCertificationsSummaryDTO } from "../models/dtos/EmployeeCertificationsSummaryDTO";
import { HttpClient } from "../../../lib/infrastructure/http/HttpClient";
import { QueryOptions } from "../../../models/api/QueryOptions";
import { SignURLRequestDTO } from "../models/dtos/SignURLRequestDTO";
import { SignURLResponseDTO } from "../models/dtos/SignURLResponseDTO";
import qs from "qs";

const employeeCertificationsMock: EmployeeCertificationsResponseDTO = {
    employeeCertifications: [{
        employeeCertificationId: 1,
        employeeValmetId: "1",
        certificationName: "Certificação A",
        startDate: "2024-01-01T00:00:00+00:00",
        endDate: "2024-01-01T00:00:00+00:00",
        state: EmployeeCertificationState.REGISTERED,
        createdBy: {
            userDisplayName: "",
            userEmail: "", userId: "", userEmployeeID: ""
        },
        createdOn: "2024-01-01T00:00:00+00:00",
        attachmentFilename: null,
        attachmentId: null,
    }, {
        employeeCertificationId: 2,
        employeeValmetId: "2",
        certificationName: "Certificação B",
        startDate: "2024-01-01T00:00:00+00:00",
        endDate: "2024-01-01T00:00:00+00:00",
        createdBy: {
            userDisplayName: "",
            userEmail: "", userId: "", userEmployeeID: ""
        },
        createdOn: "2024-01-01T00:00:00+00:00",
        state: EmployeeCertificationState.REGISTERED,
        attachmentFilename: null,
        attachmentId: null
    }],
    totalItems: 2
}

const employeeCertificationMock: EmployeeCertificationsSummaryDTO = {
    employeeCertificationId: 1,
    employeeValmetId: "1",
    certificationName: "Certificação A",
    startDate: "2024-01-01T00:00:00+00:00",
    endDate: "2024-01-01T00:00:00+00:00",
    createdBy: {
        userDisplayName: "",
        userEmail: "", userId: "", userEmployeeID: ""
    },
    createdOn: "2024-01-01T00:00:00+00:00",
    state: EmployeeCertificationState.REGISTERED,
    attachmentFilename: null,
    attachmentId: null,

};

const Route = (path: string) => {
    return ConfigurationProvider.getConfiguration().App.BackendUrl + path;
};

export class EmployeeCertificationsService {

    getEmployeeCertifications(locationId: string, employeeId: string, query: QueryOptions | null, abortSignal: AbortSignal | undefined): Promise<EmployeeCertificationsResponseDTO | null> {
        return HttpClient.sessionRequest<EmployeeCertificationsResponseDTO>({
            method: "GET",
            url: Route(`/api/v1/Locations/${locationId}/Employees/${employeeId}/Certifications`),
            signal: abortSignal,
            params: query,
            paramsSerializer: (params) => {
                return qs.stringify(params, { arrayFormat: "repeat" });
            },
        })
            .then((response) => {
                if (!response.data) return null;
                return {
                    employeeCertifications: response.data.employeeCertifications,
                    totalItems: response.data.totalItems
                }
            })
            .catch((error) => {
                throw error;
            });
    }

    getEmployeeCertificationSummaryById(locationId: string, employeeId: string, certificationId: string): Promise<EmployeeCertificationsSummaryDTO | null> {
        return HttpClient.sessionRequest<EmployeeCertificationsSummaryDTO>({
            method: "GET",
            url: Route(`/api/v1/Locations/${locationId}/Employees/${employeeId}/Certifications/${certificationId}`),
        })
            .then((res) => {
                if (!res.data) return null;
                return res.data;
            })
            .catch((error) => {
                throw error;
            });
    }

    addEmployeeCertification(locationId: string, employeeId: string, requestDto: EmployeeCertificationCreateDTO): Promise<EmployeeCertificationsSummaryDTO> {

        return HttpClient.sessionRequest<EmployeeCertificationsSummaryDTO>({
            method: "POST",
            url: Route(`/api/v1/Locations/${locationId}/Employees/${employeeId}/Certifications`),
            data: requestDto,
        })
            .then((response) => response.data)
            .catch((error) => {
                throw error;
            });
    }


    uploadEmployeeCertificationAttachment(locationId: string, employeeId: string, certificationId: string, file: File): Promise<void> {

        var formData = new FormData();
        formData.append("file", file, file.name);

        return HttpClient.sessionRequest({
            method: "POST",
            headers: {
                "Content-Type": "multipart/form-data",
                "Accept": "application/json",
            },
             url: Route(`/api/v1/Locations/${locationId}/Employees/${employeeId}/Certifications/${certificationId}/Attachment`),
            data: formData
        })
            .then((_) => { })
            .catch((error) => {
                throw error;
            });
    }

    editEmployeeCertification(locationId: string, employeeId: string, certificationId: string, requestDto: EmployeeCertificationUpdateDTO): Promise<void> {
        return HttpClient.sessionRequest({
            method: "PUT",
            url: Route(`/api/v1/Locations/${locationId}/Employees/${employeeId}/Certifications/${certificationId}`),
            data: requestDto
        })
            .then((_) => { })
            .catch((error) => {
                throw error;
            });
    }

    deleteEmployeeCertification(locationId: string, employeeId: string, certificationId: string): Promise<void> {
        return HttpClient.sessionRequest({
            method: "DELETE",
            url: Route(`/api/v1/Locations/${locationId}/Employees/${employeeId}/Certifications/${certificationId}`),
        })
            .then((_) => { })
            .catch((error) => {
                throw error;
            });
    }


    getUrlDownloadAttachment(locationId: string, employeeId: string, certificationId: number, attachmentId: number): Promise<string> {
        
        var requestDTO: SignURLRequestDTO = {
            url: Route(`/api/v1/Locations/${locationId}/Employees/${employeeId}/Certifications/${certificationId}/Attachments/${attachmentId}`)
        }

        return HttpClient.sessionRequest<SignURLResponseDTO>({
            method: "POST",
            url: Route(`/api/v1/Locations/${locationId}/Employees/${employeeId}/Certifications/SignURL`),
            data: requestDTO
        })
            .then((res) => {
                return res.data.url;
            })
            .catch((error) => {
                throw error;
            });
    }






























    deleteEmployeeCertificationAttachment(locationId: string, employeeId: string, certificationId: string): Promise<void> {
        return HttpClient.sessionRequest({
            method: "DELETE",
            // url: Route(`/api/v1/Locations/${locationId}/Employees/${employeeId}/Certifications/${certificationId}`),
        })
            .then((_) => { })
            .catch((error) => {
                throw error;
            });
    }


    uploadEmployeeCertificationsFile(locationId: string, employeeId: string, file: File): Promise<void> {
        var formData = new FormData();
        formData.append("file", file)

        return HttpClient.sessionRequest({
            method: "POST",
            headers: {
                "Content-Type": "multipart/form-data",
                "Accept": "application/json",
            },
            url: Route(`/api/v1/Location/${locationId}/Employees/${employeeId}/FromXLS`),
            data: formData
        })
            .then((_) => { })
            .catch((error) => {
                throw error;
            });
    }
}
