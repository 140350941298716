export function finalBonusScoreFromPercentage(percentage: number | undefined): number | undefined {
    if (percentage === undefined) { return undefined; }
    if (percentage < 26) {
        return 1;
    } else if (percentage < 51) {
        return 2;
    } else if (percentage < 71) {
        return 3;
    } else if (percentage < 90) {
        return 4;
    }
    return 5;
}