import './EmployeeEvaluationHistory.css'

import { Avatar } from '../../../../../lib/components/avatar/Avatar';
import { DateTimeHelper } from '../../../../../lib/helpers/datetime/DateTime';
import { EmployeeEvaluationHistoryDetailsDTO } from '../../../models/dtos/EmployeeEvaluationHistoryDetailsDTO';
import { EvaluationOperationType } from '../../../../evaluations/models/EvaluationOperationTypeEnum';
import { Spacer } from '../../../../../lib/components/separator/Spacer';
import { translate } from '../../../../../lib/infrastructure/i18n/InternationalizationService';
import { useCallback } from 'react';

export interface IHistoryLineProps {
    history: EmployeeEvaluationHistoryDetailsDTO[];
}


export function EmployeeEvaluationHistory(props: IHistoryLineProps) {


    /****************************
     * DATA REQUESTS
    *****************************/

    const calculateHistoryLineText = useCallback((historyLine: EmployeeEvaluationHistoryDetailsDTO) => {
        switch (historyLine.operationType) {
            case EvaluationOperationType.Unknown:
                return translate("BUSINESSLINES.EVALUATIONS.HISTORY.HistoryLineForUnknownAction");

            case EvaluationOperationType.SaveEvaluation:
                return translate("BUSINESSLINES.EVALUATIONS.HISTORY.HistoryLineForSaveEvaluation");

            case EvaluationOperationType.ConfirmEvaluation:
                return translate("BUSINESSLINES.EVALUATIONS.HISTORY.HistoryLineForConfirmEvaluation");

            case EvaluationOperationType.CloseEvaluation:
                return translate("BUSINESSLINES.EVALUATIONS.HISTORY.HistoryLineForCloseEvaluation");

            case EvaluationOperationType.CalibrateEvaluation:
                return translate("BUSINESSLINES.EVALUATIONS.HISTORY.HistoryLineForCalibrateEvaluation");

            case EvaluationOperationType.RevertEvaluation:
                return translate("BUSINESSLINES.EVALUATIONS.HISTORY.HistoryLineForRevertEvaluation");

            case EvaluationOperationType.RejectEvaluation:
                return translate("BUSINESSLINES.EVALUATIONS.HISTORY.HistoryLineForRejectEvaluation", historyLine?.rejectionReason ?? "-");

            case EvaluationOperationType.NextRoleRecomendation:
                return translate("BUSINESSLINES.EVALUATIONS.HISTORY.HistoryLineForNextRoleRecomendation");

            case EvaluationOperationType.RemoveNextRoleRecomendation:
                return translate("BUSINESSLINES.EVALUATIONS.HISTORY.HistoryLineForRemoveNextRoleRecomendation");
            default:
                return "";
        }
    }, []);



    /****************************
     * CSS & HTML
    *****************************/

    return <>
        {props.history?.map((historyLine) =>
            <div className="history">
                <Avatar className="history-avatar" name={historyLine.user.userDisplayName} />
                <div className="history-info">
                    <div className="small-copy operated-by">{`${historyLine.user.userDisplayName} ${DateTimeHelper.formatDateTime(historyLine.operationDate)}`}</div>
                    <div className="history-line-text">{calculateHistoryLineText(historyLine)}</div>
                    <div className="separator"></div>
                    <Spacer mode={"vertical"} px="16" />
                </div>
            </div>
        )}</>
}