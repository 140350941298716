import { useCallback, useEffect, useState } from "react";
import { useClosePopup, useCreateNotification, usePopup } from "../../../../../lib/infrastructure/ui/UIServices";

import { AllValid } from "../../../../../common/validators/ValidateFormControls";
import { BusinessLinesService } from "../../services/BusinessLinesService";
import { ErrorPopup } from "../../../../../lib/components/popup/ErrorPopup";
import { FormContainer } from "../../../../../lib/layouts/containers/form/FormContainer";
import { FormFieldTextInput } from "../../../../../lib/components/form/form-field/FormFieldTextInput";
import { FullScreenLoader } from "../../../../../lib/components/loader/FullScreenLoader";
import { Notification } from "../../../../../lib/components/notifications/Notification";
import { PopupActionButtons } from "../../../../../lib/layouts/containers/popup-buttons/PopupActionButtons";
import { PopupContainer } from "../../../../../lib/layouts/containers/popup-container/PopupContainer";
import { PopupContent } from "../../../../../lib/layouts/containers/popup-content/PopupContent";
import { PopupHeader } from "../../../../../lib/layouts/containers/popup-header/PopupHeader";
import { Spacer } from "../../../../../lib/components/separator/Spacer";
import { translate } from "../../../../../lib/infrastructure/i18n/InternationalizationService";
import { trimString } from "../../../../../lib/utils/TrimString";
import { useFormControl } from "../../../../../lib/components/form/Form";
import { useServiceCallPro } from "../../../../../lib/hooks/useServiceCall";
import { validateWhiteSpaceStrings } from "../../../../../lib/validators/ValidateWhiteSpaceStrings";
import { ConditionalRender } from "../../../../../lib/functional/ConditionalRender";
import { MessageBar } from "../../../../../lib/components/message-bar/MessageBar";
import { ExceptionType } from "../../../../../lib/infrastructure/http/ExceptionType";
import { ValidationErrorMessage } from "../../../../../lib/infrastructure/http/APIError";

var businessLinesSvc = new BusinessLinesService();

interface IEditBusinessLinePopupProps {
  businessLineId: number;
  locationId: string;
  onCompleted: () => void;
}

export function EditBusinessLinePopup(props: IEditBusinessLinePopupProps) {
  const closePopup = useClosePopup();
  const openPopup = usePopup();

  const createNotification = useCreateNotification();

  const getBusinessLineByIdCall = useServiceCallPro(businessLinesSvc.getBusinessLineById);
  const updateBusinessLineCall = useServiceCallPro(businessLinesSvc.updateBusinessLineName);

  const nameFormControl = useFormControl<string>({
    validators: [validateWhiteSpaceStrings()],
    enableAutoValidate: true,
  });


  const [validationAlert, setValidationAlert] = useState<ValidationErrorMessage>({ showAlert: false });




  useEffect(() => {
    if (props.locationId && props.businessLineId) {
      getBusinessLineByIdCall
        .invoke(props.locationId, "" + props.businessLineId)
        .then((response) => {
          nameFormControl.setValue(response.name);
        })
        .catch((_) => {
          openPopup(<ErrorPopup>{translate("GLOBALADMIN.LOCATIONS.BUSINESSLINES.POPUP.LoadingBusinessLineError")}</ErrorPopup>);
        });
    }
  }, []);






  const handleEditBusinessLineClicked = useCallback(() => {

    if (!AllValid(nameFormControl.validate())) return;


    const handleSuccess = () => {
      props.onCompleted();
      closePopup();
      createNotification(
        <Notification
          type="success"
          title={translate("COMMON.SYSTEMPOPUPS.Success")}
          text={translate("GLOBALADMIN.LOCATIONS.BUSINESSLINES.POPUP.EditBusinessLineSuccess")}
        />
      );
    };

    const handleError = (error: any) => {
      if (!error) return;

      const errorMessage = error?.response?.data?.message || translate("ERRORS.UnknownError");
      const enumCode = error?.response?.data?.enumCode;

      if (enumCode === ExceptionType.ERROR_VALIDATION_NAME_ALREADY_EXISTS) {
        setValidationAlert({
          showAlert: true,
          message: errorMessage,
        });
      } else {
        openPopup(<ErrorPopup>{errorMessage}</ErrorPopup>);
      }
    };



    updateBusinessLineCall.invoke(props.locationId, "" + props.businessLineId, trimString(nameFormControl.value))
      .then(handleSuccess)
      .catch(handleError);

      

  }, [nameFormControl.value, nameFormControl.validate, closePopup, updateBusinessLineCall.invoke,
    openPopup, props.onCompleted, createNotification, , setValidationAlert,]);





  return (
    <PopupContainer className="popup-edit-business-line">
      {updateBusinessLineCall.isLoading ? <FullScreenLoader /> : null}

      <PopupHeader
        title={translate("GLOBALADMIN.LOCATIONS.BUSINESSLINES.POPUP.EditBusinessLine")}
      />
      <PopupContent isLoading={getBusinessLineByIdCall.isLoading}>
        <FormContainer>


          <FormFieldTextInput
            formControl={nameFormControl}
            label={translate("GLOBALADMIN.LOCATIONS.BUSINESSLINES.INFOANDFORM.Name")}
            placeholder={translate("GLOBALADMIN.LOCATIONS.BUSINESSLINES.INFOANDFORM.NamePlaceholder")}
          />

          <ConditionalRender if={validationAlert.showAlert}>
            <div className="name-already-exists">
              <MessageBar type="error" text={"" + validationAlert.message} />
            </div>
          </ConditionalRender>


        </FormContainer>
        <Spacer mode={"vertical"} px="30" />
        <PopupActionButtons
          buttons={[
            {
              text: translate("COMMON.Cancel"),
              type: "tertiary",
              onClick: () => closePopup(),
            },
            {
              text: translate("COMMON.Save"),
              type: "primary",
              onClick: handleEditBusinessLineClicked,
            },
          ]}
        />
      </PopupContent>
    </PopupContainer>
  );
}
