import "./EmployeeEvaluationsPage.css";

import { useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { usePopup, useWindowResize } from "../../../../lib/infrastructure/ui/UIServices";

import { ColumnDefinition } from "../../../../lib/components/table/TableInterfaces";
import { EmployeeEvaluationSummaryDTO } from "../../models/dtos/EmployeeEvaluationSummaryDTO";
import { EmployeeEvaluationsService } from "../../services/EmployeeEvaluationsService";
import { ErrorPopup } from "../../../../lib/components/popup/ErrorPopup";
import { EvaluationStatusTranslationTerms } from "../../../evaluations/models/EvaluationStatus";
import { PageLayout } from "../../../../lib/layouts/main-content/PageLayout";
import { QueryOptions } from "../../../../models/api/QueryOptions";
import { ResponsiveDataTable } from "../../../../lib/components/table/ResponsiveDataTable";
import { Tag } from "../../../../lib/components/tag/Tag";
import axios from "axios";
import { translate } from "../../../../lib/infrastructure/i18n/InternationalizationService";
import { useGlobalLocation } from "../../../../lib/infrastructure/location/LocationServices";
import { useServiceCallPro } from "../../../../lib/hooks/useServiceCall";

var svc = new EmployeeEvaluationsService();
export interface IOrderInfo {
    direction: "ASC" | "DESC" | "NONE";
    columnKey: string;
}

export function EmployeeEvaluationsPage() {
    const navigate = useNavigate();
    const windowResize = useWindowResize();
    const openPopup = usePopup();

    const { employeeId } = useParams();

    const locationID = useGlobalLocation();
    const locationIDAsString = useMemo(() => "" + locationID, [locationID])

    const [evaluations, setEvaluations] = useState<EmployeeEvaluationSummaryDTO[]>([]);
    const [totalItems, setTotalItems] = useState<number>();

    const [itemsPerPage, setItemsPerPage] = useState(10);
    const [currentpage, setCurrentPage] = useState(0);

    // var searchFormControl = useFormControl<string>({
    //     isDisabled: false,
    //     enableAutoValidate: false,
    // });
    // const [searchWord, setSearchWord] = useState<string>();
    // const debouncedSearchTerm = useDebounce(searchFormControl.value, 1000);

    const getEmployeeEvaluationsCall = useServiceCallPro(svc.getEmployeeEvaluations);

    /****************************
    * DATA REQUESTS
    *****************************/

    const getEmployeeEvaluations = useCallback((controller: AbortController | undefined) => {
        if (!employeeId) return;

        var queryOpts: QueryOptions = {
            //searchWord: searchWord,
            page: currentpage,
            pageLength: itemsPerPage
        };

        getEmployeeEvaluationsCall.invoke(locationIDAsString, employeeId, queryOpts, controller?.signal)
            .then((data) => {
                if (data) {
                    setEvaluations(data.evaluations);
                    setTotalItems(data.totalItems);
                }
            })
            .catch((error) => {
                if (!error || axios.isCancel(error)) return;
                openPopup(<ErrorPopup>{error.response.data.message}</ErrorPopup>);
            });
    }, [locationIDAsString, employeeId, currentpage, itemsPerPage, getEmployeeEvaluationsCall, openPopup]);


    /****************************
     * DATA MANIPULATION EFFECTS
     *****************************/

    useEffect(() => {
        const controller = new AbortController();
        if (!employeeId) return;
        
        getEmployeeEvaluations(controller);
        
        return () => { controller.abort() };
    }, [employeeId, currentpage, itemsPerPage]);


    // useEffect(() => {
    //     setCurrentPage(0);
    //     setSearchWord(debouncedSearchTerm);
    // }, [debouncedSearchTerm]);


    /****************************
     * USER ACTIONS
     *****************************/

    const handleOnPageAndItemsChanged = useCallback((items: number, currentPage: number) => {
        setItemsPerPage(items);
        setCurrentPage(currentPage);
    }, [setItemsPerPage, setCurrentPage]);


    const handleEvaluationPeriodDetailsClicked = useCallback((evaluation: EmployeeEvaluationSummaryDTO) => {
        navigate(`${evaluation.evaluationId}`);
    }, [navigate]);


    /****************************
     * CSS & HTML
     *****************************/

    const columns: ColumnDefinition<EmployeeEvaluationSummaryDTO>[] = useMemo(
        () => [
            {
                cellRenderProp: (v) => v.evaluationPeriodName,
                headerRender: translate("EMPLOYEES.EVALUATIONS.INFOANDFORM.Name"),
                isMobilePrimaryCell: true,
            },
            {
                cellRenderProp: (v) => v.roleName,
                headerRender: translate("EMPLOYEES.INFOANDFORM.Role"),
            },
            {
                cellRenderProp: (v) => (
                    <Tag
                        text={"" + v.evaluationFinalScore}
                        isTiny={windowResize < 1024}
                    />
                ),
                headerRender: translate("EMPLOYEES.EVALUATIONS.INFOANDFORM.Evaluation"),
                width: "110px"
            },
        ], [windowResize]);


    return (
        <PageLayout
            tabTitle={translate("EMPLOYEES.EVALUATIONS.Evaluations")}
            pageTitle={translate("EMPLOYEES.EVALUATIONS.Evaluations")}
            className="employee-evaluations"
            showBreadcrumb
        >
            {/* <FormFieldTextInput
                formControl={searchFormControl}
                icon={<SearchIcon />}
                placeholder={translate("COMMON.FORM.SearchPlaceholder")}
                className="search-box"
            /> */}

            <ResponsiveDataTable
                columnDefinitions={columns}
                items={evaluations || []}
                totalitems={totalItems || 0}
                paginationOptions={{ itemsPerPage: itemsPerPage, itemsPerPageOptions: [10, 20, 30] }}
                currentpage={currentpage}
                isLoading={getEmployeeEvaluationsCall.isLoading}
                onPageAndItemsChanged={handleOnPageAndItemsChanged}
                onClickRow={handleEvaluationPeriodDetailsClicked}
            />
        </PageLayout>
    );
}
