import "./EmployeeProgressionLadderPage.css"

import { useCallback, useEffect, useMemo, useState } from "react";

import { AddEmployeePositionRecordPopup } from "./popups/AddEmployeePositionRecordPopup";
import { ReactComponent as AddIcon } from "../../../../lib/assets/icons/add.svg";
import { EmployeePositionRecordDTO } from "../../models/dtos/EmployeePositionRecordDTO";
import { EmployeePositionRecordsService } from "../../services/EmployeePositionRecordsService";
import { EmployeeProgressionLadder } from "./components/tree-node/EmployeeProgressionLadder";
import { EmptyState } from "../../../../lib/components/emptystates/EmptyState";
import { ErrorPopup } from "../../../../lib/components/popup/ErrorPopup";
import { FullScreenLoader } from "../../../../lib/components/loader/FullScreenLoader";
import { PageLayout } from "../../../../lib/layouts/main-content/PageLayout";
import { PagePermissions } from "../../../../lib/infrastructure/authorization/PagePermissions";
import { Permission } from "../../../../models/api/gate/Permission";
import { RoundIconButton } from "../../../../lib/components/buttons/RoundIconButton";
import { translate } from "../../../../lib/infrastructure/i18n/InternationalizationService";
import { useGlobalLocation } from "../../../../lib/infrastructure/location/LocationServices";
import { useHasPermissions } from "../../../../lib/infrastructure/authorization/useHasLocationPermissions";
import { useParams } from "react-router-dom";
import { usePopup } from "../../../../lib/infrastructure/ui/UIServices";
import { useServiceCallPro } from "../../../../lib/hooks/useServiceCall";

const topPermissions = [Permission.GLOBAL_ADMIN, Permission.LOCATION_ADMIN, Permission.HUMAN_RESOURCES];

const svc = new EmployeePositionRecordsService();


export function EmployeeProgressionLadderPage() {
    const { isLoadingPermissions, hasPermission } = useHasPermissions(topPermissions);
    const openPopup = usePopup();

    const locationID = useGlobalLocation();
    const locationIDAsString = useMemo(() => "" + locationID, [locationID])

    const { employeeId } = useParams();

    const getEmployeePositionRecordsCall = useServiceCallPro(svc.getEmployeePositionRecords);

    const [employeePositionRecords, setEmployeePositionRecords] = useState<EmployeePositionRecordDTO[]>([])



    /****************************
    * DATA REQUESTS
    *****************************/

    const getEmployeePositionRecords = useCallback(() => {
        if (!employeeId) return;

        getEmployeePositionRecordsCall.invoke(locationIDAsString, employeeId)
            .then((data) => {
                if (!data) {
                    return;
                }
                setEmployeePositionRecords(data.employeePositionRecords);

            })
            .catch((error) => {
                if (!error) return;
                openPopup(<ErrorPopup>{error.response.data.message}</ErrorPopup>);
            });
    }, [locationIDAsString, employeeId, getEmployeePositionRecordsCall.invoke, openPopup]);




    /****************************
    * DATA MANIPULATION EFFECTS
    *****************************/

    useEffect(() => {
        if (!employeeId) return;

        getEmployeePositionRecords();

    }, [employeeId]);


    /****************************
     * USER ACTIONS
     *****************************/

    const handleAddPositionRecord = useCallback(() => {
        openPopup(<AddEmployeePositionRecordPopup locationId={locationIDAsString} employeeId={employeeId} onCompletedOperations={getEmployeePositionRecords} />);
    }, [openPopup, locationIDAsString, employeeId, getEmployeePositionRecords]);



    /****************************
    * CSS & HTML
    *****************************/


    
    const renderAddPositionRecordBtn = useMemo(() => {
        if (isLoadingPermissions || !hasPermission || !employeeId) return null;

        return <RoundIconButton
            className="add-employee-career-ladder-role"
            icon={<AddIcon />}
            onClick={handleAddPositionRecord}
        />;
    }, [isLoadingPermissions, hasPermission, employeeId, handleAddPositionRecord]);


    const renderEmployeeProgression = useMemo(() => {
        if (employeePositionRecords.length > 0) {
            return <EmployeeProgressionLadder employeePositionRecords={employeePositionRecords} locationId={locationIDAsString} employeeId={employeeId} onCompleteOperations={getEmployeePositionRecords} isLoadingPermissions={isLoadingPermissions} hasPermission={hasPermission} />
        }
        else{
            return(<EmptyState/>)
        }
    }, [employeePositionRecords, locationIDAsString, employeeId, getEmployeePositionRecords, isLoadingPermissions, hasPermission])





    return (
        <PageLayout
            tabTitle={translate("EMPLOYEES.CAREER.CareerLadder")}
            pageTitle={translate("EMPLOYEES.CAREER.CareerLadder")}
            className="employee-career-ladder"
            showBreadcrumb
        >
            {getEmployeePositionRecordsCall.isLoading && <FullScreenLoader />}
            {renderAddPositionRecordBtn}
            {renderEmployeeProgression}
        </PageLayout>
    )
}