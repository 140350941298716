import "./SkillsPage.css";

import { useCallback, useEffect, useMemo, useState } from "react";
import { useClosePopup, useCreateNotification, usePopup, useWindowResize } from "../../../../lib/infrastructure/ui/UIServices";

import { ReactComponent as AddIcon } from "../../../../lib/assets/icons/add.svg";
import { AddSkillPopup } from "./popups/AddSkillPopup";
import { Button } from "../../../../lib/components/buttons/Button";
import { CardContainer } from "../../../../lib/layouts/containers/card/CardContainer";
import { ReactComponent as CheckIcon } from "../../../../lib/assets/icons/check-btn-dark.svg";
import { ColumnDefinition } from "../../../../lib/components/table/TableInterfaces";
import { ReactComponent as EditIcon } from "../../../../lib/assets/icons/editar.svg";
import { EditSkillPopup } from "./popups/EditSkillPopup";
import { ErrorPopup } from "../../../../lib/components/popup/ErrorPopup";
import { FormSection } from "../../../../lib/components/form/section/FormSection";
import { FullScreenLoader } from "../../../../lib/components/loader/FullScreenLoader";
import { IconButton } from "../../../../lib/components/buttons/IconButton";
import { InfoBlock } from "../../../../lib/components/info-block/InfoBlock";
import { Notification } from "../../../../lib/components/notifications/Notification";
import { PageLayout } from "../../../../lib/layouts/main-content/PageLayout";
import { Permission } from "../../../../models/api/gate/Permission";
import { ResponsiveDataTable } from "../../../../lib/components/table/ResponsiveDataTable";
import { ScalableIconButton } from "../../../../lib/components/buttons/ScalableIconButton";
import { SkillDomainDTO } from "../models/domain/SkillDomainDTO";
import { SkillsCategory } from "../models/domain/SkillsCategory";
import { SkillsQueryOptions } from "../models/dtos/SkillsQueryOptions";
import { SkillsService } from "../services/SkillsService";
import { Tag } from "../../../../lib/components/tag/Tag";
import { Toggle } from "../../../../lib/components/form/toggle/Toggle";
import { WarningPopup } from "../../../../lib/components/popup/WarningPopup";
import { translate } from "../../../../lib/infrastructure/i18n/InternationalizationService";
import { useFormControl } from "../../../../lib/components/form/Form";
import { useHasPermissions } from "../../../../lib/infrastructure/authorization/useHasLocationPermissions";
import { useParams } from "react-router-dom";
import { useServiceCallPro } from "../../../../lib/hooks/useServiceCall";
import { useUpdatePageData } from "../../../../lib/infrastructure/navigation/hooks/useUpdatePageData";

var skillsSvc = new SkillsService();

const permissions = [Permission.GLOBAL_ADMIN, Permission.LOCATION_ADMIN];

interface ISkillsPageProps {

}

export function SkillsPage(props: ISkillsPageProps) {
  const updateRouteDate = useUpdatePageData();
  const windowResize = useWindowResize();
  const openPopup = usePopup();
  const closePopup = useClosePopup();
  const { id: locationID, categoryId } = useParams();
  const createNotification = useCreateNotification();

  const { isLoadingPermissions, hasPermission } = useHasPermissions(permissions);

  const [skills, setSkills] = useState<SkillDomainDTO[]>();
  const [totalSkills, setTotalSkills] = useState<number>();
  const [categoryDetails, setCategoryDetails] = useState<SkillsCategory>();

  const getSkillsCall = useServiceCallPro(skillsSvc.getSkills);

  const updateSkillStateCall = useServiceCallPro(skillsSvc.updateSkillState);
  const getSkillsCategoryByIdCall = useServiceCallPro(skillsSvc.getSkillsCategoryById);

  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [currentpage, setCurrentPage] = useState(0);

  const [timer, setTimer] = useState<NodeJS.Timeout>();
  const [searchWord, setSearchWord] = useState<string>();




  var searchboxController = useFormControl<string>({
    isDisabled: false,
    enableAutoValidate: false,
  });


  /****************************
 * DATA REQUESTS
 *****************************/

  useEffect(() => {
    if (!locationID || !categoryId) return;

    getSkillsCategoryByIdCall
      .invoke(locationID, "" + categoryId)
      .then((response) => {
        setCategoryDetails({ id: response.id, categoryName: response.categoryName, isDisabled: response.isDisabled, numberOfSkills: response.numberOfSkills, isOptional: response.isOptional });
        updateRouteDate(["##SKILLSCATEGORY##", response.categoryName]);
      })
      .catch((_) => {
        openPopup(<ErrorPopup>{translate("GLOBALADMIN.LOCATIONS.SKILLS.POPUP.LoadingSkillsCategoryError")}</ErrorPopup>);
      });

  }, [locationID, categoryId, updateRouteDate, openPopup]);




  const getSkills = useCallback(() => {
    if (!locationID || !categoryId) return;

    var queryOptions: SkillsQueryOptions = {
      page: currentpage,
      pageLength: itemsPerPage,
      searchWord: searchWord,
    };



    getSkillsCall.invoke("" + locationID, "" + categoryId, queryOptions)
      .then((data) => {
        if (data) {
          setSkills(data.skills);
          setTotalSkills(data.total);
        }
      })
      .catch((error) => {
        if (!error) return;
        openPopup(<ErrorPopup>{error.response.data.message}</ErrorPopup>);
      });
  }, [locationID, categoryId, openPopup, currentpage, itemsPerPage, searchWord, getSkillsCall]);










  const handleUpdateBusinessLineStateClicked = useCallback((skillID: number, isToDisable: boolean) => {

    if (categoryId && locationID && skillID) {

      updateSkillStateCall.invoke("" + locationID, categoryId, skillID, isToDisable)
        .then((_) => {
          getSkills();
          createNotification(<Notification type="success" title={translate("COMMON.SYSTEMPOPUPS.Success")} text={isToDisable ? translate("GLOBALADMIN.LOCATIONS.SKILLS.POPUP.DeactivateSkillSuccess") : translate("GLOBALADMIN.LOCATIONS.SKILLS.POPUP.ActivateSkillSuccess")}></Notification>);
        })
        .catch((error) => {
          if (!error) return;
          openPopup(<ErrorPopup>{error.response.data.message}</ErrorPopup>);
        });
    }
  }, [getSkills, openPopup, locationID, createNotification, skillsSvc.updateSkillsCategoryState]);





  const handleOnPageAndItemsChanged = useCallback(
    (items: number, currentPage: number) => {
      setItemsPerPage(items);
      setCurrentPage(currentPage);
    },
    [setItemsPerPage, setCurrentPage]
  );


  /****************************
  * DATA MANIPULATION EFFECTS
  *****************************/

  useEffect(() => {
    getSkills();
  }, [currentpage, itemsPerPage, searchWord]);



  useEffect(() => {
    if (timer)
      clearTimeout(timer);

    var responseTimeOut = setTimeout(() => {
      if (timer) {
        setCurrentPage(0);
        setSearchWord(searchboxController.value);
      }
    }, 1000);

    setTimer(responseTimeOut);
  }, [searchboxController.value]);


  const columns: ColumnDefinition<SkillDomainDTO>[] = useMemo(
    () => [
      {
        cellRenderProp: (v) => v.name,
        headerRender: translate("GLOBALADMIN.LOCATIONS.SKILLS.INFOANDFORM.SkillName"),
        isMobilePrimaryCell: true,
      },
      {
        cellRenderProp: (v) => (
          <div className="toggle-div">
            {categoryDetails && !categoryDetails.isDisabled ? (
              <>
                <Toggle value={!v.isDisabled} />
                <div
                  className="toggle-div-overlay"
                  onClick={(ev) => {
                    ev.stopPropagation();
                    if (!v.isDisabled) {
                      openPopup(
                        <WarningPopup
                          className="disable-skill-popup"
                          onDismissClicked={() => closePopup()}
                          onConfirmClicked={() => handleUpdateBusinessLineStateClicked(v.id, true)}
                          dismissText={translate("COMMON.Cancel")}
                          confirmText={translate("GLOBALADMIN.LOCATIONS.SKILLS.POPUP.Deactivate")}
                        >
                          {translate("GLOBALADMIN.LOCATIONS.SKILLS.POPUP.DeactivateSkillConfirmText")}
                        </WarningPopup>
                      );
                    } else {
                      if (v.id) {
                        openPopup(
                          <WarningPopup
                            onDismissClicked={() => closePopup()}
                            onConfirmClicked={() => handleUpdateBusinessLineStateClicked(v.id, false)}
                            dismissText={translate("COMMON.Cancel")}
                            confirmText={translate("GLOBALADMIN.LOCATIONS.SKILLS.POPUP.Activate")}
                          >
                            {translate("GLOBALADMIN.LOCATIONS.SKILLS.POPUP.ActivateSkillConfirmText")}
                          </WarningPopup>
                        );
                      }
                    }
                  }}
                />
              </>
            ) : null}
          </div>
        ),
        headerRender: <></>,
        columnKey: "status",
        width: "5%",
        isSortable: false,
        isMobileHeaderIcon: true,
      },
      {
        cellRenderProp: (v) => {
          if (locationID && !isLoadingPermissions && hasPermission && categoryId && !categoryDetails?.isDisabled)
            return (
              <ScalableIconButton
                icon={<EditIcon />}
                onClick={() =>
                  openPopup(
                    <EditSkillPopup
                      skillId={v.id}
                      categoryId={categoryId}
                      locationId={locationID}
                      onCompleted={() => getSkills()}
                    />
                  )
                }
                size={24}
              />)
          return null;
        },
        columnKey: "edit",
        width: "24px",
        isMobileHeaderIcon: true,
      },
    ], [categoryDetails?.isDisabled, openPopup, closePopup, handleUpdateBusinessLineStateClicked, locationID, isLoadingPermissions, hasPermission, categoryId, getSkills]);


  const handleClickAddBtn = useCallback(() => {
    if (locationID && categoryId)
      openPopup(<AddSkillPopup onChangesMade={() => getSkills()} locationId={locationID} categoryId={categoryId} />);
  }, [getSkills, locationID, openPopup, categoryId])


  const renderAddSkillBtn = useMemo(() => {
    if (!isLoadingPermissions && hasPermission) {
      if (windowResize > 768)
        return (
          <Button
            text={translate("COMMON.Add")}
            type="primary"
            onClick={handleClickAddBtn}
          />
        );
      else
        return (
          <IconButton
            icon={<AddIcon />}
            type="primary"
            onClick={handleClickAddBtn}
          />
        );
    }
    //}
  }, [windowResize, isLoadingPermissions, hasPermission, handleClickAddBtn]);











  return <PageLayout
    tabTitle={translate("GLOBALADMIN.LOCATIONS.SKILLS.Skills")}
    showBreadcrumb
    pageTitle={translate("GLOBALADMIN.LOCATIONS.SKILLS.Skills")}
    className="skills-page"
    actions={categoryDetails && !categoryDetails?.isDisabled ? renderAddSkillBtn : null}
  >

    {updateSkillStateCall.isLoading && <FullScreenLoader />}

    <CardContainer className="skills-category-data">
      <div className="header">
        <div className="subtitle">
          {translate("GLOBALADMIN.LOCATIONS.SKILLS.SkillsCategoryDetails")}
        </div>


        {
          categoryDetails ?
            <Tag
              text={categoryDetails && categoryDetails.isDisabled ? translate("COMMON.Disabled") : translate("COMMON.Enabled")}
              backgroundColor={categoryDetails.isDisabled ? "status-light-grey" : "status-green"}
              isTiny={windowResize < 1024}
            /> : null}

      </div>
      <FormSection unsetFlexGrow>
        <InfoBlock label={translate("GLOBALADMIN.LOCATIONS.SKILLS.INFOANDFORM.CategoryName")} value={categoryDetails?.categoryName ? "" + categoryDetails.categoryName : "-"} />
        {categoryDetails && categoryDetails.isOptional ?
          <div className="info-block">
            <div className="info-block-label">{translate("GLOBALADMIN.LOCATIONS.SKILLS.INFOANDFORM.Multivalences")}</div>
            <div className="info-block-icon"><CheckIcon /></div>
          </div>
          : null}
      </FormSection>
    </CardContainer>

    <ResponsiveDataTable
      columnDefinitions={columns}
      items={skills || []}
      totalitems={totalSkills || 0}
      paginationOptions={{ itemsPerPage: itemsPerPage, itemsPerPageOptions: [10, 15, 20] }}
      currentpage={currentpage}
      isLoading={getSkillsCall.isLoading}
      onPageAndItemsChanged={handleOnPageAndItemsChanged}
    />
  </PageLayout>;
}