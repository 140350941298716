import "./AppPortal.css";

import { useRenderNotificationContainer, useRenderPopup } from "../../infrastructure/ui/UIServices";

import { LegacyRef } from "react";
import { NotificationContainer } from "../../components/notifications/NotificationContainer";
import { PopupArea } from '../containers/popup-area/PopupArea';
import ReactDOM from 'react-dom';

export interface IAppPortalProps {
    children: React.ReactNode;
}


export function AppPortal(props: IAppPortalProps) {
    const { showPopup, popupContent } = useRenderPopup();
    const { showNotificationContainer } = useRenderNotificationContainer();

    return (
        <div className="app-portal">
            {props.children}

            {showPopup ? <PopupArea>{popupContent}</PopupArea> : null}
            {showNotificationContainer ? <NotificationContainer /> : null}
        </div>
    )
}

interface IPortalProps {
    children: React.ReactNode;
    portalRef: LegacyRef<HTMLDivElement>;
}

export function Portal(props: IPortalProps) {
    return ReactDOM.createPortal(
        <div ref={props.portalRef}>
            {props.children}
        </div>,
        document.body
    );
};