import { ILanguage } from "./ILanguage";

export const languagePT: ILanguage = {
  COMMON: {
    ProjectName: "Carreiras",
    Continue: "Continuar",
    Yes: "Sim",
    No: "Não",
    Cancel: "Cancelar",
    Create: "Criar",
    Add: "Adicionar",
    Import: "Importar",
    Change: "Alterar",
    Suspend: "Suspender",
    Save: "Guardar",
    Edit: "Editar",
    Delete: "Apagar",
    Remove: "Remover",
    Deactivate: "Desativar",
    Activate: "Ativar",
    Logout: "Terminar Sessão",
    Optional: "Opcional",
    Registar: "Registar",
    PrepositionForDateTime: " a ",
    CreatedBy: "Registado por",
    CreatedOn: "Registada a",
    All: "Todos",
    Clear: "Limpar",
    Deliver: "Entregar",
    OtherActions: "Outras ações",
    CloneAction: "Clonar Amostra",
    SelectOneOptionPlaceholder: "Selecione uma opção",
    Export: "Exportar",
    Weight: "Peso",
    Confirm: "Confirmar",
    Name: "Nome",
    Upload: "Upload",
    Status: "Estado",
    SaveAll: "Guardar todos",
    ImportCollaborators: "Importar colaboradores",
    Saved: "Informação alterada.",
    Close: "Fechar",
    Enabled: "Ativa",
    Disabled: "Desativa",
    Next: "Avançar",
    DeleteCAPSLOCK: "Apagar",
    Select: "Selecionar",
    Conclude: "Concluir",
    Calibrate: "Calibrar",
    Reject: "Rejeitar",
    Revert: "Reverter",
    Reopen: "Reabrir",
    AddAttachment: "Adicionar Anexo",
    ChangeAttachment: "Alterar Anexo",
    Profile: "Perfil",
    Collaborator: "Colaborador",
    ViewAll: "Ver Todos",
    RegisteredBy: "Registado por",
    TestEnvironment: "Ambiente de Testes",
    SelectAnOption: "Selecione uma opção",
  

    STATUS: {
      Registered: "Registado",
      Rejected: "Rejeitado",
      Canceled: "Cancelado",
      Confirmed: "Confirmado",
      Completed: "Completado",
      InProgress: "A Decorrer",
      ReadyToEvaluate: "Pronto a Avaliar",
      Concluded: "Concluído",
      NotConcluded: "Não Concluído",
      Calibrated: "Calibrado",
      NotCompleted: "Não concluído"
    },

    POPUPS: {
      Download: "Download",
      ImportOptions: "Opções de Importação",
      ExcelTemplate: "Template Excel",
      PdfTemplate: "Template PDF",
      PdfTemplateText: "Clique no botão abaixo para fazer o download de um ficheiro modelo. Este é um exemplo de orientação para avaliação de desempenho para os gestores de equipa",
      ExcelTemplateText: "Clique no botão abaixo para fazer o download de um ficheiro modelo de Excel. Este modelo contém a estrutura correta para a importação de dados.",
      Import: "Importar",
      ImportText: "Clique no botão abaixo para selecionar um ficheiro Excel e iniciar o processo de importação. Certifique-se que seleciona o ficheiro correto.",
      ImportEvaluationAttachs: "Clique no botão abaixo para importar um ficheiro. O ficheiro será exibido para todos os utilizadores com permissão na área de avaliações.",
      SelectDate: "Selecione uma data"
    },

    ERRORS: {
      UnknownError: "Erro desconhecido. Por favor contacte a equipa de suporte.",
      ErrorSavingValues: "Ocorreu um problema ao guardar os dados. Por favor verifique avaliações que não foram alteradas."
    },

    FORM: {
      Details: "Detalhes",
      Search: "Pesquisar",
      SearchPlaceholder: "Pesquisar",
      InitialDate: "Data Inicial",
      InitialDatePlaceholder: "Data Inicial",
      InitialCreationDatePlaceholder: "Data Inicial de Criação",
      FinalDate: "Data Final",
      FinalDatePlaceholder: "Data Final",
      FinalCreationDatePlaceholder: "Data Fim de Criação",
      RequestDatePlaceholder: "Selecione uma data",
      DefaultPlaceholder: "Digite Aqui",
      SelectDefaultPlaceholder: "Digite para pesquisar",
      SelectStatusPlaceholder: "Selecione estados",
      Filters: "Filtros",
      CleanFilters: "Limpar Filtros",
      ClearSelection: "Limpar seleção",
      Sunday: "Do",
      Monday: "Se",
      Tuesday: "Te",
      Wednesday: "Qa",
      Thursday: "Qi",
      Friday: "Sx",
      Saturday: "Sa",

      VALIDATIONS: {
        CannotBeEmpty: "Este campo não pode estar vazio.",
        RequiredField: "Este campo é obrigatório.",
        GuidValidator: "Formato GUID inválido.",
        SelectOneOption: "Selecione uma opção.",
        BatchFormatError: "Deverá conter o seguinte formato: ####### ou #######/# ou #######/##",
        FillMandatoryFields: "Por favor preencha os campos obrigatórios!",
        InvalidLocationId: "O ID da localização é inválido.",
        InvalidRequestId: "O ID do pedido é inválido.",
        PositiveNumber: "Insira um número inteiro",
        PositiveNumberOrDecimal: "Insira um número positivo / decimal",
        SelectDate: "Por favor selecione uma data",
        ZeroOrDecimalNumber: "Insira um número inteiro / decimal",


      }
    },

    ERRORPAGES: {
      SomethingWrong: "Ocorreu um erro",
      BackToHome: "Página Inicial",
      AccessDenied: "Acesso Negado",
      AccessDeniedText1: "Não tem permissão para ver esta página!",
      AccessDeniedText2: "Por favor, verifique as suas credenciais e tente novamente.",
      PageNotFound: "Página Não Encontrada",
      PageNotFoundText: "Não conseguimos encontrar a página que procura.",
      SessionExpired: "Sessão Expirada",
      SessionExpiredText: "A sessão expirou devido à sua inatividade",
      Login: "Login",
    },

    SYSTEMPOPUPS: {
      Error: "Erro!",
      ErrorText: "",
      Success: "Sucesso!",
      SuccessText: "",
      Warning: "Aviso!",
      WarningText: "",
    },

    TABLE: {
      NoResults: "Nenhum resultado encontrado",
      NoResultsText: "Não encontramos o que procura.",
      /*       NoResultsText: "Não encontramos o que procura, por favor procure novamente", */
      RowsPerPage: "Items por pág.",
      Of: "de",
    }
  },

  BUSINESSLINES: {
    BusinessLine: "Linha de Negócio",
    BusinessLines: "Linhas de Negócio",

    INFOANDFORM: {
      Name: "Nome",
    },

    EVALUATIONPERIODS: {
      EvaluationPeriods: "Períodos de Avaliação",
      EvaluationPeriodDetails: "Detalhes do Período de Avaliação",
      EditEvaluationPeriod: "Editar Período de Avaliação",
      CreatePeriod: "Criar Período",

      COLLABORATORS: {
        ImportCollaborators: "Importar Colaboradores",
        POPUPS: {
          ImportCollaboratorsConfirmText: "Tem a certeza que pretende importar Colaboradores para o Período de Avaliação?",
          InvalidImport: "{0} linhas(s) do ficheiro foram importadas devido a campos inválidos.",
          ImportSuccess: "Colaboradores do Período de Avaliação importados."
        }
      },
      INFOANDFORM: {
        EvaluationPeriodData: "Dados do Período de Avaliação",
        SubmittedEvaluations: "Avaliações Submetidas",
        Name: "Nome",
        NamePlaceholder: "Ex: 2024",
        StartDate: "Data Início",
        StartDatePlaceholder: "Selecione uma data inicial",
        EndDate: "Data Fim",
        EndDatePlaceholder: "Selecione uma data final",
        Status: "Estado",
        StartEvaluations: "Início Avaliações",
        EndEvaluations: "Fim Avaliações",
        StartCalibrations: "Início Calibrações",
        EndCalibrations: "Fim Calibrações",
        DatesToNotificateManagersByEmail: "Datas para notificar Gestores de Equipa via e-mail",
        InitialDayToEvaluate: "Primeiro dia para avaliar",
        FinalDayToEvaluate: "Último dia para avaliar",
        InitialDayToCalibrate: "Primeiro dia para calibrar",
        FinalDayToCalibrate: "Último dia para calibrar",
      },

      POPUPS: {
        Cancel: "Cancelar Período",
        CancelEvaluationPeriodConfirmText: "Tem a certeza que quer cancelar o período de avaliação?",
        CloseEvaluationPeriodConfirmText: "Tem a certeza que quer fechar o período de avaliação?",
        ActivateEvaluationPeriodConfirmText: "Tem a certeza que quer ativar o período de avaliação?",
        ReopenEvaluationPeriodConfirmText: "Tem a certeza que quer reabrir o período de avaliação?",
      },

      MESSAGES: {
        CreatePeriodSuccess: "Novo Período de Avaliação adicionado",
        EditPeriodSuccess: "Período de Avaliação Editado",
        PeriodCanceledSuccess: "Período de Avaliação Cancelado",
        PeriodAtivatedSuccess: "Período de Avaliação em Progresso",
        PeriodCompletedSuccess: "Período de Avaliação Concluído",
        PeriodReopenSuccess: "Período de Avaliação Reaberto",
        CreatedCompanyCriteriaSuccess: "Fator da Empresa criado",
        MultipleCompanyCriteriasUpdatedSuccess: "Fatores da Empresa atualizados",
        MultipleEmployeeCriteriasUpdatedSuccess: "Fatores de Colaborador atualizados",
        ChangedCompanyCriteriaSuccess: "Fator da Empresa alterado",
        DeleteCompanyCriteriaSucess: "Fator da Empresa removido",
        CreatedEmployeeCriteriaSuccess: "Fator de Colaborador criado",
        ChangedEmployeeCriteriaSuccess: "Fator de Colaborador alterado",
        DeleteEmployeeCriteriaSucess: "Fator de Colaborador removido",
      },

      ABSENCES: {
        Absences: "Ausências",
        AbsencesMap: "Mapa de Ausências",
        ImportAbsences: "Importar Ausências",
        

        INFOANDFORM: {
          EmployeeName: "Colaborador",
          AbsenceDays: "Dias de Ausência",
          UnjustifiedAbsences: "Faltas Injustificadas",
        },

        POPUPS: {
          ImportAbsencesConfirmText: "Reimportar 'Mapa de Ausências' irá substituir os dados existentes!",
          InvalidImport: "Não foram importadas {0} linha(s) do ficheiro devido a dias de ausência inválidos ou colaboradores não encontrados.",
          ImportSuccess: "Mapa de Ausências importado."
        }
      }
    },

    EVALUATIONS: {
      Evaluation: "Avaliação",
      Evaluate: "Avaliar",

      INFOANDFORM: {
        EmployeeData: "Dados do Colaborador",
        Name: "Nome",
        Role: "Função",
        Value: "Valor",
        Checklist: "Critérios de Evolução de Carreira",
        Skill: "Competência",
        Evaluation: "Avaliação",
        Employee: "Colaborador",
        SuggestNextRole: "Rec. Próx. Função",
        RemoveSuggestedNextRole: "Remover recomendação de função",
        History: "Histórico",
        Bonus: "Bónus",
        Period: "Período",
        Certificates: "Certificados",
        CertificateName: "Nome",
        CertificateDate: "Data",
        CertificateInitialDate: "Data ínicio",
        CertificateConclusionDate: "Data Conclusão",
        CertificateApproval: "Aprovação",
        UnjustifiedAbsences: "Faltas Injustificadas",
        FeedbackManager: "Feedback Chefia",
        FeedbackEmployee: "Feedback Colaborador",
        FollowUp: "Acompanhamento",
        FollowUpItem: "Item",
        FollowUpItemName: "Nome",
        FollowUpItemNamePlaceholder: "Ex: Formação",
        FollowUpDescription: "Descrição",
        FollowUpDescriptionPlaceholder: "Digite uma descrição",
        FollowUpStatus: "Estado",
        FollowUpStartDate: "Data Início",
        FollowUpStartDatePlaceholder: "Selecione uma data",
        FollowUpConclusionDate: "Data Conclusão",
        FollowUpConclusionDatePlaceholder: "Selecione uma data",
        Reason: "Justificação",
        RejectReasonPlaceholder: "Digite uma razão para a rejeição",
        AddFollowUpItem: "Adicionar Item",

        AreaData: "Dados da Área",
        ColaboratorsToEvaluate: "Colaboradores a Avaliar",
        TotalEvaluation: "Total Avaliação",
        TotalNextRole: "Total Próx. Função",
        State: "Estado",
      },

      COMMENTS: {
        Comment: "Mensagem para Calibrador",
        Comments: "Mensagens para Calibrador",
        AddComment: "Mensagens para Calibrador",
        ConsultComments: "Consultar Mensagens",
        CommentPlaceholder: "Digite a sua mensagem",
        NoComments: "Ainda não há mensagens sobre esta avaliação.",

        MESSAGES: {
          CommentAddedSuccess: "Mensagem adicionado"
        },
      },

      HISTORY: {
        NoHistoryLines: "Ainda não há registos de histórico sobre esta avaliação.",
        HistoryLineForUnknownAction: "Ação Desconhecida.",
        HistoryLineForSaveEvaluation: "Avaliação foi guardada.",
        HistoryLineForConfirmEvaluation: "Avaliação foi confirmada.",
        HistoryLineForCloseEvaluation: "Avaliação foi fechada.",
        HistoryLineForCalibrateEvaluation: "Avaliação foi calibrada.",
        HistoryLineForRevertEvaluation: "Avaliação foi revertida.",
        HistoryLineForRejectEvaluation: "Avaliação foi rejeitada com a seguinte justificação: {0}.",
        HistoryLineForNextRoleRecomendation: "Avaliação foi marcada como recomendação para promoção.",
        HistoryLineForRemoveNextRoleRecomendation: "Removida a recomendação para promoção",
        HistoryLineForAddActionItem: "Item de acompanhamento adicionado.",
        HistoryLineForRemoveActionItem: "Item de acompanhamento removido."
      },

      POPUPS: {
        DeleteFollowUpItemConfirmText: "Tem a certeza que pretende remover o item?",
        NextRole: "Próxima Função",
        NextRolePopupText: "Selecione a próxima função da progressão de carreira do colaborador.",
        EmptyCriterias: "A função selecionada não tem critérios associados.",
        RejectEvaluation: "Rejeitar Avaliação",
        CalibrateEvaluation: "Calibrar Avaliação",
        ConcludeEvaluation: "Concluir Avaliação",
        MESSAGES: {
          ConcludeEvaluationWarningMessage: "Atenção: esta ação irá concluir a avaliação.",
          CalibrateEvaluationWarningMessage: "Atenção: Esta ação irá calibrar a avaliação."
        },
      },

      ERRORS: {
        InputFieldInvalid: "Foram encontrados valores inválidos nos inputs de níveis de competência",
        ErrorSavingEvaluationDetails: "Ocorreu um erro ao gravar os detalhes da avaliação.",
        ErrorSavingFollowUpItems: "Ocorreu um erro ao gravar os items de acompanhamento.",
      },

      NOTIFICATIONS: {
        RevertEvaluationDetailsSucess: "Avaliação revertida",
        UpdateEvaluationDetailsSuccess: "Avaliação guardada.",
        ConfirmEvaluationSuccessMessage: "Avaliação confirmada",
        RejectEvaluationSuccessMessage: "Avaliação rejeitada",
        CalibrateEvaluationSuccessMessage: "Avaliação calibrada",
        ConcludeEvaluationSuccessMessage: "Avaliação concluída",
      }
    },

    ROLES: {
      Roles: "Funções",
      CreateRole: "Criar Função",
      RoleDetails: "Detalhes da Função",
      RoleData: "Dados da Função",

      INFOANDFORM: {
        Role: "Função",
        JobPositions: "Postos de Trabalho",
        JobGrade: "Grau de Função",
        JobGradePlaceholder: "Ex: 5",
        RoleReferenceValue: "Valor de Ref. da Função",
        Name: "Nome",
        NamePlaceholder: "Ex: Operador de Logística",
      },

      POPUP: {
        CreateRole: "Criar Função",
        CreateRoleSuccess: "Função criada!",
        EditRole: "Editar Função",
        EditRoleSuccess: "Função editada!",
        LoadingRoleError: "Erro ao carregar a informação da Função.",
        Activate: "Ativar",
        Deactivate: "Desativar",
        ActivateRoleConfirmText: "Tem a certeza que quer ativar a Função?",
        DeactivateRoleConfirmText: "Tem a certeza que quer desativar a Função?",
        ActivateRoleSuccess: "Função ativada!",
        DeactivateRoleSuccess: "Função desativada!",
      },

      JOBPOSITIONS: {
        JobPositions: "Postos de Trabalho",

        INFOANDFORM: {
          Name: "Nome",
          NamePlaceholder: "Ex: Operador de Logística",
          Goal: "Finalidade",
          GoalPlaceholder: "Ex: ",
          Description: "Descrição",
          DescriptionPlaceholder: "Ex: ",
          Qualification: "Qualificação",
          QualificationPlaceholder: "Ex: ",
        },

        POPUP: {
          AddJobPosition: "Criar Posto de Trabalho",
          AddJobPositionSuccess: "Posto de Trabalho criado!",
          EditJobPosition: "Editar Posto de Trabalho",
          EditJobPositionSuccess: "Posto de Trabalho editado!",
          LoadingJobPositionError: "Erro ao carregar a informação do Posto de Trabalho.",
          Activate: "Ativar",
          Deactivate: "Desativar",
          ActivateJobPositionConfirmText: "Tem a certeza que quer ativar o Posto de Trabalho?",
          DeactivateJobPositionConfirmText: "Tem a certeza que quer desativar o Posto de Trabalho?",
          ActivateJobPositionSuccess: "Posto de Trabalho ativado!",
          DeactivateJobPositionSuccess: "Posto de Trabalho desativado!",
        }
      },

      CRITERIAS: {
        Criterias: "Critérios",

        INFOANDFORM: {
          Name: "Nome",
          NamePlaceholder: "Ex: Polivalência",
          Description: "Descrição",
          DescriptionPlaceholder: "Ex: ",
        },

        POPUP: {
          AddCriteria: "Adicionar Critério",
          AddCriteriaSuccess: "Critério adicionado!",
          EditCriteria: "Editar Critério",
          EditCriteriaSuccess: "Critério editado!",
          LoadingCriteriaError: "Erro ao carregar a informação do Critério.",
          Delete: "Remover",
          DeleteCriteriaConfirmText: "Tem a certeza que quer remover o Critério?",
          DeleteCriteriaSuccess: "Critério removido!",
        }
      }
    },

    MATRIX: {
      MatrixDetails: "Detalhes da Matriz",
      EvaluationMatrix: "Matriz de Avaliação",
      EvaluationMatrices: "Matrizes de Avaliação",
      CreateMatrix: "Criar Matriz",
      CreateNewMatrix: "Criar Nova Matriz",
      NewMatrix: "Nova Matriz",
      EditMatrix: "Editar Matriz",
      CurrentMatrix: "Matriz Atual",
      SelectRoles: "Selecionar Funções",
      SelectSkills: "Selecionar Competências",
      AddChecklistItem: "Adicionar Critério de Evolução de Carreira",


      INFOANDFORM: {
        BasicInfo: "Informação Básica",
        Area: "Área",
        AreaPlaceholder: "Ex: Production Cutting & Needling",
        Manager: "Responsável",
        ManagerPlaceholder: "Ex: john.doe@domain.com",
        CreationDate: "Data Criação",
        History: "Histórico",
        EvaluationPeriods: "Períodos de Avaliação",
        Skill: "Competência",
        Level: "Nível",
        Checklist: "Critérios de Evolução de Carreira",
        Description: "Descrição",
        DescriptionPlaceholder: "Digite uma descrição para o critério de evolução de carreira",
        Roles: "Funções",
        RolesPlaceholder: "Selecione as funções",
        Skills: "Competências",
        SkillsPlaceholder: "Selecione as competências",
        Name: "Nome",

      },

      NOTIFICATIONS: {
        CreateMatrixSuccess: "Matriz criada.",
        EditMatrixSuccess: "Matriz editada.",
      },

      POPUP: {
        CreateMatrix: "Criar Matriz",
        DeleteChecklistItemConfirmText: "Tem a certeza que quer remover o critério de evolução de carreira?",
        SelectRoles: "Selecionar Funções",
        SelectRolesSuccess: "Funções adicionadas à matrix.",
        SelectSkills: "Selecionar Competências",
        AddChecklistItem: "Adicionar Critério de Evolução de Carreira",
        ManageSkills: "Gerir Skills",
        ActivateMatrixConfirmText: "Tem a certeza que pretende ativar a Matriz de avaliação?",
        DeactivateMatrixConfirmText: "Tem a certeza que pretende desativar a Matriz de avaliação?",
        ActivateMatrixSuccess: "Matriz ativada",
        DeactivateMatrixSuccess: "Matriz desativada"
      },

      ERRORS: {
        InputFieldInvalid: "Foram encontrados valores inválidos nos inputs de níveis de competências",
        NoRolesSelected: "Nenhuma função selecionada.",
        NoCategoriesSelected: "Nenhuma categoria de competências selecionada.",
        NoSkillsSelected: "Nenhuma competência selecionada.",
      }
    },

    CAREER: {
      CareerLadder: "Progressão de Carreira",
      CreateCareerLadder: "Criar Carreira",
      EditCareerLadder: "Editar Carreira",

      INFOANDFORM: {
        Role: "Função",
        RoleName: "Nome da função",
        RoleNamePlaceholder: "Digite o nome da função",
        RolePlaceholder: "Ex: Operador de Logística",
        Roles: "Funções",
        RolesPlaceholder: "Selecione uma função",
        JobGrade: "Grau de Função",
        JobGradePlaceholder: "Ex: 4",
        DeprecatedFunction: "Função descontinuada",
        FilterByActiveRoles: "Filtrar apenas por funções ativas"
      },

      POPUP: {
        AddRole: "Adicionar Função",
        AddRoleSuccess: "Função adicionada.",
        RemoveRoleSuccess: "Função removida.",
        RemovePositionRecordConfirmText: "Tem a certeza que pretende remover a função?"
      },

      ERRORS: {
        NoRolesSelected: "Nenhuma função selecionada.",
      }
    },
  },

  EMPLOYEES: {
    Employees: "Colaboradores",

    INFOANDFORM: {
      Name: "Nome",
      Email: "Email",
      Manager: "Manager",
      Role: "Função",
      Evaluation: "Avaliação",
    },

    EVALUATIONS: {
      Evaluations: "Avaliações",
      EvaluationPeriods: "Períodos de Avaliação",
      RegisteredPeriodMessage: "Este Período de avaliação encontra-se na fase de definição pela administração, por isso, as avaliações ainda não se encontram disponíveis.",

      INFOANDFORM: {
        Name: "Nome",
        Evaluation: "Avaliação",
      }
    },

    ACTIONITEMS: {
      OTJLExpandingScopeOfRole: "Aprendizagem no Posto de Trabalho: Expandir o âmbito atual da função",
      OTJLWorkShadowing: "Aprendizagem no Posto de Trabalho: Trabalho de observação (work shadowing)",
      OTJLLeadingTeamOrProject: "Aprendizagem no Posto de Trabalho: Liderar uma equipa / projeto",
      OTJLCoveringForOthersOnLeave:"Aprendizagem no Posto de Trabalho: Substituir colegas em ausência",
      OTJLParticipatingInProjects: "Aprendizagem no Posto de Trabalho: Participar em projetos específicos",
      OTJLExposureToOtherRoles: "Aprendizagem no Posto de Trabalho: Mudança de função",
      OTJLInteractionWithSeniorManagement: "Aprendizagem no Posto de Trabalho: Interação com liderança sénior",
      
      LFO360Feedback: "Aprender com os Outros: Feedback 360°/180º",
      LFOInformalFeedbackAndDebriefs: "Aprender com os Outros: Feedback informal",  
      LFOExternalNetworksAndContacts: "Aprender com os Outros: Benchmark noutros locais",     
      LFOCoachingFromManagerOrOthers: "Aprender com os Outros: Acompanhamento de chefia / outros colegas",
      LFOFindingMentorOrMentoring: "Aprender com os Outros: Encontrar um mentor ",         
      LFOLearningThroughTeamsOrNetworks: "Aprender com os Outros: Aprender com outras equipas",

      TASExternalCourses: "Formação e Estudos: Cursos externos", 
      TASWorkdayLearning: "Formação e Estudos: Formação no Workday",
      TASArticlesBooksWhitepapers: "Formação e Estudos: Artigos, livros, documentos técnicos", 
      TASLinkedInLearning: "Formação e Estudos: Formação no LinkedIn",
      TASSeminarsAndEvents: "Formação e Estudos: Seminários e outros eventos", 
      TASFormalEducation: "Formação e Estudos: Educação (ex: universidade, formação profissional)", 
    },

    CAREER: {
      CareerLadder: "Progressão de Carreira",

      INFOANDFORM: {
        JobGrade: "Grau de Função",
        StartDate: "Data Início",
        StartDatePlaceholder: "Selecione a data de início",
        EndDate: "Data Fim",
        EndDatePlaceholder: "Selecione a data de fim",
        BusinessLines: "Linhas de Negócio",
        BusinessLinesPlaceholder: "Selecione a linha de negócio",
        Roles: "Funções",
        RolesPlaceholder: "Selecione uma função",
      },

      POPUPS: {
        SelectBusinessLine: "Selecionar Linha de Negócio",
        RemoveRoleSuccess: "Função removida.",
        AddRole: "Adicionar Função",
        AddRoleSuccess: "Função adicionada.",
      }
    },

    BONUS: {
      Bonus: "Bónus",

      INFOANDFORM: {
        EvaluationPeriod: "Período de Avaliação",
        Role: "Função",
        Evaluation: "Avaliação",
        EvaluationPeriodData: "Dados do Período de Avaliação",
        Name: "Nome",
        StartDate: "Data Início",
        EndDate: "Data Fim",
        CompanyEvaluationFactors: "Fatores de Avaliação da Empresa",
        EmployeeEvaluationFactors: "Fatores de Avaliação do Colaborador",
        Factor: "Fator",
        Feedback: "Feedback",
      },

      PERFORMANCE: {
        Performance: "Desempenho",
        Exceptional: "Excepcional",
        VeryGood: "Muito Bom",
        Good: "Bom",
        Reasonable: "Razoável",
        ToImprove: "A melhorar",
      },
    },

    CERTIFICATIONS: {
      Certifications: "Certificados",
      AddCertificate: "Adicionar",

      INFOANDFORM: {
        Name: "Nome",
        NamePlaceholder: "Ex: Suporte Básico de Vida",
        Date: "Data",
        StartDate: "Data Início",
        EndDate: "Data Fim",
        DatePlaceholder: "Ex: Selecione uma data",
        State: "Estado",
        StatePlaceholder: "Selecione um estado",
        Approval: "Aprovação",
        Approved: "Aprovado",
        ViewAttachment: "Ver Anexo",
        Attachment: "Anexo",
        DeleteCertification: "Remover Certificação",
      },

      POPUPS: {
        AddCertification: "Adicionar Certificação",
        AddCertificationSuccess: "Certificação adicionada.",
        AddCertificationUploadError: "Ocorreu um erro ao importar o ficheiro anexado.",
        EditCertification: "Editar Certificação",
        EditCertificationSuccess: "Certificação editada.",
        DeleteCertificationConfirmText: "Tem a certeza que pretende remover a certificação selecionada?",
        DeleteCertificationSuccess: "Certificação removida.",
        ViewAttachmentError: "Ocorreu um erro ao tentar visualizar o anexo selecionado.",
        ImportXLSXSuccess: "Certificações importadas.",
      }
    },

    ITEMS: {
      FollowUpItems: "Itens de Acompanhamento",
      AddFollowUpItem: "Adicionar",

      INFOANDFORM: {
        Name: "Nome",
        NamePlaceholder: "Ex: Formação",
        Description: "Descrição",
        DescriptionPlaceholder: "Digite uma descrição",
        Status: "Estado",
        StatusPlaceholder: "Selecione o estado",
        StartDate: "Data Início",
        StartDatePlaceholder: "Selecione uma data",
        ConclusionDate: "Data Conclusão",
        ConclusionDatePlaceholder: "Selecione uma data",
        DeleteFollowUpItem: "Remover item",
      },

      POPUPS: {
        AddFollowUpItem: "Adicionar Item de Acompanhamento",
        AddFollowUpItemSuccess: "Item adicionado.",
        EditFollowUpItem: "Editar Item de Acompanhamento",
        EditEditFollowUpItemSuccess: "Item editado",
        DeleteFollowUpItemConfirmText: "Tem a certeza que pretende remover o item selecionado?",
        DeleteFollowUpItemSuccess: "Item removido.",
        ImportXLSXSuccess: "Itens importados.",
      }
    }
  },

  GLOBALADMIN: {
    GlobalAdmin: "Administração global",
    LOCATIONS: {
      Locations: "Localizações",
      CreateLocation: "Criar Localização",
      AccessGroups: "Grupos de Acesso",

      INFO: {
        Name: "Nome",
        NumGroups: "Nº Grupos",
        Type: "Tipo",
      },

      POPUP: {
        Activate: "Ativar",
        ActivateConfirmText: "Tem a certeza que quer ativar a localização?",
        ActivateLocationError: "Erro ao ativar localização!",
        ActivateLocationSuccess: "Localização ativada!",
        CreateLocationSuccess: "Localização criada!",
        EditLocation: "Editar Localização",
        EditLocationError: "Erro ao editar localização!",
        EditLocationSuccess: "Localização editada!",
        DeactivateLocationError: "Erro ao desativar localização!",
        Deactivate: "Desativar",
        DeactivateConfirmText: "Tem a certeza que quer desativar a localização?",
        DeactivateLocationSuccess: "Location deactivated!"
      },

      ACCESSGROUPS: {
        AccessGroups: "Grupos de Acesso",

        INFOANDFORM: {
          Type: "Tipo",
          ID: "ID",
          Name: "Nome",
          EditedGroupSuccess: "Grupos de acesso editados!",
          SubmitError: "Por favor, verifique os erros no formulário!",
        },

        TYPES: {
          LocalAdmin: "Administrador Local",
          RH: "Recursos Humanos",
          TeamManager: "Gestor de Equipa",
          Collaborator: "Colaborador"
        }
      },

      BUSINESSLINES: {
        BusinessLines: "Linhas de Negócio",

        INFOANDFORM: {
          Name: "Nome",
          NamePlaceholder: "Ex: Serviços",
        },

        POPUP: {
          AddBusinessLine: "Adicionar Linha de Negócio",
          AddBusinessLineSuccess: "Linha de Negócio adicionada!",
          EditBusinessLine: "Editar Linha de Negócio",
          EditBusinessLineSuccess: "Linha de Negócio editada!",
          LoadingBusinessLineError: "Erro ao carregar a informação da Linha de Negócio.",
          Activate: "Ativar",
          Deactivate: "Desativar",
          ActivateBusinessLineConfirmText: "Tem a certeza que quer ativar a linha de negócio?",
          DeactivateBusinessLineConfirmText: "Tem a certeza que quer desativar a linha de negócio?",
          ActivateBusinessLineSuccess: "Linha de Negócio ativada!",
          DeactivateBusinessLineSuccess: "Linha de Negócio desativada!",
        }
      },

      SKILLS: {
        Skills: "Competências",
        SkillsCategories: "Categorias de Competências",
        SkillsCategoryDetails: "Detalhes da Categoria",

        INFOANDFORM: {
          Multivalences: "Polivalências",
          Category: "Categoria",
          NumOfSkills: "Nº. Competências",
          CategoryName: "Nome da Categoria",
          CategoryNamePlaceholder: "Ex: Behavioral Skills",
          SkillName: "Nome",
          SkillNamePlaceholder: "Ex: Creating and Innovating",
          SkillDescription: "Descrição",
          SkillDescriptionPlaceholder: "Digite uma descrição"
        },



        POPUP: {
          AddSkillsCategory: "Adicionar Categoria de Competências",
          AddSkillsCategorySuccess: "Categoria de Competências adicionada!",
          EditSkillsCategory: "Editar Categoria de Competências",
          EditSkillsCategorySuccess: "Categoria de Competências editada!",
          LoadingSkillsCategoryError: "Erro ao carregar a informação da Categoria de Competências.",
          Activate: "Ativar",
          Deactivate: "Desativar",
          ActivateSkillsCategoryConfirmText: "Tem a certeza que quer ativar a Categoria de Competências?",
          DeactivateSkillsCategoryConfirmText: "Tem a certeza que quer desativar a Categoria de Competências?",
          ActivateSkillsCategorySuccess: "Categoria de Competências ativada!",
          DeactivateSkillsCategorySuccess: "Categoria de Competências desativada!",
          AddSkill: "Adicionar Competência",
          AddSkillSuccess: "Competência adicionada!",
          EditSkill: "Editar Competência",
          EditSkillSuccess: "Competência editada!",
          LoadingSkillError: "Erro ao carregar a informação da Competência.",
          ActivateSkillConfirmText: "Tem a certeza que quer ativar a Competência?",
          DeactivateSkillConfirmText: "Tem a certeza que quer desativar a Competência?",
          ActivateSkillSuccess: "Competência ativada!",
          DeactivateSkillSuccess: "Competência desativada!",
          ImportXLSXSuccess: "Categorias importadas"
        }
      },
    },
  },
};
