import "./ViewBusinessLinesPage.css";

import { useCallback, useEffect, useMemo, useState } from "react";

import { BusinessLine } from "../../admin/business-lines/models/domain/BusinessLine";
import { BusinessLinesQueryOptions } from "../../admin/business-lines/models/dtos/BusinessLinesQueryOptions";
import { BusinessLinesService } from "../../admin/business-lines/services/BusinessLinesService";
import { ColumnDefinition } from "../../../lib/components/table/TableInterfaces";
import { ErrorPopup } from "../../../lib/components/popup/ErrorPopup";
import { PageLayout } from "../../../lib/layouts/main-content/PageLayout";
import { PagePermissions } from "../../../lib/infrastructure/authorization/PagePermissions";
import { Permission } from "../../../models/api/gate/Permission";
import { ResponsiveDataTable } from "../../../lib/components/table/ResponsiveDataTable";
import { Tag } from "../../../lib/components/tag/Tag";
import { Toggle } from "../../../lib/components/form/toggle/Toggle";
import { translate } from "../../../lib/infrastructure/i18n/InternationalizationService";
import { useFormControl } from "../../../lib/components/form/Form";
import { useGlobalLocation } from "../../../lib/infrastructure/location/LocationServices";
import { useNavigate } from "react-router-dom";
import { usePopup } from "../../../lib/infrastructure/ui/UIServices";
import { useServiceCallPro } from "../../../lib/hooks/useServiceCall";
import { useSetGlobalBusinessLine } from "../../../lib/infrastructure/business-line/BusinessLineServices";

var businessLinesSvc = new BusinessLinesService();


export const basePermissions = [
  Permission.GLOBAL_ADMIN, Permission.LOCATION_ADMIN,
  Permission.HUMAN_RESOURCES, Permission.LOCATION_MANAGER, Permission.LOCATION_ACCESS];

export function ViewBusinessLinesPage() {
  const openPopup = usePopup();
  const navigate = useNavigate();
  const locationID = useGlobalLocation();


  const setBusinessLineDetails = useSetGlobalBusinessLine();




  const [businessLines, setBusinessLines] = useState<BusinessLine[]>();
  const [totalBusinessLines, setTotalBusinessLines] = useState<number>();

  const getBusinessLinesCall = useServiceCallPro(businessLinesSvc.getBusinessLines);

  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [currentpage, setCurrentPage] = useState(0);

  const [timer, setTimer] = useState<NodeJS.Timeout>();
  const [searchWord, setSearchWord] = useState<string>();


  /*   var searchboxController = useFormControl<string>({
      isDisabled: false,
      enableAutoValidate: false,
    });
   */




  /****************************
 * DATA REQUESTS
 *****************************/

  useEffect(() => {
    setBusinessLineDetails(undefined);
  }, []);



  const getBusinessLines = useCallback(() => {
    if (!locationID) return;

    var queryOptions: BusinessLinesQueryOptions = {
      page: currentpage,
      pageLength: itemsPerPage,
      searchWord: searchWord,
    };

    getBusinessLinesCall.invoke("" + locationID, queryOptions)
      .then((data) => {
        if (data) {
          setBusinessLines(data.businessLines);
          setTotalBusinessLines(data.totalItems);
        }
      })
      .catch((error) => {
        if (!error) return;
        openPopup(<ErrorPopup>{error.response.data.message}</ErrorPopup>);
      });
  }, [locationID, setBusinessLines, getBusinessLinesCall, openPopup, setTotalBusinessLines, currentpage, itemsPerPage, searchWord]);




  const handleOnPageAndItemsChanged = useCallback(
    (items: number, currentPage: number) => {
      setItemsPerPage(items);
      setCurrentPage(currentPage);
    },
    [setItemsPerPage, setCurrentPage]
  );


  /****************************
  * DATA MANIPULATION EFFECTS
  *****************************/

  useEffect(() => {
    getBusinessLines();
  }, [currentpage, itemsPerPage, searchWord]);



  /*   useEffect(() => {
      if (timer)
        clearTimeout(timer);
  
      var responseTimeOut = setTimeout(() => {
        if (timer) {
          setCurrentPage(0);
          setSearchWord(searchboxController.value);
        }
      }, 1000);
  
      setTimer(responseTimeOut);
    }, [searchboxController.value]); */



  const columns: ColumnDefinition<BusinessLine>[] = useMemo(
    () => [
      {
        cellRenderProp: (v) => v.name,
        headerRender: translate("BUSINESSLINES.INFOANDFORM.Name"),
        isMobilePrimaryCell: true,
        width: "85%",
      },
      {
        cellRenderProp: (v) => < Tag
          text={v.isDisabled ? translate("COMMON.Disabled") : translate("COMMON.Enabled")}
          backgroundColor={v.isDisabled ? "status-light-grey" : "status-green"}
          isTiny={false}
        />,
        headerRender: translate("COMMON.Status"),
        isStatusCell: true
      }
    ], []);



  return <PagePermissions permissions={basePermissions} >
    <PageLayout
      tabTitle={translate("BUSINESSLINES.BusinessLines")}
      pageTitle={translate("BUSINESSLINES.BusinessLines")}
      className="view-business-lines-page"
    >
      <ResponsiveDataTable
        columnDefinitions={columns}
        items={businessLines || []}
        totalitems={totalBusinessLines || 0}
        paginationOptions={{ itemsPerPage: itemsPerPage, itemsPerPageOptions: [10, 15, 20] }}
        currentpage={currentpage}
        isLoading={getBusinessLinesCall.isLoading}
        onPageAndItemsChanged={handleOnPageAndItemsChanged}
        onClickRow={(b) => { navigate(`${b.id}`); }}
      />
    </PageLayout>
  </PagePermissions>
}