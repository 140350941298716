import { useCallback, useState } from "react";
import { useClosePopup, useCreateNotification, usePopup } from "../../../../../lib/infrastructure/ui/UIServices";

import { AllValid } from "../../../../../common/validators/ValidateFormControls";
import { Checkbox } from "../../../../../lib/components/checkbox/Checkbox";
import { ConditionalRender } from "../../../../../lib/functional/ConditionalRender";
import { ErrorPopup } from "../../../../../lib/components/popup/ErrorPopup";
import { ExceptionType } from "../../../../../lib/infrastructure/http/ExceptionType";
import { FormContainer } from "../../../../../lib/layouts/containers/form/FormContainer";
import { FormFieldTextInput } from "../../../../../lib/components/form/form-field/FormFieldTextInput";
import { FullScreenLoader } from "../../../../../lib/components/loader/FullScreenLoader";
import { MessageBar } from "../../../../../lib/components/message-bar/MessageBar";
import { Notification } from "../../../../../lib/components/notifications/Notification";
import { PopupActionButtons } from "../../../../../lib/layouts/containers/popup-buttons/PopupActionButtons";
import { PopupContainer } from "../../../../../lib/layouts/containers/popup-container/PopupContainer";
import { PopupContent } from "../../../../../lib/layouts/containers/popup-content/PopupContent";
import { PopupHeader } from "../../../../../lib/layouts/containers/popup-header/PopupHeader";
import { SkillsService } from "../../services/SkillsService";
import { Spacer } from "../../../../../lib/components/separator/Spacer";
import { ValidationErrorMessage } from "../../../../../lib/infrastructure/http/APIError";
import { translate } from "../../../../../lib/infrastructure/i18n/InternationalizationService";
import { trimString } from "../../../../../lib/utils/TrimString";
import { useFormControl } from "../../../../../lib/components/form/Form";
import { useServiceCallPro } from "../../../../../lib/hooks/useServiceCall";
import { validateWhiteSpaceStrings } from "../../../../../lib/validators/ValidateWhiteSpaceStrings";

interface IAddSkillsCategoryPopupProps {
    onChangesMade: () => void;
    locationId: string;
}

var skillsSvc = new SkillsService();

export function AddSkillsCategoryPopup(props: IAddSkillsCategoryPopupProps) {
    const closePopup = useClosePopup();
    const openPopup = usePopup();
    const createNotification = useCreateNotification();

    const createSkillsCategoryCall = useServiceCallPro(skillsSvc.createSkillsCategory);

    const nameFormControl = useFormControl<string>({
        validators: [validateWhiteSpaceStrings()],
        enableAutoValidate: true,
    });

    const [isOptional, setIsOptional] = useState<boolean>(false);
    const [validationAlert, setValidationAlert] = useState<ValidationErrorMessage>({ showAlert: false });



    const handleAddBusinessLineClicked = useCallback(() => {

        if (!AllValid(nameFormControl.validate())) return;


        const handleSuccess = () => {
            closePopup();
            props.onChangesMade();
            createNotification(
                <Notification
                    type="success"
                    title={translate("COMMON.SYSTEMPOPUPS.Success")}
                    text={translate("GLOBALADMIN.LOCATIONS.SKILLS.POPUP.AddSkillsCategorySuccess")}
                />
            );
        };

        const handleError = (error: any) => {
            if (!error) return;

            const errorMessage = error?.response?.data?.message || translate("ERRORS.UnknownError");
            const enumCode = error?.response?.data?.enumCode;

            if (enumCode === ExceptionType.ERROR_VALIDATION_NAME_ALREADY_EXISTS) {
                setValidationAlert({
                    showAlert: true,
                    message: errorMessage,
                });
            } else {
                openPopup(<ErrorPopup>{errorMessage}</ErrorPopup>);
            }
        };

        createSkillsCategoryCall.invoke("" + props.locationId, { name: trimString(nameFormControl.value), isOptional: isOptional })
            .then(handleSuccess)
            .catch(handleError);


    }, [nameFormControl.value, nameFormControl.validate, closePopup, createSkillsCategoryCall.invoke,
        openPopup, props.onChangesMade, createNotification, setValidationAlert, props.locationId, isOptional]);





    return (
        <PopupContainer className="popup-add-skills-category">
            {createSkillsCategoryCall.isLoading ? <FullScreenLoader /> : null}

            <PopupHeader title={translate("GLOBALADMIN.LOCATIONS.SKILLS.POPUP.AddSkillsCategory")} />
            <PopupContent>

                <FormContainer>
                    <FormFieldTextInput
                        formControl={nameFormControl}
                        label={translate("GLOBALADMIN.LOCATIONS.SKILLS.INFOANDFORM.CategoryName")}
                        placeholder={translate("GLOBALADMIN.LOCATIONS.SKILLS.INFOANDFORM.CategoryNamePlaceholder")}
                    />
                    <Checkbox
                        isChecked={isOptional}
                        text={translate("GLOBALADMIN.LOCATIONS.SKILLS.INFOANDFORM.Multivalences")}
                        onClick={() => setIsOptional(!isOptional)}
                    />


                    <ConditionalRender if={validationAlert.showAlert}>
                        <div className="name-already-exists">
                            <MessageBar type="error" text={"" + validationAlert.message} />
                        </div>
                    </ConditionalRender>


                </FormContainer>
                <Spacer mode={"vertical"} px="30" />
                <PopupActionButtons
                    buttons={[
                        {
                            text: translate("COMMON.Cancel"),
                            type: "tertiary",
                            onClick: () => closePopup(),
                        },
                        {
                            text: translate("COMMON.Add"),
                            type: "primary",
                            onClick: handleAddBusinessLineClicked,
                        },
                    ]}
                />
            </PopupContent>
        </PopupContainer>
    );
}
