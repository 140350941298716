import "./MyCertificationsPage.css";

import { useCallback, useEffect, useMemo, useState } from "react";
import { useClosePopup, useCreateNotification, usePopup, useWindowResize } from "../../../../lib/infrastructure/ui/UIServices";

import { ReactComponent as AttachmentIcon } from "../../../../lib/assets/icons/anexo.svg";
import { ColumnDefinition } from "../../../../lib/components/table/TableInterfaces";
import { DateTimeHelper } from "../../../../lib/helpers/datetime/DateTime";
import { EmployeeCertificationStateTranslationTerms } from '../../../../models/enums/EmployeeCertificationState';
import { EmployeeCertificationsSummaryDTO } from "../../../employees/models/dtos/EmployeeCertificationsSummaryDTO";
import { ErrorPopup } from "../../../../lib/components/popup/ErrorPopup";
import { MyService } from "../../services/MyService";
import { PageLayout } from "../../../../lib/layouts/main-content/PageLayout";
import { ResponsiveDataTable } from "../../../../lib/components/table/ResponsiveDataTable";
import { ScalableIconButton } from "../../../../lib/components/buttons/ScalableIconButton";
import { Tag } from "../../../../lib/components/tag/Tag";
import { Tooltip } from "../../../../lib/components/tooltip/Tooltip";
import axios from "axios";
import { getEmployeeCertificationStatusCss } from "../../../../common/helpers/getEmployeeCertificationStatusCss";
import { translate } from "../../../../lib/infrastructure/i18n/InternationalizationService";
import { useGlobalLocation } from "../../../../lib/infrastructure/location/LocationServices";
import { useServiceCallPro } from "../../../../lib/hooks/useServiceCall";

var svc = new MyService();


export function MyCertificationsPage() {
    const windowResize = useWindowResize();
    const openPopup = usePopup();

    const locationID = useGlobalLocation();
    const locationIDAsString = useMemo(() => "" + locationID, [locationID])

    const [certifications, setCertifications] = useState<EmployeeCertificationsSummaryDTO[]>();
    const [totalItems, setTotalItems] = useState<number>();

    const [itemsPerPage, setItemsPerPage] = useState(10);
    const [currentpage, setCurrentPage] = useState(0);


    const getMyCertificationsCall = useServiceCallPro(svc.getMyCertifications);
    const getUrlDownloadAttachmentCall = useServiceCallPro(svc.getUrlDownloadAttachment);



    /****************************
    * DATA REQUESTS
    *****************************/

    const getMyCertifications = useCallback((controller: AbortController | undefined) => {
        if (!locationIDAsString) return;

        getMyCertificationsCall.invoke(locationIDAsString, { page: currentpage, pageLength: itemsPerPage }, controller?.signal)
            .then((data) => {
                if (data) {
                    setCertifications(data.employeeCertifications);
                    setTotalItems(data.totalItems)
                }
            })
            .catch((error) => {
                if (!error || axios.isCancel(error)) return;
                openPopup(<ErrorPopup>{error.response.data.message}</ErrorPopup>);
            });
    }, [locationIDAsString, getMyCertificationsCall, currentpage, itemsPerPage, openPopup]);



    const downloadAttachment = useCallback((certificationId: number, fileId: number) => {
        if (!locationIDAsString) return;

        getUrlDownloadAttachmentCall.invoke(locationIDAsString, certificationId, fileId)
            .then((url) => window.open(url, "_blank"))
            .catch((error) => {
                if (!error) return;
                openPopup(<ErrorPopup>{error.response.data.message}</ErrorPopup>);
            })
    }, [locationIDAsString, getUrlDownloadAttachmentCall.invoke, openPopup]);



    /****************************
     * DATA MANIPULATION EFFECTS
     *****************************/

    useEffect(() => {
        if (!locationIDAsString) return;

        const controller = new AbortController();

        getMyCertifications(controller);

        return () => { controller.abort() };
    }, [locationIDAsString, currentpage, itemsPerPage]);



    /****************************
     * USER ACTIONS
     *****************************/

    const handleOnPageAndItemsClicked = useCallback((items: number, currentPage: number) => {
        setItemsPerPage(items);
        setCurrentPage(currentPage);
    }, [setItemsPerPage, setCurrentPage]);



    const handleDownloadAttachmentClicked = useCallback((certificationId: number, fileId: number) => {
        if (!certificationId || !fileId) return;
        downloadAttachment(certificationId, fileId);
    }, [downloadAttachment]);


    /****************************
     * CSS & HTML
     *****************************/

    const columns: ColumnDefinition<EmployeeCertificationsSummaryDTO>[] = useMemo(() => [
        {
            cellRenderProp: (v) => v.certificationName,
            headerRender: translate("EMPLOYEES.CERTIFICATIONS.INFOANDFORM.Name"),
            isMobilePrimaryCell: true,
            width: "50%",
        },
        {
            cellRenderProp: (v) => DateTimeHelper.formatDateTimeLocale(v.startDate),
            headerRender: translate("EMPLOYEES.CERTIFICATIONS.INFOANDFORM.StartDate"),
            width: "10%",
        },
        {
            cellRenderProp: (v) => DateTimeHelper.formatDateTimeLocale(v.endDate),
            headerRender: translate("EMPLOYEES.CERTIFICATIONS.INFOANDFORM.EndDate"),
            width: "10%",
        },
        {
            cellRenderProp: (v) => (
                <Tag
                    text={translate(EmployeeCertificationStateTranslationTerms[v.state])}
                    backgroundColor={getEmployeeCertificationStatusCss(v.state)}
                    isTiny={windowResize < 1024}
                />
            ),
            headerRender: translate("EMPLOYEES.CERTIFICATIONS.INFOANDFORM.State"),
            isStatusCell: true,
            width: "10%",
        },
        {
            cellRenderProp: (v) =>
                <div className="employee-info">
                    <div className="small-copy employee-name">
                        {v.createdBy.userDisplayName ?? v.createdBy.userEmail}
                    </div>
                    <div className="caption employee-id">{v.createdBy.userEmployeeID ?? "-"}</div>
                </div>,
            width: "15%",
            headerRender: translate("COMMON.RegisteredBy"),
        },
        {
            cellRenderProp: (v) => v.attachmentId &&
                <Tooltip text={translate("EMPLOYEES.CERTIFICATIONS.INFOANDFORM.ViewAttachment")}>
                    <ScalableIconButton
                        icon={<AttachmentIcon />}
                        onClick={() => v.attachmentId && handleDownloadAttachmentClicked(v.employeeCertificationId, v.attachmentId)}
                        size={24}
                    />
                </Tooltip>,
            width: "24px",
            isMobileHeaderIcon: true,
        }
    ], [windowResize, handleDownloadAttachmentClicked]);


    return <PageLayout
        tabTitle={translate("EMPLOYEES.CERTIFICATIONS.Certifications")}
        pageTitle={translate("EMPLOYEES.CERTIFICATIONS.Certifications")}
        className="my-certifications-page"
    >
        <ResponsiveDataTable
            columnDefinitions={columns}
            items={certifications || []}
            totalitems={totalItems || 0}
            paginationOptions={{ itemsPerPage: itemsPerPage, itemsPerPageOptions: [10, 20, 30] }}
            currentpage={currentpage}
            isLoading={getMyCertificationsCall.isLoading}
            onPageAndItemsChanged={handleOnPageAndItemsClicked}
        />
    </PageLayout>;
}