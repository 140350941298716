import "./Button.css";
import "./IconButton.css";

import { Button } from "./Button";
import { IconButton } from "./IconButton";
import { useMemo } from "react";
import { useWindowResize } from "../../infrastructure/ui/UIServices";

export interface IResponsiveButtonProps {
  text?: string;
  type: "primary" | "secondary" | "tertiary";
  icon: React.ReactNode;
  showButtonIcon?: boolean;
  isDisabled?: boolean;
  className?: string;
  onClick?: (ev: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
}

export function ResponsiveButton(props: IResponsiveButtonProps) {
  const windowResize = useWindowResize();

  const renderBtn = useMemo(() => {
    if (windowResize > 768 && props.text)
      return <Button
        text={props.text}
        type={props.type}
        icon={props.showButtonIcon ? props.icon : null}
        isDisabled={props.isDisabled}
        className={props.className}
        onClick={props.onClick}
      />
    else
      return <IconButton
        type={props.type}
        icon={props.icon}
        isDisabled={props.isDisabled}
        className={props.className}
        onClick={props.onClick}
      />
  }, [props, windowResize]);

  return renderBtn;
}