import { useCallback, useEffect, useState } from "react";
import { useClosePopup, useCreateNotification, usePopup } from "../../../../../lib/infrastructure/ui/UIServices";

import { AllValid } from "../../../../../common/validators/ValidateFormControls";
import { ErrorPopup } from "../../../../../lib/components/popup/ErrorPopup";
import { FormContainer } from "../../../../../lib/layouts/containers/form/FormContainer";
import { FormFieldTextInput } from "../../../../../lib/components/form/form-field/FormFieldTextInput";
import { FullScreenLoader } from "../../../../../lib/components/loader/FullScreenLoader";
import { LocationService } from "../../services/LocationServices";
import { Notification } from "../../../../../lib/components/notifications/Notification";
import { PopupActionButtons } from "../../../../../lib/layouts/containers/popup-buttons/PopupActionButtons";
import { PopupContainer } from "../../../../../lib/layouts/containers/popup-container/PopupContainer";
import { PopupContent } from "../../../../../lib/layouts/containers/popup-content/PopupContent";
import { PopupHeader } from "../../../../../lib/layouts/containers/popup-header/PopupHeader";
import { Spacer } from "../../../../../lib/components/separator/Spacer";
import { translate } from "../../../../../lib/infrastructure/i18n/InternationalizationService";
import { trimString } from "../../../../../lib/utils/TrimString";
import { useFormControl } from "../../../../../lib/components/form/Form";
import { useServiceCallPro } from "../../../../../lib/hooks/useServiceCall";
import { validateWhiteSpaceStrings } from "../../../../../lib/validators/ValidateWhiteSpaceStrings";
import { ExceptionType } from "../../../../../lib/infrastructure/http/ExceptionType";
import { ValidationErrorMessage } from "../../../../../lib/infrastructure/http/APIError";
import { ConditionalRender } from "../../../../../lib/functional/ConditionalRender";
import { MessageBar } from "../../../../../lib/components/message-bar/MessageBar";

var locationService = new LocationService();

interface IProps {
  locationId: number;
  onCompleted: () => void;
}

export function EditLocationPopup(props: IProps) {
  const closePopup = useClosePopup();
  const openPopup = usePopup();

  const createNotification = useCreateNotification();

  const getLocationByIdCall = useServiceCallPro(locationService.getLocationById);
  const editLocationCall = useServiceCallPro(locationService.editLocation);

  const nameFormControl = useFormControl<string>({
    validators: [validateWhiteSpaceStrings()],
    enableAutoValidate: true,
  });


  const [validationAlert, setValidationAlert] = useState<ValidationErrorMessage>({ showAlert: false });


  useEffect(() => {
    getLocationById();
  }, []);


  const getLocationById = useCallback(() => {
    if (props.locationId) {
      getLocationByIdCall
        .invoke(props.locationId + "")
        .then((response) => {
          nameFormControl.setValue(response.name);
        })
        .catch((error) => {
          if (!error) return;
          openPopup(<ErrorPopup>{error.response.data.message}</ErrorPopup>);
        });
    }
  }, [props.locationId, nameFormControl.value, openPopup, getLocationByIdCall]);




  const editLocation = useCallback(() => {
    if (!props.locationId || !AllValid(nameFormControl.validate())) return;

    const handleSuccess = () => {
      props.onCompleted();
      closePopup();
      createNotification(
        <Notification
          type="success"
          title={translate("COMMON.SYSTEMPOPUPS.Success")}
          text={translate(
            "GLOBALADMIN.LOCATIONS.POPUP.EditLocationSuccess"
          )}
        />
      );
    };



    const handleError = (error: any) => {
      if (!error) return;

      const errorMessage = error?.response?.data?.message || translate("ERRORS.UnknownError");
      const enumCode = error?.response?.data?.enumCode;

      if (enumCode === ExceptionType.ERROR_VALIDATION_NAME_ALREADY_EXISTS) {
        setValidationAlert({
          showAlert: true,
          message: errorMessage,
        });
      } else {
        openPopup(<ErrorPopup>{errorMessage}</ErrorPopup>);
      }
    };


    editLocationCall.invoke({ locationId: props.locationId, locationName: trimString(nameFormControl.value) })
      .then(handleSuccess)
      .catch(handleError);

  }, [props.locationId, nameFormControl.value, nameFormControl.validate,
  props.onCompleted, closePopup, openPopup, createNotification, editLocationCall.invoke]);




  return (
    <PopupContainer className="popup-edit-location">
      {editLocationCall.isLoading ? <FullScreenLoader /> : null}

      <PopupHeader
        title={translate("GLOBALADMIN.LOCATIONS.POPUP.EditLocation")}
      />

      <PopupContent isLoading={getLocationByIdCall.isLoading}>
        <FormContainer>
          <FormFieldTextInput
            formControl={nameFormControl}
            label={translate("GLOBALADMIN.LOCATIONS.INFO.Name")}
          />

          <ConditionalRender if={validationAlert.showAlert}>
            <div className="Name-already-exists">
              <MessageBar type="error" text={"" + validationAlert.message} />
            </div>
          </ConditionalRender>


        </FormContainer>
        <Spacer mode={"vertical"} px="30" />
        <PopupActionButtons
          buttons={[
            {
              text: translate("COMMON.Cancel"),
              type: "tertiary",
              onClick: () => closePopup(),
            },
            {
              text: translate("COMMON.Edit"),
              type: "primary",
              onClick: editLocation,
            },
          ]}
        />
      </PopupContent>
    </PopupContainer>
  );
}
