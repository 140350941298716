import { useClosePopup, useCreateNotification, usePopup } from "../../../../lib/infrastructure/ui/UIServices";

import { AllValid } from "../../../../common/validators/ValidateFormControls";
import { CriteriaCreateDTO } from "../../models/dtos/CriteriaCreateDTO";
import { CriteriasService } from "../../services/CriteriasService";
import { ErrorPopup } from "../../../../lib/components/popup/ErrorPopup";
import { FormFieldTextArea } from "../../../../lib/components/form/form-field/FormFieldTextArea";
import { FormFieldTextInput } from "../../../../lib/components/form/form-field/FormFieldTextInput";
import { FormSection } from "../../../../lib/components/form/section/FormSection";
import { FullScreenLoader } from "../../../../lib/components/loader/FullScreenLoader";
import { Notification } from "../../../../lib/components/notifications/Notification";
import { PopupActionButtons } from "../../../../lib/layouts/containers/popup-buttons/PopupActionButtons";
import { PopupContainer } from "../../../../lib/layouts/containers/popup-container/PopupContainer";
import { PopupContent } from "../../../../lib/layouts/containers/popup-content/PopupContent";
import { PopupHeader } from "../../../../lib/layouts/containers/popup-header/PopupHeader";
import { Spacer } from "../../../../lib/components/separator/Spacer";
import { translate } from "../../../../lib/infrastructure/i18n/InternationalizationService";
import { trimString } from "../../../../lib/utils/TrimString";
import { useCallback, useState } from "react";
import { useFormControl } from "../../../../lib/components/form/Form";
import { useServiceCallPro } from "../../../../lib/hooks/useServiceCall";
import { validateWhiteSpaceStrings } from "../../../../lib/validators/ValidateWhiteSpaceStrings";
import { MessageBar } from "../../../../lib/components/message-bar/MessageBar";
import { ExceptionType } from "../../../../lib/infrastructure/http/ExceptionType";
import { ConditionalRender } from "../../../../lib/functional/ConditionalRender";
import { ValidationErrorMessage } from "../../../../lib/infrastructure/http/APIError";

interface IAddCriteriaPopupProps {
    onCompletedOperations: () => void;
    locationId: string;
    businessLineId: string;
    roleId: string;
}

var criteriasSvc = new CriteriasService();

export function AddCriteriaPopup(props: IAddCriteriaPopupProps) {
    const closePopup = useClosePopup();
    const openPopup = usePopup();
    const createNotification = useCreateNotification();

    const addCriteriaCall = useServiceCallPro(criteriasSvc.addCriteria);

    const nameFormControl = useFormControl<string>({
        validators: [validateWhiteSpaceStrings()],
        enableAutoValidate: true,
    });

    const descriptionFormControl = useFormControl<string>({
        validators: [validateWhiteSpaceStrings()],
        enableAutoValidate: true,
    });

    const [validationAlert, setValidationAlert] = useState<ValidationErrorMessage>({ showAlert: false });


    const handleAddCriteria = useCallback(() => {
        if (!AllValid(nameFormControl.validate(), descriptionFormControl.validate())) return;

        let request: CriteriaCreateDTO = {
            name: trimString(nameFormControl.value),
            description: trimString(descriptionFormControl.value),
        };

        addCriteriaCall
            .invoke(props.locationId, props.businessLineId, props.roleId, request)
            .then(() => {
                closePopup();
                props.onCompletedOperations();
                createNotification(
                    <Notification
                        type="success"
                        title={translate("COMMON.SYSTEMPOPUPS.Success")}
                        text={translate("BUSINESSLINES.ROLES.CRITERIAS.POPUP.AddCriteriaSuccess")}
                    />
                );
            })
            .catch((error) => {
                const errorMessage = error?.response?.data?.message || translate("ERRORS.UnknownError");
                const enumCode = error?.response?.data?.enumCode;

                if (enumCode === ExceptionType.ERROR_VALIDATION_NAME_ALREADY_EXISTS) {
                    setValidationAlert({
                        showAlert: true,
                        message: errorMessage,
                    });
                } else {
                    openPopup(<ErrorPopup>{errorMessage}</ErrorPopup>);
                }
            });
    }, [props.businessLineId, setValidationAlert, props.locationId, props.roleId, props.onCompletedOperations, nameFormControl, descriptionFormControl, addCriteriaCall, closePopup, createNotification, openPopup]);


    return (
        <PopupContainer className="popup-add-criteria">
            {addCriteriaCall.isLoading ? <FullScreenLoader /> : null}

            <PopupHeader title={translate("BUSINESSLINES.ROLES.CRITERIAS.POPUP.AddCriteria")} />
            <PopupContent>
                <FormSection>
                    <div className="role-criteria-name-container">
                        <FormFieldTextInput
                            formControl={nameFormControl}
                            label={translate("BUSINESSLINES.ROLES.CRITERIAS.INFOANDFORM.Name")}
                            placeholder={translate("BUSINESSLINES.ROLES.CRITERIAS.INFOANDFORM.NamePlaceholder")}
                        />
                        <ConditionalRender if={validationAlert.showAlert}>
                            <Spacer mode="vertical" px="10" />
                            <div className="name-already-exists">
                                <MessageBar type="error" text={"" + validationAlert.message} />
                            </div>
                        </ConditionalRender>
                    </div>



                    <FormFieldTextArea
                        formControl={descriptionFormControl}
                        label={translate("BUSINESSLINES.ROLES.CRITERIAS.INFOANDFORM.Description")}
                    // placeholder={translate("BUSINESSLINES.ROLES.CRITERIAS.INFOANDFORM.DescriptionPlaceholder")}
                    />
                </FormSection>
                <Spacer mode={"vertical"} px="30" />
                <PopupActionButtons
                    buttons={[
                        {
                            text: translate("COMMON.Cancel"),
                            type: "tertiary",
                            onClick: () => closePopup(),
                        },
                        {
                            text: translate("COMMON.Add"),
                            type: "primary",
                            onClick: handleAddCriteria,
                        },
                    ]}
                />
            </PopupContent>
        </PopupContainer>
    );
}
