import "./ViewEvaluationPeriodsPage.css";

import { EvaluationPeriodState, EvaluationPeriodStateTranslationTerms } from "../../../models/enums/EvaluationPeriodState";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { usePopup, useWindowResize } from "../../../lib/infrastructure/ui/UIServices";

import { ReactComponent as AddIcon } from "../../../lib/assets/icons/add.svg";
import { ColumnDefinition } from "../../../lib/components/table/TableInterfaces";
import { CreatePeriodPopup } from "./popups/CreatePeriodPopup";
import { DateTimeHelper } from "../../../lib/helpers/datetime/DateTime";
import { EditEvaluationPeriodPopup } from "./popups/EditEvaluationPeriodPopup";
import { ReactComponent as EditIcon } from "../../../lib/assets/icons/editar.svg";
import { ErrorPopup } from "../../../lib/components/popup/ErrorPopup";
import { EvaluationPeriodSummaryDTO } from "../models/dtos/EvaluationPeriodSummaryDTO";
import { EvaluationPeriodsService } from "../services/EvaluationPeriodsService";
import { FormFieldTextInput } from "../../../lib/components/form/form-field/FormFieldTextInput";
import { PageLayout } from "../../../lib/layouts/main-content/PageLayout";
import { Permission } from "../../../models/api/gate/Permission";
import { QueryOptions } from "../../../models/api/QueryOptions";
import { ResponsiveButton } from "../../../lib/components/buttons/ResponsiveButton";
import { ResponsiveDataTable } from "../../../lib/components/table/ResponsiveDataTable";
import { ScalableIconButton } from "../../../lib/components/buttons/ScalableIconButton";
import { ReactComponent as SearchIcon } from "../../../lib/assets/icons/search-icon.svg";
import { Tag } from "../../../lib/components/tag/Tag";
import { Tooltip } from "../../../lib/components/tooltip/Tooltip";
import axios from "axios";
import { getEvaluationPeriodStateCss } from "../../../common/helpers/GetEvaluationPeriodStateCss";
import { translate } from "../../../lib/infrastructure/i18n/InternationalizationService";
import { useDebounce } from "../../../lib/hooks/useDebounce";
import { useFormControl } from "../../../lib/components/form/Form";
import { useGlobalLocation } from "../../../lib/infrastructure/location/LocationServices";
import { useHasPermissions } from "../../../lib/infrastructure/authorization/useHasLocationPermissions";
import { useServiceCallPro } from "../../../lib/hooks/useServiceCall";

var evaluationPeriodsSvc = new EvaluationPeriodsService();

export interface IOrderInfo {
  direction: "ASC" | "DESC" | "NONE";
  columnKey: string;
}


const topPermissions = [
  Permission.GLOBAL_ADMIN,
  Permission.LOCATION_ADMIN,
  Permission.HUMAN_RESOURCES
];


export function ViewEvaluationPeriodsPage() {

  const { isLoadingPermissions, hasPermission } = useHasPermissions(topPermissions);
  const navigate = useNavigate();

  const windowResize = useWindowResize();

  const openPopup = usePopup();
  const locationID = useGlobalLocation();
  const { id: businessLineID } = useParams();

  const [evaluationPeriods, setEvaluationPeriods] = useState<EvaluationPeriodSummaryDTO[]>([]);
  const [totalItems, setTotalItems] = useState<number>();

  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [currentpage, setCurrentPage] = useState(0);



  var searchFormControl = useFormControl<string>({
    isDisabled: false,
    enableAutoValidate: false,
  });


  const [searchWord, setSearchWord] = useState<string>();
  const debouncedSearchTerm = useDebounce(searchFormControl.value, 1000);

  const getEvaluationPeriodsCall = useServiceCallPro(evaluationPeriodsSvc.getEvaluationPeriods);



  /****************************
  * DATA REQUESTS
  *****************************/

  const getEvaluationPeriods = useCallback((controller: AbortController | undefined) => {

    if (!locationID) return;

    var queryOpts: QueryOptions = {
      searchWord: searchWord,
      page: currentpage,
      pageLength: itemsPerPage
    };

    getEvaluationPeriodsCall.invoke(`${locationID}`, "" + businessLineID, queryOpts, controller?.signal)
      .then((data) => {
        if (data) {
          setEvaluationPeriods(data.periods);
          setTotalItems(data.totalItems);
        }
      })
      .catch((error) => {
        if (!error || axios.isCancel(error)) return;
        openPopup(<ErrorPopup>{error.response.data.message}</ErrorPopup>);
      });
  }, [locationID, businessLineID, getEvaluationPeriodsCall.invoke, openPopup,
    setEvaluationPeriods, setTotalItems, searchWord, currentpage, itemsPerPage]);


  /****************************
   * DATA MANIPULATION EFFECTS
   *****************************/

  useEffect(() => {

    const controller = new AbortController();
    getEvaluationPeriods(controller);
    return () => { controller.abort() };

  }, [currentpage, itemsPerPage, searchWord]);

  useEffect(() => {
    setCurrentPage(0);
    setSearchWord(debouncedSearchTerm);
  }, [debouncedSearchTerm]);


  /****************************
   * USER ACTIONS
   *****************************/

  const handleOnPageAndItemsChanged = useCallback((items: number, currentPage: number) => {
    setItemsPerPage(items);
    setCurrentPage(currentPage);
  }, [setItemsPerPage, setCurrentPage]);


  const handleCreatePeriodButtonClicked = useCallback(() => {
    if (!locationID) return;
    openPopup(<CreatePeriodPopup
      locationID={`${locationID}`}
      businessLineId={"" + businessLineID}
      onCompletedOperations={() => getEvaluationPeriods(undefined)} />)
  }, [getEvaluationPeriods, locationID, businessLineID, openPopup]);


  const handleEditPeriodButtonClicked = useCallback((periodId: number) => {
    if (!locationID) return;
    openPopup(
      <EditEvaluationPeriodPopup
        locationID={`${locationID}`}
        businessLineId={"" + businessLineID}
        periodID={`${periodId}`}
        onCompletedOperations={() => getEvaluationPeriods(undefined)}
      />
    );
  }, [getEvaluationPeriods, locationID, businessLineID, openPopup]);


  /****************************
   * CSS & HTML
   *****************************/

  const columns: ColumnDefinition<EvaluationPeriodSummaryDTO>[] = useMemo(
    () => [
      {
        cellRenderProp: (v) => v.name,
        headerRender: translate("BUSINESSLINES.EVALUATIONPERIODS.INFOANDFORM.Name"),
        isMobilePrimaryCell: true,
      },
      {
        cellRenderProp: (v) => DateTimeHelper.formatDateTimeLocale(v.startDate),
        headerRender: translate("BUSINESSLINES.EVALUATIONPERIODS.INFOANDFORM.StartDate"),
      },
      {
        cellRenderProp: (v) => DateTimeHelper.formatDateTimeLocale(v.endDate),
        headerRender: translate("BUSINESSLINES.EVALUATIONPERIODS.INFOANDFORM.EndDate"),
      },
      {
        cellRenderProp: (v) => (
          <Tag
            text={translate(EvaluationPeriodStateTranslationTerms[v.state])}
            backgroundColor={getEvaluationPeriodStateCss(v.state)}
            isTiny={windowResize < 1024}
          />
        ),
        headerRender: translate("BUSINESSLINES.EVALUATIONPERIODS.INFOANDFORM.Status"),
        isStatusCell: true
      },
      {
        cellRenderProp: (v) => {
          if (isLoadingPermissions || !hasPermission)
            return null;

          return (
            <Tooltip text={translate("BUSINESSLINES.EVALUATIONPERIODS.EditEvaluationPeriod")}>
              <ScalableIconButton
                isDisabled={v.state === EvaluationPeriodState.Completed || v.state === EvaluationPeriodState.Canceled}
                icon={<EditIcon />}
                onClick={() => handleEditPeriodButtonClicked(v.id)}
                size={24}
              />
            </Tooltip>
          );
        },
        width: "24px",
        columnKey: "stats",
        isSortable: false,
        isMobileHeaderIcon: true,
      }
    ], [windowResize, isLoadingPermissions, hasPermission, handleEditPeriodButtonClicked]);


  const renderCreatePeriodButton = useMemo(() => {
    if (isLoadingPermissions || !hasPermission)
      return null;

    return (<ResponsiveButton
      text={translate("BUSINESSLINES.EVALUATIONPERIODS.CreatePeriod")}
      type="primary"
      icon={<AddIcon />}
      onClick={handleCreatePeriodButtonClicked}
    />);
  }, [isLoadingPermissions, hasPermission, handleCreatePeriodButtonClicked]);


  return (
    <PageLayout
      tabTitle={translate("BUSINESSLINES.EVALUATIONPERIODS.EvaluationPeriods")}
      pageTitle={translate("BUSINESSLINES.EVALUATIONPERIODS.EvaluationPeriods")}
      className="evaluation-periods"
      showBreadcrumb
      actions={renderCreatePeriodButton}
    >
      <FormFieldTextInput
        formControl={searchFormControl}
        icon={<SearchIcon />}
        placeholder={translate("COMMON.FORM.SearchPlaceholder")}
        className="search-box"
      />

      <ResponsiveDataTable
        columnDefinitions={columns}
        items={evaluationPeriods || []}
        totalitems={totalItems || 0}
        paginationOptions={{ itemsPerPage: itemsPerPage, itemsPerPageOptions: [10, 20, 30] }}
        currentpage={currentpage}
        isLoading={getEvaluationPeriodsCall.isLoading}
        onPageAndItemsChanged={handleOnPageAndItemsChanged}
        onClickRow={(period) => navigate(`${period.id}`)}
      />
    </PageLayout>

  );
}
