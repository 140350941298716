export function getEvaluationStateCss(statusId: number) {
    switch (statusId) {
        case 1:
            return "status-light-grey";                  // Ready to evaluate
        case 2:
            return "status-yellow";                     // in progress
        case 3:
            return "status-secondary-green";            // confirmed
        case 4:
            return "status-blue";                       // calibrated
        case 5:
        return "status-green";                          // concluded
        case 6:
            return "status-secondary-orange";          //rejected
        case 7:
            return "status-red";                        //canceled

        default:
            return undefined;
    }
}
