import { EmployeeCertificationState, EmployeeCertificationStateAsEnum, EmployeeCertificationStateInfo, EmployeeCertificationStateTranslationTerms, getEmployeeCertificationStateOptions } from "../../../../../models/enums/EmployeeCertificationState";
import { useCallback, useMemo, useState } from "react";
import { useClosePopup, useCreateNotification, usePopup } from "../../../../../lib/infrastructure/ui/UIServices";

import { AllValid } from "../../../../../common/validators/ValidateFormControls";
import { DateTime } from "luxon";
import { EmployeeCertificationCreateDTO } from "../../../models/dtos/EmployeeCertificationCreateDTO";
import { EmployeeCertificationsService } from "../../../services/EmployeeCertificationsService";
import { ErrorPopup } from "../../../../../lib/components/popup/ErrorPopup";
import { FileUploadButton } from "../../../../../lib/components/file/FileUploadButton";
import { FormFieldDatePicker } from "../../../../../lib/components/form/form-field/FormFieldDatePicker";
import { FormFieldSelectSingle } from "../../../../../lib/components/form/form-field/FormFieldSelectSingle";
import { FormFieldTextInput } from "../../../../../lib/components/form/form-field/FormFieldTextInput";
import { FormSection } from "../../../../../lib/components/form/section/FormSection";
import { FullScreenLoader } from "../../../../../lib/components/loader/FullScreenLoader";
import { ReactComponent as ImportIcon } from "../../../../../lib/assets/icons/import.svg";
import { Notification } from "../../../../../lib/components/notifications/Notification";
import { PopupActionButtons } from "../../../../../lib/layouts/containers/popup-buttons/PopupActionButtons";
import { PopupContainer } from "../../../../../lib/layouts/containers/popup-container/PopupContainer";
import { PopupContent } from "../../../../../lib/layouts/containers/popup-content/PopupContent";
import { PopupHeader } from "../../../../../lib/layouts/containers/popup-header/PopupHeader";
import { Spacer } from "../../../../../lib/components/separator/Spacer";
import { hasOneElementSelected } from "../../../../../lib/validators/ValidateSelectSingle";
import { translate } from "../../../../../lib/infrastructure/i18n/InternationalizationService";
import { useFormControl } from "../../../../../lib/components/form/Form";
import { useGlobalLocation } from "../../../../../lib/infrastructure/location/LocationServices";
import { useServiceCallPro } from "../../../../../lib/hooks/useServiceCall";
import { validateDateTime } from "../../../../../lib/validators/ValidateDateTime";
import { validateWhiteSpaceStrings } from "../../../../../lib/validators/ValidateWhiteSpaceStrings";

interface IAddEmployeeCertificationPopupProps {
    employeeId: string | undefined;
    onCompletedOperations: () => void;
}

var svc = new EmployeeCertificationsService();

const labelCertificateStateSelector = (item: EmployeeCertificationStateInfo) => item.label;
const idCertificateStateSelector = (item: EmployeeCertificationStateInfo) => item.id;

export function AddEmployeeCertificationPopup(props: IAddEmployeeCertificationPopupProps) {
    const closePopup = useClosePopup();
    const openPopup = usePopup();
    const createNotification = useCreateNotification();
    const { employeeId } = props;

    const locationID = useGlobalLocation();
    const locationIDAsString = useMemo(() => "" + locationID, [locationID])

    const [certificationStateOptions] = useState<EmployeeCertificationStateInfo[]>(getEmployeeCertificationStateOptions());

    const [attachmentFile, setAttachmentFile] = useState<File>();

    const addCertificationCall = useServiceCallPro(svc.addEmployeeCertification);
    const uploadCertificationAttachmentCall = useServiceCallPro(svc.uploadEmployeeCertificationAttachment);

    const nameFormControl = useFormControl<string>({
        validators: [validateWhiteSpaceStrings()],
        enableAutoValidate: true,
    });

    const stateFormControl = useFormControl<EmployeeCertificationStateInfo>({
        validators: [hasOneElementSelected()],
        enableAutoValidate: false
    });

    const startDateFormControl = useFormControl<DateTime>({
        validators: [validateDateTime()],
        enableAutoValidate: false
    });

    const endDateFormControl = useFormControl<DateTime>({
        validators: [],
        enableAutoValidate: false
    });



    /****************************
    * DATA REQUESTS
    *****************************/



    /****************************
    * DATA MANIPULATION EFFECTS
    *****************************/



    /****************************
    * USER ACTIONS
    *****************************/

    const handleFileImport = useCallback((file: File) => {
        setAttachmentFile(file);
    }, [setAttachmentFile]);


    const handleSelectClicked = useCallback(() => {
        if (!employeeId) return;

        if (!AllValid(nameFormControl.validate(), startDateFormControl.validate(), stateFormControl.validate())) return;


        let request: EmployeeCertificationCreateDTO = {
            certificationName: nameFormControl.value ?? "",
            startDate: startDateFormControl.value?.toISO() || null,
            endDate: endDateFormControl.value?.toISO() || null,
            state: stateFormControl.value?.id ? EmployeeCertificationStateAsEnum[parseInt(stateFormControl?.value?.id)] : EmployeeCertificationState.REGISTERED
        };

        addCertificationCall
            .invoke(locationIDAsString, employeeId, request)
            .then((response) => {
                if (attachmentFile && response.employeeCertificationId) {
                    uploadCertificationAttachmentCall
                        .invoke(locationIDAsString, employeeId, "" + response.employeeCertificationId, attachmentFile)
                        .then(_ => {
                            createNotification(<Notification
                                type="success"
                                title={translate("COMMON.SYSTEMPOPUPS.Success")}
                                text={translate("EMPLOYEES.CERTIFICATIONS.POPUPS.AddCertificationSuccess")}
                            />);
                            props.onCompletedOperations();
                            closePopup();
                        }).catch(error => {
                            if (!error) return;
                            openPopup(<ErrorPopup>{translate("EMPLOYEES.CERTIFICATIONS.POPUPS.AddCertificationUploadError")}</ErrorPopup>);
                        });
                }
                else {
                    createNotification(<Notification
                        type="success"
                        title={translate("COMMON.SYSTEMPOPUPS.Success")}
                        text={translate("EMPLOYEES.CERTIFICATIONS.POPUPS.AddCertificationSuccess")}
                    />);
                    closePopup();
                    props.onCompletedOperations();
                }
            })
            .catch((error) => {
                if (!error) return;
                openPopup(<ErrorPopup>{error.response.data.message}</ErrorPopup>);
            });
    }, [locationIDAsString,
        employeeId,
        nameFormControl.value,
        startDateFormControl.value,
        endDateFormControl.value,
        stateFormControl.value,
        nameFormControl.validate,
        startDateFormControl.validate,
        endDateFormControl.validate,
        stateFormControl.validate,
        addCertificationCall.invoke,
        attachmentFile,
        uploadCertificationAttachmentCall.invoke,
        createNotification,
        closePopup,
        props,
        openPopup]);







    return <PopupContainer className="popup-add-employee-certification">
        {addCertificationCall.isLoading || uploadCertificationAttachmentCall.isLoading ? <FullScreenLoader /> : null}
        <PopupHeader title={translate("EMPLOYEES.CERTIFICATIONS.POPUPS.AddCertification")} />
        <PopupContent>
            <FormSection isInputGap childrenFlexDirColumn>
                <FormFieldTextInput
                    formControl={nameFormControl}
                    label={translate("EMPLOYEES.CERTIFICATIONS.INFOANDFORM.Name")}
                    placeholder={translate("EMPLOYEES.CERTIFICATIONS.INFOANDFORM.NamePlaceholder")}
                />
                <FormFieldSelectSingle
                    formControl={stateFormControl}
                    label={translate("EMPLOYEES.CERTIFICATIONS.INFOANDFORM.State")}
                    placeholder={translate("EMPLOYEES.CERTIFICATIONS.INFOANDFORM.StatePlaceholder")}
                    options={certificationStateOptions}
                    labelSelector={labelCertificateStateSelector}
                    idSelector={idCertificateStateSelector}
                    maxHeightOptions={"16rem"}
                />
                <FormFieldDatePicker
                    formControl={startDateFormControl}
                    label={translate("EMPLOYEES.CERTIFICATIONS.INFOANDFORM.StartDate")}
                    placeholder={translate("EMPLOYEES.CERTIFICATIONS.INFOANDFORM.DatePlaceholder")}
                    maxDate={endDateFormControl.value}
                />
                <FormFieldDatePicker
                    formControl={endDateFormControl}
                    label={translate("EMPLOYEES.CERTIFICATIONS.INFOANDFORM.EndDate")}
                    placeholder={translate("EMPLOYEES.CERTIFICATIONS.INFOANDFORM.DatePlaceholder")}
                    minDate={startDateFormControl.value}
                />
                <Spacer mode={"vertical"} px="0" />
                <div className="attachment-info">
                    <FileUploadButton
                        text={translate("COMMON.AddAttachment")}
                        type="secondary"
                        onFileSelected={handleFileImport}
                        icon={<ImportIcon />}
                    />
                    <Spacer mode={"vertical"} px="10" />
                    {attachmentFile ? <div className="attachment-name">{attachmentFile.name}</div> : null}
                </div>
            </FormSection>
            <Spacer mode={"vertical"} px="30" />
            <PopupActionButtons
                buttons={[
                    {
                        text: translate("COMMON.Cancel"),
                        type: "tertiary",
                        onClick: () => closePopup(),
                    },
                    {
                        text: translate("COMMON.Add"),
                        type: "primary",
                        onClick: handleSelectClicked,
                    },
                ]}
            />
        </PopupContent>
    </PopupContainer>;
}