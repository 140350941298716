import { ConfigurationProvider } from "../../../lib/infrastructure/configuration/ConfigurationProvider";
import { CreateEvaluationCommentRequestDTO } from "../models/CreateEvaluationCommentRequestDTO";
import { EmployeeActionItemCreateDTO } from "../../employees/models/dtos/EmployeeActionItemCreateDTO";
import { EmployeeActionItemDetailsDTO } from "../../employees/models/dtos/EmployeeActionItemDetailsDTO";
import { EmployeeActionItemSummaryDTO } from "../../employees/models/dtos/EmployeeActionItemSummaryDTO";
import { EmployeeActionItemsResponseDTO } from "../../employees/models/dtos/EmployeeActionItemsResponseDTO";
import { EvaluationAbsencesDTO } from "../models/EvaluationAbsencesDTO";
import { EvaluationBonusResponseDTO } from "../models/EvaluationBonusResponseDTO";
import { EvaluationCertificatesResponseDTO } from "../models/EvaluationCertificatesResponseDTO";
import { EvaluationCommentsResponseDTO } from "../models/EvaluationCommentsResponseDTO";
import { EvaluationDetailsDTO } from "../models/EvaluationDetailsDTO";
import { EvaluationDetailsDTO_V2 } from "../models/EvaluationDetailsDTO_V2";
import { EvaluationDetailsUpdateDTO } from "../models/EvaluationDetailsUpdateDTO";
import { EvaluationFollowUpItemStatus } from "../models/EvaluationFollowUpItemStatus";
import { EvaluationFollowUpItemsResponseDTO } from "../models/EvaluationFollowUpItemsResponseDTO";
import { EvaluationFollowupItemsUpdateDTO } from "../models/EvaluationFollowupItemsUpdateDTO";
import { EvaluationHistoryResponseDTO } from "../models/EvaluationHistoryResponseDTO";
import { EvaluationMatrixResponseDTO } from "../models/EvaluationMatrixResponseDTO";
import { EvaluationState } from "../models/EvaluationStatus";
import { EvaluationUpdateStatusDTO } from "../models/EvaluationUpdateStatusDTO";
import { HttpClient } from "../../../lib/infrastructure/http/HttpClient";
import { UpdateEvaluationRequestDTO } from "../models/UpdateEvaluationRequestDTO";
import { EmployeeBonusResponseDTO } from "../../employees/models/dtos/EmployeeBonusReponseDTO";
import { NextRoleDetailsDTO } from "../models/NextRoleDetailsDTO";

/* const evaluationDetailsMock: EvaluationDetailsDTO = {
    evaluationId: 1,
    evaluationStatus: EvaluationState.READY_TO_EVALUATE,
    employee: {
        userId: "1",
        userEmployeeID: "1",
        userEmail: "john.doe@doamin.com",
        userDisplayName: "John Doe"
    },
    employeeRoleId: 1,
    employeeRoleDescription: "Operador Logística",
    evaluationTotal: 0,
    skillsCategories: [
        {
            id: 1,
            name: "Behavioral Skills",
            skills: [
                {
                    id: 1,
                    name: "Adapting and Responding to Change",
                    value: "",
                    refValue: "1",
                    isValid: true
                },
                {
                    id: 2,
                    name: "Analysing",
                    value: "",
                    refValue: "1",
                    isValid: true
                },
            ]
        },
        {
            id: 2,
            name: "Needling",
            skills: [
                {
                    id: 3,
                    name: "Cabine Agulhas",
                    value: "",
                    refValue: "1",
                    isValid: true
                },
                {
                    id: 4,
                    name: "Needling Di 4.5",
                    value: "",
                    refValue: "1",
                    isValid: true
                },
            ]
        },
    ],
    checklistItems: [
        {
            id: 2,
            description: "Desempenho: Média da avaliação do bónus > 4 nos últimos 12 meses",
            isChecked: false
        },
        {
            id: 1,
            description: "Cumprimento do regulamento interno: Últimos 3 anos",
            isChecked: true
        },
        {
            id: 3,
            description: "Absentismo: Máx X faltas injustificadas nos últimos 3 anos",
            isChecked: false
        },
    ],
    feedbackManagerEmployee: "Lorem Ipsum",
    feedbackEmployeeManager: "Lorem Ipsum",
    canCalibrate: true,
    canReject: true,
    nextRoleID: undefined
} */

const bonusMock: EvaluationBonusResponseDTO = {
    items: [
        {
            id: 1,
            periodName: "1º Trimestre 2023",
            evaluationValue: 4
        },
        {
            id: 2,
            periodName: "2º Trimestre 2023",
            evaluationValue: 4
        },
    ]
}

const unjustifiedAbsencesMock: string = "4";

const certificatesMock: EvaluationCertificatesResponseDTO = {
    items: [
        {
            id: 1,
            certificateName: "Certificado A",
            startDate: "2024-01-01T00:00:00+00:00",
            state: 1,
        },
        {
            id: 2,
            certificateName: "Certificado B",
            startDate: "2024-01-01T00:00:00+00:00",
            state: 2,
        },
        {
            id: 3,
            certificateName: "Certificado C",
            startDate: "2024-01-01T00:00:00+00:00",
            state: 3,
        },
    ]
}

const followUpItemsMock: EvaluationFollowUpItemsResponseDTO = {
    items: [
        // {
        //     name: "Item A",
        //     description: "Lorem Ipsum",
        //     startDate: "2024-01-01T00:00:00+00:00",
        //     conclusionDate: "2024-01-01T00:00:00+00:00",
        //     status: EvaluationFollowUpItemStatus.REGISTERED,
        // },
        {
            name: "Item B",
            description: "Lorem Ipsum",
            startDate: "2024-01-01T00:00:00+00:00",
            conclusionDate: "2024-01-01T00:00:00+00:00",
            status: EvaluationFollowUpItemStatus.INPROGRESS,
        },
    ]
};


const Route = (path: string) => {
    return ConfigurationProvider.getConfiguration().App.BackendUrl + path;
};

export class EvaluationsService {

/*     getEvaluationDetailsById(locationId: string, businessLineId: string, preiodId: string, matrixId: string, evaluationId: string): Promise<EvaluationDetailsDTO> {
        return new Promise((resolve) => {
            setTimeout(() => {
                resolve(evaluationDetailsMock);
            }, 1000);
        });
    } */





    getEvaluationDetailsById_V2(locationId: string, businessLineId: string, period: string, evaluationPeriodMatrix: string, evaluationId: string): Promise<EvaluationDetailsDTO_V2> {

        return HttpClient.sessionRequest<EvaluationDetailsDTO_V2>({
            method: "GET",
            url: Route(`/api/v1/Locations/${locationId}/BusinessLines/${businessLineId}/EvaluationPeriods/${period}/EvaluationPeriodMatrices/${evaluationPeriodMatrix}/Evaluations/${evaluationId}`),
        })
            .then((response) => {
                return response.data
            })
            .catch((error) => {
                throw error;
            });
    }



    getEvaluationMatrix(locationId: string, businessLineId: string, periodId: string, evaluationPeriodMatrix: string, evaluationId: string): Promise<EvaluationMatrixResponseDTO> {

        return HttpClient.sessionRequest<EvaluationMatrixResponseDTO>({
            method: "GET",
            url: Route(`/api/v1/Locations/${locationId}/BusinessLines/${businessLineId}/EvaluationPeriods/${periodId}/EvaluationPeriodMatrices/${evaluationPeriodMatrix}/Evaluations/${evaluationId}/Matrix`),
        })
            .then((response) => {
                return response.data
            })
            .catch((error) => {
                throw error;
            });
    }


    updateEvaluationDetails_V2(locationId: string, businessLineId: string, periodId: string, matrixId: string, evaluationId: string, requestDto: UpdateEvaluationRequestDTO): Promise<EvaluationDetailsDTO_V2> {
        return HttpClient.sessionRequest({
            method: "PUT",
            url: Route(`/api/v1/Locations/${locationId}/BusinessLines/${businessLineId}/EvaluationPeriods/${periodId}/EvaluationPeriodMatrices/${matrixId}/Evaluations/${evaluationId}`),
            data: requestDto,
        })
            .then((response) => {
                return response.data
            })
            .catch((error) => {
                throw error;
            });
    }






    getEvaluationBonus_OLD(locationId: string, businessLineId: string, preiodId: string, matrixId: string, evaluationId: string): Promise<EvaluationBonusResponseDTO> {
        return new Promise((resolve) => {
            setTimeout(() => {
                resolve(bonusMock);
            }, 1000);
        });
    }

    getUnjustifiedAbsences(locationId: string, businessLineId: string, periodId: string, evaluationId: string): Promise<EvaluationAbsencesDTO> {
        return HttpClient.sessionRequest({
            method: "GET",
            url: Route(`/api/v1/Locations/${locationId}/BusinessLines/${businessLineId}/EvaluationPeriods/${periodId}/Absenteeism/Evaluations/${evaluationId}`),
        })
            .then((response) => {
                return response.data
            })
            .catch((error) => {
                throw error;
            });
    }

    getEvaluationHistory(locationId: string, businessLineId: string, periodId: string, matrixId: string, evaluationId: string): Promise<EvaluationHistoryResponseDTO> {
        return HttpClient.sessionRequest({
            method: "GET",
            url: Route(`/api/v1/Locations/${locationId}/BusinessLines/${businessLineId}/EvaluationPeriods/${periodId}/EvaluationPeriodMatrices/${matrixId}/Evaluations/${evaluationId}/History`),
        })
            .then((response) => {
                return response.data
            })
            .catch((error) => {
                throw error;
            });
    }


    getNexRoleDetails_V2(locationId: string, businessLineId: string, evaluationPeriodId: string, evaluationPeriodMatrixId: string, evaluationId: string, nextRoleId: number): Promise<NextRoleDetailsDTO> {
        return HttpClient.sessionRequest<NextRoleDetailsDTO>({
            method: "GET",
            url: Route(`/api/v1/Locations/${locationId}/BusinessLines/${businessLineId}/EvaluationPeriods/${evaluationPeriodId}/EvaluationPeriodMatrices/${evaluationPeriodMatrixId}/Evaluations/${evaluationId}/NextRoleEntries/${nextRoleId}`),
        })
            .then((response) => {
                return response.data
            })
            .catch((error) => {
                throw error;
            });
    }




    createEvaluationActionItem(locationId: string, businessLineId: string, periodId: string, matrixId: string, evaluationId: string, request: EmployeeActionItemCreateDTO): Promise<EmployeeActionItemDetailsDTO> {
        return HttpClient.sessionRequest<EmployeeActionItemDetailsDTO>({
            method: "POST",
            url: Route(`/api/v1/Locations/${locationId}/BusinessLines/${businessLineId}/EvaluationPeriods/${periodId}/EvaluationPeriodMatrices/${matrixId}/Evaluations/${evaluationId}/ActionItems`),
            data: request,
        })
            .then((response) => {
                return response.data
            })
            .catch((error) => {
                throw error;
            });
    }


    getEvaluationActionItems(locationId: string, businessLineId: string, periodId: string, matrixId: string, evaluationId: string): Promise<EmployeeActionItemSummaryDTO[]> {
        return HttpClient.sessionRequest<EmployeeActionItemsResponseDTO>({
            method: "GET",
            url: Route(`/api/v1/Locations/${locationId}/BusinessLines/${businessLineId}/EvaluationPeriods/${periodId}/EvaluationPeriodMatrices/${matrixId}/Evaluations/${evaluationId}/ActionItems`),
        })
            .then((response) => {
                return response.data.items
            })
            .catch((error) => {
                throw error;
            });
    }


    getEvaluationBonus(locationId: string, businessLineId: string, periodId: string, matrixId: string, evaluationId: string): Promise<EmployeeBonusResponseDTO> {
        return HttpClient.sessionRequest<EmployeeBonusResponseDTO>({
            method: "GET",
            url: Route(`/api/v1/Locations/${locationId}/BusinessLines/${businessLineId}/EvaluationPeriods/${periodId}/EvaluationPeriodMatrices/${matrixId}/Evaluations/${evaluationId}/Bonus`),
        })
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                throw error;
            });
    }


    
    deleteEvaluationActionItem(locationId: string, businessLineId: string, periodId: string, matrixId: string, evaluationId: string, actionItemId: string): Promise<void> {
        return HttpClient.sessionRequest({
            method: "DELETE",
            url: Route(`/api/v1/Locations/${locationId}/BusinessLines/${businessLineId}/EvaluationPeriods/${periodId}/EvaluationPeriodMatrices/${matrixId}/Evaluations/${evaluationId}/ActionItems/${actionItemId}`),
        })
            .then((_) => {
            })
            .catch((error) => {
                throw error;
            });
    }



    getEvaluationCertificates(locationId: string, businessLineId: string, preiodId: string, matrixId: string, evaluationId: string): Promise<EvaluationCertificatesResponseDTO> {
        return new Promise((resolve) => {
            setTimeout(() => {
                resolve(certificatesMock);
            }, 1000);
        });
    }

    getEvaluationFollowUpItems(locationId: string, businessLineId: string, preiodId: string, matrixId: string, evaluationId: string): Promise<EvaluationFollowUpItemsResponseDTO> {
        return new Promise((resolve) => {
            setTimeout(() => {
                resolve(followUpItemsMock);
            }, 1000);
        });
    }



    updateEvaluationDetails(locationId: string, businessLineId: string, periodId: string, matrixId: string, evaluationId: string, requestDto: EvaluationDetailsUpdateDTO): Promise<void> {
        return HttpClient.sessionRequest({
            method: "PUT",
            url: Route(`/api/v1/Locations/${locationId}/BusinessLines/${businessLineId}/EvaluationPeriods/${periodId}/EvaluationPeriodMatrices/${matrixId}/Evaluation/${evaluationId}`),
            data: requestDto,
        })
            .then((_) => { })
            .catch((error) => {
                throw error;
            });
    }

    updateEvaluationFollowUpItems(locationId: string, businessLineId: string, periodId: string, matrixId: string, evaluationId: string, requestDto: EvaluationFollowupItemsUpdateDTO): Promise<void> {
        return HttpClient.sessionRequest({
            method: "PUT",
            url: Route(`/api/v1/Locations/${locationId}/BusinessLines/${businessLineId}/EvaluationPeriods/${periodId}/EvaluationPeriodMatrices/${matrixId}/Evaluation/${evaluationId}`),
            data: requestDto,
        })
            .then((_) => { })
            .catch((error) => {
                throw error;
            });
    }


    updateEvaluationStatus(locationId: string, businessLineId: string, periodId: string, matrixId: string, evaluationId: string, requestDto: EvaluationUpdateStatusDTO): Promise<EvaluationDetailsDTO_V2> {
        return HttpClient.sessionRequest({
            method: "PUT",
            url: Route(`/api/v1/Locations/${locationId}/BusinessLines/${businessLineId}/EvaluationPeriods/${periodId}/EvaluationPeriodMatrices/${matrixId}/Evaluations/${evaluationId}/Status`),
            data: requestDto,
        })
            .then((response) => {
                return response.data
            })
            .catch((error) => {
                throw error;
            });
    }


    getEvaluationComments(locationId: string, businessLineId: string, periodId: string, matrixId: string, evaluationId: string): Promise<EvaluationCommentsResponseDTO> {
        return HttpClient.sessionRequest<EvaluationCommentsResponseDTO>({
            method: "GET",
            url: Route(`/api/v1/Locations/${locationId}/BusinessLines/${businessLineId}/EvaluationPeriods/${periodId}/EvaluationPeriodMatrices/${matrixId}/Evaluations/${evaluationId}/Comments`),
        })
            .then((response) => {
                return response.data
            })
            .catch((error) => {
                throw error;
            });
    }


    createEvaluationComment(locationId: string, businessLineId: string, periodId: string, matrixId: string, evaluationId: string, requestDto: CreateEvaluationCommentRequestDTO): Promise<void> {
        return HttpClient.sessionRequest({
            method: "POST",
            url: Route(`/api/v1/Locations/${locationId}/BusinessLines/${businessLineId}/EvaluationPeriods/${periodId}/EvaluationPeriodMatrices/${matrixId}/Evaluations/${evaluationId}/Comments`),
            data: requestDto,
        })
            .then((response) => {
                return response.data
            })
            .catch((error) => {
                throw error;
            });
    }
}
