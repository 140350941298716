import "./EvaluationMatrixPage.css";

import { useCallback, useEffect, useMemo, useState } from "react";
import { useClosePopup, useCreateNotification, usePopup, useWindowResize } from "../../../lib/infrastructure/ui/UIServices";
import { useNavigate, useParams } from "react-router-dom";

import { ColumnDefinition } from "../../../lib/components/table/TableInterfaces";
import { DateTime } from "ts-luxon";
import { ErrorPopup } from "../../../lib/components/popup/ErrorPopup";
import { EvaluationMatricesService } from "../services/EvaluationMatricesService";
import { EvaluationMatrixSummaryDTO } from "../models/dtos/EvaluationMatrixSummaryDTO";
import { EvaluationMatrixVersionSummaryDTO } from "../models/dtos/EvaluationMatrixVersionSummaryDTO";
import { FullScreenLoader } from "../../../lib/components/loader/FullScreenLoader";
import { Notification } from "../../../lib/components/notifications/Notification";
import { PageLayout } from "../../../lib/layouts/main-content/PageLayout";
import { PagePermissions } from "../../../lib/infrastructure/authorization/PagePermissions";
import { Permission } from "../../../models/api/gate/Permission";
import { QueryOptions } from "../../../models/api/QueryOptions";
import { ResponsiveDataTable } from "../../../lib/components/table/ResponsiveDataTable";
import { Tag } from "../../../lib/components/tag/Tag";
import { TitledCardContainer } from "../../../lib/layouts/containers/card/TitledCardContainer";
import { Toggle } from "../../../lib/components/form/toggle/Toggle";
import { WarningPopup } from "../../../lib/components/popup/WarningPopup";
import { translate } from "../../../lib/infrastructure/i18n/InternationalizationService";
import { useGlobalLocation } from "../../../lib/infrastructure/location/LocationServices";
import { useHasPermissions } from "../../../lib/infrastructure/authorization/useHasLocationPermissions";
import { useServiceCallPro } from "../../../lib/hooks/useServiceCall";
import { useUpdatePageData } from "../../../lib/infrastructure/navigation/hooks/useUpdatePageData";

var evaluationMatricesSvc = new EvaluationMatricesService();

const topPermissions = [Permission.GLOBAL_ADMIN, Permission.LOCATION_ADMIN, Permission.HUMAN_RESOURCES];

export function EvaluationMatrixPage() {

  const updateRouteDate = useUpdatePageData();
  const navigate = useNavigate();
  const windowResize = useWindowResize();
  const openPopup = usePopup();
  const locationID = useGlobalLocation();
  const closePopup = useClosePopup();
  const createNotification = useCreateNotification();

  const { id: businessLineID, matrixId } = useParams();

  const [totalItems, setTotalItems] = useState<number>();
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [currentpage, setCurrentPage] = useState(0);

  //const [timer, setTimer] = useState<NodeJS.Timeout>();
  //const [searchWord, setSearchWord] = useState<string>();

  const [evaluationMatrix, setEvaluationMatrix] = useState<EvaluationMatrixSummaryDTO[]>([]);
  const [currentEvaluationMatrix, setCurrentEvaluationMatrix] = useState<EvaluationMatrixSummaryDTO>();


  const [evaluationMatrixVersions, setEvaluationMatrixVersions] = useState<EvaluationMatrixVersionSummaryDTO[]>([]);


  /*****************
   * SERVICE CALLS 
   ****************/

  const { isLoadingPermissions, hasPermission } = useHasPermissions(topPermissions);
  const getEvaluationMatrixByIdCall = useServiceCallPro(evaluationMatricesSvc.getEvaluationMatrixSummaryById);
  const getEvaluationMatrixVersionsCall = useServiceCallPro(evaluationMatricesSvc.getEvaluationMatrixVersions);

  const updateEvaluationMatrixStateCall = useServiceCallPro(evaluationMatricesSvc.updateMatrixState);


  useEffect(() => {
    if (!locationID || !businessLineID || !matrixId) return;

    getEvaluationMatrixById();
    getEvaluationMatrixVersions();

  }, [locationID, businessLineID, matrixId]);





  const getEvaluationMatrixById = useCallback(() => {

    if (!locationID && !businessLineID && !matrixId) return;

    getEvaluationMatrixByIdCall.invoke(`${locationID}`, "" + businessLineID, "" + matrixId)
      .then((data) => {
        if (data) {
          setEvaluationMatrix([data]);
          setCurrentEvaluationMatrix(data);
          updateRouteDate(["##AREA##", data.description]);
        }
      })
      .catch((error) => {
        if (!error) return;
        openPopup(<ErrorPopup>{error.response.data.message}</ErrorPopup>);
      });
  }, [locationID, businessLineID, matrixId, getEvaluationMatrixByIdCall, openPopup, updateRouteDate]);


  const getEvaluationMatrixVersions = useCallback(() => {

    if (!locationID && !businessLineID && !matrixId) return;

    var queryOpts: QueryOptions = {
      page: currentpage,
      pageLength: itemsPerPage
    };

    getEvaluationMatrixVersionsCall.invoke(`${locationID}`, "" + businessLineID, "" + matrixId, queryOpts)
      .then((data) => {
        if (data) {
          setEvaluationMatrixVersions(data.evaluationMatrixVersions);
          setTotalItems(data.totalItems);
        }
      })
      .catch((error) => {
        if (!error) return;
        openPopup(<ErrorPopup>{error.response.data.message}</ErrorPopup>);
      });
  }, [locationID, businessLineID, matrixId, currentpage, itemsPerPage, getEvaluationMatrixVersionsCall, openPopup]);



  const updateEvaluationMatrixState = useCallback((matrixId: number, isToDisable: boolean) => {

    if (!locationID || !businessLineID || !matrixId) return;

    updateEvaluationMatrixStateCall.invoke("" + locationID, "" + businessLineID, "" + matrixId, { state: isToDisable })
      .then((_) => {
        getEvaluationMatrixById();
        createNotification(<Notification type="success"
          title={translate("COMMON.SYSTEMPOPUPS.Success")}
          text={isToDisable ?
            translate("BUSINESSLINES.MATRIX.POPUP.DeactivateMatrixSuccess")
            : translate("BUSINESSLINES.MATRIX.POPUP.ActivateMatrixSuccess")} />
        );
      })
      .catch((error) => {
        if (!error) return;
        openPopup(<ErrorPopup>{error.response.data.message}</ErrorPopup>);
      });


  }, [businessLineID, createNotification, getEvaluationMatrixById, locationID, openPopup, updateEvaluationMatrixStateCall]);


  const handleOnPageAndItemsChanged = useCallback(
    (items: number, currentPage: number) => {
      setItemsPerPage(items);
      setCurrentPage(currentPage);
    },
    [setItemsPerPage, setCurrentPage]
  );

  const currentMatrixColumns: ColumnDefinition<EvaluationMatrixSummaryDTO>[] = useMemo(
    () => [
      {
        cellRenderProp: (v) => v.creationDate ? DateTime.fromISO(v.creationDate).toFormat("dd/MM/yyyy") : "",
        headerRender: translate("BUSINESSLINES.MATRIX.INFOANDFORM.CreationDate"),
        width: "20%",
        isMobilePrimaryCell: true,
      },
      {
        cellRenderProp: (v) => v.description,
        headerRender: translate("BUSINESSLINES.MATRIX.INFOANDFORM.Area"),
        isMobilePrimaryCell: true,
      },
      {
        cellRenderProp: (v) => v.manager.userDisplayName,
        headerRender: translate("BUSINESSLINES.MATRIX.INFOANDFORM.Manager"),
      },
    ], []);

  const renderEditMatrixState = useMemo(() => {

    if (!currentEvaluationMatrix) return null;

    if (!isLoadingPermissions && hasPermission) {
      return (
        <div className="toggle-div">
          <Toggle value={!currentEvaluationMatrix.isDisabled} />
          <div
            className="toggle-div-overlay"
            onClick={(ev) => {
              ev.stopPropagation();
              if (!currentEvaluationMatrix.isDisabled && currentEvaluationMatrix.id) {
                openPopup(
                  <WarningPopup
                    className="disable-evaluation-matrix-popup"
                    onDismissClicked={() => closePopup()}
                    onConfirmClicked={() => updateEvaluationMatrixState(currentEvaluationMatrix.id, true)}
                    dismissText={translate("COMMON.Cancel")}
                    confirmText={translate("COMMON.Deactivate")}
                  >
                    {translate("BUSINESSLINES.MATRIX.POPUP.DeactivateMatrixConfirmText")}
                  </WarningPopup>
                );
              }
              else {
                if (currentEvaluationMatrix.id) {
                  openPopup(
                    <WarningPopup
                      className="enable-evaluation-matrix-popup"
                      onDismissClicked={() => closePopup()}
                      onConfirmClicked={() => updateEvaluationMatrixState(currentEvaluationMatrix.id, false)}
                      dismissText={translate("COMMON.Cancel")}
                      confirmText={translate("COMMON.Activate")}
                    >
                      {translate("BUSINESSLINES.MATRIX.POPUP.ActivateMatrixConfirmText")}
                    </WarningPopup>
                  );
                }
              }
            }}
          />
        </div >)
    }
    else {
      return (<Tag
        text={currentEvaluationMatrix && currentEvaluationMatrix.isDisabled ? translate("COMMON.Disabled") : translate("COMMON.Enabled")}
        backgroundColor={currentEvaluationMatrix.isDisabled ? "status-light-grey" : "status-green"}
        isTiny={windowResize < 1024}
      />)
    }

  }, [closePopup, currentEvaluationMatrix, hasPermission,
    isLoadingPermissions, openPopup, updateEvaluationMatrixState, windowResize]);


  const columns: ColumnDefinition<EvaluationMatrixVersionSummaryDTO>[] = useMemo(
    () => [
      {
        cellRenderProp: (v) => v.creationDate ? DateTime.fromISO(v.creationDate).toFormat("dd/MM/yyyy") : "",
        headerRender: translate("BUSINESSLINES.MATRIX.INFOANDFORM.CreationDate"),
        isMobilePrimaryCell: true,
        width: "20%",
      },
      {
        cellRenderProp: (v) => v.managerName,
        headerRender: translate("BUSINESSLINES.MATRIX.INFOANDFORM.Manager"),
        width: "65%",
      },
      {
        cellRenderProp: (v) => v.countEvaluationPeriodMatrices,
        headerRender: translate("BUSINESSLINES.MATRIX.INFOANDFORM.EvaluationPeriods"),
      },
    ], []);




  return (
    <PagePermissions permissions={topPermissions} >
      <PageLayout
        tabTitle={translate("BUSINESSLINES.MATRIX.EvaluationMatrices")}
        pageTitle={translate("BUSINESSLINES.MATRIX.EvaluationMatrices")}
        className="evaluation-matrix"
        showBreadcrumb

      >

        {updateEvaluationMatrixStateCall.isLoading && <FullScreenLoader />}

        <TitledCardContainer
          className="current-matrix-card"
          title={translate("BUSINESSLINES.MATRIX.CurrentMatrix")}
          extraTitle={renderEditMatrixState}
        >

          <ResponsiveDataTable
            columnDefinitions={currentMatrixColumns}
            items={evaluationMatrix || []}
            totalitems={0}
            isLoading={getEvaluationMatrixByIdCall.isLoading}
            onClickRow={(matrix) => navigate(`versions/${matrix.matrixVersionId}/details`)}
          />
        </TitledCardContainer>

        <div className="evaluation-matrix-history">
          <div className="subtitle">{translate("BUSINESSLINES.MATRIX.INFOANDFORM.History")}</div>

          <ResponsiveDataTable
            columnDefinitions={columns}
            items={evaluationMatrixVersions || []}
            totalitems={totalItems || 0}
            paginationOptions={{ itemsPerPage: itemsPerPage, itemsPerPageOptions: [10, 20, 30] }}
            currentpage={currentpage}
            isLoading={getEvaluationMatrixVersionsCall.isLoading}
            onPageAndItemsChanged={handleOnPageAndItemsChanged}
            onClickRow={(version) => navigate(`versions/${version.evaluationMatrixVersionId}/details`)}
          />
        </div>
      </PageLayout>
    </PagePermissions>

  );
}
