export enum EvaluationOperationType {
    Unknown,
    SaveEvaluation,
    ConfirmEvaluation,
    CloseEvaluation,
    CalibrateEvaluation,
    RevertEvaluation,
    RejectEvaluation,
    NextRoleRecomendation,
    RemoveNextRoleRecomendation,
    AddActionItem,
    RemoveActionItem
}